import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { ModalController, NavController, PopoverController, ToastController } from '@ionic/angular';
import { 
  AuthService, 
  ViewService, ViewService as View,
} from 'common-lib';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';
import { TrackerAdmin } from 'src/classes/trackerAdmin';
import {ApiService} from "../../services/api.service";
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DataService } from 'src/services/data.service';
import Activity from 'src/classes/activity';
import { Platform } from 'src/classes/platform';

// import * as lodash from 'lodash';

@Component({
  selector: 'app-cc-emails-info',
  templateUrl: './cc-emails-info.page.html',
  styleUrls: ['./cc-emails-info.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CcEmailsInfoPage implements OnInit {
  
  string1 = `   fibb98@libero.it;EMAIL@wi-tek.com  bigg_@W.
  
  ;_aCapo@prov.c ; 5thmail@ `;
  string2 = ` fibb98@libero.it
  EMAIL@wi-tek.com
  bigg_@W.
  _aCapo@prov.c
  5thmail@`;
  
  /* @Input() myvar */
  listener = {
    view: null,
  };
  
  constructor(
    private cd: ChangeDetectorRef,
    private api: ApiService,
    private data: DataService,
    private modalCtrl: ModalController,
    private auth: AuthService,
    private nav: NavController,
    public popoverController: PopoverController,
    private route: ActivatedRoute,
    private router: Router,
    private toastCtrl: ToastController,
    private view: ViewService,
  ) {}

  ngOnInit() {}
  
  ionViewDidEnter() {
    this.listenerView()
  }

  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }
  
  // * feedback
  
  async presentToast(msg: string) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 2000,
      // position: "middle",
    });
    toast.present();
  }
  
  // * listener
  
  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
      setTimeout(() => {
        this.cd.markForCheck();
      }, 250);
    });
  }
  
  // * navigation
  
  dismiss(data?: any) {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss(data);
  }
  
  // * test
  
  consoleLog(i) {
    console.log('i', i)
  }
  
  

}
