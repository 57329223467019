import {
  Injectable, Pipe,
  PipeTransform
} from '@angular/core';

@Pipe({
  name: 'countdown'
})
@Injectable()
export class CountdownPipe implements PipeTransform {
  transform(ms: number, changed?: number): string {
    if(!ms) return '--';
    const remaining_ms: number = ((24*60*60*1000) + ms) - Date.now();
    if(remaining_ms > 0){
      const h: number = parseInt((remaining_ms / (60 * 60 * 1000)) + "");
      const m: number = parseInt(((remaining_ms - (h * (60*60*1000))) / (60 * 1000)) + "");
      return `<b class="text-success">Scade in ` + (h > 0 ? `${h > 9 ? h : '0' + h}:${m > 9 ? m : '0' + m} h</b>`: `00:${m > 9 ? m : '0' + m} h</b>`);
    }else{
      return `<b class="text-danger">Conversazione scaduta</b>`;
    }
  }
}


