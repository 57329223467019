import * as moment from 'moment';
import {Ticket} from './ticket';
import {Contact} from './contact';
export class WebMessage{
  _id: string;
  body: string;
  mediaUrl: string;
  message_type: string;
  dateSent_ms: string;
  numMedia: string;
  private _$media_url: string
  private _media_filename: string;
  private _$media_name: string;
  private _$media_is_img: boolean;
  private _$media_metadata: boolean;
  private _direction: string;
  private _$date_sent: string;
  private _id_ticket: string;
  private _$name: string;
  
  private _from: string;
  private _to: string;
  
  private _$contact: Contact;
  private _$ticket: Ticket;
  private _$customer_phone: string;
  private _chat_owner: string;
  
  
  private $getMediaInfo: (string) => Promise<{url: string, metadata: any}> = async () => {return {url: undefined, metadata: undefined}};
  private $getTicketById: (string) => Ticket = () => {return undefined};
  
  
  constructor(rawObj?: any, options? : { getMediaInfo?: (string) => Promise<{url: string, metadata: any}> , getTicketById?: (string) => Ticket}){
    this.setAllFields(rawObj, options);
  }
  
  setAllFields(rawObj: any, options?: {getMediaInfo?: (string) => Promise<{url: string, metadata: any}>, getTicketById?: (string) => Ticket}){
    if(options){
      if(options.getMediaInfo) this.$getMediaInfo = options.getMediaInfo;
      if(options.getTicketById) this.$getTicketById = options.getTicketById
    }
    if(rawObj){
      this._id = rawObj._id;
      this.body = rawObj.body;
      this.mediaUrl = rawObj.mediaUrl;
      this.message_type  = 'web';
      this.numMedia = rawObj.numMedia;
      this.media_filename = rawObj.media_filename;
      this.direction = rawObj.direction;
      this.$date_sent = rawObj.dateSent_ms;
      this.from = rawObj.from;
      this.to = rawObj.to;
      this.id_ticket = rawObj.id_ticket
    }
  }
  
  get $media_url(): string{
    return this._$media_url;
  }
  
  set $media_url(value: string){
    this._$media_url = value;
  }
  
  get direction(): string{
    return this._direction;
  }
  
  set direction(value: string){
    switch (value){
      case 'inbound':
        this._direction = 'inbound';
        break;
      case 'outgoing':
      case 'outbound':
      case 'outbound-api':
      case 'outbound-dial':
        this._direction = 'outbound';
        break;
    }
  }
  
  get media_filename(): string{
    return this._media_filename;
  }
  
  set media_filename(value: string){
    this._media_filename = value;
    if(this.media_filename){
      try{
        let aus: string[] = this.media_filename.split('/');
        if(aus.length > 0){
          this.$media_name = aus[aus.length - 1];
          if(this.$media_name){
            aus = this.$media_name.split('.');
            if(aus.length > 0){
              const extension: string = aus[aus.length - 1];
              this.$media_is_img = extension === 'jpg' || extension === 'png' || extension === 'jpeg' || extension === 'gif';
            }
          }
        }
      }catch(err){
        console.error(err);
      }
      
      this.$getMediaInfo(this.media_filename).then((data: {url: string, metadata: any}) => {
        this.$media_url = data.url;
        this.$media_metadata = data.metadata;
      }).catch((err) => {
        console.error(err);
      });
      
      
    }
  }
  
  get $media_name(): string{
    return this._$media_name;
  }
  
  set $media_name(value: string){
    this._$media_name = value;
  }
  
  get $media_is_img(): boolean{
    return this._$media_is_img;
  }
  
  set $media_is_img(value: boolean){
    this._$media_is_img = value;
  }
  
  get $media_metadata(): boolean{
    return this._$media_metadata;
  }
  
  set $media_metadata(value: boolean){
    this._$media_metadata = value;
  }
  
  get $date_sent(): string{
    return this._$date_sent;
  }
  
  set $date_sent(value: string){
    this._$date_sent = moment(value).format('DD/MM/YYYY HH:mm');
    this.dateSent_ms = value;
  }
  
  get from(): string{
    return this._from;
  }
  
  set from(value: string){
    if(value){
      const whatsappPreamble = 'whatsapp:';
      if (value.startsWith(whatsappPreamble)) {
        this._from = value.slice(whatsappPreamble.length);
      }else{
        this._from = value;
      }
    }
  }
  
  get to(): string{
    return this._to;
  }
  
  set to(value: string){
    this._to = value;
  }
  
  get id_ticket(): string {
    return this._id_ticket;
  }
  
  set id_ticket(value: string) {
    this._id_ticket = value;
    if(this.id_ticket){
      this.$ticket = this.$getTicketById(this.id_ticket);
      if(this.$ticket){
        this.$name = this.$ticket.$organization.name || this.$ticket.$organization.login_email_partecipant_owner
      }
    }
  }
  
  get $ticket(): Ticket {
    return this._$ticket;
  }
  
  set $ticket(value: Ticket) {
    this._$ticket = value;
  }
  
  get $contact(): Contact {
    return this._$contact;
  }
  
  set $contact(value: Contact) {
    this._$contact = value;
  }
  
  get $name(): string {
    return this._$name;
  }
  
  set $name(value: string) {
    this._$name = value;
  }
  
  
  get $customer_phone(): string {
    return this._$customer_phone;
  }
  
  set $customer_phone(value: string) {
    this._$customer_phone = value;
  }
  
  get chat_owner(): string {
    return this._chat_owner;
  }
  
  set chat_owner(value: string) {
    this._chat_owner = value;
  }
  
}
