import {Platform} from './platform';
import {OrganizationAdmin} from './organizationAdmin';
import {Admin} from './admin';
import {TwilioCall} from './twilioCall';
import {WebMessage} from './webMessages';
import {WhatsappMessage} from './whatsappMessage';
import {Contact} from './contact';
import Company from './company';
import {ParticipantAdmin} from './participantAdmin';
import Activity from './activity';
import {EmailMessage} from './emailMessage';

export enum TicketTypes{
  Commerciale = 1,
  Tecnico = 2,
  Amministrativo = 3,
  Indefinito = 99
}

export enum TicketSubTypes{
  Problema_localizzatore = 4,
  Sostituzione = 5
}


export enum TicketStatus{
  Aperto = 1,
  Lavorazione = 2,
  Chiuso = 3
}

type Options = {
  getPlatformByType: (platform_type: number) => Platform,
  getOrganizationById: (id_organization: string) => OrganizationAdmin,
  getCompanyByOrganizationId: (id_organization: string) => Company,
  getAdminById: (id_admin: string) => Admin
  getContactById: (id_contact: string) => Contact;
  getParticipantByTrackerImei: (imei: string) => ParticipantAdmin
  getMediaInfo: (string) => Promise<{url: string, metadata: any}>
  getTicketById: (string) => Ticket
}

export class Ticket {
  _id: string;
  private _platform_type: number;
  private _id_organization: string;
  num: string;
  title: string;
  id_company: string;
  type: TicketTypes;
  sub_type: TicketSubTypes;
  private _id_admin: string;
  important: boolean;
  is_visible: boolean;
  to_read_client: boolean;
  unread_messages: number;
  unread_calls: number;
  timestamp_start: number;
  timestamp_end: number;
  private _contacts: any;
  private _contacts_email: any;
  private _status: TicketStatus;
  last_update: number;
  pinned: boolean;
  private _trackers: string[];
  private _trackers_replace: string[];
  id_order: string;
  note: string;
  $contacts: Contact[] = [];
  $contacts_email: Contact[] = [];
  $company: Company;
  $platform: Platform;
  $organization: OrganizationAdmin;
  $admin: Admin;
  $calls: TwilioCall[];
  $has_more_calls: boolean;
  $messages: (WebMessage | WhatsappMessage | EmailMessage)[];
  $tracker_participants: ParticipantAdmin[];
  $tracker_replace_participants: ParticipantAdmin[];
  _$is_closed: boolean;
  private _$last_message: (WebMessage | WhatsappMessage | EmailMessage);
  private _$last_message_whatsapp: (WebMessage | WhatsappMessage | EmailMessage);
  
  $next_activity: number; // Attività che scade prima con questo ticket (caricata in pagine specifiche come tickets.ts)
  
  $getPlatformByType: (platform_type: number) => Platform = () => {return undefined};
  $getOrganizationById: (id_organization: string) => OrganizationAdmin = () => {return undefined};
  $getCompanyByOrganizationId: (id_organization: string) => Company = () => {return undefined};
  $getAdminById: (id_admin: string) => Admin = () => {return undefined};
  $getContactById: (id_contact: string) => Contact = () => {return undefined};
  $getParticipantByTrackerImei: (imei: string) => ParticipantAdmin = () => {
    return undefined
  };
  $getMediaInfo: (string) => Promise<{url: string, metadata: any}> = async () => {return {url: undefined, metadata: undefined}};
  $getTicketById: (string: string) => Ticket = () => {return undefined};
  
  constructor(data?: any, options?: Options) {
    if(data){
      this.setData(data, options);
    }
  }
  
  setData(data: any, options?: Options){
    if(options?.getPlatformByType) this.$getPlatformByType = options.getPlatformByType;
    if(options?.getOrganizationById) this.$getOrganizationById = options.getOrganizationById;
    if(options?.getCompanyByOrganizationId) this.$getCompanyByOrganizationId = options.getCompanyByOrganizationId;
    if(options?.getAdminById) this.$getAdminById = options.getAdminById;
    if(options?.getContactById) this.$getContactById = options.getContactById;
    if(options?.getParticipantByTrackerImei) this.$getParticipantByTrackerImei = options.getParticipantByTrackerImei;
    if(options?.getMediaInfo) this.$getMediaInfo = options.getMediaInfo;
    if(options?.getTicketById) this.$getTicketById = options.getTicketById;
    this._id = data._id;
    this.num = data.num;
    this.title = data.title;
    this.pinned = data.pinned;
    this.platform_type = data.platform_type;
    this.id_organization = data.id_organization;
    this.id_company = data.id_company;
    this.type = data.type;
    this.sub_type = data.sub_type;
    this.status = data.status;
    this.id_admin = data.id_admin;
    this.last_update = data.last_update;
    this.important = data.important;
    this.is_visible = data.is_visible;
    this.to_read_client = data.to_read_client;
    this.unread_messages = data.unread_messages;
    this.unread_calls = data.unread_calls;
    this.timestamp_start = data.timestamp_start;
    this.timestamp_end = data.timestamp_end;
    this.contacts = data.phone_contacts;
    this.contacts_email = data.email_contacts;
    this.trackers = data.trackers;
    this.trackers_replace = data.trackers_replace;
    this.note = data.note;
    this.id_order = data.id_order;
  }
  
  get contacts(): any {
    return this._contacts;
  }
  
  set contacts(value: any) {
    this._contacts = value;
    this.$contacts = [];
    if(this.contacts){
      for(const id_contact in this.contacts){
        const contact: Contact = this.$getContactById(id_contact);
        if(contact){
          this.$contacts.push(contact);
          if(!this.$company) this.$company = contact.$company;
        }
      }
    }
  }
  
  get contacts_email(): any {
    return this._contacts_email;
  }
  
  set contacts_email(value: any) {
    this._contacts_email = value;
    this.$contacts_email = [];
    if(this.contacts_email){
      for(const id_contact in this.contacts_email){
        const contact: Contact = this.$getContactById(id_contact);
        if(contact){
          //TODO: nel caso avesse sia conctact che contact mail?
          this.$contacts_email.push(contact);
          if(!this.$company) this.$company = contact.$company;
        }
      }
    }
  }
  
  get platform_type(): number {
    return this._platform_type;
  }
  
  set platform_type(value: number) {
    this._platform_type = value;
    if(this.platform_type){
      this.$platform = this.$getPlatformByType(this.platform_type);
    }
  }
  
  get id_organization(): string {
    return this._id_organization;
  }
  
  set id_organization(value: string) {
    this._id_organization = value;
    if(this.id_organization){
      this.$organization = this.$getOrganizationById(this.id_organization);
      if(!this.$company) this.$company = this.$getCompanyByOrganizationId(this.id_organization);
    }
  }
  
  get id_admin(): string {
    return this._id_admin;
  }
  
  set id_admin(value: string) {
    this._id_admin = value;
    if(this.id_admin){
      this.$admin = this.$getAdminById(this.id_admin);
    }
  }
  
  get trackers(): string[] {
    return this._trackers;
  }
  
  set trackers(value: string[]) {
    this._trackers = value;
    this.$tracker_participants = [];
    if(this.trackers){
      for(const imei of this.trackers){
        const participant: ParticipantAdmin = this.$getParticipantByTrackerImei(imei);
        if(participant) this.$tracker_participants.push(participant);
      }
    }
  }
  
  get trackers_replace(): string[] {
    return this._trackers_replace;
  }
  
  set trackers_replace(value: string[]) {
    this._trackers_replace = value;
    this.$tracker_replace_participants = [];
    if(this.trackers_replace){
      for(const imei of this.trackers_replace){
        const participant: ParticipantAdmin = this.$getParticipantByTrackerImei(imei);
        if(participant) this.$tracker_replace_participants.push(participant);
      }
    }
  }
  
  haveContact(id_contact): boolean{
    return this.contacts?.hasOwnProperty(id_contact) || this.contacts_email?.hasOwnProperty(id_contact) ;
  }
  
  updateMessagesInTicketFromApiResponse(rawsObject: {
    whatsapp: any[],
    web: any[],
    email: any[],
  }, update?: boolean){
    if(!update){
      this.$messages = [];
    }
    if(rawsObject){
      for(let raw of rawsObject.whatsapp){
        //ho la lista di messaggi, se sono messaggi whatsapp devo ottenere il numero del customer e ottenere il contatto che ha quel numero
        const message: WhatsappMessage = new WhatsappMessage(raw, {
          getAdmin: this.$getAdminById,
          getMediaInfo: this.$getMediaInfo,
          getTicketById: this.$getTicketById,
        });
        this.$messages.push(message);
      }
      for(let raw of rawsObject.web){
        const message: WebMessage = new WebMessage(raw, {getMediaInfo: this.$getMediaInfo, getTicketById: this.$getTicketById});
        this.$messages.push(message);
      }
      for(let raw of rawsObject.email){
        //ho la lista di email, se sono messaggi email devo ottenere la mail del customer e ottenere il contatto che ha quella mail
        const message: EmailMessage = new EmailMessage(raw, {getMediaInfo: this.$getMediaInfo, getTicketById: this.$getTicketById});
        this.$messages.push(message);
      }
      
      if(this.$messages.length > 0 ){
        this.$last_message = this.$messages[0];
        this.$last_message_whatsapp = this.$messages.find((elem) => elem.message_type === 'whatsapp');
      }
    }
  }
  
  
  get status(): number{
    return this._status;
  }
  
  set status(value: number){
    this._status = value;
    if(this.status){
      this.$is_closed = value === 3;
    }
  }
  
  isOpen(): boolean{
    return this.status === 1 || this.status === 2;
  }
  
  get $is_closed(): boolean {
    return this._$is_closed;
  }
  
  set $is_closed(value: boolean) {
    this._$is_closed = value;
  }
  
  haveChatWithAdmin(){
  
  }
  
  get $last_message(): WebMessage | WhatsappMessage | EmailMessage {
    return this._$last_message;
  }
  
  set $last_message(value: WebMessage | WhatsappMessage | EmailMessage) {
    this._$last_message = value;
  }
  
  get $last_message_whatsapp(): WebMessage | WhatsappMessage | EmailMessage {
    return this._$last_message_whatsapp;
  }
  
  set $last_message_whatsapp(value: WebMessage | WhatsappMessage | EmailMessage) {
    this._$last_message_whatsapp = value;
  }
  
}
