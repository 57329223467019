import {Component, Input, OnInit} from '@angular/core';
import {Order} from '../../classes/order';
import {ToastController} from '@ionic/angular';

@Component({
  selector: 'app-shipping-info',
  templateUrl: './shipping-info.component.html',
  styleUrls: ['./shipping-info.component.scss'],
})
export class ShippingInfoComponent implements OnInit {

  @Input() order: Order;
  
  constructor(
    private toastController: ToastController
  ) { }

  ngOnInit() {}
  
  async copyText(value: string){
    navigator.clipboard.writeText(value);
    const toast = await this.toastController.create({
      message: 'Copiato!',
      duration: 1500,
      color: 'primary',
      position: 'top'
    });
  
    toast.present();
  }

}
