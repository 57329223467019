import { Injectable } from '@angular/core';
import {ApiService} from './api.service';
import {environment} from '../environments/environment';
import { AuthService } from 'common-lib';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SwService {
  
  static registration: any;
  static service_worker: any;
  static channel: BroadcastChannel = new BroadcastChannel('message_channel');
  
  static remoteUpdate = new Subject<any>();
  static remoteUpdate$ = SwService.remoteUpdate.asObservable();
  
  constructor(
    private api: ApiService
  ) {}
  
  async initialize(){
    return new Promise(async (resolve) => {
      
      // Ascolto i messaggi in broadcast con i dati gestiti dal service-worker
      SwService.channel.onmessage = (event: any) => {
        this.manageServiceWorkerMessage(event)
      };
      
      SwService.registration = await navigator.serviceWorker.register('service-worker.js');
      SwService.service_worker = SwService.registration.active || SwService.registration.installing;
  
      setTimeout(() => {
        if(SwService.registration.waiting){
          console.log('Nuova versione sw in attesa');
          SwService.service_worker = SwService.registration.waiting;
          SwService.service_worker.postMessage({ type: 'skipWaiting' });
        }
      }, 20000);
  
      if(SwService.service_worker.state !== 'activated'){
        console.log('service-worker --> non ancora attivo');
        SwService.service_worker.onstatechange = () => {
          console.log(`service-worker --> ${SwService.service_worker.state}`);
          switch (SwService.service_worker.state) {
            case 'activated':
              console.log('service-worker --> attivo');
              SwService.service_worker.onstatechange = undefined;
              this.sendApiParams(this.api.baseUrl, AuthService.getEmail(), AuthService.getUID());
              return resolve();
          }
        }
      }else{
        console.log('service-worker --> attivo');
        this.sendApiParams(this.api.baseUrl, AuthService.getEmail(), AuthService.getUID());
        return resolve();
      }
    });
  }
  
  sendApiParams(baseUrl: string, email: string, uid: string){
    SwService.service_worker.postMessage({type: 'firebase_configuration', data: environment.firebaseConfig});
    SwService.service_worker.postMessage({type: 'api_configuration', data: {baseUrl: baseUrl, email: email, uid: uid, production: environment.production}});
  }
  
  manageServiceWorkerMessage(event: any){
    console.log('Messaggio ricevuto da SW', event.data);
    if(!event.data || !event.data.type) return;
    switch (event.data.type) {
      case 'organizations': {
        if(!event.data.data) return;
        SwService.remoteUpdate.next(event.data);
        break;
      }
      case 'participants': {
        if(!event.data.data) return;
        SwService.remoteUpdate.next(event.data);
        break;
      }
    }
  }

}
