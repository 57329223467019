import {Admin} from './admin';

export class SmsToTracker {
  
  _id: string;
  private _id_admin: string;
  state: number;
  type: string;
  priority: number;
  sms_body: string;
  tracker_phone: string;
  request_date: number;
  sent_date: number;
  response_date: number;
  
  private $getAdmin: (string) => Admin = () => {return undefined};
  private _$admin: Admin;
  
  constructor(rawObj?: any, options? : {
    getAdmin?: (string) => Admin
  }) {
    this.setAllFields(rawObj, options);
  }
  
  setAllFields(rawObj: any, options? : {
    getAdmin?: (string) => Admin
  }) {
    if(options){
      if(options.getAdmin) this.$getAdmin = options.getAdmin;
    }
    if (rawObj) {
      this._id = rawObj._id;
      this.type = rawObj.type;
      this.state = rawObj.state;
      this.priority = rawObj.priority;
      this.id_admin = rawObj.id_admin;
      this.sms_body = rawObj.sms_body;
      this.tracker_phone = rawObj.tracker_phone;
      this.request_date = rawObj.request_date;
      this.sent_date = rawObj.sent_date;
      this.response_date = rawObj.response_date;
    }
  }
  
  get id_admin(): string {
    return this._id_admin;
  }
  
  set id_admin(value: string) {
    this._id_admin = value;
    if(this.$getAdmin){
      this.$admin = this.$getAdmin(this.id_admin);
    }
  }
  
  set $admin(value: Admin){
    this._$admin = value;
  }
  
  get $admin(): Admin{
    return this._$admin;
  }
}
