import { Injectable } from '@angular/core';
import {ApiService} from './api.service';
import Migration, {MigrationBatch, MigrationStatus, TrackersTrackingram, UserTrackingram} from '../classes/migration';
import {Subject} from 'rxjs';
import {DataService} from './data.service';
import {TrackerAdmin} from '../classes/trackerAdmin';

@Injectable({
  providedIn: 'root'
})
export class MigrationService {
  
  public trackingram_user: UserTrackingram[] = [];
  public trackingram_tracker: TrackersTrackingram[] = [];
  public migration_status: MigrationStatus[] = []
  public mapUserTracker: Map<string, TrackersTrackingram[]>= new Map();
  public trackers: TrackerAdmin[] = [];
  public dataReady: boolean = false;
  static dataReady: boolean;
  
  static ready = new Subject<any>();
  static ready$ = MigrationService.ready.asObservable();
  
  public batch: MigrationBatch[] = [];
  
  
  static array = {
    migration_status: <MigrationStatus[]> [],
  }
  
  atrack_price_list_subscription: any;
  
  constructor(
    private api: ApiService,
    private data: DataService
  ) { }
  
  async init(){
    try{
      await DataService.isReady();
  
      this.clearData();
  
      this.dataReady = false;
      await this.getWhiteLabelTrackers(0, 3000);
      await this.getTrackingramTrackers(0, 3000);
      await this.getMigrationStatus(0, 3000);
      await this.getTrackingramUser(0, 3000);
      await this.creteUserTrackerMap();
      await this.getAtrackSubscriptionPrice()
      await this.getMigrationBatch()
      this.dataReady = true;
      
      MigrationService.dataReady = true;
      MigrationService.ready.next();
    }catch (err) {
      console.error('Error in migration service', err)
    }
  }
  
  /**
   * Metodo utilizzato per ottenere lo stato di migrazione
   */
  async getMigrationStatus(skip: number, limit: number){
    const res: any = await this.api.getTrackingramMigrationStatus(skip, limit);
    if(res?.data){
      for(let raw of res.data){
        //loop su trackers
        this.migration_status.push(new MigrationStatus(raw, {getTrackerWhiteLabel: this.getTracker.bind(this), getTrackerTrackingram: this.getTrackerTrackingram.bind(this)}))
        
        MigrationService.array.migration_status.push(new MigrationStatus(raw, {getTrackerWhiteLabel: this.getTracker.bind(this), getTrackerTrackingram: this.getTrackerTrackingram.bind(this)}));
        
      }
    }
    if(res.has_more){
      return await this.getTrackingramTrackers((skip + res.data.length), limit);
    }
  }
  
  /**
   * Metodo per ottenere la lista di tutti i tracker di trackingram
   * @param skip
   * @param limit
   */
  async getTrackingramTrackers(skip: number, limit: number){
    const res: any = await this.api.getTrackingramTrackers(skip, limit);
    if(res?.data){
      for(let raw of res.data){
        //loop su trackers
        this.trackingram_tracker.push(new TrackersTrackingram(raw))
      }
    }
    if(res.has_more){
      return await this.getTrackingramTrackers((skip + res.data.length), limit);
    }
  }
  
  
  /**
   * Metodo utilizzato per ottenere tutti gli utenti trackingram
   * @param skip
   * @param limit
   */
  async getTrackingramUser(skip: number, limit: number){
    const res: any = await this.api.getTrackingramUsers(skip, limit);
    if(res?.data){
      for(let raw of res.data){
        //loop su utenti trackingram
        this.trackingram_user.push(new UserTrackingram(raw, {getMigrationStatus: this.getMigrationStatusByUserId.bind(this), hasCanBus: this.hasCanBus.bind(this), getTrackersWhiteLabel: this.getTrackers.bind(this)}))
      }
    }
    if(res.has_more){
      return await this.getTrackingramUser((skip + res.data.length), limit);
    }
    console.log(this.trackingram_user)
  }
  
  
  /**
   * Ottengo lo stato della migrazione
   * @param user_trackingram_id
   */
  getMigrationStatusByUserId(user_trackingram_id: string): MigrationStatus{
    let migration_obj: MigrationStatus;
    for(let migration of MigrationService.array.migration_status){
      if(migration.id_user_trackingram === user_trackingram_id){
        migration_obj = migration; //scelgo questa migration come quella associata all'utente, utilizzo il for in quanto voglio ottenere l'ultima come quella valida.
      }
    }
    
    return migration_obj;
  }
  
  getMigrationStatusByWhiteLabelUserId(user_white_label_id: string): MigrationStatus{
    let migration_obj: MigrationStatus;
    for(let migration of MigrationService.array.migration_status){
      if(migration.id_organization_whitelabel === user_white_label_id){
        migration_obj = migration; //scelgo questa migration come quella associata all'utente, utilizzo il for in quanto voglio ottenere l'ultima come quella valida.
      }
    }
    
    return migration_obj;
  }
  
  /**
   * Ottengo lo stato della migrazione
   * @param migrationId
   */
  getMigrationStatusById(migrationId: string): MigrationStatus{
    let migration_obj: MigrationStatus;
    for(let migration of MigrationService.array.migration_status){
      if(migration._id === migrationId){
        migration_obj = migration; //scelgo questa migration come quella associata all'utente, utilizzo il for in quanto voglio ottenere l'ultima come quella valida.
      }
    }
    
    return migration_obj;
  }
  
  
  /**
   * Verifico che ha il can bus
   */
  hasCanBus(user_trackingram_id: string): boolean{
    if(this.mapUserTracker.has(user_trackingram_id)){
      const trackers = this.mapUserTracker.get(user_trackingram_id)
      if(trackers){
        for(let tracker of trackers){
          if(tracker.accessoryTrackerGps_can_bus){
            return true;
          }
        }
      }
    }
    return false;
  }
  
  /**
   * Creo un mappa tra user e tracker trackingram
   */
  async creteUserTrackerMap(){
    this.trackingram_tracker.map((tracker)=>{
      const trackingram_user_id = tracker.idUser;
      if (this.mapUserTracker.has(trackingram_user_id)) {
        this.mapUserTracker.get(trackingram_user_id).push(tracker);
      } else {
        this.mapUserTracker.set(trackingram_user_id, [tracker]);
      }
    })
  }
  
  
  /**
   * Metodo utilizzato per ottenere il conteggio dei tracker in map
   * @param user_trackingram_id
   */
  countTracker(user_trackingram_id: string){
    if(this.mapUserTracker.has(user_trackingram_id)){
      return this.mapUserTracker.get(user_trackingram_id).length;
    }
    return 0;
  }
  
  /**
   * Metodo utilizzato per ottenere il conteggio dei tracker in map
   */
  countWhiteLabelTracker(user: UserTrackingram){
    return user.$white_label_trackers?.length || 0
  }
  
  countWhiteLabelTrackerProgrammed(user: UserTrackingram){
   let counter = 0;
    if(!user.$white_label_trackers){
      return counter;
    }
   for(let tracker of user.$white_label_trackers){
     if(tracker.isProgrammed){
       counter++;
     }
   }
   return counter;
  }
  
  
  clearData(){
    this.mapUserTracker = new Map();
    this.migration_status = [];
    this.trackingram_tracker = [];
    this.trackingram_user = [];
    MigrationService.array.migration_status = []
  }
  
  /**
   * Ottengo i prezzi per la subscription del localizzatore
   */
  getAtrackSubscriptionPrice(){
    //schiano come platform la  2, cosi ottengo la lista di prezzi
    let subscription_price = DataService.general_parameter.stripe.find(elem => elem.platform_type === 2);
    if(subscription_price){
      //ho la lista di prezzi in abbonamento
      const subscription_plan_list = subscription_price.plans
      //devo trovare il plan con type_tracker 3 cio'è atrak
      const atrack_price_list = subscription_plan_list.find(elem => elem.type_trackerGps === 3)
      this.atrack_price_list_subscription = atrack_price_list.plan_id_list
    }
  }
  
  /**
   * Dice se è terminato il caricamento dei dati iniziali
   */
  static isReady(){
    return new Promise((resolve) => {
      if(MigrationService.dataReady) return resolve();
      let listener: any = MigrationService.ready$.subscribe(() => {
        listener.unsubscribe();
        return resolve();
      });
    });
  }
  
  /**
   * Metodo utilizzato per capire se è in migrazione
   */
  isInMigration(trackingram_user: UserTrackingram): boolean{
    if(trackingram_user.$migration_status?.steps && Object.keys(trackingram_user.$migration_status.steps).length > 0 ){
      return true
    }
    return false;
  }
  
  
  /**
   * Metodo per ottenere l'utimo stato di migrazione
   * @param trackingram_user
   */
  lastMigrationStatus(trackingram_user: UserTrackingram): string{
    if(trackingram_user.$migration_status?.steps){
      const arr_keys = Object.keys(trackingram_user.$migration_status.steps);
      return arr_keys[arr_keys.length - 1]
    }
  }
  
  /**
   * Ottengo la lista di trackers
   */
  getWhiteLabelTrackers(skip: number, limit: number) {
    return new Promise((resolve, reject) => {
      this.api.getTrackers(skip, limit).then(async (res: any) => {
        if (res.data) {
          
          if (!this.trackers) {
            this.trackers = [];
          }
          
          for (let raw of res.data) {
            this.trackers.push(new TrackerAdmin(raw, {
              getPlatform: this.data.getPlatformByType,
              getTypeTracker: this.data.getTypeTrackerGps,
              getTypeSim: this.data.getTypeSim
            }));
          }
        }
        
        if (!res.has_more) {
          return resolve();
        } else {
          return resolve(this.getWhiteLabelTrackers((skip + res.data.length), limit));
        }
        
      });
    });
  }
  
  /**
   * Ottengo i tracker white label
   * @param trackers
   */
  getTrackers(trackers: any[]): TrackerAdmin[]{
    let res: TrackerAdmin[] = [];
    if(trackers && trackers.length){
      for(let t of trackers){
        let tracker_find = this.trackers.find(elem => elem.imei === t.imei)
        if(tracker_find){
          res.push(tracker_find)
        }
        
      }
      
    }
    return res
  }
  
  /**
   * Ottengo i tracker whitelabel
   * @param imei
   */
  getTracker(imei: string){
    return this.trackers.find(elem => elem.imei === imei)
  }
  
  
  /**
   * Ottengo i tracker trackingram
   * @param id_trackingram
   */
  getTrackerTrackingram(id_trackingram: string){
    return this.trackingram_tracker.find((elem) => {return elem._id === id_trackingram} )
  }
  
  
  
  /**
   * Verifico se l'utente puo entrare in lista migrazione
   * @param trackingram_user
   */
  isValidMigration(trackingram_user: UserTrackingram){
    return trackingram_user.$white_label_trackers || this.mapUserTracker.has(trackingram_user._id)
  }
  
  /**
   * Ottengo il price_id suggerito per l'utente trackingram prima della migrazione
   * @param target_price
   */
  priceSuggestion(target_price) {
    if (this.atrack_price_list_subscription.length === 0) {
      return null; // La lista dei prezzi è vuota, nessun prezzo da suggerire
    }
  
    this.atrack_price_list_subscription.sort((a, b) => {
      return a.price - b.price;
    });
    
    let prezzoScelto = this.atrack_price_list_subscription[0]; // Inizializza con il primo prezzo
    
    for(const price of this.atrack_price_list_subscription){
      if(price.price * 100 > target_price) break;
      prezzoScelto = price;
    }
    
    return prezzoScelto;
  }
  
  /**
   * Ottengo il prezzo di default per migrazione
   */
  getDefaultTrackerPrice(){
    if(this.atrack_price_list_subscription){
      const default_price = this.atrack_price_list_subscription.find(elem => elem.is_default)
      if(default_price){
        return default_price.stripe_plan_id
      }
    }
    return undefined;
  }
  
  
  getUserTrackingramByEmail(email: string){
    return this.trackingram_user.find((elem)=>elem.email === email)
  }
  
  /**
   * Metodo per ottenere la lista di batch della migration
   */
  getMigrationBatch(){
    return new Promise((resolve, reject) => {
      this.api.getMigrationBatch().then(async (res: any) => {
        if (res.data) {
          this.batch = [];
          for (let raw of res.data) {
            //per ogni migration batch instanzion un nuovo elemento
            this.batch.unshift(new MigrationBatch(raw, {
              getUserTrackingramByEmail: this.getUserTrackingramByEmail.bind(this),
              getUserWhiteLabel: this.data.getOrganizationByEmailOwner,
              getMigrationStatus: this.getMigrationStatusByWhiteLabelUserId,
            }));
          }
        }
      
        return resolve();
      });
    });
  }
  
  getUncompletedBatch(){
    return this.batch.find((elem)=> elem.$status === 1)
  }
  
  hasWindSim(user_trackingram_id: string){
    if(this.mapUserTracker.has(user_trackingram_id)){
      const trackers = this.mapUserTracker.get(user_trackingram_id)
      if(trackers){
        for(let tracker of trackers){
          if(tracker.typeSim_trackerGps === 1){
            return true;
          }
        }
      }
    }
    return false;
  }
  
  
  batchHasWindSim(batch: MigrationBatch){
    if(batch.$user_trackingram && batch.$user_trackingram.length > 0){
      for(let user of batch.$user_trackingram){
        const userHasWindSim = this.hasWindSim(user._id)
        if(userHasWindSim){
            return true;
        }
      }
    }
    
    return false;
  }
  
  countMigationInProgressBatch(batch: MigrationBatch){
    let counter = 0;
    if(batch && batch.$user_trackingram.length > 0){
      
      for(let user of batch.$user_trackingram){
        if(user.$migration_status?.automation_process_completed && user?.$count_tracker_white_label_programmed !== user?.$white_label_trackers?.length){
          counter++;
        }
      }
    }
    return counter;
  }
  
  countMigationEndBatch(batch: MigrationBatch){
    let counter = 0;
    if(batch && batch.$user_trackingram.length > 0){
      
      for(let user of batch.$user_trackingram){
        if(user.$migration_status?.automation_process_completed && user?.$count_tracker_white_label_programmed === user?.$white_label_trackers?.length){
          counter++;
        }
      }
    }
    return counter;
  }
  
  getBatchError(){
    return this.batch.map((elem)=>{
      elem.batch_delete === true;
    })
  }
  
  hasBatchError(){
    for(let batch of this.batch){
      if(batch.batch_delete === true){
        return true
      }
    }
    return false;
  }
  
  
  getBatchById(id: string){
    return this.batch.find(elem => elem._id === id);
  }
  
}
  
  

