import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Call, Device} from '@twilio/voice-sdk';
import {ViewService} from 'common-lib';
import {TwilioCall} from '../classes/twilioCall';
import {DataService} from './data.service';
import {Utility} from '../classes/utility';
import {Admin, AdminRole} from '../classes/admin';
import {Subject} from 'rxjs';
import {Storage} from '@ionic/storage';
import {AlertController, ToastController} from '@ionic/angular';
import Codec = Call.Codec;

@Injectable({
  providedIn: 'root'
})
export class TwilioService {

  client: Device;
  
  counter_calls: number = 0;
  client_calls: Map<string, TwilioCall>;
  calls: TwilioCall[]; // Oggetti interni nostri TwilioCall
  current_call: TwilioCall; // Oggetto call di twilio
  
  admins_listeners: Admin[]; // Lista degli admin che fanno il redirect sul client attualmente in uso
  admins_not_listeners: Admin[]; // Lista degli admin che fanno il redirect sul client attualmente in uso
  
  static setInputDevice = new Subject<any>()
  static setInputDevice$ = TwilioService.setInputDevice.asObservable()
  
  static updateAdmin = new Subject<string>();
  static updateAdmin$ = TwilioService.updateAdmin.asObservable();
  
  interval_alive: any;
  
  static init_client_channel: BroadcastChannel = new BroadcastChannel('init_client'); // Canale che rimane attivo per inibire la creazione del client twilio su altri tab dello stesso browser
  static init_client_response_channel: BroadcastChannel = new BroadcastChannel('init_client_response'); // Canale ausiliario che serve solo la prima volta per capire se ci sono altri client twilio già aperti in altri tab dello stesso browser;
  
  constructor(
    private api: ApiService,
    private data: DataService,
    private storage: Storage,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private view: ViewService
  ) {}
  
  /** Inizializza il client di twilio in modo da poter ricevere ed effettuare chiamate **/
  async initClient(){
    try{
      
      this.calls = [];
      this.client_calls = new Map<string, TwilioCall>();
      this.counter_calls = 0;
      
      DataService.remoteUpdate$.subscribe((data: any) => {
        if(data.element === 'call') this.manageCallUpdate(data);
        if(data.element === 'admin') this.manageAdminUpdate(data);
      });
  
      DataService.updateData$.subscribe((data: any) => {
        if(data.type === 'contact' || data.type === 'ticket') this.countUnreadCallForAdmins(); // Se arriva un aggiornamento di compoany riparte il conteggio delle call non lette
      });
  
      this.api.getAdminsListenerCalls().then((res: any) => {
        this.admins_listeners = [];
        this.admins_not_listeners = [];
        if(res){
          for(const raw of res){
            const admin: Admin = this.data.getAdminById(raw._id);
            if(admin) this.admins_listeners.push(admin);
          }
  
          if(this.data.current_admin.role === AdminRole.Superadmin){
            //Ottengo la lista di tutti gli admin se sono super admin
            for(const admin of DataService.array.admin){
              if(!admin.call_center && admin._id !== this.data.current_admin._id){
                this.admins_not_listeners.push(admin);
              }
            }
          }
          
        }
        
        this.countUnreadCallForAdmins();
  
        this.updateAdminCallsList(0, 100);
  
      }).catch(err => {
        console.error(err);
      });
    
      
      
      // Chiede al server di generare un token per creare il client voice di twilio
      const response: any = await this.api.getTwilioDeviceAccessToken();

      // Prima di creare il client di twilio verifica che non ce ne siano altri già aperti in altri tab, mandando un messaggio in broadcast e attendendo una eventuale risposta per 1 secondo. Se nessun altro tab risponde allora posso avviare il client di twilio;
      let ho_altri_client: boolean;
      const tab_response_listener: any = (event: any) => {
        console.log('Messaggio risposta in broadcast', event);
        ho_altri_client = true;
      }
      TwilioService.init_client_response_channel.addEventListener('message', tab_response_listener);
      TwilioService.init_client_channel.postMessage('Ho altri client?');
      
      setTimeout(async () => {
        TwilioService.init_client_response_channel.removeEventListener('message', tab_response_listener);
        if(!ho_altri_client){
          this.client = new Device(response.token, {
            closeProtection: true,
            maxAverageBitrate: 39000,
            tokenRefreshMs: 10000, // 10 s prima che scada il token ci manda un evento, che ci permette di fare il refresh del token
            codecPreferences: [Codec.PCMU], // E`il codec con miglior qualità audio
            allowIncomingWhileBusy: true // Altrimenti non arrivano le chiamate quando il client è in chiamata in corso
          });
          this.client.on("registered", async () => {
            console.log("Twilio client Ready to make and receive calls!", this.client);
  
            // Una volta che il client è registrato attivo il listener per evitare l'attivazione di altri client
            TwilioService.init_client_channel.addEventListener('message', TwilioService.other_clients_listener);
            TwilioService.changeFavicon('assets/imgs/favicon_io/android-chrome-512x512.png');
  
            // Attivazione interval che imposta lo stato del client come online (viene rimosso quando il client di chiude)
            await this.updateClientOnlineStatus();
            this.interval_alive = setInterval(() => {
              this.updateClientOnlineStatus()
            }, 10000); // Refresh ogni 30 s
            
            ViewService.updateView.next();
          });
          this.client.on("error", (error) => {
            console.error("Twilio client Error: " + error.message, error);
          });
          this.client.on("incoming", (call: Call) => {
            this.manageIncomingCall(call)
          });
          this.client.on("tokenWillExpire", async () => {
            console.log('Twilio device token sta per scadere')
            try{
              const response: any = await this.api.getTwilioDeviceAccessToken();
              this.client.updateToken(response.token); // Aggiorna il token del client per mantenerlo attivo
            }catch(err){
              console.error('Impossibile aggiornare il token Twilio', err);
            }
          });
          this.client.on("unregistered", (call: Call) => {
            console.log('Twilio client unregistered');
            TwilioService.init_client_channel.removeEventListener('message', TwilioService.other_clients_listener); // Rimuovo il listerner che impedisce l'apertura di altri client su altri tab
            TwilioService.changeFavicon('assets/imgs/favicon_io/no-bell.png');
            ViewService.updateView.next();
            clearTimeout(this.interval_alive); // Rimuovo l'interval che dice che il client è connesso
          });
      
          // Registra il client su twilio
          this.client.register();
          
        }else{
          const toast = await this.toastCtrl.create({
            message: 'Client Twilio non avviato perchè aperto in un altro tab',
            duration: 2000,
            position: "middle",
            color: 'danger'
          });
          toast.present();
        }
      }, 1000);
      
    }catch(err){
      console.error("Impossibile inizializzare il client voice di Twilio", err);
    }
  }
  
  /** Calcola quante chiamate senza risposta ha ogni admin */
  private countUnreadCallForAdmins(){
    for(const admin of DataService.array.admin){
      admin.$count_unread_call = 0;
    }
    for(const ticket of DataService.array.tickets){
      if(ticket.$admin){
        ticket.$admin.$count_unread_call += ticket.unread_calls || 0;
      }
    }
    for(const contact of DataService.array.contacts){
      if(contact.sub_chat?.length > 0){
        for(const sub_chat of contact.sub_chat){
          if(sub_chat.$admin){
            sub_chat.$admin.$count_unread_call += sub_chat.unread_calls || 0;
          }
        }
      }
    }
    console.log('update counter', DataService.array.admin)
  }
  
  /** Cambia la favicon per far vedere che è il client aperto per le chiamate */
  private static changeFavicon(icon: string){
    try{
      const fav: any = document.getElementById('favicon');
      if(fav){
        fav.href = icon;
      }
    }catch(err){
      console.error(err);
    }
  }
  
  private async updateClientOnlineStatus(){
    try{
      const elem: any = await this.api.updateTwilioClientIsOnline(this.current_call && this.current_call.status === 'in-progress');
      for(const raw of elem.admins){
        const admin: Admin = this.data.getAdminById(raw._id);
        if(admin){
          admin.setAllFields(raw);
        }
      }
    }catch(err){
      console.error(err)
    }
  }
  
  /** Attende eventuali messaggi da altri tab che vogliono avviare il client, bloccandoli prima che lo facciano per evitare problemi di doppi client */
  private static other_clients_listener(event: any){
    console.warn('Un altro client sta cercando di aprirsi, rispondo che quello attivo sono io.', event);
    TwilioService.init_client_response_channel.postMessage('Client già attivo');
  }
  
  async updateAdminCallsList(skip?: number, limit?: number){
    try{
      let id_admins: string = '';
      for(const admin of this.admins_listeners){
        id_admins += admin._id + ',';
      }
      if(id_admins) id_admins = id_admins.slice(0, -1);
      const res: any = await this.api.getTwilioCalls(id_admins, skip || 0, limit || 100);
      if(res?.data){
        for(const raw of res.data){
          this.calls.push(new TwilioCall(raw, {
            getAdmin: this.data.getAdminById,
            getPlatformByType: this.data.getPlatformByType,
            getTicketById: this.data.getTicketById,
            getContactById: this.data.getContactById
          }));
        }
      }
    }catch(err){
      console.error('Impossibile recuperare le twilio call', err);
    }
  }
  
  /** Gestisce una chiamata in entrata **/
  manageIncomingCall(call: Call){
    console.log(`Call from ${call.parameters.From}`);
    
    const internal_call: TwilioCall = new TwilioCall(call, {
      getAdmin: this.data.getAdminById,
      getPlatformByType: this.data.getPlatformByType,
      getTicketById: this.data.getTicketById,
      getContactById: this.data.getContactById
    });
    if(!this.current_call) {
      this.current_call = internal_call;
    }
    this.counter_calls++;
    
    ViewService.updateView.next();
    
    // Quando abbiamo risposto alla chiamata
    call.on("accept", () => {
      this.current_call = internal_call;
      ViewService.updateView.next();
      
      this.api.patchAdminInCall(true).catch((err: any) => {
        console.error('Impossibile notificare admin in call', err);
      });
    });
    
    call.on("cancel", (data: any) => {
      this.removeIncomingCurrentCall(internal_call);
    });
    
    call.on("disconnect", (data: any) => {
      this.removeIncomingCurrentCall(internal_call);
    });
    
    call.on("reject", (data: any) => {
      this.removeIncomingCurrentCall(internal_call);
    });
  
    this.addClientCallInList(internal_call);
  }
  
  async addClientCallInList(internal_call: TwilioCall){
    try{
      if(!this.client_calls.get(internal_call.getClientCallSid())){
        this.client_calls.set(internal_call.getClientCallSid(), internal_call);
        ViewService.updateView.next();
      }
      try{
        await Utility.wait(1000); // Ci mette un attimo a salvarlo su db
        await this.updateCallFromRemote(internal_call);
      }catch(err){
        console.error(err);
        await Utility.wait(2000); // Non è ancora stata salvata sul db dal webbhook e ci riprovo dopo una attesa
        await this.updateCallFromRemote(internal_call);
      }
    }catch(err){
      console.error(err);
    }
  }
  
  async updateCallFromRemote(call?: TwilioCall, sid?: string){
    if(call){
      sid = call.getDbSid() || call.getClientCallSid();
    }
    const res: any = await this.api.getTwilioCall(sid);
    if(res){
      if(!call){
        call = new TwilioCall(res, {
          getAdmin: this.data.getAdminById,
          getPlatformByType: this.data.getPlatformByType,
          getTicketById: this.data.getTicketById,
          getContactById: this.data.getContactById
        });
      }else{
        call.setData(res);
      }
      ViewService.updateView.next();
      return call;
    }
  }
  
  private removeIncomingCurrentCall(internal_call: TwilioCall){
    console.log('Remove incoming call');
    if(this.current_call && internal_call.sid === this.current_call.sid){
      this.current_call = undefined;
      
      this.api.patchAdminInCall(false).catch((err: any) => {
        console.error('Impossibile notificare admin non più in call', err);
      });
    }
    this.counter_calls--;
    ViewService.updateView.next();
  }
  
  /** Risponde ad una chiamata **/
  answerCall(call: TwilioCall){
    const client_call: TwilioCall = this.getClientCall(call);
    if(client_call) client_call.raw_twilio.accept();
  }
  
  /** Rifiuta una chimata **/
  rejectCall(call: TwilioCall){
    const client_call: TwilioCall = this.getClientCall(call);
    if(client_call.raw_twilio.status() === Call.State.Open){
      client_call.raw_twilio.disconnect()
    }else{
      client_call.raw_twilio.reject();
    }
  }
  
  /** Rifiuta una chimata **/
  async redirectCall(call: TwilioCall, admin_id: string){
    this.api.postCallRedirect(call.raw_db.sid, admin_id);
  }
  
  /** Aggiorno il campo is_mute in call **/
  isMute(call: TwilioCall): boolean{
    const client_call: TwilioCall = this.getClientCall(call);
    return client_call?.raw_twilio?.isMuted()
  }
  
  /** Rimuovi muto chimata **/
  muteCall(call: TwilioCall){
    const client_call: TwilioCall = this.getClientCall(call);
    if(client_call.raw_twilio?.isMuted()){
      client_call.raw_twilio.mute(false)
    }else{
      client_call.raw_twilio.mute(true)
    }
    ViewService.updateView.next()
  }
  
  /** Passando una call va ad ottenere la twilio call su cui fare l'azione, ad esempio il reject */
  getClientCall(call: TwilioCall): TwilioCall{
    if(this.current_call?.getClientCallSid() === call.getClientCallSid()) return this.current_call;
    return this.client_calls.get(call.getDbDialSid()) || this.client_calls.get(call.getDbSid()) || this.client_calls.get(call.getClientCallSid());
  }
  
  /** Effettua una chiamata esterna usando il sip di voice@work **/
  async makeCall(from_phone: string, to_phone: string, type: number, platform_type: number) {
    if(this.current_call){
      console.error('Abbiamo la current call')
      return; // Ho già una chiamata in corso
    }
    const params = {
      To: to_phone,
      From: from_phone,
      type: `${type}`,
      platform_type: `${platform_type}`,
      admin_id: this.data.current_admin._id
    };
    if (this.client) {
      console.log(`Attempting to call ${params.To} ...`);
      const call: Call = await this.client.connect({ params });
      this.current_call = new TwilioCall(call, {
        getAdmin: this.data.getAdminById,
        getPlatformByType: this.data.getPlatformByType,
        getTicketById: this.data.getTicketById,
        getContactById: this.data.getContactById
      });
      this.addClientCallInList(this.current_call);
      this.api.patchAdminInCall(true).catch((err: any) => {
        console.error('Impossibile notificare admin in call', err);
      });
  
      call.on("cancel", (data: Call) => {
        console.log('cancel')
        this.removeOutgoingCurrentCall(data);
      });
  
      call.on("disconnect", (data: Call) => {
        console.log('disconnect')
        this.removeOutgoingCurrentCall(data);
      });
  
      call.on("reject", (data: Call) => {
        console.log('reject')
        this.removeOutgoingCurrentCall(data);
      });
      
      ViewService.updateView.next();
    } else {
      console.log("Unable to make call.");
    }
  }
  
  private removeOutgoingCurrentCall(internal_call: Call){
    if(this.current_call && internal_call.parameters.CallSid === this.current_call.sid){
      this.current_call = undefined;
      
      this.api.patchAdminInCall(false).catch((err: any) => {
        console.error('Impossibile notificare admin non più in call', err);
      });
    }
    ViewService.updateView.next();
  }
  
  /** Riproduce l'audio in un altra scheda **/
  playAudio(call: TwilioCall){
    window.open(call.audio);
  }
  
  private async addNewCallInList(data: any){
    let call: TwilioCall = new TwilioCall({sid: data.sid}, {
      getAdmin: this.data.getAdminById,
      getPlatformByType: this.data.getPlatformByType,
      getTicketById: this.data.getTicketById,
      getContactById: this.data.getContactById
    });
    this.calls.unshift(call);
    await this.updateCallFromRemote(call);
  }
  
  isClientInactive(){
    return !this.client || this.data.current_admin?.status === 1;
  }
  
  /** Gestisce un update di call **/
  async manageCallUpdate(data: any){
    try{
      if(data.sid){
        let call: TwilioCall = this.calls.find((elem: TwilioCall) => {if(elem.haveSid(data.sid) || elem.haveSid(data.parent_sid)) return elem});
        if(call){
          await this.updateCallFromRemote(call);
        }else if( // Aggiungo la chiamata solo è effettuata da uno dei client che seguo o se è effettuata da me
          (data.direction === 'inbound' && this.admins_listeners.find((admin: Admin) => admin._id === data.origin_admin_id || admin._id === data.final_admin_id)) ||
          (data.direction === 'outgoing' && data.origin_admin_id === this.data.current_admin._id)
        ){
          await this.addNewCallInList(data);
        }
        if(call && this.current_call?.sid === call.sid){ // Garantisce che la current call sia sempre quella giusta;
          if(!call.raw_twilio) call.raw_twilio = this.current_call.raw_twilio;
          this.current_call = call;
        }
      }else{
        if(data.type === 'update_list_to_read'){ // update specifico per l'aggiornamento del campo to_read di una lista di call.
  
          if(data.id_admin){ // Significa che deve aggiornare tutte le call di uno specifico admin
            for(const call of this.calls){
              if(call.origin_admin_id === data.id_admin){
                call.to_read = data.to_read;
              }
            }
            
            // aggiorna anche tutti i conteggi di contatti e ticket in modo da fare correttamente poi il calcolo
            for(const ticket of DataService.array.tickets){
              if(ticket.id_admin === data.id_admin){
                ticket.unread_calls = 0;
              }
            }
            for(const contact of DataService.array.contacts){
              if(contact.sub_chat?.length > 0){
                for(const sub_chat of contact.sub_chat){
                  if(sub_chat.id_admin === data.id_admin){
                    sub_chat.unread_calls = 0;
                  }
                }
              }
            }
            this.countUnreadCallForAdmins();
          }
          
          // Deprecated
          if(data.id_contact && !data.id_ticket){ // Significa che deve aggiornare solo le call che sono del contatto e non di un ticket di quel contatto
            for(const call of this.calls){
              if(call.id_contact === data.id_contact && !call.id_ticket && call.platform_type === data.platform_type && call.type === data.sub_chat){
                call.to_read = data.to_read;
              }
            }
          }
  
          // Deprecated
          if(!data.id_contact && data.id_ticket){ // Significa che deve aggiornare solo le call che sono di uno specifico ticket
            for(const call of this.calls){
              if(!call.id_contact && call.id_ticket === data.id_ticket && call.platform_type === data.platform_type && call.type === data.sub_chat){
                call.to_read = data.to_read;
              }
            }
          }
          ViewService.updateView.next();
        }
      }
    }catch(err){
      console.error('Errore in gestione update di call', err);
    }
  }
  
  /** Gestisce gli update di admin, tenendo attivo il client ma riscaricando le chiamate e la lista di client che sono in ascolto **/
  async manageAdminUpdate(data: any){
    try{
      const res: any = await this.api.getAdmin(data._id);
      const admin: Admin = this.data.getAdminById(data._id);
      if(admin) admin.setAllFields(res);
      TwilioService.updateAdmin.next(data._id);
      this.api.getAdminsListenerCalls().then((res: any) => {
        this.admins_listeners = [];
        this.admins_not_listeners = [];
        if(res){
          for(const raw of res){
            const admin: Admin = this.data.getAdminById(raw._id);
            if(admin) this.admins_listeners.push(admin);
          }
  
          if(this.data.current_admin.role === AdminRole.Superadmin){
            //Ottengo la lista di tutti gli admin se sono super admin
            for(const admin of DataService.array.admin){
              if(!admin.call_center && admin._id !== this.data.current_admin._id){
                this.admins_not_listeners.push(admin);
              }
            }
          }
          
          ViewService.updateView.next();
        }
      }).catch(err => {
        console.error(err);
      });
    }catch(err){
      console.error('Errore in gestione update di call', err);
    }
  }
  
  /** Utilizzata per chiedere la conferma di uscita da sezione chat e quindi rimozione client */
  async canDeactivate(): Promise<boolean>{
    return new Promise(async (resolve) => {
      const buttons: any[] =[
        {
          text: 'Annulla',
          role: 'cancel',
          cssClass: 'secondary',
          handler: async () => {
            return resolve(false);
          }
        }, {
          text: 'Conferma',
          handler: async () => {
            this.client.destroy(); // Distuggiamo il client
            return resolve(true);
          }
        }
      ];
      await this.view.presentAlert('Attenzione', 'Se lasci questa pagina, chiuderai il client di Twilio e non potrai più ricevere chiamate su questa browser', buttons);
    });
  }
  
  /** Apre alert che sostituisce il tastierino numerico che serve per inviare input in chiamata */
  async sendDigits(){
    try{
      const alert: any = await this.alertCtrl.create({
        header: "Tastierino numerico",
        message: 'Digita il numero o # e * da inviare nella chiamata',
        inputs: [
          {
            name: 'digit',
            type: 'text',
            placeholder: '1-9, #, *',
          },
        ],
        buttons: [
          {
            text: 'Annulla',
            role: 'cancel',
          },
          {
            text: 'Invia',
            handler: async (data) => {
              if (this.current_call && data?.digit && /^[\d#*]$/.test(data.digit)) {
                this.current_call.raw_twilio.sendDigits(data.digit);
              }
            }
          }
        ]
      });
      await alert.present();
    }catch(err){
      console.error('Impossbile inviare il numero sulla chiamata', err);
    }
  }
}
