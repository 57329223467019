export class ThingsmobileSim{
  
  raw: any;
  iccid: string;
  msisdn: string;
  
  constructor(rawObj?: any) {
    this.setAllFields(rawObj);
  }
  
  setAllFields(rawObj: any = {}) {
    if (rawObj) {
      this.raw = rawObj[0]; // things mobile restituisce un oggetto in una forma array strano
      this.iccid = rawObj[0].iccid[0];
      this.msisdn = rawObj[0].msisdn[0];
    }
  }
}
