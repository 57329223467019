import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, Input,
  OnInit
} from '@angular/core';
import {ViewService, ViewService as View} from "common-lib";
import {ModalController} from "@ionic/angular";
import {DataService} from "../../services/data.service";
import {ApiService} from "../../services/api.service";
import {TrackerAdmin} from "../../classes/trackerAdmin";

@Component({
  selector: 'app-sostituisci',
  templateUrl: './sostituisci.page.html',
  styleUrls: ['./sostituisci.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SostituisciPage implements OnInit {

  @Input() tracker: TrackerAdmin;

  imei: string;

  listener = {
    view: null,
  };

  constructor(
    private modalController: ModalController,
    private view: ViewService,
    private ref: ChangeDetectorRef,
    public data: DataService,
    private api: ApiService
  ) { }

  ngOnInit() {
    this.listenerView();
  }

  ionViewDidEnter() {}

  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }

  async sostituisci(){
    if(!this.imei){
      return this.view.presentAlert('Attenzione', 'Devi inserire un IMEI', ['ok']);
    }
    try{
      await this.view.showLoading('Sostituzione in corso...');
      const res: any = await this.api.patchTrackerSostituisci(this.tracker._id, this.imei);
      let oldTrackerId: string;
      let newTrackerId: string;
      if(res){
        if(res.oldTrackerGps) oldTrackerId = res.oldTrackerGps._id;
        if(res.newTrackerGps) newTrackerId = res.newTrackerGps._id;
      }
      let buttons: any[] = [
        {
          text: 'Apri vecchio tracker',
          handler: async () => {
            this.dismiss({id: oldTrackerId});
          }
        }, {
          text: 'Apri nuovo tracker',
          handler: async () => {
            this.dismiss({id: newTrackerId});
          }
        }
      ];
      this.view.presentAlert('Successo', 'Sostituzione avvenuta con successo', buttons, undefined, undefined, false);
    }catch(err){
      console.error(err);
      if(err.status){
        switch (err.status) {
          case 452:
            this.view.presentAlert('Errore', "Non esiste un tracker con l'IMEI inserito", ['Ok']);
            break;
          case 453:
            this.view.presentAlert('Errore', "Il nuovo tracker è già assegnato ad un partecipante", ['Ok']);
            break;
          case 454:
            this.view.presentAlert('Errore', "Il tracker è di un'altra piattaforma", ['Ok']);
            break;
          default:
            this.view.presentAlert('Errore', `Riprova più tardi<br><br><small>` + err.error + `</small>`, ['Ok']);
            break;
        }
        ViewService.updateView.next();
      }
    }
    this.view.hideLoading();
  }

  dismiss(data?: any){
    this.modalController.dismiss(data);
  }

  // listener
  listenerView() {
    this.listener.view = View.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.ref.markForCheck();
      setTimeout(() => {
        this.ref.markForCheck();
      }, 250);
    });
  }
}
