
export default class OnceCommand {
  
  raw: any;
  id: number;
  iccid: string;
  private _submit_date: number;
  private _delivery_date: number;
  private _expiry_date: number;
  private _retry_count: number;
  payload: string;
  private _status: string;
  source_address: string;
  msisdn: string;
  
  // "id": 19666109,
  // "submit_date": "2023-09-18T07:50:42.000+0000",
  // "delivery_date": "2023-09-18T07:50:42.000+0000",
  // "expiry_date": "2023-09-19T07:50:42.000+0000",
  // "retry_count": "0",
  // "source_address": "882285105439500",
  // "msisdn": "123",
  // "udh": "",
  // "payload": "RTC:2023/9/18 7:50 Init:2023/9/18 7:40 UpTime:604s PWR:PwrVoltage RST:0 GPS:2 SAT:0 TTFF:0 TTLF:0 NOGPS:0:10 SR:6 FG:0 FL:0 SMS:0 REC:0 MD:4 DB:0",
  // "status": {
  //   "id": 1,
  //   "description": "DELIVERY ATTEMPT PENDING"
  // },
  // "sms_type": {
  //   "id": 2,
  //   "description": "MO"
  // },
  // "source_address_type": {
  //   "id": 145,
  //   "description": "International"
  // }
  
  constructor(rawObj?: any) {
    this.setAllFields(rawObj);
  }
  
  setAllFields(rawObj: any = {}, ) {
    if (rawObj) {
      this.raw = rawObj;
      this.id = rawObj.id;
      this.iccid = rawObj.iccid;
      this.payload = rawObj.payload;
      this.source_address = rawObj.source_address;
      this.msisdn = rawObj.msisdn;
      this.submit_date = rawObj.submit_date;
      this.delivery_date = rawObj.delivery_date;
      this.expiry_date = rawObj.expiry_date;
      this.status = rawObj.status;
    }
  }
  
  get submit_date(): number {
    return this._submit_date;
  }
  
  set submit_date(value: number) {
    const data = new Date(value); // è stringa
    this._submit_date = data.getTime();
  }
  
  get delivery_date(): number {
    return this._delivery_date;
  }
  
  set delivery_date(value: number) {
    const data = new Date(value); // è stringa
    this._delivery_date = data.getTime();
  }
  
  get expiry_date(): number {
    return this._expiry_date;
  }
  
  set expiry_date(value: number) {
    const data = new Date(value); // è stringa
    this._expiry_date = data.getTime();
  }
  
  get status(): string | any {
    return this._status;
  }
  
  set status(value: string | any) {
    this._status = value?.description;
  }
  
  readIccidImeiFromSMS(): {imei: string, iccd: string}{
    let response: any = {imei: '', iccid: ''};
    if(this.payload && this.payload.indexOf('IMEI:') >= 0 && this.payload.indexOf('CCID:') >= 0){
      // Recupero imei e iccid da sms
      const parts: string[] = this.payload.split(',');
      if(parts.length === 2){
        response.imei = parts[0];
        response.imei = response.imei.replace('IMEI: ', '');
        response.imei = response.imei.replace(',', '');
        response.iccid = parts[1];
        response.iccid = response.iccid.replace(' CCID: ', '');
        if(response.iccid.length > 19) response.iccid = response.iccid.slice(0, -1);
      }
    }
    return response;
  }
  
  readResponseCommandFromSMS(expected_response: string): boolean{
    if(this.payload && this.payload.indexOf(expected_response) >= 0){
      return true;
    }
    return false;
  }
}
