import {Admin} from "./admin";
import { OrganizationAdmin } from "./organizationAdmin";
import {WhatsappChat} from "./whatsappChat";
import {EmailChat} from "./emailChat";
import Activity from "./activity";
import {Platform} from "./platform";
import {SmsChat} from "./smsChat";
import {ParticipantAdmin} from './participantAdmin';
import {ChipCompany, ChipCompanyHistory} from './chipCompany';
import {CallChat} from './callChat';
import {TwilioCall} from './twilioCall';
import {Contact} from './contact';
import {Ticket} from './ticket';

type Options = {
  getOrganization?: (id: string) => OrganizationAdmin | undefined;
  getAdmin?: (string) => Admin | undefined,
  getAdminByType?: (string, number) => Admin | undefined,
  getPlatformByOwnerName?: (string) => Platform | undefined;
  getParticipantByImei?: (string) => ParticipantAdmin | undefined;
  getChipCompany: (string) => ChipCompany | undefined;
  getPlatformByType?: (number) => Platform | undefined;
  getCompanyByPhone?: (string) => Company | undefined;
  getTicketById: (string) => Ticket | undefined;
  getContacts?: (id_company) => Contact[] | [];
  getTickets?: (company) => Ticket[] | [];
}

export default class Company {
  _id: string;
  private _organizations: string[] = [];
  label: string;
  private _type: number;
  private _whatsapp_chats: WhatsappChat[] = [];
  private _email_chats: EmailChat[] = [];
  private _sms_chats: SmsChat[] = [];
  private _call_chats: CallChat[] = [];
  pinned: any[] = [];
  
  $isPinned: boolean;
  $organization_type: number;
  
  $countUnread: number;
  $lastComunication: number = 0;
  $lastDirection: string;
  $chat_in_expiration: boolean;
  
  activities: Activity[] = [];
  loading_activities: boolean;
  has_more_activities: boolean;
  
  review_status: number;
  review_status_last_change_date: number;
  
  note: string;
  
  $getOrganization: (id: string) => OrganizationAdmin | undefined = () => undefined;
  $organizations: OrganizationAdmin[] = [];
  
  $organization_hints: {
    organization: OrganizationAdmin,
    company_contact: string,
    organization_contact: string,
    match_type: 'phone_contact' | 'email_owner_email' | 'email_subuser'
  }[] = [];
  $involved_admins: {tickets_to_read: boolean, admin: Admin}[] = [];
  $contacts: Contact[];
  $tickets: Ticket[];
  
  $selected_whatsapp_chat: WhatsappChat;
  $selected_email_chat: EmailChat;
  $selected_sms_chat: SmsChat;
  $selected_call_chat: CallChat;
  
  private _chips: ChipCompany[] | any;
  private _chips_history: ChipCompanyHistory[] | any;
  
  private _$calls_in_company: TwilioCall[] = [];
  private _$hasMoreCallsInCompany: boolean;
  
  $calls: TwilioCall[] = []; // Serve solo per frontend in dettaglio company
  $has_more_calls: boolean;
  
  $getAdmin: (string) => Admin = () => {return undefined};
  $getAdminByType: (string, number) => Admin = () => {return undefined};
  $getOwnerPlatform: (string) => Platform = () => {return undefined};
  $getParticipantByImei: (string) => ParticipantAdmin = () => {return undefined};
  $getChipCompany: (string) => ChipCompany = () => {return undefined};
  $getPlatformByType: (number) => Platform = () => {return undefined};
  $getCompanyByPhone: (string) => Company = () => {return undefined};
  $getTicketById: (string) => Ticket = () => {return undefined};
  $getContacts: (string) => Contact[] = () => {return []};
  $getTickets: (Company) => Ticket[] = () => {return []};
  
  constructor(rawObj?: any, options?: Options) {
    this.setAllFields(rawObj, options);
  }
  
  setAllFields(rawObj: any, options?: Options) {
    if(options){
      if (options.getOrganization) this.$getOrganization = options.getOrganization;
      if (options.getAdmin) this.$getAdmin = options.getAdmin;
      if (options.getAdminByType) this.$getAdminByType = options.getAdminByType;
      if (options.getPlatformByOwnerName) this.$getOwnerPlatform = options.getPlatformByOwnerName;
      if (options.getParticipantByImei) this.$getParticipantByImei = options.getParticipantByImei;
      if (options.getChipCompany) this.$getChipCompany = options.getChipCompany;
      if(options.getPlatformByType) this.$getPlatformByType = options.getPlatformByType;
      if(options.getCompanyByPhone) this.$getCompanyByPhone = options.getCompanyByPhone;
      if(options.getTicketById) this.$getTicketById = options.getTicketById;
      if(options.getContacts) this.$getContacts = options.getContacts;
      if(options.getTickets) this.$getTickets = options.getTickets;
    }
    if (rawObj) {
      this._id = rawObj._id;
      if(this._id) this.$contacts = this.$getContacts(this._id);
      this.label = rawObj.label;
      this.type = rawObj.type;
      this.pinned = rawObj.pinned;
      this.note = rawObj.note;
      this.review_status = rawObj.review_status;
      this.review_status_last_change_date = rawObj.review_status_last_change_date;
      this.chips = rawObj.chips;
      this.chips_history = rawObj.chips_history;
      this.organizations = rawObj.organizations;
      this.$tickets = this.$getTickets(this);
      this.setInvolvedAdmins();
    }
  }
  
  private setInvolvedAdmins(){
  }
  
  public get organizations(): string[] {
    return this._organizations;
  }
  
  public set organizations(value: string[]) {
    this._organizations = value;
    if(this.organizations){
      this.$organizations = [];
      for(let organization in this.organizations){
        let org: OrganizationAdmin = this.$getOrganization(organization);
        if (org) {
          this.$organizations.push(org);
          if (org.billing_type === 2) {
            this.$organization_type = org.billing_type;
          }
        }
      }
    }
  }
  
  get whatsapp_chats(): WhatsappChat[] {
    return this._whatsapp_chats;
  }
  
  set whatsapp_chats(value: WhatsappChat[]) {
    this._whatsapp_chats = value;
  }
  
  get email_chats(): EmailChat[] {
    return this._email_chats;
  }
  
  set email_chats(value: EmailChat[]) {
    this._email_chats = value;
  }
  
  get sms_chats(): SmsChat[] {
    return this._sms_chats;
  }
  
  set sms_chats(value: SmsChat[]) {
    this._sms_chats = value;
  }
  
  get call_chats(): CallChat[] {
    return this._call_chats;
  }
  
  set call_chats(value: CallChat[]) {
    this._call_chats = value;
  }
  
  get chips(): ChipCompany[] | any {
    return this._chips;
  }
  
  set chips(value: any) {
    this._chips = [];
    for(const chip_key in value){
      if(value[chip_key]){
        const chip: ChipCompany = this.$getChipCompany(chip_key);
        if(chip) this._chips.push(chip);
      }
    }
  }
  
  get chips_history(): ChipCompanyHistory[] | any {
    return this._chips_history;
  }
  
  set chips_history(value: any) {
    this._chips_history = [];
    if(!value) return;
    for(const history of value){
      const chip_history: ChipCompanyHistory = new ChipCompanyHistory(history, {getChipCompany: this.$getChipCompany});
      this._chips_history.push(chip_history);
    }
  }
  
  get type(): number {
    return this._type;
  }
  
  set type(value: number) {
    if(typeof value === 'string'){
      this._type = parseInt(value);
    }else{
      this._type = value;
    }
  }
  
  get $calls_in_company(): TwilioCall[] {
    return this._$calls_in_company;
  }
  
  set $calls_in_company(value: TwilioCall[]) {
    this._$calls_in_company = value;
  }
  
  get $hasMoreCallsInCompany(): boolean {
    return this._$hasMoreCallsInCompany;
  }
  
  set $hasMoreCallsInCompany(value: boolean) {
    this._$hasMoreCallsInCompany = value;
  }
  
  
  

  // Setta tutte le informazioni relative alle chat da far vedere nella lista di company
  setChatInformations(admins: Map<string, Admin>, me: Admin){
    let count_call: number = 0;
    let count: number = 0;
    this.$isPinned = false;
    if(this.pinned){
      for(let pin of this.pinned){
        if(pin === me._id){
          this.$isPinned = true;
          break;
        }
      }
    }
    this.$lastComunication = 0;
    for(let chat of this.whatsapp_chats){
      chat.$disabled = false;
      if(admins.get(chat.idAdmin) || (!chat.idAdmin && admins.get("-1"))){
        count += (chat.countUnread || 0);
        if(this.$lastComunication <= chat.last_message_dateSent){
          this.$lastComunication = chat.last_message_dateSent;
          this.$lastDirection = chat.last_message_direction;
        }
        if(chat.window_24h){
          const delta: number = chat.window_24h + (24*60*60*1000) - Date.now();
          if(delta > 0 && delta <= (60*60*1000)){
            this.$chat_in_expiration = true;
          }
        }
      }else{
        if(chat.chat_owner) chat.$disabled = true;
      }
    }
    for(let chat of this.email_chats){
      chat.$disabled = false;
      if(admins.get(chat.idAdmin) || (!chat.idAdmin && admins.get("-1"))){
        count += (chat.countUnread || 0);
        if(this.$lastComunication <= chat.last_message_dateSent){
          this.$lastComunication = chat.last_message_dateSent;
          this.$lastDirection = chat.last_message_direction;
        }
      }else{
        if(chat.email_owner) chat.$disabled = true;
      }
    }
    for(let chat of this.sms_chats){
      chat.$disabled = false;
      if(admins.get(chat.idAdmin) || (!chat.idAdmin && admins.get("-1"))){
        count += (chat.countUnread || 0);
        if(this.$lastComunication <= chat.last_message_dateSent){
          this.$lastComunication = chat.last_message_dateSent;
          this.$lastDirection = chat.last_message_direction;
        }
      }else{
        if(chat.chat_owner) chat.$disabled = true;
      }
    }
    for(let chat of this.call_chats){
      chat.$disabled = false;
      if(!chat.haveChatWithAdmin(admins)){
        chat.$disabled = true;
      }
    }
    this.$countUnread = count;
  }
  
  haveContactWithPhone(phone: string){
    for(let chat of this.whatsapp_chats){
      if(chat.$customer_phone && chat.$customer_phone === phone){
        return true;
      }
    }
    for(let chat of this.sms_chats){
      if(chat.$customer_phone && chat.$customer_phone === phone){
        return true;
      }
    }
    for(let chat of this.call_chats){
      if(chat.$customer_phone && chat.$customer_phone === phone){
        return true;
      }
    }
    return false;
  }
  
  haveContactWithEmail(email: string){
    for(let chat of this.email_chats){
      if(chat.email_customer && chat.email_customer === email){
        return true;
      }
    }
    return false;
  }
  
  /** Setta le attività di una chat partendo da l'oggetto ricevuto da api di get activity **/
  setActivities(raws: any, getActivity: (number) => {description: string, type: number}, getCompany: (string) => Company){
    if(!raws) return;
    for(let raw of raws){
      this.activities.push(new Activity(raw, {
        getAdmin: this.$getAdmin,
        getActivity: getActivity,
        getCompany: getCompany,
        getParticipantByImei: this.$getParticipantByImei,
        getTicketById: this.$getTicketById
      }));
    }
  }
  
  /** Elimina un'attività per id **/
  deleteActivity(id: string){
    if(!this.activities) return;
    for(let i=0; i<this.activities.length; i++){
      if(this.activities[i]._id === id){
        this.activities.splice(i, 1);
        return;
      }
    }
  }
  
  // Cerca suggerimenti di accompiamento company-organization
  searchOrganizationHints(organizations: OrganizationAdmin[]){
    this.$organization_hints = [];
    for(let organization of organizations){
      let found: boolean = false;
      if(organization.phone_contacts){
        for(let contact of organization.phone_contacts){
          for(let chat of this.whatsapp_chats){
            if(chat.chat_customer === `whatsapp:${contact.dial_code}${contact.partial_phone}`){
              if(!this.$organizations.find((elem: OrganizationAdmin) => {
                return elem._id === organization._id;
              })){
                this.$organization_hints.push({
                  organization: organization,
                  match_type: 'phone_contact',
                  organization_contact: `${contact.dial_code}${contact.partial_phone}`,
                  company_contact: chat.chat_customer.replace('whatsapp:', '')
                });
              }
              found = true;
              break;
            }
          }
          if(found) break;
          for(let chat of this.sms_chats){
            if(chat.chat_customer === `${contact.dial_code}${contact.partial_phone}`){
              if(!this.$organizations.find((elem: OrganizationAdmin) => {
                return elem._id === organization._id;
              })){
                this.$organization_hints.push({
                  organization: organization,
                  match_type: 'phone_contact',
                  organization_contact: `${contact.dial_code}${contact.partial_phone}`,
                  company_contact: chat.chat_customer
                });
              }
              found = true;
              break;
            }
          }
          if(found) break;
          for(let chat of this.call_chats){
            if(chat.chat_customer === `${contact.dial_code}${contact.partial_phone}`){
              if(!this.$organizations.find((elem: OrganizationAdmin) => {
                return elem._id === organization._id;
              })){
                this.$organization_hints.push({
                  organization: organization,
                  match_type: 'phone_contact',
                  organization_contact: `${contact.dial_code}${contact.partial_phone}`,
                  company_contact: chat.chat_customer
                });
              }
              found = true;
              break;
            }
          }
          if(found) break;
        }
      }
      if(found) continue;
      if(organization.login_email_partecipant_owner){
        for(let chat of this.email_chats){
          if(chat.email_customer === organization.login_email_partecipant_owner){
            if(!this.$organizations.find((elem: OrganizationAdmin) => {
              return elem._id === organization._id;
            })){
              this.$organization_hints.push({
                organization: organization,
                match_type: 'email_owner_email',
                organization_contact: organization.login_email_partecipant_owner,
                company_contact: chat.email_customer
              });
            }
            break;
          }
        }
      }
    }
  }
  
  getChipById(id?: string){
    if(this.chips){
      return this.chips.find((element: ChipCompany) => element._id === id);
    }
  }
  
  getActivityById(id: string){
    if(!this.activities) return;
    return this.activities.find((elem: Activity) => elem._id === id);
  }
  
  haveCallWithPhone(phone: string): boolean{
    for(let chat of this.call_chats){
      if(chat.$customer_phone && chat.$customer_phone === phone){
        return true;
      }
    }
    return false;
  }
  
}


