import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController, PopoverController } from '@ionic/angular';
import {ViewService} from 'common-lib';
import {PopoverSelectComponent} from "../popover-select/popover-select.component";
import {DataService} from "../../services/data.service";
import {Admin} from "../../classes/admin";
import {Storage} from "@ionic/storage-angular";

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopMenuComponent implements OnInit {

  @Input() currentPage: string;
  
  listener = {
    view: null
  };

  constructor(
    public data: DataService,
    private nav: NavController,
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    public view: ViewService,
    public popoverController: PopoverController,
    private storage: Storage,
    
  ) { }

  async ngOnInit() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.ref.markForCheck();
    });
  }
  
  ionViewDidEnter() {
  
  }
  
  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }
  
  // * content
  
  contentBtnAction(btnId: string) {
    // this.currentPage = btnId;
    ViewService.updateView.next()
    switch (btnId) {
      case "activities":
        this.router.navigate(['activities-to-do'])
        break;
  
      case "reviews":
        this.router.navigate(['reviews'])
        break;
  
      case "rubrica":
        this.router.navigate(['rubrica'])
        break;
        
      case "call":
        this.router.navigate(['phone-calls']);
        break;
        
      case "companies":
        this.currentPage = "companies";
        this.router.navigate(['companies'])
        break;
        
      case "contacts":
        this.currentPage = "contacts";
        this.router.navigate(['contacts'])
        break;
  
      case "sms":
        this.router.navigate(['sms']);
        break;
        
      case "tickets":
        this.router.navigate(['tickets']);
        break;
        
      case "whatsapp":
        // this.router.navigate(['chat']);
        this.router.navigate(['whatsapp']);
        break;
  
      case "calls":
        // this.router.navigate(['chat']);
        this.router.navigate(['calls']);
        break;
    
      default:
        
    }
  }
  
  async dropdownPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: PopoverSelectComponent,
      cssClass: 'popover_listPlain--w450',
      event: ev
    });
    return await popover.present();
  }
  
  async openAdminsFilter(ev: any){
    let admins: any = [];
    if(DataService.array.admin){
      DataService.array.admin.map((elem: Admin) => {
        if(!elem.call_center){
          admins.push({
            name: elem.nickname,
            id: elem._id,
            elem: elem,
            value: this.data.selectedAdmin.get(elem._id) ? true : false
          });
        }
      });
    }
    const componentProps: any = {
      options: admins,
      type: 'checkboxes'
    };
    const popover = await this.popoverController.create({
      component: PopoverSelectComponent,
      event: ev,
      translucent: true,
      componentProps: componentProps
    });
    popover.onDidDismiss().then(() => {
      let selectedAdmins: any = {};
      let toUpdate: boolean;
      for(let elem of admins){
        if(elem.value){
          selectedAdmins[elem.id] = true;
          if(!this.data.selectedAdmin.has(elem.id)){
            this.data.selectedAdmin.set(elem.id, elem.elem);
            toUpdate = true;
          }
        }
        if(!elem.value && this.data.selectedAdmin.has(elem.id)){
          this.data.selectedAdmin.delete(elem.id);
          toUpdate = true;
        }
      }
      if(toUpdate){
        this.storage.set('selectedAdmins', selectedAdmins);
        this.data.loadAdminsFilter();
        DataService.changeFilter.next();
      }
      ViewService.updateView.next();
    });
    await popover.present();
    return popover;
  }
  
  goToVisitor(){
    this.nav.navigateRoot('adv', {queryParams: {page: 1}});
  }
}
