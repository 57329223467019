import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { ViewService, ViewService as View } from 'common-lib';
import { DataService } from 'src/services/data.service';

import * as lodash from 'lodash';
import Activity from 'src/classes/activity';
import { ActivityAddPage } from 'src/app/activity-add/activity-add.page';
import { CompanyEditPage } from 'src/app/company-edit/company-edit.page';
import { ContactEditPage } from 'src/app/contact-edit/contact-edit.page';
import { ApiService } from 'src/services/api.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent implements OnInit {
  
  // DOM
  DataService = DataService;
  
  // * TableCol
  // {
  //   btnActionTd: this.callback,
  //   btnActionTh: this.callback,
  //   css: "",
  //   cssTd: "",
  //   cssTh: "",
  //   prop: "",
  //   label: "",
  //   cellContentTd: "",
  //   cellContentTh: "",
  //   func: this.callback,
  //   outputOptions: [],
  //   radioClick: this.callback,
  //   selectChanged: this.callback,
  //   isAscending: true, // ? legacy
  // },
  
  cols: any[] = [];
  entity: string;
  rows: any[] = []; // only used for mocks
  search: any = {
    fields: [], // string[]
    query: "",
  };
  
  @Input() mocks: boolean;
  @Input() model: string; // entity
  @Input() rowInteractive: boolean;
  @Input() virtualScroll: boolean;
  // @Output() cellElemClick = new EventEmitter<any>();
  // @Output() rowClick = new EventEmitter<any>();
  
  listener = {
    view: null,
  };
  
  constructor(
    private alertCtrl: AlertController,
    private api: ApiService,
    private cd: ChangeDetectorRef,
    private data: DataService,
    private modalCtrl: ModalController,
    private route: ActivatedRoute,
    private router: Router,
    private toastCtrl: ToastController,
    private view: ViewService,
  ) {}

  ngOnInit() {
    this.listenerView();
    this.cols = this.createColumns(this.model);
    this.createMocks(this.model);
    this.entity = this.getEntity(this.model);
    this.initPipes(this.model);
  }

  ngOnDestroy() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }
  
  // * listener
  
  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
      setTimeout(() => {
        this.cd.markForCheck();
      }, 250);
    });
  }
  
  // * methods
  
  alertConfirm_edit(): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      const alert = await this.alertCtrl.create({
        cssClass: 'my-custom-class',
        header: 'Richiesta di conferma',
        message: 'Effettuare la modifica?',
        buttons: [
          {
            text: 'Annulla',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              return resolve(false);
            }
          }, {
            text: 'Si',
            handler: () => {
              return resolve(true);
            }
          }
        ]
      });

      await alert.present();
    })
  }
  
  consoleLog(x?) {
    console.log('consoleLog()', x);
  }
  
  async presentToast(msg: string) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 2000,
      // position: "middle",
    });
    toast.present();
  }
  
  // * navigation
  
  openPhonebookActivities({ row }) {
    if (row.$company) {
      this.router.navigate([
        "phonebook-activities", 
        { company__id: row.$company._id }
      ]);
    }
  }
  
  // * table setup
  
  createColumns(dataType: string) {
    switch (dataType) {
      case "activity":
        return ([
          {
            btnActionTd: async ({ row }) => {
              const modal = await this.modalCtrl.create({
                component: ActivityAddPage,
                componentProps: {
                  row: lodash.cloneDeep(row),
                },
                cssClass: "modal_big",
              });
              
              await modal.present();
              
              const { data } = await modal.onDidDismiss();
              console.log("modal.onDidDismiss data", data)
            },
            btnActionTh: async ({ col }) => {
              const modal = await this.modalCtrl.create({
                component: ActivityAddPage,
                componentProps: {
                  row: new Activity(),
                },
                cssClass: "modal_big",
              });
              
              await modal.present();
              
              const { data } = await modal.onDidDismiss();
              console.log("modal.onDidDismiss data", data)
            },
            css: "",
            prop: "",
            label: "",
            cellContentTd: "btn-only",
            cellContentTh: "btn-only",
            isAscending: true,
          },
          {
            css: "",
            cssTh: "text-center",
            prop: "isRead",
            label: "Letto | Da leggere",
            cellContentTd: "radio",
            outputOptions: [
              {
                lbl: "Letto",
                val: true,
              },
              {
                lbl: "Da leggere",
                val: false,
              },
            ],
            radioClick: this.tableRowTd_radioClick_patchActivity.bind(this),
            isAscending: true,
          },
          {
            css: "text-nowrap",
            prop: "description",
            label: "Descrizione",
            cellContentTd: "",
            isAscending: true,
          },
          // OLD way
          // {
          //   css: "",
          //   prop: "idAdminCreator",
          //   label: "Admin creator",
          //   cellContentTd: "getter-func",
          //   func: this.data.getAdminNickname,
          //   isAscending: true,
          // },
          {
            css: "",
            prop: "$adminCreator_nickname",
            label: "Admin creator",
            cellContentTd: "",
            isAscending: true,
          },
          // OLD way
          // {
          //   css: "",
          //   prop: "idAdminManager",
          //   label: "Admin manager",
          //   cellContentTd: "getter-func",
          //   func: this.data.getAdminNickname,
          //   isAscending: true,
          // },
          {
            css: "",
            prop: "$adminManager_nickname",
            label: "Admin manager",
            cellContentTd: "",
            isAscending: true,
          },
          {
            btnActionTd: this.openPhonebookActivities.bind(this),
            css: "text-nowrap",
            prop: "$company_label",
            label: "Azienda",
            cellContentTd: "btn-left",
            isAscending: true,
          },
          // {
          //   css: "",
          //   prop: "idContact",
          //   label: "Contatto azienda",
          //   cellContentTd: "",
          //   isAscending: true,
          // },
          {
            css: "text-nowrap",
            prop: "$typeActivity_description",
            label: "Tipo di attività",
            cellContentTd: "",
            isAscending: true,
          },
          {
            css: "",
            cssTh: "text-center",
            prop: "isDo",
            label: "Fatto / Da fare",
            cellContentTd: "radio",
            // cellContentTd: "select",
            outputOptions: [
              {
                lbl: "Fatto",
                val: true,
              },
              {
                lbl: "Da fare",
                val: false,
              },
            ],
            radioClick: this.tableRowTd_radioClick_patchActivity.bind(this),
            // selectChanged: this.tableRowTd_selectChanged,
            isAscending: true,
          },
          {
            css: "",
            cssTd: "",
            prop: "timestamp_deadline",
            label: "Data scadenza",
            cellContentTd: "date-time",
            isAscending: true,
          },
          {
            css: "",
            cssTd: "",
            prop: "timestamp_create",
            label: "Data creazione",
            cellContentTd: "date-time",
            isAscending: true,
          },
        ]);
        break;
      case "activityByCompany":
        return ([
          {
            btnActionTd: async ({ row }) => {
              const modal = await this.modalCtrl.create({
                component: ActivityAddPage,
                componentProps: {
                  row: lodash.cloneDeep(row),
                },
                cssClass: "modal_big",
              });
              
              await modal.present();
              
              const { data } = await modal.onDidDismiss();
              console.log("modal.onDidDismiss data", data)
            },
            btnActionTh: async ({ col }) => {
              const modal = await this.modalCtrl.create({
                component: ActivityAddPage,
                componentProps: {
                  row: new Activity({
                    idCompany: this.route.snapshot.paramMap.get("company__id"),
                  }),
                },
                cssClass: "modal_big",
              });
              
              await modal.present();
              
              const { data } = await modal.onDidDismiss();
              console.log("modal.onDidDismiss data", data)
            },
            css: "",
            prop: "",
            label: "",
            cellContentTd: "btn-only",
            cellContentTh: "btn-only",
            isAscending: true,
          },
          {
            css: "",
            cssTh: "text-center",
            prop: "isRead",
            label: "Letto | Da leggere",
            cellContentTd: "radio",
            outputOptions: [
              {
                lbl: "Letto",
                val: true,
              },
              {
                lbl: "Da leggere",
                val: false,
              },
            ],
            radioClick: this.tableRowTd_radioClick_patchActivity.bind(this),
            isAscending: true,
          },
          {
            css: "text-nowrap",
            prop: "description",
            label: "Descrizione",
            cellContentTd: "",
            isAscending: true,
          },
          {
            css: "",
            prop: "$adminCreator_nickname",
            label: "Admin creator",
            cellContentTd: "",
            isAscending: true,
          },
          {
            css: "",
            prop: "$adminManager_nickname",
            label: "Admin manager",
            cellContentTd: "",
            isAscending: true,
          },
          {
            css: "text-nowrap",
            prop: "$typeActivity_description",
            label: "Tipo di attività",
            cellContentTd: "",
            isAscending: true,
          },
          {
            css: "",
            cssTh: "text-center",
            prop: "isDo",
            label: "Fatto / Da fare",
            cellContentTd: "radio",
            outputOptions: [
              {
                lbl: "Fatto",
                val: true,
              },
              {
                lbl: "Da fare",
                val: false,
              },
            ],
            radioClick: this.tableRowTd_radioClick_patchActivity.bind(this),
            isAscending: true,
          },
          {
            css: "",
            cssTd: "",
            prop: "timestamp_deadline",
            label: "Data scadenza",
            cellContentTd: "date-time",
            isAscending: true,
          },
          {
            css: "",
            cssTd: "",
            prop: "timestamp_create",
            label: "Data creazione",
            cellContentTd: "date-time",
            isAscending: true,
          },
        ]);
        break;
      case "company":
        return ([
          { 
            btnActionTd: async ({ row }) => {
              const modal = await this.modalCtrl.create({
                component: CompanyEditPage,
                componentProps: {
                  row: lodash.cloneDeep(row),
                },
                cssClass: "modal_big",
              });
              
              await modal.present();
              
              const { data } = await modal.onDidDismiss();
              console.log("modal.onDidDismiss data", data)
            },
            css: "",
            prop: "",
            label: "",
            cellContentTd: "btn-only",
            isAscending: true,
          },
          {
            css: "text-nowrap",
            prop: "label",
            label: "Azienda",
            cellContentTd: "",
            isAscending: true,
          },
          // {
          //   css: "",
          //   prop: "indirizzo",
          //   label: "Indirizzo",
          //   cellContentTd: "",
          //   isAscending: true,
          // },
          {
            css: "text-center",
            prop: "idContacts",
            label: "Numero contatti",
            cellContentTd: "array-length",
            isAscending: true,
          },
          {
            css: "text-center",
            prop: "idOrganization",
            label: "Clienti in Balin?",
            cellContentTd: "bool",
            isAscending: true,
          },
          // OLD
          // {
          //   css: "",
          //   prop: "idAdmin",
          //   label: "Agente",
          //   cellContentTd: "getter-func",
          //   func: this.data.getAdminNickname,
          //   isAscending: true,
          // },
          {
            prop: "$admin_nickname",
            label: "Agente",
            cellContentTd: "",
            isAscending: true,
          },
          // OLD
          // {
          //   css: "",
          //   prop: "platform_type",
          //   label: "Piattaforma",
          //   cellContentTd: "getter-func",
          //   // func: this.data.returnPlatformTypeName,
          //   func: ListParsers.parseTypePlatform,
          //   isAscending: true,
          // },
          {
            prop: "$platform_type",
            label: "Piattaforma",
            cellContentTd: "",
            outputOpt_labelProp: "lbl",
            outputOpt_valueProp: "val",
            isAscending: true,
          },
        ]);
        break;
      case "contact":
        return ([
          {
            btnActionTd: async ({ row }) => {
              const modal = await this.modalCtrl.create({
                component: ContactEditPage,
                componentProps: {
                  row: lodash.cloneDeep(row),
                },
                cssClass: "modal_big",
              });
              
              await modal.present();
              
              const { data } = await modal.onDidDismiss();
              console.log("modal.onDidDismiss data", data)
            },
            css: "",
            prop: "",
            label: "",
            cellContentTd: "btn-only",
            isAscending: true,
          },
          {
            css: "",
            prop: "label",
            label: "Nome",
            cellContentTd: "",
            isAscending: true,
          },
          {
            css: "",
            prop: "email",
            label: "Email",
            cellContentTd: "",
            isAscending: true,
          },
          {
            css: "",
            prop: "phone",
            label: "Phone",
            cellContentTd: "",
            isAscending: true,
          },
          {
            css: "",
            prop: "whatsapp_phone",
            label: "Phone (Whatsapp)",
            cellContentTd: "",
            isAscending: true,
          },
          // {
          //   css: "text-center",
          //   prop: "cliente_balin",
          //   label: "Cliente Balin?",
          //   cellContentTd: "bool",
          //   isAscending: true,
          // },
          // {
          //   css: "text-nowrap",
          //   prop: "azienda",
          //   label: "Azienda",
          //   cellContentTd: "",
          //   isAscending: true,
          // },
          // {
          //   css: "",
          //   prop: "agente",
          //   label: "Agente",
          //   cellContentTd: "",
          //   isAscending: true,
          // },
        ]);
        break;
    }
  }
  
  createMocks(dataType: string) {
    if (this.mocks) {
      let i: number;
      let localArray = [];
      
      switch (dataType) {
        case "activity":
        case "activityByCompany":
          localArray = [
            {
              _id: "id001",
              data_scadenza: 1615984210,
              todo_status: "fatto",
              read_status: undefined,
              azienda: "azienda e Cogazienda srl",
              email: "aziendaCogazienda@provider.dom",
              numero_contatti: -3,
              cliente_balin: false,
              descrizione: "Fabianriejgier",
              adminManager: "Ancora nessuno",
              assegnato_a: "",
            },
            {
              _id: "id002",
              todo_status: null,
              read_status: true,
              azienda: "DIMAR",
              email: "mr@em.it",
              numero_contatti: "1234567890",
              cliente_balin: true,
              descrizione: "via brv",
              adminManager: "Umberto",
              assegnato_a: "Ancora nessuno",
            },
            {
              _id: "id003",
              todo_status: "da-fare",
              read_status: false,
              azienda: null,
              email: "EMAILEMAILEMAIL@sfijgsjif.dfs",
              numero_contatti: "123 456 78 90",
              cliente_balin: true,
              descrizione: "Afesijgi Zgeifdgjdi Sgeirgjd Sfjerighjerh SgirejgeigAfesijgi Zgeifdgjdi Sgeirgjd Sfjerighjerh SgirejgeigAfesijgi Zgeifdgjdi Sgeirgjd Sfjerighjerh SgirejgeigAfesijgi Zgeifdgjdi Sgeirgjd Sfjerighjerh Sgirejgeig",
              adminManager: "Masoero Umberto",
              assegnato_a: "",
            },
            {
              _id: "id004",
              todo_status: "opzione-inesistente",
              read_status: null,
              azienda: "unknown",
              email: "dfgmk@gmail.com",
              numero_contatti: "+39 12 34 567 890",
              cliente_balin: false,
              descrizione: "AfesijgiZgeifdgjdiSgeirgjdSfjerighjerhSgirejgeigAfesijgiZgeifdgjdiSgeirgjdSfjerighjerhSgirejgeigAfesijgiZgeifdgjdiSgeirgjdSfjerighjerhSgirejgeigAfesijgiZgeifdgjdiSgeirgjdSfjerighjerhSgirejgeig",
              adminManager: "Mattia",
              assegnato_a: "Danilo",
            },
            {
              _id: "id005",
              todo_status: "nota",
              // read_status: true,
              azienda: "V elem",
              email: "the5thColumn@hotmail.dr",
              numero_contatti: "+39 12 34 567 890",
              cliente_balin: null,
              descrizione: "",
              adminManager: "",
              assegnato_a: "Marco",
            },
          ];
          break;
        case "company":
          localArray = [
            {
              _id: "id001",
              azienda: "azienda e Cogazienda srl",
              email: "aziendaCogazienda@provider.dom",
              numero_contatti: -3,
              cliente_balin: false,
              indirizzo: "Fabianriejgier",
              agente: "Ancora nessuno",
            },
            {
              _id: "id002",
              azienda: "max roy",
              email: "mr@em.it",
              numero_contatti: "1234567890",
              cliente_balin: true,
              indirizzo: "via brv",
              agente: "Umberto",
            },
            {
              _id: "id003",
              azienda: null,
              email: "EMAILEMAILEMAIL@sfijgsjif.dfs",
              numero_contatti: "123 456 78 90",
              cliente_balin: true,
              indirizzo: "Afesijgi Zgeifdgjdi Sgeirgjd Sfjerighjerh SgirejgeigAfesijgi Zgeifdgjdi Sgeirgjd Sfjerighjerh SgirejgeigAfesijgi Zgeifdgjdi Sgeirgjd Sfjerighjerh SgirejgeigAfesijgi Zgeifdgjdi Sgeirgjd Sfjerighjerh Sgirejgeig",
              agente: "Masoero Umberto",
            },
            {
              _id: "id004",
              azienda: "unknown",
              email: "dfgmk@gmail.com",
              numero_contatti: "+39 12 34 567 890",
              cliente_balin: false,
              indirizzo: "AfesijgiZgeifdgjdiSgeirgjdSfjerighjerhSgirejgeigAfesijgiZgeifdgjdiSgeirgjdSfjerighjerhSgirejgeigAfesijgiZgeifdgjdiSgeirgjdSfjerighjerhSgirejgeigAfesijgiZgeifdgjdiSgeirgjdSfjerighjerhSgirejgeig",
              agente: "Mattia",
            },
            {
              _id: "id005",
              azienda: "V elem",
              email: "the5thColumn@hotmail.dr",
              numero_contatti: "+39 12 34 567 890",
              cliente_balin: null,
              indirizzo: "",
              agente: "",
            },
          ];
          break;
        case "contact":
          localArray = [
            {
              _id: "id001",
              nome: "Nome e Cognome",
              email: "nomeCognome@provider.dom",
              telefono: "+391234567890",
              cliente_balin: false,
              azienda: "Fabianriejgier",
              agente: "Ancora nessuno",
            },
            {
              _id: "id002",
              nome: "max roy",
              email: "mr@em.it",
              telefono: "1234567890",
              cliente_balin: true,
              azienda: "",
              agente: "Umberto",
            },
            {
              _id: "id003",
              nome: null,
              email: "EMAILEMAILEMAIL@sfijgsjif.dfs",
              telefono: "123 456 78 90",
              cliente_balin: true,
              azienda: "Afesijgi Zgeifdgjdi Sgeirgjd Sfjerighjerh Sgirejgeig",
              agente: "Masoero Umberto",
            },
            {
              _id: "id004",
              nome: "unknown",
              email: "dfgmk@gmail.com",
              telefono: "+39 12 34 567 890",
              cliente_balin: false,
              azienda: "",
              agente: "Mattia",
            },
            {
              _id: "id005",
              nome: "V elem",
              email: "the5thColumn@hotmail.dr",
              telefono: "+39 12 34 567 890",
              cliente_balin: null,
              azienda: "",
              agente: "",
            },
          ];
          localArray.forEach(elem => {
            i = 0;
            // 20000 = 100000 elems
            while (i < 20000) {
              this.rows.push(Object.assign({}, elem));
              // this.contacts.push(...localArray);
              i++;
            }
          });
          return;
          break;
      }
      
      this.rows = localArray;
      View.updateView.next();
    }
  }
  
  getEntity(dataType: string) {
    switch (dataType) {
      case "activityByCompany":
        return "activity";
        break;
      default:
        return dataType;
    }
  }
  
  // search
  initPipes(dataType: string/* , elem */) {
    switch (dataType) {
      case "activityByCompany":
        this.search.fields.push("idCompany");
        // if (elem) {
          // this.search.query = elem._id;
          this.search.query = this.route.snapshot.paramMap.get("company__id");
          View.updateView.next()
        // }
        break;
    }
  }
  
  // * table methods
  
  tableRowClick(row) {
    console.log('tableRowClick()', row);
    // this.rowClick.emit(row);
    
    // switch (this.model) {
    //   case "company":
    //     this.router.navigate(['phonebook-activities']);
    //     break;
    
    //   default:
    //     break;
    // }
  }
  
  tableRowTd_btnAction(row) {
    console.log('row', row);
  }
  
  tableRowTd_radioClick(paramsObj) {
    const { row } = paramsObj;
    console.log('row', row);
  }
  
  async tableRowTd_radioClick_patchActivity({ col, row, opt }) {
    // console.log('paramsObj', paramsObj)
    // const { col, row, opt } = paramsObj;
    
    // * check: "to execute or not execute?"
    
    if (row[col.prop] === opt.val) {
      console.log("row[col.prop] == opt.val, return")
      return;
    }
    if (!await this.alertConfirm_edit()) {
      console.log("alertConfirm_edit() => 'cancel', return")
      return;
    }
    
    // * api executions
    
    await this.view.showLoading("salvataggio in corso")
    View.updateView.next()
    
    let res: any;
    try {
      // res = await this.api.patchActivity(this.row);
      res = await this.api.patchActivity({
        _id: row._id,
        [col.prop]: opt.val,
        // : row.,
      });
      console.log("save() res", res)
      
      row[col.prop] = opt.val;
      this.presentToast("Salvataggio avvenuto")
      
    } catch (e) {
      console.error(e);
      const { error } = e;
      // untested
      await this.view.modalAlert(
        "Errore salvataggio", 
        `
        Tipo: ${error.type}, \n
        Descrizione: ${error.description}
        `,
        "", // icon
        ["Ok"],
      );
    }
    
    await this.view.hideLoading()
    View.updateView.next()
  }
  
  tableRowTd_selectChanged(row) {
    console.log('row', row);
  }
  
  tableRowTh_btnAction(col) {
    console.log('col', col);
  }
  

}
