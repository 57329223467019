import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import {ViewService, ViewService as View} from "common-lib";
import {ModalController} from "@ionic/angular";
import {DataService} from "../../services/data.service";
import {ApiService} from "../../services/api.service";
import {TrackerAdmin} from "../../classes/trackerAdmin";

@Component({
  selector: 'app-send-command',
  templateUrl: './send-command.page.html',
  styleUrls: ['./send-command.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendCommandPage implements OnInit {

  @Input() pageType: string;
  @Input() imei: string;
  @Input() tracker: TrackerAdmin;

  comando: string;
  response: string;
  timeout: number = 60;
  tentativi: number = 1;
  noResponse: boolean = true;
  onSuccess: boolean = false;
  onError: boolean = false;
  email: string;

  commandConfigurations: any[];

  typeConfig: string;

  listener = {
    view: null,
  };

  constructor(
    private modalController: ModalController,
    private view: ViewService,
    private ref: ChangeDetectorRef,
    public data: DataService,
    private api: ApiService
  ) { }

  async ngOnInit() {
    this.listenerView();

    if(this.pageType === 'configuration'){
      if(!this.data.commandConfigurationsMap[this.tracker.type_trackerGps]){
        await this.getCommandConfiguration(0, 200);
      }else{
        this.commandConfigurations = this.data.commandConfigurationsMap[this.tracker.type_trackerGps];
      }
      if(this.commandConfigurations.length > 0){
        this.typeConfig = this.commandConfigurations[0]._id;
      }
    }
  }

  getCommandConfiguration(skip: number, limit: number){
    return new Promise((resolve, reject) => {
      this.api.getCommandConfiguration(skip, limit, this.tracker.type_trackerGps).then( async(res: any) => {
        if(res.data){
          if(!this.commandConfigurations){
            this.commandConfigurations = [];
          }
          for(let raw of res.data){
            this.commandConfigurations.push(raw);
          }
        }
        if(!res.has_more){
          this.data.commandConfigurationsMap[this.tracker.type_trackerGps] = this.commandConfigurations;
          ViewService.updateView.next();
          return resolve();
        }else{
          return resolve(this.getCommandConfiguration((skip + res.data.length), limit));
        }
      }).catch((err: any) => {
        return reject(err);
      })
    });
  }

  async sendSingleCommand(){
    if(!this.comando){
      return this.view.presentAlert('Attenzione', 'Devi inserire un comando', ['ok']);
    }
    if(!this.noResponse && !this.response){
      return this.view.presentAlert('Attenzione', 'Devi inserire una risposta attesa', ['ok']);
    }
    if(!this.timeout || this.timeout <= 0){
      return this.view.presentAlert('Attenzione', 'Il timeout deve essere almeno di 1 secondo', ['ok']);
    }
    if(!this.tentativi || this.tentativi <= 0){
      return this.view.presentAlert('Attenzione', 'Il numero di tentativi deve essere almeno uguale a 1', ['ok']);
    }
    if((this.onError || this.onSuccess) && !this.email){
      return this.view.presentAlert('Attenzione', 'Devi inserire un\'email se vuoi ricevere notifiche al completamento', ['ok']);
    }
    try{
      await this.view.showLoading('Invio comando in corso...');
      await this.api.postSingleCommand(this.comando, this.noResponse, this.response, this.imei, this.tentativi, this.onSuccess, this.onError, this.email);
      await this.view.hideLoading();
      this.dismiss({sent: true});
    }catch(err){
      console.error(err);
      this.view.presentAlert('Errore', 'Errore invio comando, riprova più tardi', ['Ok']);
      this.view.hideLoading();
    }
  }

  async sendConfiguration(){
    if(!this.typeConfig){
      return this.view.presentAlert('Attenzione', 'Devi selezionare un tipo di configurazione', ['ok']);
    }
    if(!this.tentativi || this.tentativi <= 0){
      return this.view.presentAlert('Attenzione', 'Il numero di tentativi deve essere almeno uguale a 1', ['ok']);
    }
    if((this.onError || this.onSuccess) && !this.email){
      return this.view.presentAlert('Attenzione', 'Devi inserire un\'email se vuoi ricevere notifiche al completamento', ['ok']);
    }
    try{
      await this.view.showLoading('Invio configurazione in corso...');
      await this.api.postConfiguration(this.typeConfig, this.tracker.imei, this.tentativi, this.onSuccess, this.onError, this.email);
      await this.view.hideLoading();
      this.dismiss({sent: true});
    }catch(err){
      console.error(err);
      this.view.presentAlert('Errore', 'Errore invio configurazione, riprova più tardi', ['Ok']);
      this.view.hideLoading();
    }
  }

  dismiss(data?: any){
    this.modalController.dismiss(data);
  }

  // listener
  listenerView() {
    this.listener.view = View.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.ref.markForCheck();
      setTimeout(() => {
        this.ref.markForCheck();
      }, 250);
    });
  }

}
