import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Ticket} from '../../classes/ticket';
import {ViewService} from 'common-lib';
import {ApiService} from '../../services/api.service';
import {EmailMessage} from '../../classes/emailMessage';
import {SendEmailPage} from '../../app/send-email/send-email.page';
import {ModalController, PopoverController} from '@ionic/angular';
import {AttachmentPage} from '../../app/attachment/attachment.page';
import {WhatsappMessage} from '../../classes/whatsappMessage';
import {DataService} from '../../services/data.service';
import {Contact} from '../../classes/contact';
import {WebMessage} from '../../classes/webMessages';
import {EmailComponent} from '../email/email.component';

@Component({
  selector: 'app-message-item',
  templateUrl: './message-item.component.html',
  styleUrls: ['./message-item.component.scss']
})
export class MessageItemComponent implements OnInit {
  
  @Input() message: (WebMessage | WhatsappMessage | EmailMessage);
  @Input() ticket: Ticket;
  @Input() actions: boolean = true;
  @Input() show_ticket_badge: boolean = false;
  
  @Output() selectTicket = new EventEmitter<Ticket | Contact>();
  
  attachment: any;
  
  
  constructor(
    public view: ViewService,
    private cd: ChangeDetectorRef,
    private api: ApiService,
    private modalController: ModalController,
    private popoverController: PopoverController,
    private data: DataService
  ) { }

  ngOnInit() {}
  
  // Apre il modal di invio email ad una nuova email
  async modalNewEmail(platform_type?: number, email_owner?: string,  email_customer?: string, old_email?: EmailMessage, ticket?: Ticket) {
    const modal = await this.modalController.create({
      backdropDismiss: true,
      component: SendEmailPage,
      componentProps: {
        platform_type: platform_type,
        email_destinatario: email_customer,
        email_mittente: email_owner,
        old_email: old_email,
        ticket: ticket
      },
      cssClass: "modal_big",
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
  }
  
  openImage(url: string){
    window.open(url, '_blank')
  }
  // Fa vedere lista allegati di una email
  async showAttachmentsEmail(ev: any, email: EmailMessage) {
    if(email.$mediaInfos){
      const popover = await this.popoverController.create({
        component: AttachmentPage,
        event: ev,
        translucent: true,
        componentProps: {
          attachments: email.$mediaInfos,
          type: 'show'
        }
      });
      popover.onDidDismiss().then((data: any) => {
        if(data.data === null) this.attachment = undefined;
        ViewService.updateView.next();
      });
      await popover.present();
    }
  }
  
  async createTickteFromMessage(message?: WhatsappMessage | EmailMessage, ticket?: Ticket){
    return await this.view.presentAlert('Attenzione', `Vuoi creare un nuovo ticket partendo da questo messaggio?`, [{
      text: 'Annulla',
      role: 'cancel',
      cssClass: 'secondary'
    }, {
      text: 'Conferma',
      handler: async () => {
        this.view.showLoading('Creazione ticket in corso')
        
        try{
          const res: any = await this.api.createTicketFromMessageId(ticket._id.toString(), message._id.toString(), message.message_type)
          if(res){
            let ticket_result: Ticket = this.data.getTicketById(res._id.toString())
            await this.selectTicket.emit(ticket_result)
          }
          this.view.hideLoading()
        }catch(err){
          this.view.hideLoading()
          console.error(err)
        }
      }
    }]);
  }
  
  openTicket(ticket: Ticket){
    this.selectTicket.emit(ticket)
  }
  
  async openMessageDetail(message: EmailMessage){
    
    switch(message.message_type){
      case 'email':{
        const modal = await this.modalController.create({
          backdropDismiss: true,
          component: EmailComponent,
          componentProps: {
            email: message.htmlBody
          },
          cssClass: "modal_big",
        });
        await modal.present();
        
      }
    }
    
  }
  
}
