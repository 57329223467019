import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import { ModalController, PopoverController} from '@ionic/angular';
import {
  AuthService,
  ViewService, ViewService as View,
} from 'common-lib';
import {ApiService} from "../../services/api.service";
import { DataService } from 'src/services/data.service';
import Activity from 'src/classes/activity';
import {Admin} from "../../classes/admin";

import * as moment from 'moment';
import {OrganizationAdmin} from '../../classes/organizationAdmin';
import Company from '../../classes/company';
import {PopoverSelectComponent} from '../../components/popover-select/popover-select.component';
import {Ticket} from '../../classes/ticket';

@Component({
  selector: 'app-activity-add',
  templateUrl: './activity-add.page.html',
  styleUrls: ['./activity-add.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityAddPage implements OnInit {
  
  // DOM
  DataService = DataService;
  
  // data passed in by componentProps
  @Input() row: Activity;
  @Input() idCompany: string;
  @Input() organizations: OrganizationAdmin[];
  @Input() trackers: any[];
  
  ticket: Ticket;
  
  admins: Admin[] = DataService.array.admin;
  activity_types: any[] = DataService.general_parameter.typeActivity;
  disable_activity_type: boolean;
  disable_organizations_select: boolean;
  disable_tracker_select: boolean;
  disable_company_select: boolean;
  company: Company;
  historyActivityAddTime: number;
  historyActivityDescription: string;
  listener = {
    data: null,
    view: null,
  };
  
  chips_company: any[] = [];
  
  constructor(
    private cd: ChangeDetectorRef,
    private api: ApiService,
    private data: DataService,
    private modalCtrl: ModalController,
    public popoverController: PopoverController,
    public view: ViewService,
  ) { }

  ngOnInit() {}
  
  ionViewDidEnter() {
    this.listenerView();
    
    if(!this.row?._id){
      let myAdmin: Admin = this.data.getAdminByEmail(AuthService.getEmail());
      if(myAdmin) this.row.idAdminManager = myAdmin._id;
      this.row.timestamp_deadline = moment().add(1, 'day').unix(); // Domani
      this.row.status = 1; // Da fare;
      this.row.type_activity = this.row.type_activity || 2;
      this.row.idCompany = this.idCompany;
    }else{
      this.disable_organizations_select = true;
      this.disable_company_select = true;
      this.disable_tracker_select = true;
      this.idCompany = this.row.idCompany;
    }
    
    if(this.row.id_organization){
      this.disable_organizations_select = true;
      this.organizations = [this.data.getOrganizationById(this.row.id_organization)];
      this.company = this.data.getCompanyByOrganizationId(this.row.id_organization); // Per recuperare i ticket su cui si possono applicare le attività
    }
    
    if(this.row.id_ticket){
      this.ticket = this.row.$ticket;
    }
    if(this.idCompany){
      this.company = this.data.getCompanyById(this.idCompany);
    }
    if(this.row.imei_tracker){
      this.disable_tracker_select = true;
      this.trackers = [{imei: this.row.imei_tracker}];
    }
    
    if(this.company){
      this.loadTicketsCompany(); // Carica i ticket della company
    }
  
    this.historyActivityAddTime = Date.now()
  
    if(this.idCompany && this.company){
      this.chips_company = [];
      for (const [key, chip] of DataService.chips_company.entries()) { // Using the default iterator (could be `map.entries()` instead)
        this.chips_company.push({
          id: chip._id,
          lbl: chip.code,
          category: chip.category,
          order: chip.order,
          selected: this.company?.getChipById(chip._id) || false
        });
      }
      this.chips_company.sort((a, b) => {
        if (a.category < b.category) {
          return -1;
        } else if (a.category > b.category) {
          return 1;
        } else {
          if (a.order < b.order) {
            return -1;
          } else if (a.order > b.order) {
            return 1;
          } else {
            return 0;
          }
        }
      });
    }
    
    
    View.updateView.next();
  }

  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }
  
  dismiss(data?: any) {
    this.modalCtrl.dismiss(data);
  }
  
  async save() {
    if(!this.row.description) return this.view.presentAlert('Attenzione', 'Devi inserire una descrizione', ['Ok']);
    if(!this.row.idAdminManager) return this.view.presentAlert('Attenzione', "Devi selezionare un gestore dell'attività", ['Ok']);
    if(this.row.status.toString() !== '3' && !this.row.timestamp_deadline) return this.view.presentAlert('Attenzione', "Se metti ''da fare o in lavorazione'' devi impostare una data di scadenza", ['Ok']);
    if(this.row.status === 3) this.row.timestamp_deadline = undefined;
    if(!this.row.idCompany && !this.row.id_organization) return this.view.presentAlert('Attenzione', 'Devi applicare su company o organization', ['Ok']);
    if(this.row.id_organization){
      this.row.idCompany = undefined; // Se è impostata su una organizzazione non può essere assegnata ad una company
    }else if(this.row.idCompany){
      this.row.id_organization = undefined; // Se è impostata su una company non può essere assegnata ad una organizzazione
    }
  
    await this.view.showLoading("salvataggio in corso");
    View.updateView.next();
    
    let res: any;
    try {
      if(this.row._id){
        res = await this.api.patchActivity({
          _id: this.row._id,
          timestamp_deadline: Math.floor(this.row.timestamp_deadline / 1000) || undefined,
          idAdminManager: this.row.idAdminManager,
          description: this.row.description,
          status: parseInt(this.row.status.toString()),
          id_ticket: this.row.id_ticket,
          type_activity: this.row.type_activity || -1,
          imei_tracker: this.row.type_activity === 1 ? this.row.imei_tracker : undefined
        });
      }else{
        res = await this.api.postActivity({
          timestamp_deadline: Math.floor(this.row.timestamp_deadline / 1000) || undefined,
          idAdminManager: this.row.idAdminManager,
          description: this.row.description,
          status: parseInt(this.row.status.toString()),
          idCompany: this.row.idCompany,
          id_organization: this.row.id_organization,
          id_ticket: this.row.id_ticket,
          type_activity: this.row.type_activity || -1,
          imei_tracker: this.row.type_activity === 1 ? this.row.imei_tracker : undefined
        });
      }
      this.view.presentAlert('Successo', "Salvataggio avvenuto", ['Ok']);
      this.dismiss({ recordAdded: true });
    } catch (e) {
      console.error(e);
      await this.view.presentAlert(
        "Errore salvataggio",
        "Impossibile salvare l\'attività",
        ["Ok"],
      );
    }
    
    await this.view.hideLoading();
    View.updateView.next();
  }
  
  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
    });
  }

  changeActivityStatus(){
    if(typeof this.row.status !== 'number') this.row.status = parseInt(this.row.status);
  }
  
  changeActivityType(){
    if(typeof this.row.type_activity !== 'number') this.row.type_activity = parseInt(this.row.type_activity);
  }
  
  changeCompany(){
    if(this.row.idCompany){
      this.row.id_organization = undefined;
      this.row.imei_tracker = undefined;
      this.loadTicketsCompany();
      ViewService.updateView.next();
    }
  }
  
  loadTicketsCompany(){
    if(!this.company.$tickets || this.company.$tickets.length <= 0){
      // Recupero tutti i contatti e tutte i ticket della company
      this.company.$contacts = this.data.getContactsByCompany(this.company._id);
      this.company.$tickets = this.data.getTicketByCompany(this.company); // Si basa anche sui contatti trovati nel metodo precedente
    }
  }
  
  async changeOrganization(){
    if(this.row.id_organization){
      this.row.idCompany = undefined;
      this.row.imei_tracker = undefined;
      this.company = this.data.getCompanyByOrganizationId(this.row.id_organization); // Per recuperare i ticket su cui si possono applicare le attività
      if(this.company) this.loadTicketsCompany();
      const org: OrganizationAdmin = this.organizations.find((elem: any) => elem._id === this.row.id_organization);
      if(org && !org.$trackers_imei){
        await this.view.showLoading('Caricamento trackers organizzazione');
        try{
          const res: any = await this.api.getTrackersPartecipantOrganization(org._id);
          if(res){
            org.$trackers_imei = [];
            for(let raw of res){
              org.$trackers_imei.push({imei: raw.imei, label: raw.label_string});
            }
          }
        }catch(err){
          console.error(err);
        }
        await this.view.hideLoading();
      }
      this.trackers = org.$trackers_imei;
      ViewService.updateView.next();
    }
  }
  
  async openSelectTicket(event: any){
    const componentProps: any = {
      options: this.company.$tickets,
      type: 'tickets',
    }
    
    const popover = await this.popoverController.create({
      component: PopoverSelectComponent,
      event: event,
      translucent: true,
      componentProps: componentProps,
      cssClass: 'popover_big'
    });
    popover.onDidDismiss().then(async (data: any) => {
      if(data && data.data){
        this.ticket = data.data;
        this.row.id_ticket = this.ticket._id;
        ViewService.updateView.next();
      }
    });
    await popover.present();
  }
  
  rimuoviTicket(){
    this.ticket = undefined;
    this.row.id_ticket = undefined;
    ViewService.updateView.next();
  }
  
  // Salva il chip relativo alla company
  async saveChipCompany(event: any){
    try{
      const me: Admin = this.data.getAdminByEmail(AuthService.getEmail());
      const res: any = this.api.patchUpdateChipCompany(this.company._id, event.id, event.selected, me._id);
      if(res){
        const chip: any = this.chips_company.find((elem: any) => elem.id === event.id);
        if(chip) chip.selected = event.selected;
      }
    }catch(err){
      console.error(err);
    }
    ViewService.updateView.next();
  }
  
  
  async addNewActivityHistory(){
    //devo aggiungere una nuova attività in activity
    await this.view.showLoading("salvataggio in corso");
    
    let res;
    if(this.row._id){
      
      res = await this.api.patchHistoryActivity({
        _id: this.row._id,
        timestamp_activity: this.historyActivityAddTime,
        timestamp_created: Date.now(),
        description: this.historyActivityDescription
      });
      this.row.setAllFields(res)
    }
    this.clearHistoryData()
  
    View.updateView.next()
    await this.view.hideLoading();
    
  }
  
  async removeActivityFromHistory(timestamp:number){
  
    await this.view.showLoading("cancellazione in corso");
  
    //remove element
    let res;
    if(this.row._id){
      //aggiungere all'attivita il task che ho appena inserito, come posso fare?
      res = await this.api.deleteHistoryActivity({
        _id: this.row._id,
        timestamp_created: timestamp,
      });
      
      this.row.setAllFields(res)
    }
    
    View.updateView.next()
    await this.view.hideLoading();
  
  }
  
  clearHistoryData(){
    this.historyActivityDescription = '';
  }
  
  async editActivityFromHistory(index: number){
    this.row.history_list[index].editable = true;
  }
  removeEditable(index: number){
    this.row.history_list[index].editable = false;
  }
  async updateHistoryActionElement(index: number){
    await this.view.showLoading("Aggiornamento in corso");
    let res;
    if(this.row._id){
      //aggiungere all'attivita il task che ho appena inserito, come posso fare?
      res = await this.api.deleteHistoryActivity({
        _id: this.row._id,
        timestamp_created: this.row.history_list[index].timestamp_created,
      });
  
      res = await this.api.patchHistoryActivity({
        _id: this.row._id,
        timestamp_activity: this.row.history_list[index].timestamp_activity,
        timestamp_created: this.row.history_list[index].timestamp_created,
        description: this.row.history_list[index].activity_description
      });
      
      this.row.setAllFields(res)
    }
  
    View.updateView.next()
    await this.view.hideLoading();
  }
  
  changeHistoryActivityDate(event: any){
    this.historyActivityAddTime = new Date(event.detail.value).getTime();
  }
  
}
