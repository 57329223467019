
import {Partecipant} from "common-lib";
import {Subject} from 'rxjs';

export class ParticipantAdmin extends Partecipant{

  raw: string;
  timestampDeactivation: number;
  timestampDeactivation_ms: number;

  longitude: number;
  latitude: number;
  angle: number;
  speed: number;
  gsm_signal: number;
  external_voltage: number;
  gsm_cell_id: number;
  gsm_area_code: number;
  tripStatus: boolean;
  timestamp_tripStatus: number;
  din1: number;
  timestamp_din1: number;
  $selected: boolean = false;
  
  static participantUpdate = new Subject<ParticipantAdmin>();
  static participantUpdate$ = ParticipantAdmin.participantUpdate.asObservable();

  constructor(data?: any) {
    super(data);
    if(data){
      this.setDataAdmin(data);
    }
  }

  setDataAdmin(data: any){
    this.setData(data);
    this.raw = data;
    this.timestampDeactivation = data.timestampDeactivation;
    if(this.timestampDeactivation){
      this.timestampDeactivation_ms = this.timestampDeactivation * 1000;
    }

    this.longitude = data.longitude;
    this.latitude = data.latitude;
    this.angle = data.angle;
    this.speed = data.speed;
    this.gsm_signal = data.gsm_signal;
    this.external_voltage = data.external_voltage;
    this.gsm_cell_id = data.gsm_cell_id;
    this.gsm_area_code = data.gsm_area_code;
    this.tripStatus = data.tripStatus;
    this.timestamp_tripStatus = data.timestamp_tripStatus;
    this.din1 = data.din1;
    this.timestamp_din1 = data.timestamp_din1;
  
    ParticipantAdmin.participantUpdate.next(this); // Ogni volta che viene fatto un set data lancio un evento generale così chi vuole può aggiornare il riferimento possa farlo
  
  }

}
