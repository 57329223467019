import {TypeParticipant} from "./typeParticipant";
import {TypeTrackerGps} from "./typeTrackerGps";

export class Platform{
  
  private _email_platform: {
    fromMail: string;
    fromName: string;
    label: string;
    templateMail: string;
    type: number;
  }[];
  name: string;
  type: number;
  type_partecipant: TypeParticipant[] = [];
  private _whatsapp_number_platform: any[];
  private _sms_number_platform: any[];
  private _call_number_platform: any[];
  
  ios_review_version: string;
  ios_min_version: string;
  android_min_version: string;
  image: string;
  url_web_pre_production: string;
  
  constructor(rawObj?: any, options? : {
    getSim?: (number) => {description: string, type_sim: number},
    getTypeTracker?: (number) => TypeTrackerGps
  }) {
    this.setAllFields(rawObj, options);
  }
  
  setAllFields(rawObj?: any, options? : {
    getSim?: (number) => {description: string, type_sim: number},
    getTypeTracker?: (number) => TypeTrackerGps
  }) {
    if (rawObj) {
      this.email_platform = rawObj.email_platform;
      this.name = rawObj.name;
      this.type = rawObj.type;
      this.whatsapp_number_platform = rawObj.whatsapp_number_platform;
      this.sms_number_platform = rawObj.sms_number_platform;
      this.call_number_platform = rawObj.call_number_platform;
      this.ios_review_version = rawObj.ios_review_version;
      this.ios_min_version = rawObj.ios_min_version;
      this.android_min_version = rawObj.android_min_version;
      this.image = rawObj.image;
      this.url_web_pre_production = rawObj.url_web_pre_production;
      
      if(rawObj.type_partecipant?.length > 0){
        for(let raw of rawObj.type_partecipant){
          this.type_partecipant.push(new TypeParticipant(raw, {
            getSim: options?.getSim,
            getTypeTracker: options?.getTypeTracker
          }));
        }
      }
    }
  }
  
  getParticipantByType(type: number): TypeParticipant{
    return this.type_partecipant.find((elem: TypeParticipant) => {return elem.type_trackerGps === type});
  }
  
  // * getter/setter
  
  public get email_platform(): {
    fromMail: string;
    fromName: string;
    label: string;
    templateMail: string;
    type: number;
  }[] {
    return this._email_platform;
  }
  public set email_platform(value: {
    fromMail: string;
    fromName: string;
    label: string;
    templateMail: string;
    type: number;
  }[]) {
    this._email_platform = value ?? [];
  }
  
  public get whatsapp_number_platform(): any[] {
    return this._whatsapp_number_platform;
  }
  public set whatsapp_number_platform(value: any[]) {
    this._whatsapp_number_platform = value ?? [];
  }
  
  public get sms_number_platform(): any[] {
    return this._sms_number_platform;
  }
  public set sms_number_platform(value: any[]) {
    this._sms_number_platform = value ?? [];
  }
  
  public get call_number_platform(): any[] {
    let numbers: any = []
    if(this._call_number_platform){
      for(let phone of this._call_number_platform){
        let split = phone.number.split('_')
        numbers.push({type: split[1], phone_owner: split[0], ...phone})
      }
    }
    return numbers;
  }
  public set call_number_platform(value: any[]) {
    this._call_number_platform = value ?? [];
  }
}
