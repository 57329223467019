import {
  Injectable, Pipe,
  PipeTransform
} from '@angular/core';
import {Platform} from "../classes/platform";

@Pipe({
  name: 'filterPlatforms'
})
@Injectable()
export class FilterPlatformsPipe implements PipeTransform {
  transform(values: Map<number, Platform>, changed?: number): string {
    if(values.size === 1){
      for(let value of values.values()){
        return value?.name || 'Non assegnata';
      }
    }else if(values.size > 1){
      return `${values.size} Piattafome`;
    }else{
      return "Piattaforma";
    }
  }
}


