import {
  Injectable, Pipe,
  PipeTransform
} from '@angular/core';
import Company from "../classes/company";
import {DataService} from "../services/data.service";

@Pipe({
  name: 'companiesSort'
})
@Injectable()
export class CompaniesSortPipe implements PipeTransform {
  transform(companies: Company[], filter: string, idCompany?: string, changed?: number): Company[] {
    if(!companies || companies.length <= 0) return companies;
    let pinned: Company[] = [];
    let unPinned: Company[] = [];
    for(let company of companies){
      if(company.$isPinned){
        pinned.push(company);
      }else{
        unPinned.push(company);
      }
    }
    pinned = pinned.sort((a, b) => {
      if ( a.$lastComunication < b.$lastComunication ){
        return 1;
      }
      if ( a.$lastComunication > b.$lastComunication ){
        return -1;
      }
      return 0;
    });
    unPinned = unPinned.sort((a, b) => {
      if ( a.$lastComunication < b.$lastComunication ){
        return 1;
      }
      if ( a.$lastComunication > b.$lastComunication ){
        return -1;
      }
      return 0;
    });
    companies = pinned.concat(unPinned);
    let filteredCompany: Company[] = [];
    switch (filter) {
      case 'in_scadenza':
        for(let company of companies){
          if(company.$chat_in_expiration){
            filteredCompany.push(company);
          }
        }
        break;
      case 'da_leggere':
        for(let company of companies){
          if(company.$countUnread > 0){
            filteredCompany.push(company);
          }
        }
        break;
      case 'company':
        if(idCompany){
          const c: Company = DataService.array.companies.find((elem: Company) => elem._id === idCompany);
          if(c) filteredCompany.push(c);
        }
        break;
      default:
        filteredCompany = companies;
        break;
    }
    return filteredCompany;
  }
}


