import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CcEmailsInfoPageRoutingModule } from './cc-emails-info-routing.module';

import { CcEmailsInfoPage } from './cc-emails-info.page';
import { ComponentsModule } from 'src/components/components.module';
import { CommonLibModule } from 'common-lib';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CcEmailsInfoPageRoutingModule,
    
    ComponentsModule,
    CommonLibModule,
  ],
  declarations: [CcEmailsInfoPage]
})
export class CcEmailsInfoPageModule {}
