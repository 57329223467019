import {Admin} from "./admin";
import Company from "./company";
import {ParticipantAdmin} from './participantAdmin';
import {Subscription} from 'rxjs';
import {Ticket} from './ticket';
import {OrganizationAdmin} from './organizationAdmin';

export default class Activity {
  _id: string;
  private _timestamp_create: number;
  private _timestamp_deadline: number;
  private _idAdminCreator: string;
  private _idAdminManager: string;
  private _idCompany: string;
  private _id_organization: string;
  private _imei_tracker: string;
  $participant_tracker: ParticipantAdmin;
  $participantListener: Subscription;
  description: string;
  history_list: HistoryActivity[] = [];
  media_filename: string;
  status: number; // 1: to do; 2: doing; 3: done;
  private _type_activity: number;
  $typeActivity: {description: string, type: number};
  
  private _id_ticket: string;
  $ticket: Ticket;
  
  private _$adminCreator: Admin;
  private _$adminManager: Admin;
  
  private _$timestamp_deadline_ISOString_precisionMinute_local: string;
  
  private $getAdmin: (string) => Admin = () => {return undefined};
  private $getActivity: (number) => any = () => {return undefined};
  private $getCompany: (string) => Company = () => {return undefined};
  private $getParticipantByImei: (string) => ParticipantAdmin = () => {return undefined};
  private $getTicketById: (string) => Ticket = () => {return undefined};
  private $getOrganizationById: (string) => OrganizationAdmin = () => {return undefined};
  
  $company: Company;
  $organization: OrganizationAdmin;
  
  constructor(rawObj?: any, options? : {
    getAdmin?: (string) => Admin,
    getActivity?: (number) => {description: string, type: number},
    getCompany?: (string) => Company,
    getParticipantByImei?: (string) => ParticipantAdmin,
    getTicketById?: (string) => Ticket,
    getOrganizationById?: (string) => OrganizationAdmin
  }) {
    this.setAllFields(rawObj, options);
  }
  
  setAllFields(rawObj?: any, options? : {
    getAdmin?: (string) => Admin,
    getActivity?: (number) => {description: string, type: number},
    getCompany?: (string) => Company,
    getParticipantByImei?: (string) => ParticipantAdmin,
    getTicketById?: (string) => Ticket,
    getOrganizationById?: (string) => OrganizationAdmin
  }) {
    if(options) {
      if (options.getAdmin) this.$getAdmin = options.getAdmin;
      if (options.getActivity) this.$getActivity = options.getActivity;
      if (options.getCompany) this.$getCompany = options.getCompany;
      if (options.getParticipantByImei) this.$getParticipantByImei = options.getParticipantByImei;
      if (options.getTicketById) this.$getTicketById = options.getTicketById;
      if (options.getOrganizationById) this.$getOrganizationById = options.getOrganizationById;
    }
    if (rawObj) {
      this._id = rawObj._id;
      this.timestamp_create = rawObj.timestamp_create;
      this.timestamp_deadline = rawObj.timestamp_deadline;
      this.idAdminCreator = rawObj.idAdminCreator;
      this.idAdminManager = rawObj.idAdminManager;
      this.idCompany = rawObj.idCompany;
      this.status = rawObj.status;
      this.description = rawObj.description;
      this.media_filename = rawObj.media_filename;
      this.type_activity = rawObj.type_activity;
      this.id_organization = rawObj.id_organization;
      this.imei_tracker = rawObj.imei_tracker;
      if(rawObj.history_list){
        this.history_list = []
        for(let activity of rawObj.history_list){
          this.history_list.push(new HistoryActivity(activity))
        }
      }
      this.id_ticket = rawObj.id_ticket;
    }
  }
  
  secondsToISOString(val: number = 0, precision?: string, local?: boolean) {
    try{
      // console.log('secondsToISOString() val', val)
      // const date/* : Date */ = new Date(val * 1000)
      let timezoneOffset_secs: number = 0;
      if (local) {
        // getTimezoneOffset() returns minutes (to be exact: -120)
        // HACK the '-' is an hack(forzatura)
        timezoneOffset_secs = (- new Date(val * 1000).getTimezoneOffset()) * 60
      }
      val += timezoneOffset_secs;
      const val_ms = val * 1000;
      switch (precision) {
        case "min":
        case "minute":
          // return eg. "2021-03-27T18:25"
          return new Date(val_ms).toISOString().slice(0, -8);
          break;
        default:
          // precision: ms(millisecond)
          // return eg. "2021-03-27T18:25:51.123Z"
          return new Date(val_ms).toISOString();
      }
    }catch(err){
      console.error(err);
    }
  }
  
  
  public get idAdminCreator(): string {
    return this._idAdminCreator;
  }
  public set idAdminCreator(value: string) {
    value = (value === "") ? null : value;
    this._idAdminCreator = value;
    // set custom fields
    this.$adminCreator = this.$getAdmin(this.idAdminCreator);
  }
  
  public get idAdminManager(): string {
    return this._idAdminManager;
  }
  public set idAdminManager(value: string) {
    value = (value === "") ? null : value;
    this._idAdminManager = value;
    // set custom fields
    this.$adminManager = this.$getAdmin(this.idAdminManager);
  }
  
  public get idCompany(): string {
    return this._idCompany;
  }
  public set idCompany(value: string) {
    value = (value === "") ? null : value;
    this._idCompany = value;
    if(this.idCompany){
      this.$company = this.$getCompany(this.idCompany);
    }
  }
  
  public get timestamp_deadline(): any {
    return this._timestamp_deadline * 1000;
  }
  public set timestamp_deadline(value: any) {
    if (typeof value === "string") {
      // ISOString to seconds
      value = new Date(value).getTime() / 1000; // seconds
    }
    // avoid recursion
    if (this.timestamp_deadline !== value) {
      this._timestamp_deadline = value;
      // set custom fields
      this.$timestamp_deadline_ISOString_precisionMinute_local = value;
    }
  }
  
  public get timestamp_create(): any {
    return this._timestamp_create * 1000;
  }
  public set timestamp_create(value: any) {
    this._timestamp_create = value;
  }
  
  public get type_activity(): number {
    return this._type_activity;
  }
  public set type_activity(value: number) {
    this._type_activity = value;
    this.$typeActivity = this.$getActivity(this.type_activity);
  }
  
  public get $adminCreator(): Admin {
    return this._$adminCreator;
  }
  public set $adminCreator(value: Admin) {
    // const foundObj = DataService.array.admin
    //   .find((el) => el._id === value);
    // this._$adminCreator = foundObj;
    // // set subfields
    // this.$adminCreator_nickname = foundObj?.nickname;
    // View.updateView.next()
    this._$adminCreator = value;
  }
  
  public get $adminManager(): Admin {
    return this._$adminManager;
  }
  public set $adminManager(value: Admin) {
    // const foundObj = DataService.array.admin
    //   .find((el) => el._id === value);
    // this._$adminManager = foundObj;
    // // set subfields
    // this.$adminManager_nickname = foundObj?.nickname;
    // View.updateView.next()
    this._$adminManager = value;
  }
  
  public get $timestamp_deadline_ISOString_precisionMinute_local(): any {
    return this._$timestamp_deadline_ISOString_precisionMinute_local;
  }
  public set $timestamp_deadline_ISOString_precisionMinute_local(value: any) {
    if (typeof value === "number") {
      value = this.secondsToISOString(value, "minute", true);
    }
    // avoid recursion
    if (this.$timestamp_deadline_ISOString_precisionMinute_local !== value) {
      this._$timestamp_deadline_ISOString_precisionMinute_local = value;
      this.timestamp_deadline = value;
      // View.updateView.next()
    }
  }
  
  get id_organization(): string {
    return this._id_organization;
  }
  
  set id_organization(value: string) {
    this._id_organization = value;
    if(this.id_organization){
      this.$organization = this.$getOrganizationById(this._id_organization);
    }
  }
  
  get id_ticket(): string {
    return this._id_ticket;
  }
  
  set id_ticket(value: string) {
    this._id_ticket = value;
    if(this.id_ticket){
      this.$ticket = this.$getTicketById(this.id_ticket);
    }
  }
  
  get imei_tracker(): string {
    return this._imei_tracker;
  }
  
  set imei_tracker(value: string) {
    this._imei_tracker = value;
    if(!this.$participantListener){ // Setto lister per aggiornamento organization
      this.$participantListener = ParticipantAdmin.participantUpdate$.subscribe((participant?: ParticipantAdmin) => {
        if(participant.imei === this._imei_tracker){
          if(this.$participant_tracker !== participant){
            this._imei_tracker = participant.imei; // Richiamo il setter
          }
        }
      });
    }
    this.$participant_tracker = this.$getParticipantByImei(this.imei_tracker);
  }
}


export class HistoryActivity{
 
  timestamp_activity: number;
  timestamp_created: number;
  activity_description: string;
  
  private _editable: boolean;
  
  constructor(data?: any) {
    if(data){
      this.setData(data)
    }
  }
  
  setData(data?: any){
    if(data){
      this.timestamp_activity = data.timestamp_activity;
      this.timestamp_created = data.timestamp_created;
      this.activity_description = data.activity_description;
    }
  }
  
  get editable(): boolean {
    return this._editable;
  }
  
  set editable(value: boolean) {
    this._editable = value;
  }
  
 
  

}
