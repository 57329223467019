import {TypeTrackerGps} from "./typeTrackerGps";

export class TypeParticipant{

  type: number;
  private _type_trackerGps: number;
  $type_trackerGps_info: TypeTrackerGps;
  avl_id_list: number[];
  private _type_sim_list: number[];
  
  $sims: {description: string, type_sim: number}[] = [];
  
  $getSim: (number) => {description: string, type_sim: number} = () => {return undefined};
  $getTypeTracker: (number) => TypeTrackerGps = () => {return undefined};

  constructor(data?: any, options? : {
    getSim?: (number) => {description: string, type_sim: number},
    getTypeTracker?: (number) => TypeTrackerGps
  }){
    if(data){
      this.setData(data, options);
    }
  }

  setData(data?: any, options? : {
    getSim?: (number) => {description: string, type_sim: number},
    getTypeTracker?: (number) => TypeTrackerGps
  }){
    if(options?.getSim) this.$getSim = options.getSim;
    if(options?.getTypeTracker) this.$getTypeTracker = options.getTypeTracker;
    this.type = data.type;
    this.type_trackerGps = data.type_trackerGps;
    this.avl_id_list = data.avl_id_list;
    this.type_sim_list = data.type_sim_list;
  }
  
  get type_sim_list(): number[] {
    return this._type_sim_list;
  }
  
  set type_sim_list(value: number[]) {
    this._type_sim_list = value;
    this.$sims = [];
    if(this.type_sim_list){
      for(let type of this.type_sim_list){
        this.$sims.push(this.$getSim(type));
      }
    }
  }
  
  get type_trackerGps(): number {
    return this._type_trackerGps;
  }
  
  set type_trackerGps(value: number) {
    this._type_trackerGps = value;
    this.$type_trackerGps_info = this.$getTypeTracker(this.type_trackerGps);
  }
}
