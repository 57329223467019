import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Platform} from '../../classes/platform';
import {DataService} from '../../services/data.service';
import {TypeParticipant} from '../../classes/typeParticipant';
import {TrackerAdmin} from '../../classes/trackerAdmin';

@Component({
  selector: 'app-select-fota-files',
  templateUrl: './select-fota-files.component.html',
  styleUrls: ['./select-fota-files.component.scss'],
})
export class SelectFotaFilesComponent implements OnInit {
  
  @Input() type: 'firmware' | 'configuration';
  @Input() tracker: TrackerAdmin;
  
  @Input() fota_firmwares: any[];
  @Input() fota_configurations: any[];
  
  platforms: Platform[] = [];
  platform: Platform;
  fota_config_files: {id: number, name: string, default: boolean, type_trackerGps: number}[] = [];
  fota_config_file: {id: number, name: string, default: boolean, type_trackerGps: number};
  fota_firmware_files: {id: number, name: string, firmware: string, default: boolean}[] = [];
  fota_firmware_file: {id: number, name: string, firmware: string, default: boolean};
  
  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {}
  
  ionViewWillEnter(){
    this.initPage();
  }
  
  initPage(){
    this.platforms = DataService.platforms;
    this.platform = this.tracker.$platform;
    if(this.platform) this.checkFotaFiles();
  }
  
  checkFotaFiles(){
    if(this.type === 'configuration'){
      this.fota_config_files = [];
      const config: any = this.fota_configurations.find((elem: any) => elem.platform_type === this.platform.type);
      if(config?.files) this.fota_config_files = config.files.filter(elem => elem.type_trackerGps === this.tracker.type_trackerGps && elem.type_sim === this.tracker.type_sim);
      if(this.fota_config_files?.length > 0){
        this.fota_config_file = this.fota_config_files[0];
        for(let file of this.fota_config_files){
          if(file.default){
            this.fota_config_file = file;
            break;
          }
        }
      }
    }
  
    if(this.type === 'firmware'){
      let firmware: any;
      if(this.fota_firmwares){
        firmware = this.fota_firmwares.find((elem: any) => elem.type_trackerGps === this.tracker.type_trackerGps);
      }
      this.fota_firmware_files = firmware?.files || [];
      if(this.fota_firmware_files?.length > 0){
        this.fota_firmware_file = this.fota_firmware_files[0];
        for(let file of this.fota_firmware_files){
          if(file.default){
            this.fota_firmware_file = file;
            break;
          }
        }
      }
    }
    
  }
  
  confirmFile(){
    switch (this.type) {
      case 'firmware':
        return this.modalCtrl.dismiss(this.fota_firmware_file);
      case 'configuration':
        return this.modalCtrl.dismiss(this.fota_config_file);
    }
  }
  
  dismiss() {
    this.modalCtrl.dismiss();
  }

}
