import { NgModule,CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {CommonLibModule} from "common-lib";
import {HttpClientModule} from "@angular/common/http";
import {ComponentsModule} from "../components/components.module";
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import {PipesModule} from "../pipes/pipes.module";
import {IonicStorageModule} from "@ionic/storage-angular";
import { CompanySelectPageModule } from './company-select/company-select.module';
import { StockAmazonRecapPageModule } from './stock-amazon-recap/stock-amazon-recap.module';
import { TwilioSimStatusSelectPageModule } from './twilio-sim-status-select/twilio-sim-status-select.module';
import { CcEmailsInfoPageModule } from './cc-emails-info/cc-emails-info.module';
import { WhatsappMsgToExpiredChatPageModule } from './whatsapp-msg-to-expired-chat/whatsapp-msg-to-expired-chat.module';
import {NgJsonEditorModule} from 'ang-jsoneditor';
import { FormsModule } from '@angular/forms';


registerLocaleData(localeIt);

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    // BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    CommonLibModule,
    IonicStorageModule.forRoot(),
    HttpClientModule,
    ComponentsModule,
    VirtualScrollerModule,
    PipesModule,
    
    // modal
    CcEmailsInfoPageModule,
    CompanySelectPageModule,
    StockAmazonRecapPageModule,
    TwilioSimStatusSelectPageModule,
    WhatsappMsgToExpiredChatPageModule,
    NgJsonEditorModule,
    FormsModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    { provide: LOCALE_ID, useValue: "it-IT" },
  ],
  exports: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
  
})
export class AppModule {}
