
export default class OnomondoCommand {
  
  direction: string
  iccid: string;
  sim_id: string;
  sim_label: string;
  to: string;
  text: string;
  text_base64: string;
  timestamp_create: string;
  
  constructor(rawObj?: any) {
    this.setAllFields(rawObj);
  }
  
  setAllFields(rawObj: any = {}, ) {
    if (rawObj) {
      this.direction = rawObj.direction;
      this.iccid = rawObj.iccid;
      this.sim_id = rawObj.sim_id;
      this.sim_label = rawObj.sim_label;
      this.to = rawObj.to;
      this.text = rawObj.text;
      this.text_base64 = rawObj.text_base64;
      this.timestamp_create = rawObj.timestamp_create
    }
  }
  
  
  readIccidImeiFromSMS(): {imei: string, iccd: string}{
    let response: any = {imei: '', iccid: ''};
    if(this.text && this.text.indexOf('IMEI:') >= 0 && this.text.indexOf('CCID:') >= 0){
      // Recupero imei e iccid da sms
      const parts: string[] = this.text.split(',');
      if(parts.length === 2){
        response.imei = parts[0];
        response.imei = response.imei.replace('IMEI: ', '');
        response.imei = response.imei.replace(',', '');
        response.iccid = parts[1];
        response.iccid = response.iccid.replace(' CCID: ', '');
      }
    }
    return response;
  }
  
  
  readResponseCommandFromSMS(expected_response: string): boolean{
    if(this.text && this.text.indexOf(expected_response) >= 0){
      return true;
    }
    return false;
  }
  
  
  
}
