import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrganizationAdmin} from '../../classes/organizationAdmin';

@Component({
  selector: 'app-organization-element',
  templateUrl: './organization-element.component.html',
  styleUrls: ['./organization-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationElementComponent implements OnInit {
  
  @Input() organization: OrganizationAdmin;
  
  @Output() dxClick = new EventEmitter<any>();
  @Output() sxClick = new EventEmitter<any>();
  
  
  constructor() { }

  ngOnInit() {}

}
