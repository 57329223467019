import { Pipe, PipeTransform } from '@angular/core';
import {Ticket} from '../classes/ticket';
import {SubChat} from '../classes/subChat';

@Pipe({
  name: 'chatTypeTickets'
})
export class ChatTypeTicketsPipe implements PipeTransform {
  
  transform(sub_chat: SubChat, tickets: Ticket[],  changed: number): number {
    if(!sub_chat || !tickets || tickets.length <= 0) return 0;
    
    let tickets_hash = new Map<string, Ticket>();
    for(const ticket of tickets){
      if(ticket.type.valueOf() === sub_chat.type.valueOf()) tickets_hash.set(ticket._id, ticket);
    }
    
    return Array.from(tickets_hash.values()).length || 0;
  }

}
