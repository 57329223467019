import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {environment} from '../../environments/environment';
import {Utility, ViewService} from 'common-lib';
import {ApiService} from '../../services/api.service';
import {Order} from '../../classes/order';
import {ParticipantAdmin} from '../../classes/participantAdmin';
import {DataService} from '../../services/data.service';
import {JsonEditorComponent, JsonEditorOptions} from 'ang-jsoneditor';
import {TrackerAdmin} from '../../classes/trackerAdmin';
import {OrganizationAdmin} from '../../classes/organizationAdmin';
import {jsPDF} from 'jspdf';
import {DomSanitizer} from '@angular/platform-browser';
import {DatePipe} from '@angular/common';
import {UtilService} from '../../services/util.service';

@Component({
  selector: 'app-order-action',
  templateUrl: './order-action.component.html',
  styleUrls: ['./order-action.component.scss'],
  providers: [DatePipe]
})
export class OrderActionComponent implements OnInit {

  @Input() order: Order;
  @Input() orders: Order[];
  @Input() title: string;
  @Input() type: 'details' | 'invoice' | 'list_imei' | 'tracking_url' | 'received' | 'activated' | 'cancelled';
  
  editorOptions: JsonEditorOptions;
  @ViewChild(JsonEditorComponent, { static: false }) editor: JsonEditorComponent;
  
  imei_list: string;
  url_tracking: string;
  is_received: any;
  note: string;
  shipping_number: string;
  imeis_status: any[];
  lines: any[] = [];
  transportation: string;
  transportation_subject: string;
  transportation_note: string;
  n_packages: number;
  weight: number;
  port: string;
  date_start_shipping: any;
  
  constructor(
    private modalCtrl: ModalController,
    public view: ViewService,
    private api: ApiService,
    private data: DataService,
    private sanitizer: DomSanitizer,
    private datePipe: DatePipe,
    private util: UtilService
  ) {}

  ngOnInit() {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.indentation = 1;
    this.editorOptions.expandAll = false;
    this.editorOptions.mainMenuBar = true;
    this.editorOptions.statusBar = false;
    this.editorOptions.mode = 'tree';
    this.editorOptions.modes = ['tree', 'code'];
  }
  
  ionViewWillEnter() {}
  
  ionViewDidEnter() {
    this.initPage();
  }
  
  ionViewDidLeave() {}
  
  initPage(){
    switch (this.type){
      case 'details': {
        this.note = this.order.note;
        break;
      }
      case 'invoice': {
        this.api.getOrganization(this.order.organization_id).then(async (res: any) => {
          const organization: OrganizationAdmin = await this.data.getOrganizationById(res._id);
          if(organization){
            organization.setData(res);
            this.order.organization_id = res._id;
            let elenco_prodotti = DataService.general_parameter.elenco_prodotti_shop.find((elem)=>elem.platform_type === organization.platform_type);
            this.order.$invoice.mapInvoiceLineWithProduct(elenco_prodotti.products, this.order.$invoice.metadata?.type);
            if(this.order.$invoice &&  this.order.$invoice.metadata?.type !== 'free'){
            }
          }
        }).catch(err => {
          console.error(err);
        });
        break;
      }
      case 'list_imei': {
        break;
      }
      case 'tracking_url': {
        this.transportation = this.order.transportation || 'Corriere espresso';
        this.transportation_subject = this.order.subject_transportation || 'Conto vendita';
        this.n_packages = this.order.n_packages || 1;
        this.weight = this.order.weight || 2.3;
        this.port = this.order.port || 'Franco';
        this.date_start_shipping = Utility.toDateString(this.order.date_start_shipping ? new Date(this.order.date_start_shipping) : new Date());
        this.url_tracking = this.order.url_tracking;
        this.shipping_number = this.order.shipping_number;
        this.transportation_note = this.order.transportation_note || `DDT relativo a Fattura n. ${this.order.$invoice?.metadata?.number_fattura_elettronica || '--'}`;
        this.restoreLine()
        break;
      }
      case 'received': {
        this.is_received = this.order.status >= 3;
        break;
      }
      case 'activated': {
        if(this.order.$imei_activation_status?.$imei_not_activated){
          for(let imei of this.order.$imei_activation_status.$imei_not_activated){
            imei['selected'] = false;
          }
        }
        break;
      }
      case 'cancelled': {
        // Fa api per vedere lo stato del tracker
        this.checkTrackerStatus();
        break;
      }
    }
  }
  
  addLine(){
    this.lines.push({qty: 0, description: ''})
  }
  
  restoreLine(){
    for( let line of this.order.$invoice?.lines) {
      if(line._prodotto_associato){
        let description: string;
        let _desc: string = line._prodotto_associato.description['it'] || line._prodotto_associato.description
        switch (line._prodotto_associato.type) {
          case 1:
            description = `Tracker Gps ${_desc}`;
            break;
          case 2:
            description = `Accessorio ${_desc}`;
            break;
          default:
            continue;
        }
        this.lines.push({quantity: line.quantity, description: description})
      }else{
        this.lines.push({quantity: line.quantity, description: line.description})
      }
      
    }
  }
  
  
  async removeLine(index: number){
    await this.view.presentAlert('Sei sicuro?', `Stai per eliminare una linea dalla bolla`, [{
      text: 'Annulla',
      role: 'cancel',
      cssClass: 'secondary'
    }, {
      text: 'Conferma',
      handler: () => {
        this.lines.splice(index, 1)
      }
    }]);
  }
  
  // Controlla lo stato dei tracker gps in caso di cancellazione
  async checkTrackerStatus(){
    await this.view.showLoading('Recupero tracker...');
    try{
      this.imeis_status = [];
      const res: any = await this.api.getTrackerInOrder(this.order._id);
      if(res){
        let trackers: any = {};
        for(let raw of res){
          trackers[raw.imei] = new TrackerAdmin(raw);
        }
        for(let imei of this.order.list_imei){
          let status: string, status_error: string;
          if(this.data.getParticipantByImei(imei)){
            status_error = 'Tracker attivo, liberare da pagina dettaglio del tracker';
          }else {
            if(!trackers[imei]){
              status_error = 'Tracker inesistente';
            }else if(trackers[imei].status_trackerGps === 1 && trackers[imei].magazzino_trackerGps !== 1){
              status_error = 'Tracker disponibile ma non in magazzino Wi-tek, modificare pagina da dettaglio tracker';
            }else if(trackers[imei].status_trackerGps === 1 && trackers[imei].magazzino_trackerGps === 1){
              status = 'Tracker correttamente liberato';
            }else if(trackers[imei].status_trackerGps === 2){
              status = 'Tracker già distrutto';
            }else{
              status_error = 'Stato sconosciuto';
            }
          }
          this.imeis_status.push({
            imei: imei,
            status: status,
            status_error: status_error
          });
        }
      }
    }catch(err){
      console.error(err);
    }
    await this.view.hideLoading();
  }
  
  dismiss() {
    this.modalCtrl.dismiss();
  }
  
  // Apre il portale nella pagina billing per i controlli delle fatture
  async openBillingPortal(){
    try{
      await this.view.showLoading('Apertura portale in corso...');
      await this.util.openPortal(this.order.$organization, !environment.production, 'billing')
    }catch(err){
      console.error(err);
      this.view.presentAlert('Errore', 'Impossibile aprire il portale', ['OK']);
    }
    await this.view.hideLoading();
  }
  
  async openCheckPayments(){
    const url: string = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/check-payments?id_organization=${this.order.$organization._id}`;
    window.open(url, '_blank');
  }
  
  // Salva la lista di imei nell'ordine e imposta lo stato dell'ordine come pronto
  async saveImeiList(no_trackers?: boolean){
    try{
      if(!no_trackers && (!this.order.list_imei || this.order.list_imei.length <= 0) && (!this.imei_list || this.imei_list.length <= 0)) {
        return await this.view.presentAlert('Attenzione', `Devi specificare gli IMEI dei localizzatori.`, ['Ok']);
      }
      if(no_trackers){
        return await this.view.presentAlert('Sei sicuro?', `Confermi che è un ordine senza localizzatori?`, [{
          text: 'Annulla',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Conferma',
          handler: async () => {
            this.savePreparedOrder([]);
          }
        }]);
      }
      let imeis: string[] = this.imei_list.split('\n');
      let imeis_to_save: string[] = [];
      for(let imei of imeis){
        if(imei && imei.length > 0) imeis_to_save.push(imei);
        if(this.order.list_imei?.length > 0 && this.order.list_imei.find((elem: string) => elem === imei)){ // ho già l'imei in lista ritorno errore
          return this.view.presentAlert('Attenzione', `${imei} è già in questo ordine!`, ['OK']);
        }
      }
      if(imeis_to_save.length > 0){
        this.savePreparedOrder(imeis_to_save);
      }
    }catch(err){
      console.error(err);
      this.view.presentAlert('Errore', 'Impossibile salvare ordine', ['OK']);
    }
    await this.view.hideLoading();
  }
  
  private async savePreparedOrder(imeis_to_save: string[]){
    try{
      await this.view.showLoading('Salvataggio ordine...');
      const res: any = await this.api.patchPrepareOrder(this.order._id, imeis_to_save);
      if(res){
        this.order.setAllFields(res);
      }
    }catch(err){
      console.error(err);
      if(err?.error?.type === 2){
        let imeis: string = "";
        if(err.error.trackers_in_error){
          for(let imei of err.error.trackers_in_error){
            imeis += `<br>${imei}`;
          }
        }
        this.view.presentAlert('Errore', `Alcuni tracker non sono disponibili, controlla che esistano, siano programmati e non in altri ordini<br><small>${imeis}</small>`, ['OK'])
      }else{
        this.view.presentAlert('Errore', 'Impossibile salvare ordine', ['OK']);
      }
    }
    await this.view.hideLoading();
  }
  
  // Salva url della spedizione e imposta lo stato come spedito
  async saveTrackingUrl(){
    try{
      if(this.order.status < 1 || this.order.status === 99 || this.order.status === 98) return this.view.presentAlert('Attenzione', 'Ordine non ancora pronto. Imposta come pronto nello step precedente.', ['Ok']);
      if(!this.shipping_number) return this.view.presentAlert('Attenzione', 'Devi inserire numero di spedizione', ['Ok']);
      if(!this.url_tracking) return this.view.presentAlert('Attenzione', 'Devi inserire un URL valido', ['Ok']);
      await this.view.showLoading('Salvataggio ordine...');
      const res: any = await this.api.patchTrackingUrl(this.order._id, this.shipping_number, this.url_tracking, this.order.organization_id);
      if(res){
        this.order.setAllFields(res);
      }
    }catch(err){
      console.error(err);
      this.view.presentAlert('Errore', 'Impossibile salvare ordine', ['OK']);
    }
    await this.view.hideLoading();
  }
  
  // Salva lo stato di consegna dell'ordine
  async saveOrderDelivered(){
    try{
      if(this.order.status < 1 || this.order.status === 99 || this.order.status === 98) return this.view.presentAlert('Attenzione', 'Ordine non ancora spedito. Specifica url di tracking nello step precedente.', ['Ok']);
      if(!this.is_received || this.is_received == 'false'){
        this.is_received = false;
      }else{
        this.is_received = true;
      }
      await this.view.showLoading('Salvataggio ordine...');
      const res: any = await this.api.patchDeliveredStatus(this.order._id, this.is_received);
      if(res){
        this.order.setAllFields(res);
      }
    }catch(err){
      console.error(err);
      this.view.presentAlert('Errore', 'Impossibile salvare ordine', ['OK']);
    }
    await this.view.hideLoading();
  }
  
  // Seleziona tutti gli elementi di una lista
  selectAllImei(list: any[], value: boolean){
    for(let elem of list){
      elem['selected'] = value;
    }
  }
  
  // Attiva tutti i tracker ancora attivabili manualmente
  async activateTrackers(){
    if(this.order.$imei_activation_status?.$imei_not_activated){
      let to_activate: any[] = [];
      let to_activate_str: string = "<br>";
      for(let imei of this.order.$imei_activation_status.$imei_not_activated) {
        if (imei['selected']) {
          to_activate.push(imei);
          to_activate_str += `<br>${imei.imei}`;
        }
      }
      if(to_activate.length <= 0) return this.view.presentAlert('Attenzione', 'Nessun tracker selezionato', ['OK']);
      return await this.view.presentAlert('Sei sicuro?', `Vuoi attivare tutti i tracker selezionati?<small>${to_activate_str}</small>`, [{
        text: 'Annulla',
        role: 'cancel',
        cssClass: 'secondary'
      }, {
        text: 'Conferma',
        handler: async () => {
          await this.view.showLoading('Attivazione tracker manuale in corso...');
          for(let imei of to_activate){
            imei.api_error = undefined;
            imei.api_success = undefined;
            try{
              const res: any = await this.api.postTrackerManualActivation(imei.imei, this.order.organization_id);
              const participant: ParticipantAdmin = new ParticipantAdmin(res);
              DataService.map.participants_trackers.set(participant.imei, participant);
              imei.api_success = 'Localizzatore attivato';
              this.order.setActivationStatus();
            }catch(err){
              console.error(err);
              imei.api_error = 'Errore generico';
              if(err.error?.type === 2){
                imei.api_error = err.error.description;
              }
            }
            await Utility.wait(2000);
          }
          await this.view.hideLoading();
        }
      }]);
    }
  }
  
  // Cancella l'ordine
  async cancelOrder(){
    let message: string = `Vuoi annullare l'ordine?`;
    if(this.order.$invoice?.status_formatted === 'paid'){
      message += `<br><b>La fattura è pagata quindi dovrai occuparti di emettere il credito verso l'utente se necessario</b>`;
    }
    await this.view.presentAlert('Sei sicuro?', message, [{
      text: 'Annulla',
      role: 'cancel',
      cssClass: 'secondary'
    }, {
      text: 'Conferma',
      handler: async () => {
        try{
          await this.view.showLoading('Salvataggio ordine...');
          const res: any = await this.api.patchCancelOrder(this.order._id);
          if(res){
            this.order.setAllFields(res);
          }
        }catch(err){
          console.error(err);
          this.view.presentAlert('Errore', 'Impossibile salvare ordine', ['OK']);
        }
        await this.view.hideLoading();
      }
    }]);
  }
  
  // Aggiorna le note di un ordine
  async saveNoteOrder(){
    try{
      if(!this.note) return this.view.presentAlert('Attenzione', 'Devi inserire delle note.', ['Ok']);
      await this.view.showLoading('Salvataggio ordine...');
      const res: any = await this.api.patchNoteOrder(this.order._id, this.note);
      if(res){
        this.order.setAllFields(res);
      }
    }catch(err){
      console.error(err);
      this.view.presentAlert('Errore', 'Impossibile salvare ordine', ['OK']);
    }
    await this.view.hideLoading();
  }
  
  // Cancella un imei da un ordine in preparazione
  async deleteImei(imei: string){
    await this.view.presentAlert('Sei sicuro?', `Il tracker tornerà disponibile in magazzino e lo stato dell'ordine tornerà in preparazione.`, [{
      text: 'Annulla',
      role: 'cancel',
      cssClass: 'secondary'
    }, {
      text: 'Conferma',
      handler: async () => {
        try{
          await this.view.showLoading('Salvataggio ordine...');
          const res: any = await this.api.patchDeleteImeiOrder(this.order._id, imei);
          if(res){
            this.order.setAllFields(res);
          }
        }catch(err){
          console.error(err);
          this.view.presentAlert('Errore', `Impossibile salvare ordine ${err?.error?.description}`, ['OK']);
        }
        await this.view.hideLoading();          }
    }]);
  }
  
  // Setta un ordine in annullamento come cancellato definitivamente
  async hardCancelOrder(){
    let message: string = `Confermi di voler cancellare definitivamente l'ordine?`;
    for(let imei of this.imeis_status){
      if(imei.status_error){
        message += `<br><br><b>Ci sono ancora tracker non "liberi" in questo ordine!</b>`;
        break;
      }
    }
    return await this.view.presentAlert('Sei sicuro?', message, [{
      text: 'Annulla',
      role: 'cancel',
      cssClass: 'secondary'
    }, {
      text: 'Conferma',
      handler: async () => {
        try{
          await this.view.showLoading('Salvataggio ordine...');
          const res: any = await this.api.patchHardCancelOrder(this.order._id);
          if(res){
            this.order.setAllFields(res);
          }
        }catch(err){
          console.error(err);
          this.view.presentAlert('Errore', 'Impossibile salvare ordine', ['OK']);
        }
        await this.view.hideLoading();
      }
    }]);
  }
  
  // Genera e apre il pdf
  openPdf(){
    const padding: number = 15;
    const width: number = 210 - (padding * 2);
    const height: number = 297 - (padding * 2);
    const mm_conversion: number = 72 / 25.6;
    let elenco_prodotti = DataService.general_parameter.elenco_prodotti_shop.find((elem)=>elem.platform_type === this.order.$organization.platform_type);
   this.order.$invoice.mapInvoiceLineWithProduct(elenco_prodotti.products,  this.order.$invoice.metadata?.type);
    if(this.order.$invoice &&  this.order.$invoice.metadata?.type !== 'free') {
    }
  
    const doc = new jsPDF({
      unit: 'mm'
    });
    
    const increment_y: any = (delta: number) => {
      current_y += delta;
      if(current_y > (height + padding)){
        doc.addPage();
        current_y = padding;
      }
    }
    
    /** Intestazione **/
    let current_y: number = 15;
    doc.addImage('assets/imgs/logo_witek_small.png', "PNG", padding - 4, current_y, 28, 12);
    increment_y(12 + 4);
    doc.setFontSize(15).text("Wi-tek group srl", padding, current_y);
    increment_y(doc.getLineHeight() / mm_conversion);
    doc.setFontSize(10).text('Via umberto I, 20 - 12042 Bra (CN) - Italy', padding, current_y);
    increment_y((doc.getLineHeight() / mm_conversion) + 0.5);
    doc.setFont(undefined, 'bold').text('Email: ', padding, current_y);
    doc.setFont(undefined, 'normal').text('info@balin.app', padding + 12, current_y);
    increment_y((doc.getLineHeight() / mm_conversion) + 0.5);
    doc.setFont(undefined, 'bold').text('C.F./P.Iva: ', padding, current_y);
    doc.setFont(undefined, 'normal').text('IT03549220048', padding + 18, current_y);
    increment_y((doc.getLineHeight() / mm_conversion) + 0.5);
    doc.setFont(undefined, 'bold').text('Reg. Imprese: ', padding, current_y);
    doc.setFont(undefined, 'normal').text('CN-299155', padding + 24, current_y);
    
    /** N. Trasporto **/
    increment_y((doc.getLineHeight() / mm_conversion) + 10);
    let num_delivery = doc.setFontSize(15).setFont(undefined, 'bold').splitTextToSize(`Doc. di trasporto n. ${this.order.delivery_note_number}-web-${this.datePipe.transform(this.order.delivery_note_creation_date,'yyyy')}`, width);
    doc.text(num_delivery, width + padding, current_y, {align: 'right'});
    increment_y(((doc.getLineHeight() / mm_conversion) * num_delivery.length) - 2);
    let date = doc.setFontSize(10).setFont(undefined, 'normal').splitTextToSize(`Data: ${this.datePipe.transform(this.order.delivery_note_creation_date,'dd/MM/yyyy')}`, width);
    doc.text(date, width + padding, current_y, {align: 'right'});
    
    /** Cliente **/
    increment_y((doc.getLineHeight() / mm_conversion) + 10);
    doc.setFontSize(12).setFont(undefined, 'bold').text('Cliente', padding, current_y);
    increment_y((doc.getLineHeight() / mm_conversion) + 0.5);
    let raw = doc.setFontSize(10).setFont(undefined, 'normal').splitTextToSize(this.order.delivery_client_info?.name || '', width)
    doc.text(raw, padding, current_y);
    increment_y((doc.getLineHeight() / mm_conversion) * raw.length);
    raw = doc.setFontSize(10).setFont(undefined, 'normal').splitTextToSize(this.order.delivery_client_info?.address || '', width)
    doc.text(raw, padding, current_y);
    increment_y((doc.getLineHeight() / mm_conversion) * raw.length);
    doc.text(this.order.delivery_client_info?.fiscal_info || '', padding, current_y);
  
    /** Destinazione **/
    increment_y((doc.getLineHeight() / mm_conversion) + 10);
    doc.setFontSize(12).setFont(undefined, 'bold').text('Destinazione', padding, current_y);
    increment_y((doc.getLineHeight() / mm_conversion) + 0.5);
    raw = doc.setFontSize(10).setFont(undefined, 'normal').splitTextToSize(this.order.shipping_address?.name, width)
    doc.text(raw, padding, current_y);
    increment_y((doc.getLineHeight() / mm_conversion) * raw.length);
    raw = doc.setFontSize(10).setFont(undefined, 'normal').splitTextToSize(`${this.order.shipping_address?.address1}, ${this.order.shipping_address?.city}, ${this.order.shipping_address?.zipcode}, ${this.order.shipping_address?.state}, ${this.order.shipping_address?.country}`, width)
    doc.text(raw, padding, current_y);
    increment_y((doc.getLineHeight() / mm_conversion) * raw.length);
    doc.text(`${this.order.shipping_address?.phone}`, padding, current_y);
  
    /** Descrizione **/
    if(this.lines){
      increment_y((doc.getLineHeight() / mm_conversion) + 10);
      doc.setFontSize(12).setFont(undefined, 'bold').text('Descrizione', padding, current_y);
      for(let line of this.lines){
        increment_y((doc.getLineHeight() / mm_conversion) + 0.5);
        raw = doc.setFontSize(10).setFont(undefined, 'normal').splitTextToSize(`${line.quantity} x ${line.description}`, width)
        doc.text(raw, padding, current_y);
      }
    }
    increment_y((doc.getLineHeight() / mm_conversion) * raw.length);
  
    /** Info trasporto **/
    increment_y(10);
    let label: string = 'Incaricato trasporto: ';
    doc.setFontSize(10).setFont(undefined, 'bold').text(label, padding, current_y);
    doc.setFont(undefined, 'normal').text(this.order.transportation, doc.getTextWidth(label) + padding + 3, current_y);
    increment_y((doc.getLineHeight() / mm_conversion) + 0.5);
    label = 'Causale di trasporto: ';
    doc.setFontSize(10).setFont(undefined, 'bold').text(label, padding, current_y);
    doc.setFont(undefined, 'normal').text(this.order.subject_transportation, doc.getTextWidth(label) + padding + 3, current_y);
    increment_y((doc.getLineHeight() / mm_conversion) + 0.5);
    label = 'N. colli: ';
    doc.setFontSize(10).setFont(undefined, 'bold').text(label, padding, current_y);
    doc.setFont(undefined, 'normal').text(this.order.n_packages.toString(), doc.getTextWidth(label) + padding + 1, current_y);
    increment_y((doc.getLineHeight() / mm_conversion) + 0.5);
    label = 'Peso (kg): ';
    doc.setFontSize(10).setFont(undefined, 'bold').text(label, padding, current_y);
    doc.setFont(undefined, 'normal').text(this.order.weight.toString(), doc.getTextWidth(label) + padding + 1, current_y);
    increment_y((doc.getLineHeight() / mm_conversion) + 0.5);
    label = 'Porto: ';
    doc.setFontSize(10).setFont(undefined, 'bold').text(label, padding, current_y);
    doc.setFont(undefined, 'normal').text(this.order.port, doc.getTextWidth(label) + padding + 1, current_y);
    increment_y((doc.getLineHeight() / mm_conversion) + 0.5);
    label = 'Date e ora inizio trasporto: ';
    doc.setFontSize(10).setFont(undefined, 'bold').text(label, padding, current_y);
    doc.setFont(undefined, 'normal').text(`${this.datePipe.transform(this.order.date_start_shipping,'dd/MM/yyyy - HH:mm')}`, doc.getTextWidth(label) + padding + 4, current_y);
    increment_y((doc.getLineHeight() / mm_conversion) + 0.5);
    label = 'Note: ';
    doc.setFontSize(10).setFont(undefined, 'bold').text(label, padding, current_y);
    doc.setFont(undefined, 'normal').text(this.order.transportation_note, doc.getTextWidth(label) + padding + 1, current_y);
    increment_y((doc.getLineHeight() / mm_conversion) + 0.5);
    increment_y(10);
    
    /** Informativa **/
    if(current_y !== padding){
      current_y = height + padding;
    }
    raw = doc.setFontSize(7).splitTextToSize('Ai sensi del D.Lgs. 196/2003 Vi informiamo che i Vs. dati saranno utilizzati esclusivamente per i fini connessi ai rapporti commerciali tra di noi in essere.\n' +
      'Contibuto Conai Assolto ove dovuto - Eco-contributo RAEE assolto ove dovuto - Registro Produttori AEE n° IT17040000009820\n' +
      'Vi preghiamo di controllare i Vs. dati anagrafici, la Partita IVA e il Codice Fiscale. Non ci riteniamo responsabili di eventuali errori.', width);
    doc.text(raw, padding + (width / 2), current_y, {align: 'center', baseline: 'top'});
    
    const blob: URL = doc.output("bloburl");
    window.open(blob.toString());
    
    // doc.autoPrint()
  }
  
  // Fa api per settare i campi del pdf
  async createDeliveryNote(){
    if(!this.transportation || !this.transportation_subject || !this.n_packages || this.n_packages <= 0 || !this.weight || this.weight <= 0 || !this.port || !this.transportation_note){
      return this.view.presentAlert('Attenzione', 'Tutti i campi della bolla di trasporto sono necessari', ['Ok']);
    }
    if(this.order.delivery_note_number){
      await this.view.presentAlert('Sei sicuro?', `Vuoi modificare la bolla relativa a questo ordine?`, [{
        text: 'Annulla',
        role: 'cancel',
        cssClass: 'secondary'
      }, {
        text: 'Conferma',
        handler: () => {
          this.updateDeliveryNoteOrder();
        }
      }]);
    }else{
      this.updateDeliveryNoteOrder();
    }
  }
  
  private async updateDeliveryNoteOrder(){
    try{
      await this.view.showLoading('Creazione bolla...');
      let client: any;
      if(!this.order.delivery_note_number){
        // Compongo i dati del cliente
        client = {
          name: '--',
          address: '--',
          fiscal: ''
        }
        let name: string = '--';
        let address: string = '--';
        let fiscal_info: string = '';
        if(this.order.$organization?.billing_type === 1){ // Azienda
          client.name = `${this.order.$organization.billing_name} ${this.order.$organization.billing_surname}` || '--';
          client.address = `${this.order.$organization.billing_address}, ${this.order.$organization.billing_city}, ${this.order.$organization.billing_zip}, ${this.order.$organization.billing_province}, ${this.order.$organization.billing_country}`;
          client.fiscal_info = `CF: ${this.order.$organization.billing_codiceFiscale}`;
        }else if(this.order.$organization?.billing_type === 2){
          client.name = this.order.$organization.billing_companyName || '--';
          client.address = `${this.order.$organization.billing_address}, ${this.order.$organization.billing_city}, ${this.order.$organization.billing_zip}, ${this.order.$organization.billing_province}, ${this.order.$organization.billing_country}`;
          client.fiscal_info = `P.iva: ${this.order.$organization.billing_vat}`;
        }
      }
      const res: any = await this.api.patchDeliveryNote(this.order._id, this.transportation, this.transportation_subject, this.n_packages, this.weight, this.port, this.transportation_note, (new Date(this.date_start_shipping)).getTime(), client);
      if(res){
        this.order.setAllFields(res);
        this.openPdf();
      }
    }catch(err){
      console.error(err);
      this.view.presentAlert('Errore', `Impossibile creare la bolla ${err?.error?.description}`, ['OK']);
    }
    await this.view.hideLoading();
  }
  
  // Precompila il tracking url di gls
  precompileTrackingUrl(){
    if(this.shipping_number){
      this.url_tracking = `https://gls-group.com/IT/it/servizi-online/ricerca-spedizioni.html?match=${this.shipping_number}`;
    }
  }
  
  goNext(){
    if(this.order.status >= 98){ // Ordine annullato
      return this.view.presentAlert('Attenzione', 'Non puoi proseguire perchè l\'ordine è stato annullato.', ['OK']);
    }
    switch (this.type) {
      case 'invoice': {
        this.type = 'list_imei';
        this.title = 'Preparazione ordine';
        this.initPage();
        break;
      }
      case 'list_imei': {
        if(this.order.status >= 1){
          this.type = 'tracking_url';
          this.title = 'Spedizione ordine';
          this.initPage();
        }else{
          this.view.presentAlert('Attenzione', 'Non puoi proseguire se non è ancora stato preparato l\'ordine', ['OK']);
        }
        break;
      }
      case 'tracking_url': {
        if(this.order.status >= 2){
          this.type = 'received';
          this.title = 'Consegna ordine';
          this.initPage();
        }else{
          this.view.presentAlert('Attenzione', 'Non puoi specificare lo stato della ricezione se non hai ancora spedito l\'ordine', ['OK']);
        }
        break;
      }
      case 'received': {
        if(this.order.status >= 3){
          this.type = 'activated';
          this.title = 'Stato attivazione';
          this.initPage();
        }else{
          this.view.presentAlert('Attenzione', 'Non puoi specificare lo stato della ricezione se non hai ancora spedito l\'ordine', ['OK']);
        }
        break;
      }
    }
  }
  
  
  // Aggiorna le note di un ordine
  async archivieOrder(archivie: boolean){
    if(archivie){
      //voglio archiviare l'ordine
      if(this.order.archivied) return this.view.presentAlert('Attenzione', 'L\'ordine è gia archiviato', ['Ok']);
     
      await this.view.presentAlert('Sei sicuro?', `Vuoi archiviare questo ordine?`, [{
        text: 'Annulla',
        role: 'cancel',
        cssClass: 'secondary'
      }, {
        text: 'Conferma',
        handler: async () => {
      
          try{
            await this.view.showLoading('Archiviazione ordine...');
            const res: any = await this.api.archivieOrder(this.order._id, true);
            if(res){
              this.order.setAllFields(res);
              //remove order from list
              if(this.orders){
                const orderIndex = this.orders.findIndex(order => order._id === this.order._id);
                // Se l'indice è valido, rimuovi l'ordine dall'array
                if (orderIndex !== -1) {
                  this.orders.splice(orderIndex, 1);
                }
              }
              ViewService.updateView.next()
            }
        
          }catch(err){
            console.error(err);
            this.view.presentAlert('Errore', 'Impossibile salvare ordine', ['OK']);
          }
          await this.view.hideLoading();
      
      
        }
      }]);
      
      
    }else{
      //voglio ripristinare l'ordine
      if(!this.order.archivied) return this.view.presentAlert('Attenzione', 'L\'ordine non è archiviato', ['Ok']);
      
  
      await this.view.presentAlert('Sei sicuro?', `Vuoi ripristinare questo ordine?`, [{
        text: 'Annulla',
        role: 'cancel',
        cssClass: 'secondary'
      }, {
        text: 'Conferma',
        handler: async () => {
      
          try{
            await this.view.showLoading('Ripristino ordine...');
            const res: any = await this.api.archivieOrder(this.order._id, false);
            if(res){
              this.order.setAllFields(res);
              //remove order from list
              if(this.orders){
                const orderIndex = this.orders.findIndex(order => order._id === this.order._id);
                // Se l'indice è valido, rimuovi l'ordine dall'array
                if (orderIndex !== -1) {
                  this.orders.splice(orderIndex, 1);
                }
              }
              ViewService.updateView.next()
            }
        
          }catch(err){
            console.error(err);
            this.view.presentAlert('Errore', 'Impossibile salvare ordine', ['OK']);
          }
          await this.view.hideLoading();
      
      
        }
      }]);
  
    }
    

    

  }
  
}
