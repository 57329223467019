import { Pipe, PipeTransform } from '@angular/core';
import  {UserTrackingram} from '../classes/migration';
import {MigrationService} from '../services/migration.service';

@Pipe({
  name: 'validMigration'
})

/** Pipe specifico per la ricerca dalla lista company in visualizzazione chat **/
export class ValidMigrationPipe implements PipeTransform {
  constructor(private migration: MigrationService) {} // Inietta il servizio 'migration' nel costruttore
  
  transform(user: UserTrackingram[], changed: number): any {
    if(!user || user.length <= 0) return user;
    const returnElement: UserTrackingram[] = [];
    for(let item of user){
      if(item.$white_label_trackers || this.migration.mapUserTracker.has(item._id)){
        returnElement.push(item)
      }
    }
    
    return returnElement;
  
  }
  
}
