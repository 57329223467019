export default class OnceSim {
  
  raw: any;
  iccid: string;
  imsi: string;
  status: string;
  current_quota: number;
  current_quota_SMS: number;
  usage_sms: OnceSimQuotaSms;
  usage_data: OnceSimQuotaData;
  
  constructor(rawObj?: any) {
    this.setAllFields(rawObj);
  }
  
  setAllFields(rawObj: any = {}) {
    if (rawObj) {
      this.raw = rawObj;
      this.iccid = rawObj.iccid;
      this.imsi = rawObj.imsi;
      this.status = rawObj.status;
      this.current_quota = rawObj.current_quota;
      this.current_quota_SMS = rawObj.current_quota_SMS;
    }
  }
  
}

export class OnceSimQuotaData {
  
  volume: number;
  total_volume: number;
  private _expiry_date: number;
  
  // "expiry_date": "2033-02-23 00:00:00",
  // "peak_throughput": 128000,
  // "last_volume_added": 500,
  // "last_status_change_date": "2022-11-23 13:15:16",
  // "threshold_percentage": 20
  
  constructor(rawObj?: any) {
    this.setAllFields(rawObj);
  }
  
  setAllFields(rawObj: any = {}) {
    if (rawObj) {
      this.total_volume = rawObj.total_volume;
      this.volume = rawObj.volume;
      this.expiry_date = rawObj.expiry_date;
    }
  }
  
  get expiry_date(): number {
    return this._expiry_date;
  }
  
  set expiry_date(value: number) {
    const data = new Date(value); // è stringa
    this._expiry_date = data.getTime();
  }
  
}

export class OnceSimQuotaSms {
  
  volume: number;
  total_volume: number;
  private _expiry_date: number;
  
  // "volume": 246,
  // "total_volume": 250,
  // "expiry_date": "2033-02-23 00:00:00",
  // "last_volume_added": 250,
  // "last_status_change_date": "2022-11-23 13:15:22",
  // "threshold_percentage": 20
  
  constructor(rawObj?: any) {
    this.setAllFields(rawObj);
  }
  
  setAllFields(rawObj: any = {}) {
    if (rawObj) {
      this.total_volume = rawObj.total_volume;
      this.volume = rawObj.volume;
      this.expiry_date = rawObj.expiry_date;
    }
  }
  
  get expiry_date(): number {
    return this._expiry_date;
  }
  
  set expiry_date(value: number) {
    const data = new Date(value); // è stringa
    this._expiry_date = data.getTime();
  }
}
