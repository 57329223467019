import { Pipe, PipeTransform } from '@angular/core';
import Company from "../classes/company";

@Pipe({
  name: 'searchCompany'
})

/** Pipe specifico per la ricerca dalla lista company in visualizzazione chat **/
export class SearchCompanyPipe implements PipeTransform {
  
  transform(companies: Company[], query: string, changed: number, different?: boolean): any {
    
    if(!query) return companies;
    
    query = query.toLowerCase();
    
    let filtered: Company[] = [];
    
    for(let company of companies){
      if(company.label?.toLowerCase().indexOf(query) >= 0){
        filtered.push(company);
        continue;
      }
      let found: boolean = false;
      if(company.whatsapp_chats){
        for(let chat of company.whatsapp_chats){
          if(chat.$customer_phone?.toLowerCase().indexOf(query) >= 0){
            filtered.push(company);
            found = true;
            break;
          }
        }
      }
      if(!found && company.sms_chats){
        for(let chat of company.sms_chats){
          if(chat.$customer_phone?.toLowerCase().indexOf(query) >= 0){
            filtered.push(company);
            found = true;
            break;
          }
        }
      }
      if(!found && company.call_chats){
        for(let chat of company.call_chats){
          if(chat.$customer_phone?.toLowerCase().indexOf(query) >= 0){
            filtered.push(company);
            found = true;
            break;
          }
        }
      }
      if(!found && company.$organizations){
        for(let org of company.$organizations){
          if(org.login_email_partecipant_owner?.toLowerCase().indexOf(query) >= 0){
            filtered.push(company);
            found = true;
            break;
          }
        }
      }
      if(!found && company.email_chats){
        for(let chat of company.email_chats){
          if(chat.email_customer?.toLowerCase().indexOf(query) >= 0){
            filtered.push(company);
            break;
          }
        }
      }
    }
    
    return filtered;
  }
  
}
