import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, Input,
  OnInit
} from '@angular/core';
import {
  PopoverController
} from "@ionic/angular";

@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.page.html',
  styleUrls: ['./attachment.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentPage implements OnInit {
  
  @Input() attachment: any;
  @Input() attachments: any[];
  
  @Input() type: undefined | 'show';

  constructor(
    private popoverCtrl: PopoverController,
    private ref: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    console.log(this.attachments)
  }
  
  removeAttach(){
    this.attachment = null;
    this.popoverCtrl.dismiss(this.attachment);
  }
  
  removeAttachFromList(index: number){
    this.attachments.splice(index, 1);
    this.ref.markForCheck();
  }
  
  openFromList(index: number){
    console.log(this.attachments[index].url)
    window.open(this.attachments[index].url, 'blank', undefined, false);
  }
  
}
