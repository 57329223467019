import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ViewService} from 'common-lib';
import {ApiService} from '../../services/api.service';
import {DataService} from '../../services/data.service';
import {Ticket} from '../../classes/ticket';

@Component({
  selector: 'app-create-message',
  templateUrl: './create-message.page.html',
  styleUrls: ['./create-message.page.scss'],
})
export class CreateMessagePage implements OnInit {

  @Input() ticket: Ticket;
  
  constructor(
    private modalController: ModalController,
    private view: ViewService,
    public data: DataService,
    private api: ApiService
  ) { }
  
  whatsappAttach: any;
  whatsappMessageBody: string;
  
  
  
  webAttach: any;
  webMessageBody: string;
  
  
  currentPage: number = 1;
  routeSelected: number; //1 whatsapp, 2 web

  ngOnInit() {
    console.log(this.ticket)
    this.currentPage = 1;
  }
  
  /**
   * Chiudo modale
   */
  async dismiss() {
    await this.modalController.dismiss();
  }
  
  /**
   * Imposta il tipo di messagio da inviare, web o whatsapp
   * @param type
   */
  async setRoute(type: number){
    this.routeSelected = type;
    await this.changePageContent(true);
  }
  
  
  async changePageContent(isForward: boolean){
    switch (this.currentPage){
    
      case 1:
        if(isForward){
          this.currentPage = 2
        }else{
          this.dismiss()
        }
        break;
      case 2:
        if(isForward){
          this.currentPage = 3
        }else{
          this.currentPage = 1
        }
        break;
      case 3:
        if(isForward){
        
        }else{
          this.currentPage = 2
        }
        break;
    }
    
  }
  
  selectWhatsappFile(){
    let input: any = document.createElement('input');
    input.type = 'file';
    input.accept = "image/*,application/pdf";
    input.addEventListener("change", (event) => {
      this.uploadFile(event);
    });
    input.click();
  }
  removeWhatsappUpload(){
    this.whatsappAttach = undefined
  }
  
  
  selectWebFile(){
    let input: any = document.createElement('input');
    input.type = 'file';
    input.accept = "image/*,application/pdf";
    input.addEventListener("change", (event) => {
      this.uploadFile(event);
    });
    input.click();
  }
  removeWebUpload(){
    this.webAttach = undefined
  }
  
  sendMessage(){
  
  }
  
  
  /**
   * Riconosce la selezione di un file e fa partire l'upload
   * @param event
   */
  uploadFile(event: any) {
    const target: any = event.target || event.srcElement;
    if (target.files[0]) {
      switch (this.routeSelected) {
        case 1:
          this.whatsappAttach = {
            name: target.files[0].name,
            type: target.files[0].type.split("/")[0],
            contentType: target.files[0].type
          };
          this.whatsappAttach.body = new Blob([target.files[0]]);
          break;
        case 2:
          this.webAttach = {
            name: target.files[0].name,
            type: target.files[0].type.split("/")[0],
            contentType: target.files[0].type
          };
          this.webAttach.body = new Blob([target.files[0]]);
          break;
      }
   
      ViewService.updateView.next();
    }
  }

}
