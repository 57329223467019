import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { AlertController, ModalController, NavController, PopoverController, ToastController } from '@ionic/angular';
import { 
  AuthService, 
  ViewService, ViewService as View,
} from 'common-lib';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';
import { TrackerAdmin } from 'src/classes/trackerAdmin';
import {ApiService} from "../../services/api.service";
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DataService } from 'src/services/data.service';
import Activity from 'src/classes/activity';
import Company from 'src/classes/company';
import { ListParsers } from 'src/classes/listParsers';
import * as XLSX from "xlsx";

// import * as lodash from 'lodash';

@Component({
  selector: 'app-stock-amazon-recap',
  templateUrl: './stock-amazon-recap.page.html',
  styleUrls: ['./stock-amazon-recap.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockAmazonRecapPage implements OnInit {
  
  // API
  
  // structure:  ["_" + _id]: "error" | "success"
  apiResult = {
    all: {},
    error: {},
    success: {},
  };
  apiResult_length = {
    all: 0,
    error: 0,
    success: 0,
  };
  
  // data passed in by componentProps
  @Input() recapArray: TrackerAdmin[] = [];
  
  //  listener
  listener = {
    data: null,
    view: null,
  };
  
  constructor(
    private cd: ChangeDetectorRef,
    private alertCtrl: AlertController,
    private alertController: AlertController,
    private api: ApiService,
    private data: DataService,
    private modalCtrl: ModalController,
    private auth: AuthService,
    private nav: NavController,
    public popoverController: PopoverController,
    private route: ActivatedRoute,
    private router: Router,
    private toastCtrl: ToastController,
    private view: ViewService,
  ) {}

  ngOnInit() {}
  
  ionViewDidEnter() {
    this.listenerView();
    this.sendAllToAmazon()
  }

  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }
  
  // * API
  
  async patchTrackerMagazzino(tracker: TrackerAdmin) {
    try {
      await this.api.patchTrackerMagazzino(tracker._id, 2);
      this.set_apiResult({
        id: tracker._id,
        outcome: "success",
      });
    } catch (e) {
      console.error(`e`, e)
      this.set_apiResult({
        id: tracker._id,
        outcome: "error",
      });
    }
    
    // console.log(`end patch`, )
    View.updateView.next()
  }
  
  async sendAllToAmazon() {
    console.log(`sendAllToAmazon()`, )
    
    // await Promise.all(
    //   this.recapArray.map((tracker: TrackerAdmin) => 
    //     this.patchTrackerMagazzino(tracker)
    //   )
    // );
    for await (let tracker of this.recapArray) {
      await this.patchTrackerMagazzino(tracker)
    }
    
    // console.log(`after for/promise.all`, )
    this.toast({
      msg: "tutti i tracker settati",
    });
  }
  
  // * content (misc)
  
  composeDocument() {
    console.log(`composeDocument()`, )
    if (this.apiResult_length.all < this.recapArray.length) {
      console.log(`return: still making apis`, )
      return;
    }
    this.createCsv({
      endFunc: this.dismiss(),
      list: this.recapArray,
    });
  }
  
  createCsv({
    endFunc,
    list,
  }: {
    endFunc?: /* () =>  */void, // not a callback
    list: TrackerAdmin[],
  }) {
    try {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([], { skipHeader: true });
      XLSX.utils.sheet_add_json(
        worksheet, 
        list.map(el => (
          {
            IMEI: el.imei,
            esito: this.apiResult.all[`_${el._id}`],
          }
        )), 
        { 
          origin: "A1", 
        },
      );

      let title: string = "amazon-outcome";
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      let blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, title);
      } else {
        let link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
          let url: string = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", title + ".xlsx");
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
      
      endFunc;
    } catch(e) {
      console.error(`e`, e)
      this.alert({
        header: "Errore",
        msg: "Errore durante la scrittura del documento",
      })
    }
  }
  
  set_apiResult({
    id,
    outcome,
  }: {
    id:string, 
    outcome: "error" | "success",
  }) {
    this.apiResult.all[`_${id}`] = outcome;
    this.apiResult_length.all += 1;
    
    this.apiResult.success[`_${id}`] = outcome;
    this.apiResult_length[outcome] += 1;
    
    View.updateView.next()
  }
  
  // * feedback
  
  async alert({
    header,
    msg,
  }: {
    header?: string,
    msg: string,
  }) {
    const alert = await this.alertCtrl.create({
      buttons: ['Ok'],
      // cssClass: "",
      header: header ?? "Alert", 
      message: msg,
      // subHeader: "Subtitle",
    });

    await alert.present();

    const { data, role } = await alert.onDidDismiss();
    // console.log('alert.onDidDismiss data', data)
    // console.log('alert.onDidDismiss role', role)
  }
  
  async toast({
    duration,
    msg,
  }: {
    duration?: number,
    msg: string, 
  }) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: duration ?? 2000,
      // position: "middle",
    });
    toast.present();
  }
  
  // * listener
  
  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
      setTimeout(() => {
        this.cd.markForCheck();
      }, 250);
    });
  }
  
  // * navigation
  
  dismiss(data?: any) {
    // console.log(`dismiss()`, data)
    this.modalCtrl.dismiss(data);
  }
  
  // * test
  
  consoleLog(i) {
    console.log('i', i)
    // console.log('i', DataService.array.activity)
    // this.router.navigate(['phonebook-activities']);
  }
  
  

}
