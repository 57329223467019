import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {TrackerAdmin} from "../../classes/trackerAdmin";
import {ViewService, ViewService as View} from "common-lib";
import {
  TypeParticipant
} from "../../classes/typeParticipant";
import {DataService} from "../../services/data.service";
import {ApiService} from "../../services/api.service";
import {Platform} from "../../classes/platform";

@Component({
  selector: 'app-formatta',
  templateUrl: './formatta.page.html',
  styleUrls: ['./formatta.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormattaPage implements OnInit {

  @Input() tracker: TrackerAdmin;

  platforms: Platform[] = [];
  platformType: number;
  platform: Platform;
  typeParticipant: TypeParticipant;


  listener = {
    view: null,
  };

  constructor(
    private modalController: ModalController,
    private view: ViewService,
    private ref: ChangeDetectorRef,
    public data: DataService,
    private api: ApiService
  ) { }

  ngOnInit() {
  
  }

  ionViewDidEnter() {
    this.listenerView();
    if(this.tracker){
      this.platforms = [];
      for(let platform of DataService.platforms){
        if(platform.type_partecipant){
          if(platform.type_partecipant.find((elem: TypeParticipant) => {
            return elem.type === 2 && elem.type_trackerGps === this.tracker.type_trackerGps;
          })){
            this.platforms.push(platform);
          }
        }
      }
      this.platformType = this.tracker.platform_type;
      this.selectPlatform();
    }
  }

  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }

  selectPlatform(){
    if(typeof this.platformType !== 'number') this.platformType = parseInt(this.platformType);
    this.platform = DataService.platforms.find((elem: Platform) => {
      return elem.type === this.platformType;
    });
    this.typeParticipant = this.platform.type_partecipant.find((elem: TypeParticipant) => {
      return elem.type === 2 && elem.type_trackerGps === this.tracker.type_trackerGps;
    });
    ViewService.updateView.next();
  }


  async initialize(){
    if(!this.platformType){
      return this.view.presentAlert('Attenzione', 'Devi selezionare una piattaforma', ['ok']);
    }
    try{
      await this.view.showLoading('Inizializzazione in corso...');
      const res: any = await this.api.patchTrackerFormatta(this.tracker._id, this.platformType);
      this.view.presentAlert('Successo', 'Tracker GPS inizializzato correttamente', ['Ok']);
      this.dismiss({formatted: true, res: res});
    }catch(err){
      console.error(err);
      this.view.presentAlert('Errore', 'Riprova più tardi', ['Ok']);
    }
    this.view.hideLoading();
  }

  dismiss(data?: any){
    this.modalController.dismiss(data);
  }

  // listener
  listenerView() {
    this.listener.view = View.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.ref.markForCheck();
    });
  }
}
