import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {JsonEditorComponent, JsonEditorOptions} from 'ang-jsoneditor';
import {ModalController} from '@ionic/angular';
import {AdvFinalized} from '../../classes/advFinalized';
import {ViewService} from 'common-lib';
import {ApiService} from '../../services/api.service';

@Component({
  selector: 'app-adv-finalized-detail',
  templateUrl: './adv-finalized-detail.component.html',
  styleUrls: ['./adv-finalized-detail.component.scss'],
})
export class AdvFinalizedDetailComponent implements OnInit {
  
  @Input() adv_finalized: AdvFinalized;
  @Input() event: any;
  @Input() openOrganization: any;
  @Input() openCompany: any;
  @Input() createCompany: any;
  
  
  note: string;
  is_closed: string;
  
  editorOptions: JsonEditorOptions;
  @ViewChild(JsonEditorComponent, { static: false }) editor: JsonEditorComponent;
  
  managed: boolean;
  
  managed_chips: any = [
    {
      id: 'managed',
      lbl: 'Gestito',
      main_color: '#15a84a',
      sub_color: '#ffffff',
      selected: false
    },
    {
      id: 'unmanaged',
      lbl: 'Da gestire',
      main_color: '#fc2f00',
      sub_color: '#ffffff',
      selected: false
    }
  ];
  managed_chip_selected: any = this.managed_chips[0];
  
  constructor(
    public view: ViewService,
    private api: ApiService,
    private modalCtrl: ModalController,
  ) {}

  ngOnInit() {
    this.editorOptions = new JsonEditorOptions();
    this.note = this.adv_finalized.note;
    this.is_closed = this.adv_finalized.is_closed ? 'true' : 'false';
    this.editorOptions.indentation = 1;
    this.editorOptions.expandAll = false;
    this.editorOptions.mainMenuBar = true;
    this.editorOptions.statusBar = false;
    this.editorOptions.mode = 'tree';
    this.editorOptions.modes = ['tree', 'code'];
  
    this.managed = this.adv_finalized.managed || false;
    if(this.adv_finalized.managed){
      this.managed_chips[0].selected = true;
      this.managed_chip_selected = this.managed_chips[0]
    }else{
      this.managed_chips[1].selected = true;
      this.managed_chip_selected = this.managed_chips[1]
    }
  }
  
  dismiss(data?: any) {
    this.modalCtrl.dismiss(data);
  }

  async saveAdv(){
    await this.view.showLoading('Modifica adv...');
    try{
      console.log(this.is_closed, this.note)
      const res: any = await this.api.patchAdvFinalizedIsClosed(this.adv_finalized._id, this.is_closed === 'true', this.note, this.managed);
      if(res){
        this.adv_finalized.setAllFields(res);
        this.event()
        ViewService.updateView.next();
      }
    }catch(err){
      console.error(err);
      this.view.presentAlert('Errore', 'Impossibile modificare adv', ['Ok']);
    }
    await this.view.hideLoading();
  }
  
  
  changeManagedAdv(event: any){
    if(event.id){
      switch(event.id){
        case 'managed':
          this.managed = true;
          break;
        case 'unmanaged':
          this.managed = false;
          break;
      }
    }
    this.managed_chip_selected = event
  }
}
