import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {AuthService, ViewService} from 'common-lib';
import {AlertController, ModalController, PopoverController} from '@ionic/angular';
import {TwilioCall} from '../../classes/twilioCall';
import {TwilioService} from '../../services/twilio.service';
import {Admin} from '../../classes/admin';
import {DataService} from '../../services/data.service';
import {ApiService} from '../../services/api.service';
import {NavigationExtras, Router} from '@angular/router';

@Component({
  selector: 'app-call-info',
  templateUrl: './call-info.component.html',
  styleUrls: ['./call-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallInfoComponent implements OnInit {
  
  @Input() call: TwilioCall
  @Input() twilio: TwilioService
  @Input() selectedCompany: any;
  listener = {
    view: null,
  };
  
  admins: Admin[] = []
  originAdmin: Admin;
  finalAdmin: Admin;
  
  constructor(
    private cd: ChangeDetectorRef,
    public view: ViewService,
    private modalCtrl: ModalController,
    public data: DataService,
    private api: ApiService,
    private router: Router,
    private popoverController: PopoverController,
    private alertCtrl: AlertController
  ) { }
  
  ngOnInit() {}
  
  ionViewDidEnter() {
    console.log(this.call)
    this.listenerView();
    DataService.array.admin.map((elem: Admin) => {
      this.admins.push(elem);
      if(this.call.origin_admin_id === elem._id){
        this.originAdmin = elem
      }
      if(this.call.final_admin_id === elem._id){
        this.finalAdmin = elem
      }
      if(this.call.$company){
      
      }
    });
    
    ViewService.updateView.next();
  }
  
  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }
  
  openOrganization(id_organization: string){
    if(!id_organization) return;
    const url: string = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/organizations?idOrganization=${id_organization}`;
    window.open(url, '_blank');
  }
  
  openCompany(id_company: string){
    if(!id_company) return;
    let navigationExtras: NavigationExtras = {
      queryParams: {
        idCompany: id_company || undefined
      }
    };
    this.router.navigate(['chat/whatsapp'], navigationExtras);
    this.dismiss();
  }
  
  openTicket(id_ticket: string){
    if(!id_ticket) return;
    let navigationExtras: NavigationExtras = {
      queryParams: {
        id_ticket: id_ticket || undefined
      }
    };
    this.router.navigate(['chat/whatsapp'], navigationExtras);
    this.dismiss();
  }
  
  openContact(id_contact: string){
    if(!id_contact) return;
    let navigationExtras: NavigationExtras = {
      queryParams: {
        id_contact: id_contact || undefined
      }
    };
    this.router.navigate(['chat/whatsapp'], navigationExtras);
    this.dismiss();
  }
  
  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
    });
  }
  
  dismiss(data?: any) {
    this.modalCtrl.dismiss(data);
  }
  
  async toggleToRead(){
    await this.view.showLoading();
    try{
      const res: any = await this.api.patchCallToRead(this.call.id, !this.call.to_read);
      if(res) this.call.setData(res);
      ViewService.updateView.next();
    }catch(err){
      console.error(err);
      this.modalCtrl.dismiss()
      this.view.presentAlert('Errore', "Impossibile cambiare stato lettura call", ['OK']);
    }
    await this.view.hideLoading();
  }
  
  async openAgenda(from: any){
    if(!from) return;
    let navigationExtras: NavigationExtras = {
      queryParams: {
        from: from || undefined
      }
    };
    this.router.navigate(['chat/rubrica'], navigationExtras);
    this.dismiss();
  }
  
}
