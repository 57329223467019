import {
  Injectable, Pipe,
  PipeTransform
} from '@angular/core';
import {Admin} from "../classes/admin";

@Pipe({
  name: 'filterAdmins'
})
@Injectable()
export class FilterAdminsPipe implements PipeTransform {
  transform(values: Map<string, Admin>, changed?: number): string {
    if(values.size === 1){
      for(let value of values.values()){
        return value.nickname;
      }
    }else if(values.size > 0){
      return `${values.size} agenti`;
    }else{
      return "No agenti";
    }
  }
}


