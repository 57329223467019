import { Pipe, PipeTransform } from '@angular/core';
import {OrgProblemElement} from '../classes/organizationAdmin';

@Pipe({
  name: 'searchBankTransfer'
})

/** Pipe specifico per la ricerca dalla lista company in visualizzazione chat **/
export class SearchBankTransferPipe implements PipeTransform {
  
  transform(organizations: OrgProblemElement[], query: string, changed: number, enabled?: boolean): any {
    
    if(!query || !enabled) return organizations;
    
    query = query.toUpperCase();
    
    let filtered: OrgProblemElement[] = [];
    
    for(let org of organizations){
      if(org.bank_transfert_to_check_list?.length > 0){
        for(let invoice of org.bank_transfert_to_check_list){
          if(invoice.number?.indexOf(query) >= 0 || invoice.metadata?.amount_to_paid_bank_transfer_formatted?.indexOf(query) === 0){
            filtered.push(org);
            break;
          }
        }
      }
    }
    
    return filtered;
  }
  
}
