import {ListParsers} from "./listParsers";
import {ParticipantAdmin} from "./participantAdmin";
import {Command} from "./command";
import {Platform} from "./platform";
import {TypeTrackerGps} from "./typeTrackerGps";
import {TrackerGps} from "common-lib";

export class TrackerAdmin extends TrackerGps{
  raw: any;

  private _platform_type: number;
  magazzino_trackerGps: number;
  magazzino_trackerGps_label: string;
  simStatus: number;
  simStatus_label: string;
  private _type_sim: number;
  iccid_sim: string;
  iccid_sim_OLD: SimOld[];
  isProgrammed: boolean;
  timestampProgrammed: number;
  timestampProgrammed_ms: number; // Aggiunto per frontend
  programVersion: string;
  $fota_programVersion: string;
  device_model: string;
  list_commandTrackerGps: Command[];
  list_commandTrackerGps_finished: Command[];
  note: any[];
  history_partecipant: ParticipantAdmin[];
  status_trackerGps_label: string;
  lotto_magazzino: string;
  sim_id: string;
  status_trackerGps_history: StatusOld[];

  timestampStatus: number; // Aggiunto per frontend
  timestampStatus_ms: number; // Aggiunto per frontend
  
  $timestampStatusMagazzino_ms: number; // Aggiunto per frontend
  
  $sending_config_id: number; // Aggiunto per frontend in inserimento magazzino contiene l'id del loop di invio comandi (timestamp in ms)
  $config_timeout_lister: any;
  $config_timeout_error: boolean;
  
  $fota_error: string; // Errore in programmazione fota
  $fota_queue: string;
  $fota_next_task: string;
  $fota_web_connect_count: number = 0;
  $sim: any;
  
  $amazon_diff_months: number;
  $witek_diff_months: number;
  $selected: boolean;
  $api_status: any = {};
  
  device_fw: string;
  $fota_device_fw: string;
  reprogramming_timestamp: number;
  $fota_tasks_list: any;
  $fota_tasks_list_older: any;
  $fota_last_seen: number;
  
  private _$last_command: Command;
  
  $getPlatform: (number) => Platform;
  $platform: Platform;
  
  private $getTypeTracker: (number) => TypeTrackerGps;
  $typeTracker: TypeTrackerGps;
  
  private $getTypeSim: (number) => {description: string, type_sim: number};
  $typeSim: {description: string, type_sim: number};
  
  constructor(data?: any, options?: {
    getPlatform?: (number) => Platform,
    getTypeTracker?: (number) => TypeTrackerGps,
    getTypeSim?: (number) => {description: string, type_sim: number}
  }) {
    super();
    this.$getPlatform = () => {return undefined};
    this.$getTypeTracker = () => {return undefined};
    this.$getTypeSim = () => {return undefined};
    if(data){
      this.setData(data, options);
    }
  }

  setData(data: any, options?: {
    getPlatform?: (number) => Platform,
    getTypeTracker?: (number) => TypeTrackerGps,
    getTypeSim?: (number) => {description: string, type_sim: number}
  }){
    super.setData(data);
    if(options){
      if(options?.getPlatform) this.$getPlatform = options.getPlatform;
      if(options?.getTypeTracker) this.$getTypeTracker = options.getTypeTracker;
      if(options?.getTypeSim) this.$getTypeSim = options.getTypeSim;
    }
    this.raw = data;
    this.platform_type = data.platform_type;
    this.isProgrammed = data.isProgrammed;
    this.timestampProgrammed = data.timestampProgrammed;
    if(this.timestampProgrammed){
      this.timestampProgrammed_ms = this.timestampProgrammed * 1000;
    }
    this.magazzino_trackerGps = data.magazzino_trackerGps;
    this.simStatus = data.simStatus;
    this.type_sim = data.type_sim;
    this.iccid_sim = data.iccid_sim;
    if(data.iccid_sim_OLD){
      this.iccid_sim_OLD = [];
      for(let raw of data.iccid_sim_OLD){
        this.iccid_sim_OLD.push(new SimOld(raw, {getTypeSim: this.$getTypeSim}));
      }
    }
    if(data.status_trackerGps_history){
      this.status_trackerGps_history = [];
      for(let raw of data.status_trackerGps_history){
        this.status_trackerGps_history.push(new StatusOld(raw));
      }
    }
    this.programVersion = data.programVersion;
    this.device_model = data.device_model;
    if(data.list_commandTrackerGps){
      this.list_commandTrackerGps = [];
      for(let raw of data.list_commandTrackerGps){
        this.list_commandTrackerGps.push(new Command(raw));
      }
    }
    if(data.list_commandTrackerGps_finished){
      this.list_commandTrackerGps_finished = [];
      for(let raw of data.list_commandTrackerGps_finished){
        this.list_commandTrackerGps_finished.push(new Command(raw));
      }
    }
    this.note = data.note;
    this.$typeTracker = this.$getTypeTracker(this.type_trackerGps);
    this.status_trackerGps_label = ListParsers.statusTrackerGps(this.status_trackerGps);
    this.magazzino_trackerGps_label = ListParsers.parseStoreStatus(this.magazzino_trackerGps);
    this.simStatus_label = ListParsers.parseSimStatus(this.simStatus);
    if(this.status_trackerGps && this.timestamp_status_trackerGps && this.status_trackerGps < this.timestamp_status_trackerGps.length && this.timestamp_status_trackerGps[this.status_trackerGps]){
      this.timestampStatus = this.timestamp_status_trackerGps[this.status_trackerGps];
      if(this.timestampStatus) this.timestampStatus_ms = this.timestampStatus * 1000;
    }
    if(this.timestamp_status_trackerGps && this.timestamp_status_trackerGps.length >= 1 && this.timestamp_status_trackerGps[1]){
      this.$timestampStatusMagazzino_ms = this.timestamp_status_trackerGps[1] * 1000;
    }
    if(data.history_partecipant){
      this.history_partecipant = [];
      for(let raw of data.history_partecipant){
        this.history_partecipant.push(new ParticipantAdmin(raw));
      }
    }
    this.lotto_magazzino = data.lotto_magazzino;
    this.sim_id = data.sim_id;
    this.device_fw = data.device_fw;
    this.reprogramming_timestamp = data.reprogramming_timestamp;
  }
  
  setDataFromJSON(jsonObj: any){
    for (let prop in jsonObj) {
      this[prop] = jsonObj[prop];
    }
  }
  
  get platform_type(): number {
    return this._platform_type;
  }
  
  set platform_type(value: number) {
    this._platform_type = value;
    this.$platform = this.$getPlatform(this.platform_type);
  }
  
  get type_sim(): number {
    return this._type_sim;
  }
  
  set type_sim(value: number) {
    this._type_sim = value;
    this.$typeSim = this.$getTypeSim(this.type_sim);
  }
  
  getLastCommandByType(type: number): Command{
    if(!this.list_commandTrackerGps) return;
    return this.list_commandTrackerGps.find((elem: Command) => elem.type === type);
  }
  
  getLastCommandFinishedByType(type: number): Command{
    if(!this.list_commandTrackerGps_finished) return;
    return this.list_commandTrackerGps_finished.find((elem: Command) => elem.type === type);
  }
  
  isTeltonika(): boolean{
    return this.type_trackerGps === 1 || this.type_trackerGps === 2 || this.type_trackerGps === 4;
  }
  
  get $last_command(): Command {
    return this._$last_command;
  }
  
  set $last_command(value: Command) {
    this._$last_command = value;
  }
  
}

export class SimOld {
  iccid_sim: string;
  timestamp: number;
  timestamp_ms: number;
  private _type_sim: number;
  
  private $getTypeSim: (number) => {description: string, type_sim: number} = () => {return undefined};
  $typeSim: {description: string, type_sim: number};
  
  constructor(data?: any, options?: {
    getTypeSim?: (number) => {description: string, type_sim: number}
  }){
    if(data){
      this.setData(data);
    }
  }

  setData(data: any, options?: {
    getTypeSim?: (number) => {description: string, type_sim: number}
  }){
    if(options?.getTypeSim) this.$getTypeSim = options.getTypeSim;
    this.iccid_sim = data.iccid_sim;
    this.timestamp = data.timestamp;
    if(this.timestamp) this.timestamp_ms = this.timestamp * 1000;
    this.type_sim = data.type_sim;
  }
  
  get type_sim(): number {
    return this._type_sim;
  }
  
  set type_sim(value: number) {
    this._type_sim = value;
  }
}

export class StatusOld {
  private _from: number;
  from_label: string;
  private _to: number;
  to_label: string;
  timestamp: number;
  
  constructor(data?: any){
    if(data){
      this.setData(data);
    }
  }
  
  setData(data: any){
    this.from = data.from;
    this.to = data.to;
    this.timestamp = data.timestamp;
  }
  
  get from(): number {
    return this._from;
  }
  
  set from(value: number) {
    this._from = value;
    this.from_label = ListParsers.statusTrackerGps(this.from);
  }
  
  get to(): number {
    return this._to;
  }
  
  set to(value: number) {
    this._to = value;
    this.to_label = ListParsers.statusTrackerGps(this.to);
  }
}

export class FotaTask{
  private _raw: any;
  id: string;
  private _created_at: number;
  type: string;
  status_name: string;
  file_name: string;
  private _updated_at: number;
  
  constructor(data?: any){
    if(data){
      this.setData(data);
    }
  }
  
  setData(data: any){
    this.raw = data;
    this.id  = data.id;
    this.created_at = data.created_at;
    this.type = data.type;
    this.status_name = data.status_name;
    this.updated_at = data.updated_at;
    if(data.file){
      this.file_name = data.file.name;
    }
  }
  
  
  get raw(): any {
    return this._raw;
  }
  
  set raw(raw: any) {
    if(raw){
      raw.company = undefined;
      raw.created_by = undefined;
      raw.updated_by = undefined;
    }
    this._raw = raw;
  }
  
  get created_at(): any {
    return this._created_at;
  }
  
  set created_at(value: any) {
    if(!value) return;
    value = value.replace(' ', 'T');
    this._created_at = (new Date(value + 'Z')).valueOf();
  }
  
  get updated_at(): any {
    return this._updated_at;
  }
  
  set updated_at(value: any) {
    if(!value) return;
    value = value.replace(' ', 'T');
    this._updated_at = (new Date(value + 'Z')).valueOf();
  }
}
