// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://default-dev-2jghk7fwgq-ew.a.run.app',
  baseUrlCommunication: 'https://comunication-dev-2jghk7fwgq-ew.a.run.app',
  baseUrlMigration: '',
  firebaseConfig: {
    apiKey: "AIzaSyDll4bBGPDH7zG_atI4Vjdp9j39oAa-55E",
    authDomain: "white-label-dev-635e0.firebaseapp.com",
    databaseURL: "https://white-label-dev-635e0.firebaseio.com",
    projectId: "white-label-dev-635e0",
    storageBucket: "white-label-dev-635e0.appspot.com",
    messagingSenderId: "10862540980",
    appId: "1:10862540980:web:e039d565d29b913badbfe1"
  }
};

