import {FilterPlatformsPipe} from "./filter-platforms.pipe";
import {NgModule} from "@angular/core";
import {FilterAdminsPipe} from "./filter-admins.pipe";
import {CountdownPipe} from "./countdown.pipe";
import {CompaniesSortPipe} from "./companiesSortPipe.pipe";
import {ColorsChatExpirationPipe} from "./colorsChatExpiration.pipe";
import {SearchCompanyPipe} from "./searchCompany.pipe";
import {ChatIsExpiredPipe} from "./chatIsExpired.pipe";
import {ArrayLimitPipe} from "./arrayLimit.pipe";
import {HaveFieldsPipe} from './haveFields.pipe';
import {SearchBankTransferPipe} from './searchBankTransfer';
import { AdvVisitorsFiltersPipe } from './adv-visitors-filters.pipe';
import { OrdersFilterPipe } from './orders-filter.pipe';
import { SearchCallsPipe } from './searchCalls.pipe';
import { ChatTypeTicketsPipe } from './chat-type-tickets.pipe';
import { TicketsSortPipe } from './tickets-sort.pipe';
import { SearchTicketsPipe } from './search-tickets.pipe';
import { SearchContactsPipe } from './search-contacts.pipe';
import { ActivitiesFilterPipe } from './activities-filter.pipe';
import { ContactsMessagesPipe } from './contacts-messages.pipe';
import {AdvFinalizedFiltersPipe} from './adv-finalized-filters.pipe';
import {AdvFinalizedArchiviedPipe} from './adv-finalized-archivied.pipe';
import {ValidMigrationPipe} from './valid-migration.pipe';
import {SearchBachtPipe} from './searchBatch';
import {SearchMigrationStatusEmailsPipe} from './search-migration-status-emails.pipe';

@NgModule({
  declarations: [
    FilterPlatformsPipe,
    FilterAdminsPipe,
    CountdownPipe,
    CompaniesSortPipe,
    ColorsChatExpirationPipe,
    SearchCompanyPipe,
    ChatIsExpiredPipe,
    ArrayLimitPipe,
    HaveFieldsPipe,
    SearchBankTransferPipe,
    AdvVisitorsFiltersPipe,
    OrdersFilterPipe,
    SearchCallsPipe,
    ChatTypeTicketsPipe,
    TicketsSortPipe,
    SearchTicketsPipe,
    SearchContactsPipe,
    ActivitiesFilterPipe,
    ContactsMessagesPipe,
    AdvFinalizedFiltersPipe,
    AdvFinalizedArchiviedPipe,
    ValidMigrationPipe,
    SearchBachtPipe,
    SearchMigrationStatusEmailsPipe
  ],
  imports: [
  ],
  exports: [
    FilterPlatformsPipe,
    FilterAdminsPipe,
    CountdownPipe,
    CompaniesSortPipe,
    ColorsChatExpirationPipe,
    SearchCompanyPipe,
    ChatIsExpiredPipe,
    ArrayLimitPipe,
    HaveFieldsPipe,
    SearchBankTransferPipe,
    AdvVisitorsFiltersPipe,
    OrdersFilterPipe,
    SearchCallsPipe,
    ChatTypeTicketsPipe,
    TicketsSortPipe,
    SearchTicketsPipe,
    SearchContactsPipe,
    ActivitiesFilterPipe,
    ContactsMessagesPipe,
    AdvFinalizedFiltersPipe,
    AdvFinalizedArchiviedPipe,
    ValidMigrationPipe,
    SearchBachtPipe,
    SearchMigrationStatusEmailsPipe
  ],
})
export class PipesModule { }
