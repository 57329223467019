import {Component, Input, OnInit} from '@angular/core';
import Invoice from '../../classes/invoice';
import {DataService} from '../../services/data.service';
import {OrganizationAdmin} from '../../classes/organizationAdmin';

@Component({
  selector: 'app-invoice-cost-details',
  templateUrl: './invoice-cost-details.component.html',
  styleUrls: ['./invoice-cost-details.component.scss'],
})
export class InvoiceCostDetailsComponent implements OnInit {
  @Input() invoice: Invoice;
  @Input() organization: OrganizationAdmin;
  @Input() title: string;
  @Input() subtitle: string;
  
  constructor() { }

  ngOnInit() {
    let elenco_prodotti = DataService.general_parameter.elenco_prodotti_shop.find((elem)=>elem.platform_type === this.organization.platform_type);
  
    this.invoice.mapInvoiceLineWithProduct(elenco_prodotti.products, this.invoice.metadata?.type);
  }

}
