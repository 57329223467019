import { Pipe, PipeTransform } from '@angular/core';
import {AdvVisitor} from '../classes/advVisitor';

@Pipe({
  name: 'advVisitorsFilters'
})
export class AdvVisitorsFiltersPipe implements PipeTransform {
  
  transform(visitors: AdvVisitor[], archived: boolean, changed: number): AdvVisitor[] {
    if (!visitors || !archived) {
      return visitors;
    }
    
    return visitors.filter(contact => contact.archived === archived);
  }
  
}
