import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Contact} from '../../classes/contact';
import {PopoverSelectComponent} from '../popover-select/popover-select.component';
import {AlertController, ModalController, PopoverController} from '@ionic/angular';
import {NewTicketPage} from '../../app/new-ticket/new-ticket.page';
import {ViewService} from 'common-lib';
import {NewContactPage} from '../../app/new-contact/new-contact.page';
import {DataService} from '../../services/data.service';
import {PopoverListComponent} from '../popover-list/popover-list.component';
import {ApiService} from '../../services/api.service';
import {NewCallPage} from '../../app/new-call/new-call.page';



@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactCardComponent implements OnInit {

  @Input() contact: Contact;
  @Input() selected: boolean = false;
  @Output() clickEvent = new EventEmitter<Contact>();
  @Output() ticketEvent = new EventEmitter<any>();
  
  listener = {
    view: null
  };
  
  constructor(
    private popoverController: PopoverController,
    private modalController: ModalController,
    public view: ViewService,
    private cd: ChangeDetectorRef,
    private alertController: AlertController,
    private api: ApiService
  ) { }

  ngOnInit() {
    this.listenerView();
  }
  
  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }
  
  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
    });
  }
  
  // Gestisce l'apertura delle azioni al click con il dx su un contact
  async contactRightClick(event: any){
    event.preventDefault();
    const componentProps: any = {
      options: [
        {
          id: 'new_ticket',
          name: 'Crea ticket'
        },
        {
          id: 'edit_contact',
          name: 'Modifica contatto'
        },
        {
          id: 'union_contact',
          name: 'Unisci a altro contatto'
        },
       
      ],
      type: 'no_id'
    };
    
    if(this.contact.$list_phones.length > 0){
      componentProps.options.push( {
        id: 'call_contact',
        name: 'Effettua chiamata su contatto'
      })
    }
  
    const popover = await this.popoverController.create({
      component: PopoverSelectComponent,
      event: event,
      translucent: true,
      componentProps: componentProps
    });
    popover.onDidDismiss().then(async (data: any) => {
      if(data?.data?.id){
        switch (data.data.id) {
          case 'new_ticket':
            await this.modalNewTicket()
            //gestisco apertura modale di creazione ticket con gestione del contatto
            break;
          case 'edit_contact':
            await this.modalEditContact()
            break;
          case 'union_contact':
            await this.modalUnionContact()
            break;
          case 'call_contact':
            await this.newCall();
            break;
        }
      }
    });
    await popover.present();
  }
  
  async modalNewTicket() {
    const modal = await this.modalController.create({
      backdropDismiss: true,
      component: NewTicketPage,
      componentProps: {
        contact: this.contact
      },
      cssClass: "modal_big",
    });
    await modal.present();
    await modal.onDidDismiss().then((data)=>{
      this.ticketEvent.emit(data?.data)
    });
  }
  
  // Apre il modal di creazione nuova company
  async modalEditContact() {
    const modal = await this.modalController.create({
      backdropDismiss: true,
      component: NewContactPage,
      componentProps: {
        contact: this.contact
      },
      cssClass: "modal_big",
    });
    await modal.present();
    await modal.onDidDismiss();
  }
  
  async modalUnionContact(){
    let elements: any = [];
    
    for(let c of DataService.array.contacts){
      let aus: any = {
        first_row: `${c.name || 'Contatto senza nome'}`,
        second_row: `${c.note || ''}`,
        id: c._id
      };
      if(c.id_company === this.contact.id_company && c._id !== this.contact._id ) elements.push(aus);
    }
    
    const popover = await this.popoverController.create({
      backdropDismiss: true,
      component: PopoverListComponent,
      componentProps: {
        elements: elements,
        type: 'two_lines',
        title: `Seleziona il contatto in cui vuoi unire`,
        enableSearch: true
      },
      cssClass: "modal_list",
    });
    await popover.present();
    await popover.onDidDismiss().then(async (data: any)=>{
      if(data?.data?.id){
        let c: Contact = DataService.array.contacts.find((elem: Contact) => {return elem._id === data.data.id});
        if(!c) return;
        const alert = await this.alertController.create({
          header: 'Sei sicuro?',
          message: `<small>Vuoi eliminare il contatto <b>${this.contact.name || this.contact._id}</b> e unire ticket, email, telefono nell contatto <b>${c.name || c._id}</b>?</small>`,
          buttons: [
            {
              text: 'Annulla',
              role: 'cancel',
              cssClass: 'secondary'
            }, {
              text: 'Conferma',
              handler: async (data) => {
                await this.view.showLoading('Unione contatti');
                try{
                  /** 1. Ho entrambi i contatti **/
                  const res: any = await this.api.patchUnionContacts(this.contact._id, c._id);
                }catch(err){
                  console.error(err.error.description);
                  this.view.presentAlert('Errore', 'Impossibile unire i due contatti. ' + err?.error?.description, ['Ok']);
                }
                await this.view.hideLoading();
              }
            }
          ]
        });
        await alert.present();
    
      }
    })
  }
  
  async newCall(){
    const modal = await this.modalController.create({
      backdropDismiss: true,
      component: NewCallPage,
      componentProps: {
        contact: this.contact,
      },
      cssClass: "modal_big",
    });
    await modal.present();
    await modal.onDidDismiss();
  }
  
}
