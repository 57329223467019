
type Options = {
  // getAdmin?: (id: string) => Admin | undefined
  // getOrganization?: (id: string) => OrganizationAdmin | undefined
}

export default class OnomondoSim {
  
  account_sid: string
  imei: string
  sid: string
  iccid: string
  sim_id: string
  activated: boolean
  network_whitelist: string
  label: string;
  
  constructor(rawObj?: any, options?: Options) {
    this.setAllFields(rawObj, options);
  }
  
  setAllFields(rawObj: any = {}, options: Options = {}) {
    if (rawObj) {
      this.account_sid = rawObj.account_sid;
      this.imei = rawObj.imei;
      this.sid = rawObj.sid;
      this.sim_id = rawObj.id;
      this.iccid = rawObj.iccid;
      this.activated = rawObj.activated;
      this.network_whitelist = rawObj.network_whitelist;
      this.label = rawObj.label;
    }
  }
  
}
