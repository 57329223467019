import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ViewService} from 'common-lib';
import {ApiService} from '../../services/api.service';
import {AlertController, ModalController, PopoverController} from '@ionic/angular';
import {DataService} from '../../services/data.service';
import {TwilioService} from '../../services/twilio.service';
import {Admin} from '../../classes/admin';
import {PopoverSelectComponent} from '../popover-select/popover-select.component';
import {CallChat} from '../../classes/callChat';

@Component({
  selector: 'app-call-redirect',
  templateUrl: './call-redirect.component.html',
  styleUrls: ['./call-redirect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallRedirectComponent implements OnInit {
  
  listener = {
    view: null,
    twilio_admin: null
  };
  
  admins: Admin[] = [];
  redirect_id: string = '-1';
  
  admins_can_control: Admin[] = [];
  admins_call_center: Admin[] = [];
  admins_call_center_flotte: Admin[] = [];
  loading_super_admin: boolean;
  
  show_admin_redirect_select: boolean[] = [];
  
  selected_tab_assegnazione: string = 'balin';
  
  constructor(
    private cd: ChangeDetectorRef,
    private api: ApiService,
    public data: DataService,
    private modalCtrl: ModalController,
    public view: ViewService,
    public twilio: TwilioService,
    private alertController: AlertController,
    private popoverController: PopoverController
  ) { }

  ngOnInit() {}
  
  async ionViewDidEnter() {
    this.listenerView();
    this.listenerRemote();
    await this.data.updateAdmins()
    this.initPage();
  }
  
  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
    if(this.listener.twilio_admin){
      this.listener.twilio_admin.unsubscribe();
    }
  }
  
  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
    });
  }
  
  listenerRemote(){
    this.listener.twilio_admin = TwilioService.updateAdmin$.subscribe((admin_id: any) => {
      this.initPage();
    });
  }
  
  dismiss(data?: any) {
    this.modalCtrl.dismiss(data);
  }
  
  initPage(){
    this.admins = [];
    this.admins_can_control = [];
    this.admins_call_center = [];
    this.admins_call_center_flotte = [];
    DataService.array.admin.map((elem: Admin) => {
      if(!elem.call_center){
        this.admins.push(elem);
      }
      if(this.data.current_admin.role === 1 && elem._id !== this.data.current_admin._id){ // Super admin
        if(!elem.call_center){
            this.admins_can_control.push(elem);
        }else{
          switch(elem.platform_type){
            case 1 : //balin
              this.admins_call_center.push(elem)
              break;
            case 2:  //flotte
              this.admins_call_center_flotte.push(elem)
              break;
          }
        }
      }
    });
    ViewService.updateView.next();
    this.redirect_id = this.data.current_admin.call_redirect_admin;
    //lo imposto sul admin corrente
    if(this.redirect_id){
      this.show_admin_redirect_select[this.data.current_admin._id] = true;
    }else{
      this.show_admin_redirect_select[this.data.current_admin._id] = false;
    }
    if(this.data.current_admin.role === 1) this.initSuperAdmin();
    ViewService.updateView.next();
  }
  
  async initSuperAdmin(){
    this.loading_super_admin = true;
    this.cd.markForCheck();
  
    
    for(const admin of this.admins_can_control){
      if(!admin.call_redirect_admin){
        admin.$aus_redirect_id = undefined;
      }else{
        admin.$aus_redirect_id = admin.call_redirect_admin;
      }
      //imposto su tutti gli altri admin
      this.show_admin_redirect_select[admin._id] = admin?.$aus_redirect_id
  
      admin.$clients = [];
      const res: any = await this.api.getAdminsListenerCalls(admin._id);
      for(const raw of res){
        const a: Admin = this.data.getAdminById(raw._id);
        a.setAllFields(raw);
        admin.$clients.push(a);
      }
    }
    
    
    for(const admin of this.admins_call_center){
      if(!admin.call_redirect_admin){
        admin.$aus_redirect_id = undefined;
      }else{
        admin.$aus_redirect_id = admin.call_redirect_admin;
      }
      //imposto su tutti gli admin call center
      this.show_admin_redirect_select[admin._id] = false;
  
      admin.$clients = [];
      const res: any = await this.api.getAdminsListenerCalls(admin._id);
      for(const raw of res){
        const a: Admin = this.data.getAdminById(raw._id);
        a.setAllFields(raw);
        admin.$clients.push(a);
      }
    }
  
    for(const admin of this.admins_call_center_flotte){
      if(!admin.call_redirect_admin){
        admin.$aus_redirect_id = undefined;
      }else{
        admin.$aus_redirect_id = admin.call_redirect_admin;
      }
      //imposto su tutti gli admin call center
      this.show_admin_redirect_select[admin._id] = false;
    
      admin.$clients = [];
      const res: any = await this.api.getAdminsListenerCalls(admin._id);
      for(const raw of res){
        const a: Admin = this.data.getAdminById(raw._id);
        a.setAllFields(raw);
        admin.$clients.push(a);
      }
    }
    
    
    
    ViewService.updateView.next();
    this.loading_super_admin = false;
    this.cd.markForCheck();
  }
  
  async deleteRedirect(admins: Admin[], origin_admin: string){
    return await this.view.presentAlert('Sei sicuro?', `Confermi di voler cancellare l'inoltro?`, [{
      text: 'Annulla',
      role: 'cancel',
      cssClass: 'secondary'
    }, {
      text: 'Conferma',
      handler: async () => {
        await this.setRedirect(admins, origin_admin, undefined)
      }
    }]);
  }
  
  async edit(admins: Admin[], origin_admin: string){
    await this.setRedirect(admins, origin_admin, undefined)
  }
  
  async setRedirect(admins: Admin[], origin_admin: string, final_admin: string){
    if(admins.length > 1){
      return this.view.presentAlert('Errore', 'Questo utente riceve le chiamate di altri admin. Per impostare il redirect devi prima chiedere agli altri admin di togliere il redirect verso di te', ['OK']);
    }else{
      await this.view.showLoading('Salvataggio redirect...');
      try{
        const res: any = await this.api.postAdminForwarding(origin_admin, final_admin  ? final_admin : undefined);
        if(res){
          const admin: Admin = this.data.getAdminById(origin_admin);
          admin.setAllFields(res);
          ViewService.updateView.next();
        }
      }catch(err){
        console.error(err);
        this.view.presentAlert('Errore', 'Impossibile salvare il redirect', ['Ok']);
      }
      ViewService.updateView.next();
      await this.view.hideLoading();
    }
  }
  
  /** Metodo utilizzato per visualizzare la sezione per aggiungere i redirect **/
  enableRedirect(id_admin: string){
    this.show_admin_redirect_select[id_admin] = true;
    ViewService.updateView.next();
  }
  
  editRedirect(id_admin: string){
    this.show_admin_redirect_select[id_admin] = true;
    ViewService.updateView.next();
  }
  
  /**
   * Metodo utilizato per cambiare il ruolo (admin.role) dell'admin in standard (role = 0) o superadmin (role = 1)
   * @param currentAdmin
   */
  async changeAdminRole(currentAdmin: Admin){
    if(currentAdmin){
      const alert = await this.alertController.create({
        header: `Sei sicuro?`,
        message: `Sei sicuro di voler ${currentAdmin.role === 1 ? ' TOGLIERE' : ' CAMBIARE'} lo stato di ${currentAdmin.nickname} ${currentAdmin.role === 1 ? ' da' : ' in'} superadmin? `,
        buttons: [
          {
            text: 'Annulla',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              console.log('Confirm Cancel');
            }
          }, {
            text: 'Conferma',
            handler: async () => {
              try{
                this.view.showLoading('Aggiornamento...')
                let adminUpdated  = await this.api.patchAdminRole(currentAdmin._id, currentAdmin.role ? 0 : 1 )
                let admin: Admin  = this.data.getAdminById(currentAdmin._id)
                if(admin) admin.setAllFields(adminUpdated)
                this.view.hideLoading()
              }catch(err){
                console.error(err)
              }
              
            }
          }
        ]
      });
      await alert.present();
    }
  }
  
  /** Marca TUTTE le call come lette su tutto il sistema */
  async markAllCallsAsRead(){
    await this.view.presentAlert('Sei sicuro?', `Segnerai come lette tutte le chiamate di ogni admin e di qualsiasi piattaforma. Inoltre può richiedere molto tempo`, [{
      text: 'Annulla',
      role: 'cancel',
      cssClass: 'secondary'
    }, {
      text: 'Conferma',
      handler: async () => {
        try{
          await this.view.showLoading();
  
          for(let admin of DataService.array.admin){
            if(admin.call_center) continue;
            await this.api.patchCallCountUnread(admin._id);
          }
        }catch(err){
          console.error(err);
          this.view.presentAlert('Errore', 'Impossibile impostare tutte le chiamate come lette', ['Ok']);
        }
        await this.view.hideLoading();
      }
    }]);
  }
  
  checkAdminReserveLista(admin_id: string, admin: Admin){
    if(admin && admin_id){
      for(let admin_reserve of admin.admin_reserve_list){
        if(admin_reserve === admin_id){
          return true;
        }
      }
    }
    return false;
  }
  
  async openPopoverAdmin(ev: any, admin: Admin){
    let admins: any = [];
    
    if(DataService.array.admin){
      DataService.array.admin.map((elem: Admin) => {
        if(!elem.call_center && elem._id !== admin.$aus_redirect_id){
          admins.push({
            name: elem.nickname,
            id: elem._id,
            elem: elem,
            value: admin.admin_reserve_list?.find((el)=>el === elem._id)
          });
        }
      });
    }
    
    const componentProps: any = {
      options: admins,
      type: 'checkboxes'
    };
    const popover = await this.popoverController.create({
      component: PopoverSelectComponent,
      event: ev,
      translucent: true,
      componentProps: componentProps
    });
    popover.onDidDismiss().then(async () => {
      this.view.showLoading('Aggiornamento...')
      let selectedAdmins: any = [];
      console.log(admins)
      for(let elem of admins){
        if(elem.value){
          selectedAdmins.push(elem.id)
        }
      }
      
      try{
        await this.api.patchAdminReserveList(admin._id, selectedAdmins);
      }catch(err){
        this.view.presentAlert('Errore', 'Impossibile aggiornare gli admin riserva', ['OK']);
        console.error(err)
      }
      
      this.view.hideLoading()
      ViewService.updateView.next();
    });
    await popover.present();
    return popover;
  }
  
  async changeAdminImportantFlag(admin: Admin){
    if(admin){
      /** 1. verificare se admin è di tipo call_center*/
      if(!admin.call_center){
        this.view.presentAlert('Errore', 'Admin non valido', ['OK']);
        ViewService.updateView.next();
        return;
      }
      
      
      /** 2. Verificare se admin ha dei reidrect, in questo caso cancellare*/
      if(admin.admin_reserve_list){
       await this.view.presentAlert('Attenzione', `${admin.nickname} ha una lista di admin di riserva impostata, proseguendo la lista verrà azzerata, proseguire?`, [{
            text: 'Annulla',
            role: 'cancel',
            cssClass: 'secondary'
          }, {
            text: 'Conferma',
            handler: async () => {
              this.view.showLoading('Cancellazione lista riserve in corso...')
              try{
                let res = await this.api.patchAdminBotted(admin._id, !admin.important, true);
              }catch(err){
                this.view.presentAlert('Errore', 'Impossibile eliminare gli admin riserva', ['OK']);
                console.error(err)
              }
              this.view.hideLoading()
              ViewService.updateView.next();
              return;
            }
          }]
        )
      }else{
        this.view.showLoading('Aggiornamento...')
        try{
          let res = await this.api.patchAdminBotted(admin._id, !admin.important);
        }catch(err){
          this.view.presentAlert('Errore', 'Impossibile aggiornare stato admin', ['OK']);
          console.error(err)
        }
        this.view.hideLoading()
      }
    }
  
    ViewService.updateView.next();
    return;
   
    
  }
  
  async handleReorder(ev, admin: Admin) {
    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. Update the items variable to the
    // new order of items
    admin.admin_reserve_list = ev.detail.complete(admin.admin_reserve_list);
    //
    this.view.showLoading('Aggiornamento...')
    
    try{
      await this.api.patchAdminReserveList(admin._id, admin.admin_reserve_list);
    }catch(err){
      this.view.presentAlert('Errore', 'Impossibile aggiornare gli admin riserva', ['OK']);
      console.error(err)
    }
  
    this.view.hideLoading()
    ViewService.updateView.next();
  }
}
