import {
  Injectable, Pipe,
  PipeTransform
} from '@angular/core';

@Pipe({
  name: 'haveFields'
})
@Injectable()
export class HaveFieldsPipe implements PipeTransform {
  transform(array: any[], fields: string[], changed: number): any {
    
    if(!fields || fields.length <= 0) return array;
    
    let filtered: any[] = [];
    
    let props: string[];
    let itemProp: any;
  
    filtered = array.filter(item => {
  
      
      for(let field of fields){
        props = field.split(".");
        itemProp = item;
        let no_field: boolean = false;
  
  
        for (let prop of props) {
          if (itemProp[prop] || itemProp[prop] === 0) {
            itemProp = itemProp[prop];
          }else{
            no_field = true;
          }
        }
  
        if(no_field){
          return false;
        }
      }
      
      return true;
      
    });
      
    return filtered;
  }
}
