export class ListParsers {

  // DA ELIMINARE MA USATA IN COMPANY ADD
  static typesPlatform: any = {
    1: 'BalinApp',
    2: 'Flotta in Cloud',
  };

  static getTypesPlatform(){
    return ListParsers.getList(ListParsers.typesPlatform);
  }

  static parseTypePlatform(value: number){
    return ListParsers.typesPlatform[value] || 'Sconosciuta';
  }

  static simStates: any = {
    1: 'Attiva',
    2: 'Sospesa',
    3: 'Disattiva'
  };

  static getSimStates(){
    return ListParsers.getList(ListParsers.simStates);
  }

  static parseSimStatus(value: number){
    return ListParsers.simStates[value] || 'Sconosciuto';
  }

  static storeStates: any = {
    1: 'Magazzino Wi-tek',
    2: 'Magazzino Amazon',
    3: 'Spedito cliente'
  };

  static getStoreStates(){
    return ListParsers.getList(ListParsers.storeStates);
  }

  static parseStoreStatus(value: number){
    return ListParsers.storeStates[value] || 'Sconosciuto';
  }

  static trackerStates: any = {
    1: 'Disponibile alla vendita',
    2: 'Distrutto',
    3: 'In attesa di reso',
    4: 'Reso ricevuto',
    5: 'Da riprogrammare'
  };

  static getTrackerStates(){
    return ListParsers.getList(ListParsers.trackerStates);
  }

  static statusTrackerGps(value: number){
    return ListParsers.trackerStates[value] || 'Sconosciuto';
  }

  static typesCommand: any = {
    0: 'Api testServerTeltonika',
    1: 'Programmazione Teltonika',
    2: 'Comandi liberi Teltonika',
    3: 'Setting DOUT1 Teltonika',
    4: 'Posizione corrente (no notify)',
    5: 'Reset localizzatore (no notify)'
  };

  static getTypesCommand(){
    return ListParsers.getList(ListParsers.typesCommand);
  }

  static parseTypeCommand(value: number){
    return ListParsers.typesCommand[value] || 'Sconosciuto';
  }

  static commandStates: any = {
    1: 'Comando inviato',
    2: 'Invio in corso',
    3: 'Terminato in errore',
    4: 'Terminato con successo'
  };

  static getCommandStates(){
    return ListParsers.getList(ListParsers.commandStates);
  }

  static parseCommandState(value: number){
    return ListParsers.commandStates[value] || 'Sconosciuto';
  }

  static avlIds: any = {
    252: {
      name: 'Alimentazione',
      0: 'Collegato',
      1: 'Scollegato'
    },
    250: {
      name: 'Inizio/fine trip',
      0: 'Trip stop',
      1: 'Trip start'
    },
    240: {
      name: 'Movement',
      0: 'Off',
      1: 'On'
    },
    69: {
      name: 'Stato GPS',
      0: 'GNSS OFF',
      1: 'GNSS ON with fix',
      2: 'GNSS ON without fix',
      3: 'GNSS sleep'
    },
    1: {
      name: 'Digital input 1',
      0: 'Stato logico 0',
      1: 'Stato logico 1'
    },
    179: {
      name: 'Digital output 1',
      0: 'Stato logico 0',
      1: 'Stato logico 1'
    },
  };

  static parseAvlId(value: number){
    if(ListParsers.avlIds[value]){
      return ListParsers.avlIds[value].name || 'Sconosciuto';
    }
  }

  static parseAvlValue(avl_id: number, value: number){
    if(ListParsers.avlIds[avl_id]){
      return ListParsers.avlIds[avl_id][value] || 'Sconosciuto';
    }
  }

  static positionTypes: any = {
    1: 'Apertura connessione',
    2: 'Chiusura connessione',
    3: 'Ping',
    4: 'Posizione',
    5: 'Evento',
    98: 'Comando',
    99: 'Risposta'
  };

  static getPositionTypes(){
    return ListParsers.getList(ListParsers.positionTypes);
  }

  static parsePositionType(value: number){
    return ListParsers.positionTypes[value] || 'Sconosciuto';
  }

  private static getList(map: any){
    let res: any[] = [];
    for(let key in map){
      res.push({
        id: parseInt(key),
        name: map[key]
      });
    }
    return res;
  }
  
  static billingTypes: any = {
    1: 'Privato',
    2: 'Azienda'
  };
  
  static getBillingTypes(){
    return ListParsers.getList(ListParsers.billingTypes);
  }
  
  static parseBillingType(value: number){
    return ListParsers.billingTypes[value] || 'Sconosciuto';
  }
  
  static billingLocations: any = {
    1: 'Italia',
    2: 'EU',
    3: 'Extra-EU'
  };
  
  static getBillingLocations(){
    return ListParsers.getList(ListParsers.billingLocations);
  }
  
  static parseBillingLocations(value: number){
    return ListParsers.billingLocations[value] || 'Sconosciuto';
  }
}
