import { Pipe, PipeTransform } from '@angular/core';
import {MigrationStatus} from '../classes/migration';

@Pipe({
  name: 'searchMigrationStatusEmails'
})
export class SearchMigrationStatusEmailsPipe implements PipeTransform {
  
  transform(migrationStatus: MigrationStatus[], query: string, changed: number): any {
    
    if(!query) return migrationStatus;
    
    query = query.toLowerCase();
    
    let filtered: MigrationStatus[] = [];
    
    for(let migration of migrationStatus){
      if(migration.email){
  
        if(migration.email.indexOf(query) >= 0){
          filtered.push(migration);
          break;
        }
        
      }
    }
    
    return filtered;
  }
  
  
}
