import { Pipe, PipeTransform } from '@angular/core';
import {AdvFinalized} from '../classes/advFinalized';

@Pipe({
  name: 'advFinalizedArchivied'
})
export class AdvFinalizedArchiviedPipe implements PipeTransform {
  
  transform(finalized: AdvFinalized[], archived: boolean, changed: number): AdvFinalized[] {
    if (!finalized) {
      return finalized;
    }
    
    return finalized.filter(elem => elem.archived === archived);
  }
  
}
