import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { AlertController, ModalController, NavController, PopoverController, ToastController } from '@ionic/angular';
import { 
  AuthService, 
  ViewService, ViewService as View,
} from 'common-lib';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';
import { TrackerAdmin } from 'src/classes/trackerAdmin';
import {ApiService} from "../../services/api.service";
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DataService } from 'src/services/data.service';
import Activity from 'src/classes/activity';
import Company from 'src/classes/company';
import { ListParsers } from 'src/classes/listParsers';

// import * as lodash from 'lodash';

@Component({
  selector: 'app-twilio-sim-status-select',
  templateUrl: './twilio-sim-status-select.page.html',
  styleUrls: ['./twilio-sim-status-select.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TwilioSimStatusSelectPage implements OnInit {
  
  // Data passed in by componentProps
  @Input() selectOptions: any[] = [];
  @Input() selectedOption: any;

  listener = {
    data: null,
    view: null,
  };
  
  constructor(
    private cd: ChangeDetectorRef,
    private api: ApiService,
    private data: DataService,
    private modalCtrl: ModalController,
    private auth: AuthService,
    private nav: NavController,
    public popoverController: PopoverController,
    private route: ActivatedRoute,
    private router: Router,
    private view: ViewService,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
  ) {}

  ngOnInit() {}
  
  ionViewDidEnter() {
    // this.listenerData();
    this.listenerView();
    // this.data.getEntityMultiple(this.entity as any);
    // this.initSomeDOMelems();
    // console.log("this.row",this.row)
  }

  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }
  
  // * content
  
  async save() {
    console.log('save()', )
    switch (this.selectedOption.val) {
      case "deactivated":
        if (await this.alertSaveAsDeactivated()) {
          this.dismiss({ selItem: this.selectedOption })
        }
        break;
      default:
        this.dismiss({ selItem: this.selectedOption })
    }
  }
  
  // * listener
  
  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
      setTimeout(() => {
        this.cd.markForCheck();
      }, 250);
    });
  }
  
  // * navigation
  
  dismiss(data?: any) {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss(data);
  }
  
  // * test
  
  consoleLog(i) {
    console.log('i', i)
    // console.log('i', DataService.array.activity)
    // this.router.navigate(['phonebook-activities']);
  }
  
  // * windowed content
  
  async alertSaveAsDeactivated() {
    const alert = await this.alertCtrl.create({
      buttons: [
        {
          text: "Procedi",
          role: "confirm",
          // handler: () => true
        },
        {
          text: "Annulla",
          role: "cancel",
          cssClass: "secondary",
          // handler: () => {}
        }, 
      ],
      // cssClass: "",
      header: "Conferma", 
      message: `
        La sim verrà disattivata: 
        - non potrà connettersi al network;
        - può esser riattivata solo durante le prime 72 ore in questo stato;
        Dopo 72 ore in questo stato, la sim diventa inutilizzabile per sempre 
        in modo irreversibile.
      `,
      // subHeader: "Subtitle",
    });

    await alert.present();

    const { data, role } = await alert.onDidDismiss();
    // console.log('alert.onDidDismiss data', data)
    console.log('alert.onDidDismiss role', role)
    switch (role) {
      case "confirm":
        return true
        break;
      default:
        // return false
    }
  }
  
  

}
