import {Component, Input, OnInit} from '@angular/core';
import {AlertController, ModalController} from '@ionic/angular';
import {ViewService} from 'common-lib';
import {ApiService} from '../../services/api.service';
import {DataService} from '../../services/data.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Admin} from '../../classes/admin';
import Company from '../../classes/company';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import * as lodash from 'lodash';
import {Contact} from '../../classes/contact';
import {Utility} from '../../classes/utility';
@Component({
  selector: 'app-new-contact',
  templateUrl: './new-contact.page.html',
  styleUrls: ['./new-contact.page.scss'],
})
export class NewContactPage implements OnInit {
  
  @Input() company: Company;
  @Input() contact: Contact;
  
  @Input() is_adv: boolean;
  @Input() company_name: string;
  @Input() contact_phone: string;
  @Input() contact_email: string;
  
  
  DataService = DataService;
  contactForm: FormGroup;
  
  admins: Admin[] = [];
  admins_call_center: Admin[] = [];
  admins_call_center_flotte: Admin[] = [];
  
  phoneValid: boolean = false;
  emailValid: boolean = false;
  email_company_found: Company;
  phone_company_found: Company;
  contact_assignment: any = {};
  phone: string;
  inheritedCompanyName: boolean = false;
  list_email: string[] = [];
  
  lastPhoneVerified: string;
  lastEmailVerified: string;
  
  phoneValue: string = '+39';
  emailValue: string;
  original_contact: Contact;
  
  private isSettingValue = false;
  
  phoneIsInError: boolean = true;
  emailIsInError: boolean = true;
  
  constructor(
    private modalController: ModalController,
    private view: ViewService,
    public data: DataService,
    private api: ApiService,
    private alertCtrl: AlertController,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  
  async ngOnInit() {
    if(this.contact){
      this.original_contact = this.contact;
      this.contact = lodash.cloneDeep(this.contact);
    }
    this.contactForm = new FormGroup({
      platform: new FormControl(1, {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(1)]
      }),
      name: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.minLength(1)]
      }),
      company_name: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.minLength(1)]
      }),
      id_company: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.minLength(1)]
      }),
      phone: new FormControl('+39', {
        updateOn: 'change',
        validators: [Validators.minLength(1)]
      }),
      email: new FormControl("", {
        updateOn: 'change',
        validators: [Validators.minLength(1)]
      })
    });
    
    
    //di default metto la piattaforma uno
    await this.getAssignment();
    
    /** Creazione contatto con copmany **/
    if(this.company){
      this.contactForm.get('company_name').setValue(this.company.label)
      this.contactForm.get('id_company').setValue(this.company._id)
      this.inheritedCompanyName = true;
    }
    
    /** Modifica contatto **/
    if(this.contact){
      //sono in modifica contatto
      this.setEditContactFields();
    }
    
    /** verifico se la modale è stata aperta da adv per creazione contatto **/
    if(this.is_adv) this.setFieldFromAdv()
    
    
    
    
  }
  
  validatePhone(control: FormControl) {
    return (): boolean => {
      const phone = control.value;
      if (!phone) {
        return true;
      }
      
      // Verifica che il numero sia composto solo da numeri
      return phone.match(/^[0-9]+$/);
    };
  }
  
  async ionViewDidEnter() {
    DataService.array.admin.map((elem: Admin) => {
      if (!elem.call_center) {
        this.admins.push(elem);
      } else {
        switch(elem.platform_type){
          case 1:
            this.admins_call_center.push(elem);
            break;
          case 2:
            this.admins_call_center_flotte.push(elem);
            break;
        }
      }
    });
  }
  
  /**
   * Chiudo modale
   */
  async dismiss(data: any) {
    await this.modalController.dismiss(data);
  }
  
  resetField(type: string) {
    
    switch(type){
      case 'email':
        this.emailIsInError = Utility.validateEmail(this.contactForm.get('email').value)
        if(this.emailValid){
          this.emailValid = null;
          this.lastEmailVerified = null;
          this.email_company_found = undefined
          if(!this.list_email.length){
          }
        }
        
        break;
      case 'phone':
        this.phoneIsInError = Utility.validatePhone(this.contactForm.get('phone').value)
        this.contactForm.get('phone').setValue(this.contactForm.get('phone').value.toLowerCase())
        if (!this.isSettingValue) {
          if(this.phoneValid){
            this.phone_company_found = undefined
            this.phoneValid = null;
            this.lastPhoneVerified = null;
            this.phone = null;
          }
        }
      
        break;
    }
    
  }
  
  async verifyPhone(){
    const phone = this.contactForm.get('phone').value;
    /** 1. gestitsco l'errore nel caso in cui sto verificando un telefono non valido **/
    if(phone.length <= 3){
      const alert = await this.alertCtrl.create({
        buttons: ['Ok'],
        header: 'Errore',
        message: 'Numero di telefono non valido, correggerlo prima di verificarlo'
      });
      await alert.present();
      return
    }
    this.lastPhoneVerified = lodash.cloneDeep(phone);
    
    this.view.showLoading('Verifica numero in corso....')
    /** 2. Verifico se è gia presente un contatto con il numero inserito **/
    const result: any = await this.api.contactVerify(this.contactForm.get('platform').value, phone);
    
    if (result.success) {
      /** 2.1 il numero non è presente in un contatto **/
      this.phoneValid = true;
      this.phone = lodash.cloneDeep(phone);
      
    } else {
      /** 2.2 Il numero è gia presente in un contatto **/
      this.phone = null
      this.phoneValid = false;
      this.phone_company_found = DataService.array.companies.find((elem)=>elem._id === result.company);
      this.contactForm.get('phone').patchValue('+39')

      this.view.hideLoading();
    }
    

    this.view.hideLoading()
    
  }
  
  /**
   * Metodo utilizzato per verificare se la mail inserita è valido è non presente in un altro contact
   */
  async verifyEmail(){
    try {
      this.view.showLoading('Caricamento...');
      this.lastEmailVerified = lodash.cloneDeep( this.contactForm.get('email').value)
      const result: any = await this.api.contactVerify(this.contactForm.get('platform').value, null,this.contactForm.get('email').value);
      if (result.success) {
        
        this.emailValid = true;
        this.list_email.push(this.contactForm.get('email').value) //Aggiungo la mail alla lista
        
      } else {
        this.emailValid = false;
        //ho già un contatto con questa mail
        this.email_company_found = DataService.array.companies.find((elem)=>elem._id === result.company);
        this.view.hideLoading();
        
        this.contactForm.get('email').patchValue('')
        
      }
    } catch (err) {
      console.error(err);
    }
    this.view.hideLoading();
  }
  
  removeEmailFromList(i: number){
    this.list_email.splice(i, 1)
  }
  
  async createContact(){
    await this.view.showLoading('Attendere...');
    //validation
    if (!this.contactForm.valid) {
      const alert = await this.alertCtrl.create({
        buttons: ['Ok'],
        header: 'Errore',
        message: 'Non tutti i campi obbligatori son stati popolati'
      });
    
      await alert.present();
      await this.view.hideLoading();
      return;
    }
  
    //verifico la lista di redirect
    if (this.contact_assignment[this.getPlatformTypeSelectedInt()].redirects) {
      let redirect = [];
      for (let type in this.contact_assignment[this.getPlatformTypeSelectedInt()].redirects) {
        //In questo modo leggo per ogni type
        redirect.push({
          idAdmin: this.contact_assignment[this.getPlatformTypeSelectedInt()].redirects[type].id_admin,
          type: parseInt(type),
          label: this.contact_assignment[this.getPlatformTypeSelectedInt()].redirects[type].label,
          important: this.contact_assignment[this.getPlatformTypeSelectedInt()].redirects[type].important || false
        });
      }
      
      try {
        //devo verificare che il numero di telefono non sia solo +39 e che sia validato
        let contact;
        if(this.contact){
          //sono in modifica contatto
          redirect = [];
          let need_confirm: boolean;
          for (let type in this.contact_assignment[this.getPlatformTypeSelectedInt()].redirects) {
            //In questo modo leggo per ogni type
            let data: any = {
              idAdmin: this.contact_assignment[this.getPlatformTypeSelectedInt()].redirects[type].id_admin,
              type: parseInt(type),
              important: this.contact_assignment[this.getPlatformTypeSelectedInt()].redirects[type].important || false
            }
            if(!this.original_contact.getSubChat(data.type, parseInt(this.contactForm.get('platform').value)) || (data.idAdmin !== this.original_contact.getSubChat(data.type, parseInt(this.contactForm.get('platform').value)).id_admin)){
              data.unread_calls = 0;
              need_confirm = true;
            }
            redirect.push(data);
          }
          if(need_confirm){
            await this.view.hideLoading();
            return await this.view.presentAlert('Attenzione', `Cambiando l'admin assegnatario segni anche tutte le chiamate senza risposta del contatto come lette`, [{
              text: 'Annulla',
              role: 'cancel',
              cssClass: 'secondary'
            }, {
              text: 'Conferma',
              handler: async () => {
                contact = await this.api.patchContact(this.contact._id, this.contactForm.get('name').value , this.contactForm.get('platform').value, this.phone, this.list_email, redirect);
                if(contact){
                  try{
                    await this.dismiss({data: contact.contact._id.toString()});
                    const alert = await this.alertCtrl.create({
                      buttons: ['Ok'],
                      header: `Contatto ${this.contact ? 'modificato' : 'creato'}`,
                    });
                    await alert.present();
                  } catch (err) {
                    console.error('Errore in edit contatto', err);
                    const alert = await this.alertCtrl.create({
                      buttons: ['Ok'],
                      header: 'Errore',
                      message: `Impossibile ${this.contact ? 'modificare' : 'creare' } il contatto`
                    });
                    await alert.present();
                    this.view.hideLoading();
                    return;
                  }
                }
              }
            }]);
          }else{
            contact = await this.api.patchContact(this.contact._id, this.contactForm.get('name').value , this.contactForm.get('platform').value, this.phone, this.list_email, redirect);
          }
        }else{
          contact = await this.api.postContact(this.contactForm.get('name').value , this.contactForm.get('company_name').value, this.contactForm.get('platform').value, this.phone, this.list_email, redirect,this.contactForm.get('id_company').value );
        }
        if(contact){
          await this.view.hideLoading();
          await this.dismiss({data: contact?.contact?._id.toString(), company: contact?.company?.toString()});
          const alert = await this.alertCtrl.create({
            buttons: ['Ok'],
            header: `Contatto ${this.contact ? 'modificato' : 'creato'}`,
          });
          await alert.present();
        }
      } catch (err) {
        console.error('Errore in edit contatto', err);
        const alert = await this.alertCtrl.create({
          buttons: ['Ok'],
          header: 'Errore',
          message: `Impossibile ${this.contact ? 'modificare' : 'creare' } il contatto`
        });
        await alert.present();
        this.view.hideLoading();
        return;
      }
    }
    
    await this.view.hideLoading();
    
    
    
  }
  
  /**
   * Metodo utilizzato per cambiare l'admin per gestire l'assegnazione della sub chat
   * @param event
   * @param call_center
   */
  changeRedirectAdmin(event: any, call_center: number) {
    this.contact_assignment[this.getPlatformTypeSelectedInt()].redirects[call_center].id_admin = event._id;
  }
  
  /**
   * Metodo utilizzato per cambiare la gestione del bot per l'admin
   * @param call_center
   */
  changeBotManagedAdmin(call_center: number) {
    this.contact_assignment[this.getPlatformTypeSelectedInt()].redirects[call_center].important = !this.contact_assignment[this.getPlatformTypeSelectedInt()].redirects[call_center].important;
    
  }
  
  openCompany(id: string){
    this.modalController.dismiss();
    let navigationExtras: NavigationExtras = {
      queryParams: {
        idCompany: id
      }
    };
    this.router.navigate(['chat/whatsapp'], navigationExtras);
  }
  
  setEditContactFields(){
    console.log('edit')
    /** 1. Ottengo la company del contatto **/
    const company = this.data.getCompanyById(this.contact.id_company)
  
    this.inheritedCompanyName = true;
    
    /** 2. Alimento il form con i dati del contatto **/
    this.contactForm.get('name').setValue(this.contact.name)
    this.contactForm.get('company_name').setValue(company.name)
    this.contactForm.get('id_company').setValue(company._id)
    if(this.contact.$list_phones[0]){
      this.setPhoneValue(this.contact.$list_phones[0])
      this.phoneValid = true;
      this.phone = lodash.cloneDeep(this.contactForm.get('phone').value);
    }
    
    /** Alimento la lista di email **/
    this.list_email = this.contact.$list_emails;
    
    if(this.contact.sub_chat){
      
      //devo getsire la doppia piattaforma per le subchat
      for(let sub_chat of this.contact.sub_chat){
        // contact_sub_chat[sub_chat.type] = sub_chat;
        if (!this.contact_assignment[parseInt(sub_chat.platform_type)]) {
          // Se non esiste, crealo come un oggetto vuoto
          this.contact_assignment[parseInt(sub_chat.platform_type)] = {
            redirects: {}
          };
        }
        // Aggiungi sub_chat al campo redirects usando sub_chat.type come chiave
        this.contact_assignment[parseInt(sub_chat.platform_type)].redirects[parseInt(sub_chat.type)] = sub_chat;
      }
      
      
    }
    
  }
  
  setPhoneValue(phone: any){
    this.isSettingValue = true;
    this.contactForm.get('phone').setValue(phone)
    setTimeout(()=>{
      this.isSettingValue = false;
    }, 500)
  }
  
  setFieldFromAdv(){
    if(this.is_adv){
      if(this.company_name){
        this.contactForm.get('company_name').setValue(this.company_name)
      }
      
      if(this.contact_phone){
        this.contactForm.get('phone').setValue(this.contact_phone)
      }

      if(this.contact_email){
        this.contactForm.get('email').setValue(this.contact_email)
      }
      
    }
    
  }
  
  getPlatformTypeSelected(){
    const platform = this.contactForm.get('platform').value;
    switch (parseInt(platform)) {
      case 1:
        return 'Balin'
        break;
      case 2:
        return 'Flotta in Cloud'
        break;
    }
  }
  
  getPlatformTypeSelectedInt(){
    return parseInt(this.contactForm.get('platform').value);
  }
  
  /**
   * Quando deve entrare in gioco questo metodo?
   * Quando creao un contatto e gli seleziono in automatico la piattaforma
   */
  async getAssignment(){
    try{
      this.view.showLoading('Caricamento dati in corso')
      const assignments: any = await this.api.getAssignment();
      
      for(let i in assignments){
        this.contact_assignment[parseInt(i)]=assignments[parseInt(i)]
      }
    
      await this.view.hideLoading()
    }catch (e) {
      await this.view.hideLoading()
    };
  }
  
  hasAssignment(){
    return this.phoneValid || this.list_email.length
  }
  
}
