import { Pipe, PipeTransform } from '@angular/core';
import {Contact} from '../classes/contact';

@Pipe({
  name: 'searchContacts'
})
export class SearchContactsPipe implements PipeTransform {
  
  transform(contacts: Contact[], query: string, changed: number): Contact[] {
    
    if(!query) return contacts;
    
    query = query.toLowerCase();
    
    let filtered: Contact[] = [];
    
    for(const contact of contacts){
      let found: boolean = false;
      if(contact.name?.toLowerCase().indexOf(query) >= 0){
        filtered.push(contact);
        found = true;
      }
      if(!found && contact.phones){
        for(const phone in contact.phones){
          if(phone.indexOf(query) >= 0){
            filtered.push(contact);
            found = true;
          }
        }
      }
      if(!found && contact.$list_emails){
        for(const email of contact.$list_emails){
          if(email.indexOf(query) >= 0){
            filtered.push(contact);
            found = true;
          }
        }
      }
      if(found) continue;
  
      if(contact.$company?.label?.toLowerCase().indexOf(query) >= 0){
        filtered.push(contact);
        found = true;
      }
      if(!found && contact.$company?.$organizations){
        for(const organization of contact.$company.$organizations){
          if(organization.login_email_partecipant_owner.indexOf(query) >= 0){
            filtered.push(contact);
            found = true;
          }
        }
      }
    }
  
    return filtered;
  }


}
