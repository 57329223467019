import {Admin} from './admin';
import {Call} from '@twilio/voice-sdk';
import Company from './company';
import {Platform} from './platform';
import {CallChat, CallSubChat} from './callChat';
import {Ticket} from './ticket';
import {Contact} from './contact';
import {SubChat} from './subChat';

type Options = {
  getAdmin?: (string) => Admin | undefined,
  getPlatformByType?: (number) => Platform | undefined,
  getTicketById: (string) => Ticket,
  getContactById: (string) => Contact,
}

export enum CallDirection{
  Ricevuta = 'incoming',
  Effettuata = 'outgoing'
}

/**
 * In questa classe gestisco sia le call di mongodb sia quelle di Twilio
 */
export class TwilioCall{
  
  private _id: string;
  
  raw_twilio: Call;
  raw_db: any;
  
  // Questi stati sono solo un set degli stati possibili di call dell SDK e di quelli che settiamo su mongodb come webhook vedi doc twilio:
  // https://www.twilio.com/docs/voice/api/call-resource#statuscallback,
  // https://www.twilio.com/docs/voice/sdks/javascript/twiliocall#properties
  private _status: 'ringing' | 'in-progress' | 'completed' | 'no-answer' | 'unknown';
  private _sid: string;
  private _from: string;
  private _to: string;
  private _audio: string;
  private _creation: number;
  private _direction: 'incoming' | 'outgoing';
  private _duration: number;
  private _transcription: string;
  private _botted: boolean;
  private _to_read: boolean;
  
  private _id_ticket: string;
  $ticket: Ticket;
  
  private _id_contact: string;
  $contact: Contact;
  
  private _platform_type: number;
  $platform: Platform;
  
  private _type: number;
  $from_label: string;
  $to_label: string;
  
  private _origin_admin_id: string;
  $origin_admin: Admin;
  private _final_admin_id: string;
  $final_admin: Admin;
  
  $company: Company;
  $sub_chat: SubChat;
  
  $getAdmin: (string) => Admin = () => {return undefined};
  $getPlatformByType: (number) => Platform = () => {return undefined};
  $getContactById: (string) => Contact = () => {return undefined};
  $getTicketById: (string) => Ticket = () => {return undefined};
  
  constructor(call?: Call | any, options?: Options) {
    if(call){
      this.setData(call, options);
    }
  }
  
  setData(call: Call | any, options?: Options){
    if(options){
      if (options.getAdmin) this.$getAdmin = options.getAdmin;
      if (options.getPlatformByType) this.$getPlatformByType = options.getPlatformByType;
      if(options.getContactById) this.$getContactById = options.getContactById;
      if (options.getTicketById) this.$getTicketById = options.getTicketById;
    }
    if(call instanceof Call){
      this.raw_twilio = call;
    }else{
      this.raw_db = call;
      this.origin_admin_id = call.origin_admin_id;
      this.final_admin_id = call.final_admin_id;
      this.platform_type = call.platform_type;
      this.type = call.type
      this.id_ticket = call.id_ticket;
      this.id_contact = call.id_contact;
    }
  }
  
  get id_ticket(): string {
    return this._id_ticket;
  }
  
  set id_ticket(value: string) {
    this._id_ticket = value;
    if(this.id_ticket){
      this.$ticket = this.$getTicketById(this.id_ticket);
    }
  }
  
  get id_contact(): string {
    return this._id_contact;
  }
  
  set id_contact(value: string) {
    this._id_contact = value;
    if(this.id_contact){
      this.$contact = this.$getContactById(this.id_contact);
      if(this.$contact){
        this.$company = this.$contact.$company;
        this.$sub_chat = this.$contact.getSubChat(this.type, this.platform_type);
      }
    }
  }
  
  get id(): string {
    return this.raw_db?._id;
  }
  
  get status(): string {
    // Questo wrap mi serve per gestire meno casi di stati delle chiamate in quanto la call dell'sdk ha valori diversi rispetto
    // a quella che abbiamo su mongodb che prende i valori dal dato del webhook.
    if(this.raw_twilio){
      switch (this.raw_twilio.status()){
        case 'closed':
          break;
        case 'pending':
        case 'ringing':
          return 'ringing';
        case 'connecting':
        case 'reconnecting':
        case 'open':
          return 'in-progress';
      }
    }
    if(this.raw_db){
      switch (this.raw_db.status) {
        case 'in-progress':
        case 'ringing':
        case 'completed':
          return this.raw_db.status;
        case 'busy':
        case 'failed':
        case 'no-answer':
          return 'no-answer';
      }
    }
    return 'unknown';
  }
  
  
  get sid(): string {
    
    if(this.direction === 'incoming'){
      // Il sid della Call di Twilio è il sid della chiamata figlia essendo ricevuta, quindi, come sid della call su db dobbiamo usare il dial_sid se è già presente;
      return this.raw_twilio?.parameters?.CallSid || this.raw_db?.dial_sid || this.raw_db?.sid;
    }else{
      // Il sid della Call di Twilio è il sid della chiamata padre essendo effettuata da noi, quindi, come sid della call su db dobbiamo usiamo il sid;
      return this.raw_twilio?.parameters?.CallSid || this.raw_db?.sid || this.raw_db?.dial_sid;
    }
  }
  
  get from(): string {
    return this.raw_db?.from || this.raw_twilio?.parameters?.From;
  }
  
  get to(): string {
    return this.raw_db?.to || this.raw_twilio?.parameters?.To;
  }
  
  get audio(): string {
    return this.raw_db?.audio;
  }
  
  get origin_admin_id(): string {
    return this._origin_admin_id;
  }
  
  get creation(): number {
    return this.raw_db?.creation;
  }
  
  get direction(): CallDirection {
    // Questo wrap mi serve per gestire meno casi di direction delle chiamate in quanto la call dell'sdk ha valori diversi rispetto
    // a quella che abbiamo su mongodb che prende i valori dal dato del webhook.
    if(this.raw_twilio){
      switch (this.raw_twilio.direction){
        case 'INCOMING':
          return CallDirection.Ricevuta;
        case 'OUTGOING':
          return CallDirection.Effettuata;
      }
    }
    if(this.raw_db){
      switch (this.raw_db.direction){
        case 'inbound':
          return CallDirection.Ricevuta;
        case 'outgoing':
        case 'outbound-api':
        case 'outbound-dial':
          return CallDirection.Effettuata;
      }
    }
  }
  
  get platform_type(): number {
    return this._platform_type;
  }
  
  set platform_type(value: number) {
    this._platform_type = value;
    this.$platform = this.$getPlatformByType(this.platform_type);
  }
  
  get type(): number {
    return this._type;
  }
  
  set type(value: number) {
    this._type = value;
    if(this.$platform?.call_number_platform){
      for (let call of this.$platform?.call_number_platform){
        if(call.type === value){
          if(this.direction === 'incoming'){
            this.$to_label = call.label;
            this.$from_label = this.from;
          }else{
            this.$to_label = this.to;
            this.$from_label = call.label;
          }
        }
      }
    }
  }
  
  set origin_admin_id(value: string) {
    this._origin_admin_id = value;
    if(this.origin_admin_id){
      this.$origin_admin = this.$getAdmin(this.origin_admin_id);
    }
  }
  
  get final_admin_id(): string {
    return this._final_admin_id;
  }
  
  set final_admin_id(value: string) {
    this._final_admin_id = value;
    if(this.final_admin_id){
      this.$final_admin = this.$getAdmin(this.final_admin_id);
    }
  }
  
  get duration(): number {
    return this.raw_db?.duration * 1000;
  }
  
  get botted(): boolean {
    return this.raw_db?.botted;
  }
  
  set to_read(value: boolean) {
    if(this.raw_db){
      this.raw_db.to_read = value;
    }
  }
  
  get to_read(): boolean {
    return this.raw_db?.to_read;
  }
  
  get transcription(): string{
    return this.raw_db?.transcription
  }
  
  getClientCallSid(): string{
    return this.raw_twilio?.parameters?.CallSid;
  }
  
  getDbDialSid(): string{
    return this.raw_db?.dial_sid;
  }
  
  getDbSid(): string{
    return this.raw_db?.sid;
  }
  
  haveSid(sid: string): boolean{
    if(!sid) return false;
    return this.raw_twilio?.parameters?.CallSid === sid || this.raw_db?.sid === sid || this.raw_db?.dial_sid === sid;
  }

}
