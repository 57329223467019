import {Component, Input, OnInit} from '@angular/core';
import {TwilioCall} from '../../classes/twilioCall';
import {TwilioService} from '../../services/twilio.service';
import {ModalController} from '@ionic/angular';
import {CallInfoComponent} from '../call-info/call-info.component';
import {ViewService} from 'common-lib';
import {SelectAdminComponent} from '../select-admin/select-admin.component';
import {DataService} from '../../services/data.service';
import {Admin} from '../../classes/admin';

@Component({
  selector: 'app-call',
  templateUrl: './call.component.html',
  styleUrls: ['./call.component.scss'],
})
export class CallComponent implements OnInit {
  
  @Input() call: TwilioCall;
  @Input() twilio: TwilioService;
  @Input() type: undefined | 'call_chat' | 'call_list';
  
  @Input() target_customer: string;
  
  constructor(
    private modalCtrl: ModalController,
    private view: ViewService,
    public data: DataService
  ) { }

  ngOnInit() {}
  
  async openDetail(){
    const modal = await this.modalCtrl.create({
      component: CallInfoComponent,
      componentProps: {
        call: this.call,
        twilio: this.twilio
      }
    });
    return await modal.present();
  }
  
  async redirectCall(event: any){
    const admins: Admin[] = [];
    await this.data.updateAdmins()
    DataService.array.admin.map((elem: Admin) => {
      if(!elem.call_center && this.data.current_admin._id !== elem._id){
        admins.push(elem);
      }
    });
    let popover: any = await this.view.presentPopover(event, SelectAdminComponent, {admins: admins});
    await popover.present()
    
    let {data} = await popover.onDidDismiss()
    if(data){
      if(data.status === 1 || data.$is_client_offline || data.in_call){ // admin occupato
        return this.view.presentAlert('Errore', 'Admin occupato, non puoi fare il dial', ['Ok']);
      }
      this.twilio.redirectCall(this.call, data._id);
    }
  }

}
