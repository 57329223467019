import {Admin} from "./admin";
import * as moment from 'moment';
import {Ticket} from './ticket';
import {Contact} from './contact';

export class EmailMessage {
  
  _id: string;
  private _direction: string;
  private _email_owner: string;
  private _email_customer: string;
  private _idAdmin: string;
  message_type: string;
  messageID: string;
  subject: string;
  replyTo: string;
  textBody: string;
  htmlBody: string;
  strippedTextReply: string;
  toFull: any[];
  fromFull: any;
  ccFull: any[];
  dateSent_ms: string;
  private _attachments: string[];
  isRead: boolean;
  $mediaInfos: any[] = [];
  private _id_ticket: string;
  private _$name: string;
  private _$customer_phone: string;
  private _chat_owner: string;
  
  private $getAdmin: (string) => Admin = () => {return undefined};
  private $getTicketById: (string) => Ticket = () => {return undefined};
  private $getMediaInfo: (string) => Promise<{url: string, metadata: any}> = async () => {return {url: undefined, metadata: undefined}};

  private _$admin: Admin;
  private _$contact: Contact;
  private _$ticket: Ticket;
  
  body: string;
  private _$date_sent: string;
  private _from: string;
  private _to: string;
  private _numMedia: number;
  private _media_filename: string;
  private _$media_name: string;
  private _$media_is_img: boolean;
  
  constructor(rawObj?: any, options? : {
    getAdmin?: (string) => Admin,
    getMediaInfo?: (string) => Promise<{url: string, metadata: any}>,
    getTicketById?: (string) => Ticket,
  }) {
    this.setAllFields(rawObj, options);
  }
  
  setAllFields(rawObj: any, options? : {
    getAdmin?: (string) => Admin,
    getMediaInfo?: (string) => Promise<{url: string, metadata: any}>,
    getTicketById?: (string) => Ticket,
  }) {
    if(options){
      if(options.getAdmin) this.$getAdmin = options.getAdmin;
      if(options.getMediaInfo) this.$getMediaInfo = options.getMediaInfo;
      if(options.getTicketById) this.$getTicketById = options.getTicketById;
    }
    if (rawObj) {
      this._id = rawObj._id;
      this.direction = rawObj.direction;
      this.email_owner = rawObj.email_owner;
      this.email_customer = rawObj.email_customer;
      this.idAdmin = rawObj.idAdmin;
      this.messageID = rawObj.messageID;
      this.subject = rawObj.subject;
      this.replyTo = rawObj.replyTo;
      this.textBody = rawObj.textBody;
      this.htmlBody = rawObj.htmlBody;
      this.strippedTextReply = rawObj.strippedTextReply;
      this.toFull = rawObj.toFull;
      this.fromFull = rawObj.fromFull;
      this.ccFull = rawObj.ccFull;
      this.$date_sent = rawObj.dateSent_ms;
      this.attachments = rawObj.attachments;
      this.isRead = rawObj.isRead;
      this.body = rawObj.textBody;
      this.message_type = 'email'
      this.id_ticket = rawObj.id_ticket
    }
  }
  
  get direction(): string {
    if(this._direction.includes('outbound')) return "outbound";
    if(this._direction.includes('inbound')) return "inbound";
    return this._direction;
  }
  
  set direction(value: string) {
    this._direction = value;
  }
  
  get email_owner(): string {
    return this._email_owner;
  }
  
  set email_owner(value: string) {
    this._email_owner = value;
    this.to = value;
  }
  
  get email_customer(): string {
    return this._email_customer;
  }
  
  set email_customer(value: string) {
    this._email_customer = value;
    this.from = value;
  }
  
  get idAdmin(): string {
    return this._idAdmin;
  }
  
  set idAdmin(value: string) {
    this._idAdmin = value;
    if(this.$getAdmin){
      this.$admin = this.$getAdmin(this.idAdmin);
    }
  }
  
  set $admin(value: Admin){
    this._$admin = value;
  }
  
  get $admin(): Admin{
    return this._$admin;
  }
  
  get attachments(): string[] {
    return this._attachments;
  }
  
  set attachments(value: string[]) {
    this._attachments = value;
    if(this.attachments){
      for(let attach of this.attachments){
        this.$getMediaInfo(attach).then((data: {url: string, metadata: any}) => {
          if(data) this.$mediaInfos.push(data);
        }).catch((err) => {
          console.error(err);
        });
      }
    }
  }
  
  
  get from(): string{
    return this._from;
  }
  
  set from(value: string){
    this._from = value;
  }
  
  get to(): string{
    return this._to;
  }
  
  set to(value: string){
    this._to = value;
  }
  
  get $date_sent(): string{
    return this._$date_sent;
  }
  
  set $date_sent(value: string){
    this._$date_sent = moment(value).format('DD/MM/YYYY HH:mm');
    this.dateSent_ms = value;
  }
  
  get numMedia(): number {
    return this._attachments ? this._attachments.length : 0;
  }
  
  
  set media_filename(value: string) {
    this._media_filename = value;
    
  }
  
  get id_ticket(): string {
    return this._id_ticket;
  }
  
  set id_ticket(value: string) {
    this._id_ticket = value;
    if(this.id_ticket){
      this.$ticket = this.$getTicketById(this.id_ticket);
      if(this.$ticket){
        
        const contact_list = this.$ticket.$contacts_email;
        for(let contact of contact_list){
          
          if(contact.$list_emails && contact.$list_emails.length > 0){
            if(contact.$list_emails.includes(this.email_customer)){
              this.$contact = contact
              this.$name = this.$contact?.name
            }
          }
          
        }
      }
    }
  }
  
  get $ticket(): Ticket {
    return this._$ticket;
  }
  
  set $ticket(value: Ticket) {
    this._$ticket = value;
  }
  
  get $contact(): Contact {
    return this._$contact;
  }
  
  set $contact(value: Contact) {
    this._$contact = value;
  }
  
  get $name(): string {
    return this._$name;
  }
  
  set $name(value: string) {
    this._$name = value;
  }
  
  get $customer_phone(): string {
    return this._$customer_phone;
  }
  
  set $customer_phone(value: string) {
    this._$customer_phone = value;
  }
  
  get chat_owner(): string {
    return this._chat_owner;
  }
  
  set chat_owner(value: string) {
    this._chat_owner = value;
  }
  
  
}
