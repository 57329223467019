export class Utility{
  
  static generateRandomInt(min, max){
    return Math.floor((Math.random() * (max-min)) +min);
  }
  
  static async wait(ms){
    return new Promise((resolve) => {
      setTimeout(() => {
        return resolve();
      }, ms)
    });
  }
  
  static validateEmail(email: string): boolean{
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  }
 
  // Valida solo telefoni scritti in formato +39...
  static validatePhone(phone: string): boolean{
    let re = new RegExp('^\\d+$');
    if(!phone || phone.length < 6 || phone[0] !== '+') return false;
    const aus: string = phone.substr(1, phone.length);
    return re.test(aus);
  }
  
  // Pulisce un numero di telefono e gli aggiunge il +39 se necessario
  static sanitizePhone(phone: string): string{
    phone = phone.replace(/[^\d+]+/, '');
    if(phone.length < 0) return null;
    if(phone[0] === '+') return phone;
    return null;
  }
}
