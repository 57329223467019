import {Component, OnInit} from '@angular/core';
import {TwilioService} from '../../services/twilio.service';
import {DataService} from '../../services/data.service';
import {Admin} from '../../classes/admin';
import {AlertController, ModalController, PopoverController} from '@ionic/angular';
import {SelectAdminComponent} from '../select-admin/select-admin.component';
import {ViewService} from 'common-lib';
import {PopoverSelectComponent} from '../popover-select/popover-select.component';
import {CallRedirectComponent} from '../call-redirect/call-redirect.component';
import {CallChat, CallSubChat} from '../../classes/callChat';
import {ApiService} from '../../services/api.service';
import {NavigationExtras, Router} from '@angular/router';

@Component({
  selector: 'app-twilio-call-status',
  templateUrl: './twilio-call-status.component.html',
  styleUrls: ['./twilio-call-status.component.scss'],
})
export class TwilioCallStatusComponent implements OnInit {

  admins: Admin[] = [];
  
  constructor(
    public twilio: TwilioService,
    public data: DataService,
    public modalController: ModalController,
    private view: ViewService,
    private alertCtrl: AlertController,
    private popoverController: PopoverController,
    private api: ApiService,
    private router: Router
  ) {}
  
  async ngOnInit() {
    await DataService.isReady();
    
    if(!this.twilio.client){
      this.twilio.initClient().then(() => {});
    }
    this.admins = DataService.array.admin;
  }
  
  async redirectCall(event: any){
    const admins: Admin[] = [];
    await this.data.updateAdmins()
    DataService.array.admin.map((elem: Admin) => {
      if(!elem.call_center && this.data.current_admin._id !== elem._id){
        admins.push(elem);
      }
    });
    let popover: any = await this.view.presentPopover(event, SelectAdminComponent, {admins: admins});
    await popover.present()
    
    let {data} = await popover.onDidDismiss()
    if(data){
      console.log('Admin selezionato: ', data);
      if(data.status === 1 || data.$is_client_offline){ // admin occupato
        return this.view.presentAlert('Errore', 'Admin occupato, non puoi fare il dial', ['Ok']);
      }
      this.twilio.redirectCall(this.twilio.current_call, data._id);
    }
  }
  
  showCalls(){
    if(this.twilio.client){
      this.data.show_calls = !this.data.show_calls
    }
  }
  
  async openListenerAdminOptions(event: any, type: number, admin: Admin){
    const componentProps: any = {
      options: [
      ],
      type: 'no_id'
    };
  
    switch (type) {
      case 1:
        componentProps.options.push({
            id: 'mark_all_read',
            name: 'Segna tutte come lette',
            admin: admin
          }
        );
        break;
      case 2:
        componentProps.options.push({
            id: 'mark_all_read',
            name: 'Segna tutte come lette', admin: admin
          },
          {
            id: 'open_calls_in_list',
            name: 'Apri lista chiamate non lette', admin: admin
          }
        );
        break;
      default:
        componentProps.options.push({
            id: 'mark_all_read',
            name: 'Segna tutte come lette', admin: admin
          }
        );
        break;
    }
    
    const popover = await this.popoverController.create({
      component: PopoverSelectComponent,
      event: event,
      translucent: true,
      componentProps: componentProps
    });
    popover.onDidDismiss().then((data: any) => {
      if(data?.data?.id){
        switch (data.data.id) {
          case 'mark_all_read':
            this.markAllAsRead(admin)
            break;
          case 'open_calls_in_list':
            this.openCallNotRead(admin, 'da_leggere');
            break;
        }
      }
    });
    await popover.present();
  }
  
  async markAllAsRead(admin: Admin){
    await this.view.presentAlert('Sei sicuro?', `Segnerai come lette tutte le chiamate senza risposta dell'admin <b>${admin.nickname}</b>`, [{
      text: 'Annulla',
      role: 'cancel',
      cssClass: 'secondary'
    }, {
      text: 'Conferma',
      handler: async () => {
        try{
          await this.view.showLoading();
          
          await this.api.patchCallCountUnread(admin._id);
          
        }catch(err){
          console.error(err);
          this.view.presentAlert('Errore', 'Im possibile impostare tutte le chiamate come lette', ['Ok']);
        }
        await this.view.hideLoading();
      }
    }]);
  }
  
  /**
   * Apro pagina lista chiamate passando come parametri l'id admin e lo stato delle chiamate, se lette o non lette
   * @param admin
   * @param filter
   */
  async openCallNotRead(admin: Admin, filter: 'da_leggere' | 'lette'){
    let navigationExtras: NavigationExtras;
    navigationExtras = {
      queryParams: {
        idAdmin: admin._id,
        filter: filter
      }
    };
    this.router.navigate(['chat/calls'], navigationExtras);
  }
  
  async openRedirectManager(){
    const modal = await this.modalController.create({
      component: CallRedirectComponent,
      cssClass: 'modal_big'
    });
    modal.onDidDismiss().then((data: any) => {});
    return await modal.present();
  }
}
