import {Admin} from "./admin";
import * as moment from 'moment';
import {Ticket} from './ticket';
import {Contact} from './contact';

export class WhatsappMessage {
  
  _id: string;
  dateSent_ms: string;
  message_type: string;
  private _direction: string;
  private _chat_owner: string;
  private _chat_customer: string;
  private _$customer_phone: string;
  private _$owner_phone: string;
  private _idAdmin: string;
  private _language: string;
  private _body: string;
  private _translations: {code: string, body: string}[];
  private _data_sent: number;
  private _status: string;
  private _numMedia: number;
  private _media_filename: string;
  private _$media_name: string;
  private _$media_is_img: boolean;
  private _$date_sent: string;
  private _id_ticket: string;
  
  private $getAdmin: (string) => Admin = () => {return undefined};
  private $getTicketById: (string) => Ticket = () => {return undefined};
  private $getMediaInfo: (string) => Promise<{url: string, metadata: any}> = async () => {return {url: undefined, metadata: undefined}};
  
  private _$admin: Admin;
  private _$media_url: string;
  private _$media_metadata: any;
  private _$ticket: Ticket;
  private _$contact: Contact;
  private _from: string;
  private _to: string;
  private _$name: string;
  
  is_sms: boolean;
  
  constructor(rawObj?: any, options? : {
    getAdmin?: (string) => Admin,
    getMediaInfo?: (string) => Promise<{url: string, metadata: any}>,
    getTicketById?: (string) => Ticket,
  }) {
    this.setAllFields(rawObj, options);
  }
  
  setAllFields(rawObj: any, options? : {
    getAdmin?: (string) => Admin,
    getMediaInfo?: (string) => Promise<{url: string, metadata: any}>
    getTicketById?: (string) => Ticket,
  }) {
    if(options){
      if(options.getAdmin) this.$getAdmin = options.getAdmin;
      if(options.getMediaInfo) this.$getMediaInfo = options.getMediaInfo;
      if(options.getTicketById) this.$getTicketById = options.getTicketById;
    }
    if (rawObj) {
      this._id = rawObj._id;
      this.direction = rawObj.direction;
      this.chat_owner = rawObj.chat_owner;
      this.chat_customer = rawObj.chat_customer;
      this.idAdmin = rawObj.idAdmin;
      this.language = rawObj.code_detection;
      this.body = rawObj.body;
      this.translations = rawObj.translation;
      this.status = rawObj.status;
      this.numMedia = rawObj.numMedia;
      this.media_filename = rawObj.media_filename;
      this.is_sms = rawObj.is_sms;
      this.$date_sent = rawObj.dateSent_ms;
      this.from = rawObj.from;
      this.to = rawObj.to;
      this.message_type = 'whatsapp'
      this.id_ticket = rawObj.id_ticket
    }
  }
  
  get direction(): string {
    if(this._direction.includes('outbound')) return "outbound";
    if(this._direction.includes('inbound')) return "inbound";
    return this._direction;
  }
  
  set direction(value: string) {
    this._direction = value;
  }
  
  get chat_owner(): string {
    return this._chat_owner;
  }
  
  set chat_owner(value: string) {
    this._chat_owner = value;
    try{
      this.$owner_phone = this.chat_owner.replace('whatsapp:', '');
    }catch(err){
      console.error(err);
    }
  }
  
  get $owner_phone(): string {
    return this._$owner_phone;
  }
  
  set $owner_phone(value: string) {
    this._$owner_phone = value.replace('whatsapp:', '');
  }
  
  get chat_customer(): string {
    return this._chat_customer;
  }
  
  set chat_customer(value: string) {
    this._chat_customer = value;
    try{
      this.$customer_phone = this.chat_customer.replace('whatsapp:', '');
    }catch(err){
      console.error(err);
    }
  }
  
  get $customer_phone(): string {
    return this._$customer_phone;
  }
  
  set $customer_phone(value: string) {
    this._$customer_phone = value.replace('whatsapp:', '');
  }
  
  get idAdmin(): string {
    return this._idAdmin;
  }
  
  set idAdmin(value: string) {
    this._idAdmin = value;
    if(this.$getAdmin){
      this.$admin = this.$getAdmin(this.idAdmin);
    }
  }
  
  set $admin(value: Admin){
    this._$admin = value;
  }
  
  get $admin(): Admin{
    return this._$admin;
  }
  
  get language(): string {
    return this._language;
  }
  
  set language(value: string) {
    this._language = value;
  }
  
  get body(): string {
    return this._body;
  }
  
  set body(value: string) {
    this._body = value?.replace(/\n/g, '<br>');
  }
  
  get translations(): { code: string; body: string }[] {
    return this._translations;
  }
  
  set translations(value: { code: string; body: string }[]) {
    this._translations = value;
  }
  
  get status(): string {
    return this._status;
  }
  
  set status(value: string) {
    this._status = value;
  }
  
  get numMedia(): number {
    return this._numMedia;
  }
  
  set numMedia(value: number) {
    this._numMedia = value;
  }
  
  get media_filename(): string {
    return this._media_filename;
  }
  
  set media_filename(value: string) {
    this._media_filename = value;
    if(this.media_filename){
      try{
        let aus: string[] = this.media_filename.split('/');
        if(aus.length > 0){
          this.$media_name = aus[aus.length - 1];
          if(this.$media_name){
            aus = this.$media_name.split('.');
            if(aus.length > 0){
              const extension: string = aus[aus.length - 1];
              this.$media_is_img = extension === 'jpg' || extension === 'png' || extension === 'jpeg' || extension === 'gif';
            }
          }
        }
      }catch(err){
        console.error(err);
      }
      this.$getMediaInfo(this.media_filename).then((data: {url: string, metadata: any}) => {
        this.$media_url = data.url;
        this.$media_metadata = data.metadata;
      }).catch((err) => {
        console.error(err);
      });
    }
  }
  
  get $media_url(): string {
    return this._$media_url;
  }
  
  set $media_url(value: string) {
    this._$media_url = value;
  }
  
  get $media_name(): string {
    return this._$media_name;
  }
  
  set $media_name(value: string) {
    this._$media_name = value;
  }
  
  get $media_is_img(): boolean {
    return this._$media_is_img;
  }
  
  set $media_is_img(value: boolean) {
    this._$media_is_img = value;
  }
  
  get $media_metadata(): any {
    return this._$media_metadata;
  }
  
  set $media_metadata(value: any) {
    this._$media_metadata = value;
  }
  
  get $date_sent(): string{
    return this._$date_sent;
  }
  
  set $date_sent(value: string){
    this._$date_sent = moment(value).format('DD/MM/YYYY HH:mm');
    this.dateSent_ms = value;
  }
  
  get from(): string{
    return this._from;
  }
  
  set from(value: string){
    if(value){
      const whatsappPreamble = 'whatsapp:';
      if (value.startsWith(whatsappPreamble)) {
        this._from = value.slice(whatsappPreamble.length);
      }else{
        this._from = value;
      }
    }
  }
  
  get to(): string{
    return this._to;
  }
  
  set to(value: string){
    this._to = value;
  }
  
  get id_ticket(): string {
    return this._id_ticket;
  }
  
  set id_ticket(value: string) {
    this._id_ticket = value;
    if(this.id_ticket){
      this.$ticket = this.$getTicketById(this.id_ticket);
      if(this.$ticket){
        
        const contact_list = this.$ticket.$contacts;
        for(let contact of contact_list){

          if(contact.$list_phones && contact.$list_phones.length > 0){
            if(contact.$list_phones.includes(this.$customer_phone)){
              this.$contact = contact
              this.$name = this.$contact?.name;
            }
          }
        
        }
      }
    }
  }
  
  get $ticket(): Ticket {
    return this._$ticket;
  }
  
  set $ticket(value: Ticket) {
    this._$ticket = value;
  }
  
  get $contact(): Contact {
    return this._$contact;
  }
  
  set $contact(value: Contact) {
    this._$contact = value;
  }
  
  get $name(): string {
    return this._$name;
  }
  
  set $name(value: string) {
    this._$name = value;
  }
  
  
}
