import {Component, Input, OnInit} from '@angular/core';
import {Ticket} from '../../classes/ticket';
import {ParticipantAdmin} from '../../classes/participantAdmin';
import {TrackerPage} from '../../app/tracker/tracker.page';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-ticket-details',
  templateUrl: './ticket-details.component.html',
  styleUrls: ['./ticket-details.component.scss'],
})
export class TicketDetailsComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() ticket: Ticket;
  
  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {}
  
  async openTrackerDetail(participant: ParticipantAdmin){
    const modal = await this.modalController.create({
      component: TrackerPage,
      componentProps: {
        id: participant._id,
        tracker: participant
      },
      cssClass: "modal_very_big",
    });
    return await modal.present();
  
  }

}
