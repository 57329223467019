import {Component, Input, OnInit} from '@angular/core';
import {Order} from '../../classes/order';

@Component({
  selector: 'app-imei-order',
  templateUrl: './imei-order.component.html',
  styleUrls: ['./imei-order.component.scss'],
})
export class ImeiOrderComponent implements OnInit {

  @Input() order: Order;
  
  constructor() { }

  ngOnInit() {
    console.log(this.order)
  }

}
