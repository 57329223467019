import {PositionGps} from "common-lib";

export class PositionGpsAdmin extends PositionGps{

  raw: any;

  angle: number;
  command12: string;

  constructor(data?: any) {
    super(data);
    if(data){
      this.setData(data);
    }
  }

  setData(data: any){
    this.raw = data;
    this.angle = data.angle;
    this.command_response = data.command_response;
  }
}
