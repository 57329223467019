import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AlertController, ModalController, NavController, PopoverController, ToastController } from '@ionic/angular';
import { 
  AuthService, 
  ViewService, ViewService as View,
} from 'common-lib';
import {ApiService} from "../../services/api.service";
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from 'src/services/data.service';
import { Platform } from 'src/classes/platform';

import * as lodash from 'lodash';
import { WhatsappChat } from 'src/classes/whatsappChat';
import Company from "../../classes/company";
import {Ticket} from '../../classes/ticket';

@Component({
  selector: 'app-whatsapp-msg-to-expired-chat',
  templateUrl: './whatsapp-msg-to-expired-chat.page.html',
  styleUrls: ['./whatsapp-msg-to-expired-chat.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WhatsappMsgToExpiredChatPage implements OnInit {
  // !page initially copied from ticket-add.page
  /* 
  * regex res:
  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions/Cheatsheet
  https://medium.com/factory-mind/regex-tutorial-a-simple-cheatsheet-by-examples-649dc1c3f285
  */
  
  // * api
  attachments: any[] = [];
  // the body of the api (just the body. not the whole obj)
  /* @Input()  */row = {
    from: undefined,
    to: undefined, 
    body: undefined,
    to_whatsapp: undefined,
    // uploads: [],
    // not the body of the api, but still part of its param(s)
    selectedPlatform_type: undefined,
    messages_template_label: undefined,
    templateLang_key: undefined,
  };
  selectedPlatform: Platform
  selectedWhatsapp_number_platform: any
  selectedMessages_template: any
  selectedMessages_template_name: string
  // selectedTemplateLang: any
  
  // * componentProps
  @Input() search: {
    [key: string]: {
      fields: string[]
      query: string
    }
  } = {};
  @Input() selectedChat: WhatsappChat;
  @Input() selectedCompany: Company;
  
  @Input() noSend: boolean;
  @Input() to_phone: string;
  
  @Input() id_ticket: string;
  @Input() ticket: Ticket;
  
  // * DOM
  DataService = DataService;
  
  // * fragment
  msgFragments: {
    placeholder: string
    text: string
  }[]/*  = [] */
  fragmentRegex: RegExp = /{{\d}}+/
  
  
  cols: any[] = [
    {
      prop: "selectedPlatform_type",
      label: "Piattaforma",
      disabled: true,
      cellContentTd: "select--v2",
      outputOptions: /* <any> */DataService.platforms,
      outputOpt_labelProp: "name",
      outputOpt_valueProp: "type",
      pipe_sortAlphabetically_crescent: true,
      // pipe_sortAlphabetically_disabled: true,
      selectChanged: this.selectChanged_selectedPlatform_type.bind(this),
      show: true,
    },
    {
      prop: "from",
      label: "Numero Whatsapp",
      cellContentTd: "select--v2--whatsapp_number_platform",
      outputOptions: [], // gets populated when there's a selectedPlatform
      outputOpt_labelProp: "label",
      outputOpt_valueProp: "number",
      pipe_sortAlphabetically_crescent: true,
      // pipe_sortAlphabetically_disabled: true,
      selectChanged: this.selectChanged_from.bind(this),
      show: true,
    },
    {
      prop: "messages_template_label",
      label: "Template messaggio",
      cellContentTd: "select--v2--messages_template",
      outputOptions: [], // gets populated when there's a selectedWhatsapp_number_platform
      outputOpt_labelProp: "label",
      outputOpt_valueProp: "label",
      // pipe_sortAlphabetically_crescent: true,
      pipe_sortAlphabetically_disabled: true,
      selectChanged: this.selectTemplateMessage.bind(this),
    },
    {
      prop: "templateLang_key",
      label: "Lingua template",
      cellContentTd: "select--v2--template-lang",
      outputOptions: {}, // gets populated when there's a selectedMessages_template
      outputOpt_labelProp: "key",
      outputOpt_valueProp: "key",
      pipe_sortAlphabetically_crescent: true,
      // pipe_sortAlphabetically_disabled: true,
      selectChanged: this.selectLangTemplate.bind(this),
    },
    {
      prop: "body",
      label: "Messaggio che verrà inviato",
      cellContentTd: "textarea--composed-msg",
      textarea_onChange: ({ event, }) => {
        // console.log(`textarea_onChange`, event)
        View.updateView.next()
      },
      textarea_placeholder: "Nessun template selezionato",
      textarea_readonly: true,
      // textarea_rows: 5,
    },
    {
      label: "Frammenti messaggio",
      cellContentTd: "msg-fragment",
      fragmentChanged: () => {
        if (!this.row.body) {
          console.log(`return: !row.body`, )
          return;
        }
        
        // let workingString = this.row.messages_template_label
        let workingString = this.selectedMessages_template.lan[this.row.templateLang_key]
        
        this.msgFragments.forEach(el => {
          workingString = workingString.replace(
            // this.fragmentRegex,
            el.placeholder,
            (el.text || el.placeholder)
          )
        })
        this.row.body = workingString
        
        // console.log(`msgFragments`, this.msgFragments)
        View.updateView.next()
      }
    }
  ];

  listener = {
    data: null,
    view: null,
  };
  
  disableSelect_from: boolean = false;
  
  constructor(
    private cd: ChangeDetectorRef,
    private ref: ChangeDetectorRef,
    private alertCtrl: AlertController,
    private api: ApiService,
    private data: DataService,
    private modalCtrl: ModalController,
    private auth: AuthService,
    private nav: NavController,
    public popoverController: PopoverController,
    private route: ActivatedRoute,
    private router: Router,
    private toastCtrl: ToastController,
    public view: ViewService,
  ) {}

  ngOnInit() {
    /* Piattaforma */
    if(this.ticket){
      this.selectChanged_selectedPlatform_type({val: this.ticket.platform_type})
    }else{
      this.selectChanged_selectedPlatform_type({val: 1})
    }
    
    /* Numero whastsapp from */
    for(let whatsapp_phone of this.selectedPlatform.whatsapp_number_platform){
      if(whatsapp_phone.is_default){
        this.selectChanged_from({val: whatsapp_phone.number})
      }
    }
  
    if(this.ticket.$last_message_whatsapp){
      //il numero che devo selezionare è il chat owner
      this.selectChanged_from({val: this.ticket.$last_message_whatsapp.chat_owner})
      this.disableSelect_from = true
    }
  
    /** Scelta template **/
    this.selectedWhatsapp_number_platform.messages_template;
    if(this.selectedPlatform.type === 1){
      this.selectTemplateMessage({val: this.selectedWhatsapp_number_platform.messages_template[1].label})
    }else{
      this.selectTemplateMessage({val: this.selectedWhatsapp_number_platform.messages_template[0].label})
    }
    
    
    /** scelta lingua **/
    this.selectLangTemplate({val: 'it'})
    
    
  }
  
  ionViewDidEnter() {
    this.listenerView();
    if (this.selectedChat && this.selectedChat.chat_owner) {
      this.cols[0].show = false;
      
      this.selectChanged_from({ 
        val: this.selectedChat.chat_owner
      });
      this.cols[1].show = false
    }else if (this.to_phone) {
      this.row.to_whatsapp = this.to_phone;
      this.cols.push({
        prop: "to_whatsapp",
        inputType: 'text',
        label: "Numero whatsapp destinatario (con +39)"
      });
    }else{
      if(!this.selectedChat?.chat_customer && !this.noSend){
        this.cols.push({
          prop: "to_whatsapp",
          inputType: 'text',
          label: "Numero whatsapp destinatario (con +39)"
        });
      }
    }
    
    // this.initPipes(this.model, this.navParam);
    View.updateView.next()
  }

  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }
  
  // * api
  
  async save() {
    await this.view.hideLoading()
    await this.view.showLoading("Invio messaggio in corso");
    View.updateView.next()
    
    if(this.selectedChat){
      this.row.to = this.selectedChat.chat_customer;
    }else{
      this.row.to = 'whatsapp:' + this.row.to_whatsapp;
    }
    
    // * validation
    if (
      !this.row.from || 
      !this.row.to || 
      !this.row.body
    ) {
      this.alert({ 
        msg: "non tutti i campi obbligatori son stati popolati"
      });
      await this.view.hideLoading();
      View.updateView.next();
      return;
    }
    
    try {
      // for attachments see ticket-add.page
      
      // Se non ha ancora un admin proprietario, imposto quello corrente
      // if(this.selectedCompany && !this.selectedChat?.idAdmin){
      //   const res: any = await this.api.patchWhatsappChat(this.selectedCompany._id, {
      //     chat_customer: this.selectedChat.chat_customer,
      //     chat_owner: this.selectedChat.chat_owner || this.row.from,
      //     idAdmin: this.data.getAdminByEmail(AuthService.getEmail())._id
      //   });
      //   this.selectedCompany.setAllFields(res);
      //   this.selectedCompany.setChatInformations(this.data.selectedAdmin, this.data.getAdminByEmail(AuthService.getEmail()));
      // }
      
      const apiBody: any = lodash.cloneDeep(this.row)
      // * set "special" props
      // apiBody.cc_emails = this.get_cc_emails(this.row.cc_emails)
      // apiBody.uploads = uploads
      // * delete unwanted props for the API
      delete apiBody.selectedPlatform_type;
      delete apiBody.messages_template_label;
      delete apiBody.templateLang_key;
      console.log(`apiBody`, apiBody);
  
      if(this.noSend) { // ritorno le selezioni, l'invio lo fa la pagina chiamante
        await this.view.hideLoading();
        return this.dismiss({body: apiBody.body, from: apiBody.from});
      }
  
      console.log('test', this.msgFragments)
      let whatsapp_element;
      if (DataService.general_parameter?.array_platform) {
        for(let platform of DataService.general_parameter.array_platform) {
          if (platform.whatsapp_number_platform) {
            for (let phone of platform.whatsapp_number_platform) {
              if (phone.number === apiBody.from) {
                whatsapp_element = phone
              }
            }
          }
        }
      }
      let res;
      if(whatsapp_element && whatsapp_element.send_type === 2){
        res = await this.api.postWhatsappMessageMeta(apiBody.from, apiBody.to, apiBody.body, this.id_ticket, null, null, null, true,  this.selectedMessages_template_name, this.row.templateLang_key, this.msgFragments)
      }else{
        res = await this.api.postWhatsappMessage(
          apiBody.from,
          apiBody.to,
          apiBody.body,
          this.id_ticket,
          undefined,
          undefined,
          [
            {
              code: this.row.templateLang_key,
              body: apiBody.body,
            },
          ],
          // body: apiBody,
          // platform_type: this.selectedPlatform.type,
        );
      }
      
      console.log("save() res", res)
      
      // this.presentToast("Messaggio inviato")
      this.alert({ msg: "Messaggio inviato" })
      this.dismiss({sent: true});
    } catch (e) {
      console.error(e);
    }
    
    await this.view.hideLoading()
    View.updateView.next()
  }
  
  // * attachment
  
  removeAttachFromList(index: number){
    this.attachments.splice(index, 1);
    View.updateView.next()
    console.log(`removeAttachFromList() attachments`, this.attachments)
  }
  
  async selectFile() {
    if (!this.attachments) {
      this.attachments = []
    }
    let input: any = document.createElement('input');
    input.type = 'file';
    input.multiple = 'true';
    input.addEventListener("change", (event) => {
      this.uploadFile(event);
    });
    input.click();
  }
  
  /**
   * Riconosce la selezione di un file e fa partire l'upload
   * @param event
   */
  uploadFile(event: any) {
    // updated version w/ attachments' size check in ticket-add.page
    const target: any = event.target || event.srcElement;
    for(let file of target.files){
      this.attachments.push({
        name: file.name,
        type: file.type.split("/")[0],
        body: new Blob([file])
      });
      // this.cd.markForCheck()
      View.updateView.next()
    }
    console.log(`uploadFile() attachments`, this.attachments)
  }
  
  // * content
  
  get_cc_emails(messyString: string) {
    if (!messyString) {
      console.log(`return: !messyString`, )
      return undefined;
    }
    // "    fibb98@libero.it;EMAIL@wi-tek.com  bigg_@W.\n\r\n;_aCapo@prov.c ; 5thmail@ "
    let words = messyString.split(";")
    
    let nicerString = ""
    words.forEach(word => {
      nicerString += `${word} `
    })
    
    words = (nicerString.trim()).split(/\s+/)
    console.log("words", words)
    // return (words?.length > 0 ? words : undefined);
    return words;
    /*
    fibb98@libero.it
    EMAIL@wi-tek.com
    bigg_@W.
    _aCapo@prov.c
    5thmail@
    */
  }
  
  // * feedback
  
  async alert({
    header,
    msg,
  }: {
    header?: string,
    msg: string,
  }) {
    const alert = await this.alertCtrl.create({
      buttons: ['Ok'],
      // cssClass: "",
      header: header ?? "Successo",
      message: msg,
      // subHeader: "Subtitle",
    });

    await alert.present();

    const { data, role } = await alert.onDidDismiss();
    // console.log('alert.onDidDismiss data', data)
    // console.log('alert.onDidDismiss role', role)
  }
  
  async presentToast(msg: string) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 2000,
      // position: "middle",
    });
    toast.present();
  }
  
  // * input
  
  selectChanged_selectedPlatform_type({ val, }) {
    console.log('selectChanged_selectedPlatform_type() val', val)
    // * unselect ALL subfield's variables
    // global
    this.selectedWhatsapp_number_platform = undefined;
    // row
    this.row.from = undefined;
    
    if (val) {
      val = Number(val)
      this.row.selectedPlatform_type = val; // for preselection
      
      // * select the global variable which works in sync w/ this field
      this.selectedPlatform = DataService.platforms
        .find(platform => platform.type === val)
      
      // * get subfield's select options
      const index = this.cols.findIndex(col => col.prop === "from");
      this.cols[index].outputOptions = /* <any> */this.selectedPlatform.whatsapp_number_platform;
      
      // * set API subfields
      
    } else {
      // * unselect the global variable which works in sync w/ this field
      this.selectedPlatform = undefined;
    }
    View.updateView.next()
  }
  
  selectChanged_from({ val, }) {
    // * unselect ALL subfield's variables
    // global
    this.selectedMessages_template = undefined;
    // row
    this.row.messages_template_label = undefined;
    
    if (val) {
      this.row.from = val; // for preselection
      
      // * select the global variable which works in sync w/ this field
      if(this.selectedPlatform){
        this.selectedWhatsapp_number_platform = this.selectedPlatform.whatsapp_number_platform.find(el => el.number == val);
      }else{
        for(let platform of DataService.platforms){
          for(let phone of platform.whatsapp_number_platform){
            if(phone.number === val){
              this.selectedPlatform = platform;
              this.selectedWhatsapp_number_platform = phone;
              break;
            }
          }
        }
      }
      
      // * get subfield's select options
      const index = this.cols.findIndex(col => col.prop === "messages_template_label");
      this.cols[index].outputOptions = this.selectedWhatsapp_number_platform.messages_template;
      
      // * set API subfields
      
    } else {
      // * unselect the global variable which works in sync w/ this field
      this.selectedWhatsapp_number_platform = undefined;
    }
    View.updateView.next()
  }
  
  selectTemplateMessage({val, }){
    // * unselect ALL subfield's variables
    // global
    // this.selectedTemplateLang = undefined;
    // row
    this.row.templateLang_key = undefined;
    // this.row.body = undefined
  
    if (val) {
      // * select the global variable which works in sync w/ this field
      this.selectedMessages_template =
        this.selectedWhatsapp_number_platform.messages_template
        .find(el => el.label == val)
    
      // * get subfield's select options
      this.selectedMessages_template_name = this.selectedMessages_template.template_name
      const index = this.cols.findIndex(col => col.prop === "templateLang_key")
      this.cols[index].outputOptions = this.selectedMessages_template.lan;
    
      // * set API subfields
      this.row.messages_template_label = val;
    } else {
      // * unselect the global variable which works in sync w/ this field
      this.selectedMessages_template = undefined;
    }
    View.updateView.next()
  }
  
  selectLangTemplate({val, }){
    // * unselect ALL subfield's variables
    // global
    // row
  
    if (val) {
      // * select the global variable which works in sync w/ this field
      // this.selectedTemplateLang = this.selectedMessages_template.lan; // unused
    
      // * get subfield's select options
    
      // * set API subfield
      this.row.templateLang_key = val;
  
      this.row.body = this.selectedMessages_template.lan[val];
      // * set input subfields
      this.msgFragments = this.row.body
      .match(
        new RegExp(this.fragmentRegex.source, "g")
      )
      .map((foundStr: string) => (
          {
            placeholder: foundStr,
            text: "",
          }
        )
      );
    
      View.updateView.next()
    } else {
      // * unselect the global variable which works in sync w/ this field
      // this.selectedTemplateLang = undefined;
      // * unset subfields
      this.row.body = undefined
    }
    View.updateView.next()
  }
  
  // * listener
  
  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
      setTimeout(() => {
        this.cd.markForCheck();
      }, 250);
    });
  }
  
  // * navigation
  
  dismiss(data?: any) {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss(data);
  }
  
  // * table methods
  
  tableRowTd_selectChanged(row) {
    console.log('row', row);
  }
  
  // * test
  
  consoleLog(i?) {
    // console.log('i', i)
    
    console.log(`row`, this.row)
    console.log(`selectedPlatform`, this.selectedPlatform)
    console.log(`selectedWhatsapp_number_platform`, this.selectedWhatsapp_number_platform)
    // console.log(`selectedMessages_template`, this.selectedMessages_template)
    // console.log(`selectedTemplateLang`, this.selectedTemplateLang)
  }
  
  

}
