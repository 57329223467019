
type Options = {
  // getAdmin?: (id: string) => Admin | undefined
  // getOrganization?: (id: string) => OrganizationAdmin | undefined
}

export default class TwilioSim {
  
  account_sid: string
  sid: string
  iccid: string
  eId: string
  status: string
  friendlyName: string;
  
  constructor(rawObj?: any, options?: Options) {
    this.setAllFields(rawObj, options);
  }
  
  setAllFields(rawObj: any = {}, options: Options = {}) {
    if (rawObj) {
      this.account_sid = rawObj.account_sid;
      this.sid = rawObj.sid;
      this.iccid = rawObj.iccid;
      this.eId = rawObj.eId;
      this.status = rawObj.status;
      this.friendlyName = rawObj.friendlyName;
    }
  }
  
}
