import {Component, Input, OnInit} from '@angular/core';
import {AlertController, ModalController} from '@ionic/angular';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ViewService} from 'common-lib';
import {DataService} from '../../services/data.service';
import {ApiService} from '../../services/api.service';
import {Contact} from '../../classes/contact';
import {OrganizationAdmin} from '../../classes/organizationAdmin';
import {Ticket} from '../../classes/ticket';

@Component({
  selector: 'app-new-ticket',
  templateUrl: './new-ticket.page.html',
  styleUrls: ['./new-ticket.page.scss'],
})
export class NewTicketPage implements OnInit {
  
  @Input() contact: Contact
  @Input() organization: OrganizationAdmin
  
  DataService = DataService;
  ticketForm: FormGroup;
  
  selectedOrganization: OrganizationAdmin; //organizzazione selezionata dal admin
  availableOrganizations: OrganizationAdmin[] = []; //lista di organizzazioni selezionabili dall'admin
  availableContact: Contact; //lista di organizzazioni selezionabili dall'admin
  
  trackerSelected: any[] = [];
  
  contactTickt: Ticket;
  
  currentPage: number = 1;
  
  routeSelected: number;
  countTicket: number = 0;
  
  static Object; any;
  constructor(
    private modalController: ModalController,
    private view: ViewService,
    public data: DataService,
    private api: ApiService,
    private alertCtrl: AlertController
  ) { }

  ngOnInit() {
  
    //ottengo i ticket aperti sul contatto this.contact
    
    if(this.contact){
      for(let ticket of this.contact.$company.$tickets){
        if( ticket.contacts?.hasOwnProperty(this.contact._id) || ticket.contacts_email?.hasOwnProperty(this.contact._id)){
          if(ticket.status !== 3){
            this.countTicket++
          }
        }
      }
    }
    this.ticketForm = new FormGroup({
      platform: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(1)]
      }),
      type: new FormControl("", {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(1)]
      }),
      subType: new FormControl("", {
        updateOn: 'change',
        validators: [Validators.minLength(1)]
      }),
      title: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(2)]
      }),
      message: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.minLength(2)]
      }),
      trackers: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.minLength(2)]
      }),
      organization: new FormControl("", {
        updateOn: 'change',
        validators: [Validators.minLength(1)]
      }),
      contact: new FormControl("", {
        updateOn: 'change',
        validators: [Validators.minLength(1)]
      }),
      showTicketInWeb: new FormControl(false, {
        updateOn: 'change',
        validators: [Validators.minLength(1)]
      }),
      ticketImportant: new FormControl(false, {
        updateOn: 'change',
        validators: [Validators.minLength(1)]
      }),
      isEmail: new FormControl(false, {
        updateOn: 'change',
        validators: [Validators.minLength(1)]
      }),
      emailContactSelected: new FormControl("", {
        updateOn: 'change',
        validators: [Validators.minLength(1)]
      }),
      
    });
    this.setDefaultFormValue()
    this.manageTicketCreation(this.contact, this.organization)
  }
  
  /**
   * Metodo utilizzato per gestire la creazione e l'aggiornamento del ticket
   * @param contact
   * @param organization
   */
  manageTicketCreation(contact: Contact, organization: OrganizationAdmin){
    /** 1. Ho contatto*/
    if(contact){
      this.setContact(contact)
    }else if(organization){
      // ottengo contatti da organizzation
      this.setOrganization()
      this.availableOrganizations.push(organization)
      this.getContactFromOrganization(organization)
    }
  }
  
  getContactFromOrganization(organization: OrganizationAdmin){
    const company = DataService.array.companies.find((elem)=>{
      if(elem.organizations){
        for(let org in elem.organizations){
          if(org === organization._id) return true;
        }
      }
    })
    if(company){
      this.availableContact = DataService.array.contacts.find(elem=>elem.id_company === company._id)
    }
  }
  
  
  getOrganizationFromContact(contact: Contact){
    /** Ottengo la company associata al contatto */
    const company = DataService.array.companies.find(elem => elem._id === contact.id_company)
    if(company){
      this.availableOrganizations = [];
      /** Ottengo la lista delle organization data la company */
      for(let org of company.$organizations){
        if(org.platform_type == this.ticketForm.get('platform').value){
          this.availableOrganizations.push(org)
        }
      }
    }
  }
  
  /**
   * Imposta i valori di default del form
   */
  setDefaultFormValue(){
    //devo prendere il contatto
    this.ticketForm.get('platform').setValue(1);
    if(this.contact){
      const platform_contact = this.contact.hasSinglePlatform()
      if(platform_contact){
        this.ticketForm.get('platform').setValue(platform_contact);
      }
    }else if(this.organization){
      this.ticketForm.get('platform').setValue(this.organization.platform_type);
    }
    
    if(this.contact){
      this.ticketForm.get('contact').setValue(this.contact._id)
    }
    if(this.organization){
      this.ticketForm.get('organization').setValue(this.organization._id)
    }
  }
  
  /**
   * Al cambio di contatto verifico
   * @param contact
   */
  setContact(contact?: Contact){
    let contact_selected;
    if(contact){
      //sto forzando il contatto
      contact_selected = contact
    }else{
      let id_contact_selected = this.ticketForm.get('contact').value;
      contact_selected = DataService.array.contacts.find(elem => elem._id === id_contact_selected)
    }
    
    if(contact_selected){

      this.availableContact = contact_selected
      /** 2. Ho un ticket aperto sul contatto? */
      this.contactTickt = DataService.array.tickets.find((elem)=>{
        if(elem.status === 1){
          if(!elem.contacts) return false;
          for(let cont in elem.contacts){
            if(cont === contact_selected._id) return true;
          }
        }
      })

      if(!this.selectedOrganization){
        //devo ottenere le organization legate alla company
        this.getOrganizationFromContact(contact_selected)
      }
    }else{
      this.contactTickt = undefined;
    }
  }
  
  /**
   *  metodo che data l'organization visualizza i particiapant collegati all'org
   */
  setOrganization(){
    let selectedIdOrg: any = this.ticketForm.get('organization').value
    if(selectedIdOrg){
      /** Ottengo la lista dei partecipant */
      this.selectedOrganization = DataService.array.organization.find(elem => elem._id === selectedIdOrg)
      this.selectedOrganization.participants = DataService.array.participants.filter((part)=>part.idOrganization === this.selectedOrganization._id)
      
      /** Verifico se non è presente un contact, nel caso vado a ottenere una lista di contact inclusi nell'organization */
      if(!this.contact){
        //devo ottenere il contatto dall'organizzazione selezionata
        const company = DataService.array.companies.find((elem) =>{
          if(elem.organizations){
            for(let org in elem.organizations){
              if(org === this.selectedOrganization._id) return true;
            }
          }
        })
        
        if(company){
          //devo andare a vedere se questa company ha dei contatti legati ad esso
          const contact = DataService.array.contacts.find(elem => elem.id_company === company._id)
          if(contact){
            this.contact = contact;
          }
        }
      }
    }
  }
  
  /**
   * Metodo utilizzato per creare il ticket
   */
  async createTicket(){
    await this.view.hideLoading()
    try{
      this.view.showLoading('Creazione ticket...')
      let selectedTracker: any[] = [];
      
      for(let t in this.trackerSelected){
        if(this.trackerSelected[t]){
          selectedTracker.push(t)
        }
      }
      
      const {type, title, platform, organization, contact, showTicketInWeb, ticketImportant, subType, isEmail} = this.ticketForm.value;
      let trackers_replace: any[] = [];
      if(subType && parseInt(subType) === 5){
        if(Object.keys(selectedTracker).length <= 0){
          return this.view.presentAlert('Errore', 'Devi avere almeno un tracker per questa tipologia di ticket', ['Ok']);
        }
        trackers_replace = selectedTracker
        selectedTracker = []
      }
    
      let newTicket: any = await this.api.postTicket(contact, platform, organization, parseInt(type), title, selectedTracker, trackers_replace, showTicketInWeb, ticketImportant, parseInt(subType), isEmail)
      if(newTicket){
        await this.view.hideLoading()
        await this.dismiss({id: newTicket._id, type: this.routeSelected});
        const alert = await this.alertCtrl.create({
          buttons: ['Ok'],
          header: 'Ticket creato',
        });
        await alert.present();
      }
    }catch(err){
      await this.view.hideLoading()
      await this.dismiss();
      const alert = await this.alertCtrl.create({
        buttons: ['Ok'],
        header: 'Errore',
        message: 'impossibile creare il ticket'
      });
      await alert.present();
      console.error(err)
    }
  
  }
  
  setTracker(imei: string){
    this.trackerSelected[imei] = !this.trackerSelected[imei]
  }
  
  
  /**
   * Chiudo modale
   */
  async dismiss(data?: any) {
    await this.modalController.dismiss(data);
  }
  
  async changePageContent(isForward: boolean){
    ViewService.updateView.next()
    
    switch(this.currentPage){
      case 1: //Pagina scelta tipo messaggio
          if(isForward){
            
            switch (this.routeSelected) {
              case 1: //Whatsapp
                this.manageTicketCreation(this.contact, this.organization)
                this.ticketForm.get('showTicketInWeb').setValue(false)
                this.ticketForm.get('isEmail').setValue(false)
                break;
              case 2: //web
                this.ticketForm.get('showTicketInWeb').setValue(true)
                this.ticketForm.get('contact').setValue("")
                this.ticketForm.get('isEmail').setValue(false)
                this.contactTickt = undefined;
                break;
              case 3: //email
                this.ticketForm.get('showTicketInWeb').setValue(false)
                this.ticketForm.get('isEmail').setValue(true)
                this.contactTickt = undefined;
                break;
              case 4: //non specificata
                this.ticketForm.get('showTicketInWeb').setValue(true)
                this.ticketForm.get('contact').setValue("")
                this.ticketForm.get('isEmail').setValue(false)
                this.contactTickt = undefined;
                break;
            }
            this.currentPage = 2
          }else{
            this.dismiss()
          }
        break;
      case 2: //Pagina verifica dati e visualizzazione messaggio web si/no
        if(isForward){
          this.currentPage = 3
        }else{
          this.currentPage = 1
        }
        break;
      case 3: //pagina inserimento titolo, type e azione creazione ticket
        if(isForward){
          await this.createTicket();
        }else{
          this.currentPage = 2
        }
        break;
    }
    
    ViewService.updateView.next();
  }
  
  /**
   * Metodo usato per gestire il flag visibile su web per ticket whatsapp, se lo deseleziona, voglio che non rimanga traccia dell'organization
   * @param event
   */
  changeTicketVisibilityWeb(event: any){
    
    if(this.routeSelected === 1){
      if(this.ticketForm.get('showTicketInWeb').value === false ){
        this.ticketForm.get('organization').setValue("")
      }
    }
    
  
  }
  
  setRoute(route: number){
    this.routeSelected = route;
    this.changePageContent(true);
  }
  
  changePlatformType(){
    //cambio la piattaforma, devo vedere se ho delle organization
    if(this.contact || this.organization){
      this.manageTicketCreation(this.contact, this.organization)
    }else{
      this.setContact()
    }
  }
  
  
}
