import {Component, Input, OnInit} from '@angular/core';
import {AlertController, ModalController, PopoverController} from '@ionic/angular';
import {ViewService} from 'common-lib';
import {ApiService} from '../../services/api.service';
import {DataService} from '../../services/data.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Ticket} from '../../classes/ticket';
import {OrganizationAdmin} from '../../classes/organizationAdmin';
import {Contact} from '../../classes/contact';
import {Admin} from '../../classes/admin';
import {PopoverSelectComponent} from '../../components/popover-select/popover-select.component';

@Component({
  selector: 'app-modify-ticket',
  templateUrl: './modify-ticket.page.html',
  styleUrls: ['./modify-ticket.page.scss'],
})
export class ModifyTicketPage implements OnInit {
  
  @Input() ticket: Ticket;
  @Input() currentPage: 'info' | 'type' | 'admin' | 'status' | 'contacts';
  @Input() single_page: boolean; // Se true non ha il prosegui alla pagina successiva ma solo il salva
  
  ticketForm: FormGroup;
  DataService = DataService;
  availableOrganizations: OrganizationAdmin[] = [];
  availableContacts: Contact[] = [];
  selectedOrganization: OrganizationAdmin;
  trackerSelected: any = {};
  admins: Admin[] = [];
  
  constructor(
    private modalController: ModalController,
    private view: ViewService,
    public data: DataService,
    private api: ApiService,
    private alertCtrl: AlertController,
    private popoverController: PopoverController
  ) { }

  ngOnInit() {
    
    this.loadForm();
    this.retriveOrganizations();
    this.retriveContacts();
  }
  
  retriveContacts(){
    this.availableContacts = [];
    if(this.ticket.$company){
      this.availableContacts = this.data.getContactsByCompany(this.ticket.$company._id);
    }
  }
  
  retriveOrganizations(){
    this.availableOrganizations = [];
    if(this.ticket.$company){
      this.availableOrganizations = this.ticket.$company.$organizations.filter(elem => elem.platform_type === this.ticket.platform_type);
    }
  }
  
  /**
   *  metodo che data l'organization visualizza i particiapant collegati all'org
   */
  setOrganization(){
   
    let selectedIdOrg: any = this.ticketForm.get('organization').value;
    if(selectedIdOrg){
      /** Ottengo la lista dei partecipant */
      this.ticketForm.get('type').setValue(undefined);
      this.ticketForm.get('sub_type').setValue(undefined);
      this.trackerSelected = [];
      this.selectedOrganization = undefined;
      this.selectedOrganization = DataService.array.organization.find(elem => elem._id === selectedIdOrg);
      this.selectedOrganization.participants = DataService.array.participants.filter((part)=>part.idOrganization === this.selectedOrganization._id)
    }else{
      if((!this.ticket.$contacts || this.ticket.$contacts.length <= 0) && (!this.ticket.$contacts_email || this.ticket.$contacts_email.length <= 0)){
        return this.view.presentAlert('Attenzione', 'Non puoi eliminare organizzazione se il ticket non ha contatti telefonici o email, altrimenti perdiamo ogni riferimento', ['Ok']);
      }
      this.selectedOrganization = undefined;
    }
  }
  
  setType(){
    this.ticketForm.get('sub_type').setValue(undefined);
    this.trackerSelected = [];
  }
  
  setSubType(){
    if(!this.selectedOrganization){
      this.ticketForm.get('sub_type').setValue(undefined);
      return this.view.presentAlert('Attenzione', 'Devi prima selezionare una organizzazione', ['Ok']);
    }
    if(!this.selectedOrganization.participants || this.selectedOrganization.participants.filter((part)=>part.type === 2).length <= 0){
      this.ticketForm.get('sub_type').setValue(undefined);
      return this.view.presentAlert('Attenzione', 'Questa organizzazione non ha localizzatori', ['Ok']);
    }
  }
  
  setTracker(imei: string){
    if(this.trackerSelected[imei]){
      delete this.trackerSelected[imei];
    }else{
      this.trackerSelected[imei] = true;
    }
    console.log(this.trackerSelected)
  }
  
  setStatus(){
    if(this.ticketForm.get('status').value == 3 && this.ticket.type === 2 && this.ticket.sub_type === 5 && !this.ticket.id_order){ // se vogliamo chiudere un ticket in sostituzione gli dico che non può se non ha ancora l'ordine
      this.ticketForm.get('status').setValue(this.ticket.status);
      return this.view.presentAlert('Attenzione', 'Non puoi chiudere un ticket di sostituzione tracker senza aver emesso un ordine', ['Ok']);
    }
  }
  
  async openSelectContact(event: any){
    // Controllo se è un contatto generico prima di procedere
    if(this.ticket.id_company) return this.view.presentAlert('Attenzione', 'Non puoi aggiungere un contatto telefonico perchè sei in un ticket generico che accetta solo contatti email', ['Ok']);
    const contacts_phones: Contact[] = [];
    for(const contact of this.availableContacts){
      if(contact.$list_phones.length > 0 && !this.ticketForm.get('contacts').value.find(c => c._id === contact._id)) contacts_phones.push(contact);
    }
    if(contacts_phones.length <= 0){
      return this.view.presentAlert('Attenzione', 'Non ci sono altri contatti con numeri di telefono', ['Ok']);
    }
    const componentProps: any = {
      options: contacts_phones,
      type: 'contacts'
    }
  
    const popover = await this.popoverController.create({
      component: PopoverSelectComponent,
      event: event,
      translucent: true,
      componentProps: componentProps,
      cssClass: 'popover_big'
    });
    popover.onDidDismiss().then(async (data: any) => {
      if(data && data.data){
        const tickets: Ticket[] = this.data.getTicketsByContact(data.data._id);
        if(tickets){
          const opened_ticket: Ticket = tickets.find(ticket => ticket.isOpen());
          if(opened_ticket){
            return this.view.presentAlert('Attenzione', 'Questo contatto è già in un ticket aperto', ['Ok']);
          }
        }
        let contacts: Contact[] = this.ticketForm.get('contacts').value;
        if(!contacts){
          contacts = [data.data];
        }else{
          contacts.push(data.data);
        }
        this.ticketForm.get('contacts').setValue(contacts);
      }
    });
    await popover.present();
  }
  
  async openSelectContactEmail(event: any){
    const contacts_email: Contact[] = [];
    for(const contact of this.availableContacts){
      if(contact.$list_emails.length > 0 && !this.ticketForm.get('contacts_email').value.find(c => c._id === contact._id)) contacts_email.push(contact);
    }
    if(contacts_email.length <= 0){
      return this.view.presentAlert('Attenzione', 'Non ci sono altri contatti con email', ['Ok']);
    }
    const componentProps: any = {
      options: contacts_email,
      type: 'contacts'
    }
    
    const popover = await this.popoverController.create({
      component: PopoverSelectComponent,
      event: event,
      translucent: true,
      componentProps: componentProps,
      cssClass: 'popover_big'
    });
    popover.onDidDismiss().then(async (data: any) => {
      if(data && data.data){
        let contacts: Contact[] = this.ticketForm.get('contacts_email').value;
        if(!contacts){
          contacts = [data.data];
        }else{
          contacts.push(data.data);
        }
        this.ticketForm.get('contacts_email').setValue(contacts);
      }
    });
    await popover.present();
  }
  
  removeContact(contact: Contact){
    let contacts: Contact[] = this.ticketForm.get('contacts').value;
    let contacts_email: Contact[] = this.ticketForm.get('contacts_email').value;
  
    if(contacts.length === 1 && (!contacts_email || contacts_email.length <= 0) && (!this.ticket.id_organization)){
      return this.view.presentAlert('Attenzione', 'Non puoi eliminare tutti i contatti di un ticket senza organizzazione', ['Ok']);
    }
    
    const index: number = contacts.findIndex(c => c._id === contact._id);
    contacts.splice(index, 1);
  }
  
  removeContactEmail(contact: Contact){
    let contacts: Contact[] = this.ticketForm.get('contacts').value;
    let contacts_email: Contact[] = this.ticketForm.get('contacts_email').value;
    
    if(contacts_email.length === 1 && (!contacts || contacts.length <= 0) && (!this.ticket.id_organization)){
      return this.view.presentAlert('Attenzione', 'Non puoi eliminare tutti i contatti di un ticket senza organizzazione', ['Ok']);
    }
    
    const index: number = contacts_email.findIndex(c => c._id === contact._id);
    contacts_email.splice(index, 1);
  }
  
  
  loadForm(){
    switch (this.currentPage) {
      case 'info': {
        this.ticketForm = new FormGroup({
          title: new FormControl(this.ticket.title, {
            updateOn: 'change',
            validators: [Validators.required, Validators.minLength(2)]
          }),
          is_visible: new FormControl(this.ticket.is_visible, {
            updateOn: 'change',
            validators: [Validators.minLength(1)]
          })
        });
        break;
      }
      case 'type': {
        this.ticketForm = new FormGroup({
          organization: new FormControl(this.ticket.$organization?._id, {
            updateOn: 'change',
            validators: [Validators.minLength(1)]
          }),
          type: new FormControl(this.ticket.type, {
            updateOn: 'change',
            validators: [Validators.required, Validators.minLength(1)]
          }),
          sub_type: new FormControl(this.ticket.sub_type, {
            updateOn: 'change',
            validators: [Validators.minLength(1)]
          })
        });
        if(this.ticket.$organization){
          this.selectedOrganization = this.ticket.$organization;
          this.selectedOrganization.participants = DataService.array.participants.filter((part)=>part.idOrganization === this.selectedOrganization._id);
          if(this.ticket.sub_type === 4){
            for(const imei of this.ticket.trackers){
              this.trackerSelected[imei] = true;
            }
          }
          if(this.ticket.sub_type === 5 && this.ticket?.trackers_replace?.length){
            for(const imei of this.ticket.trackers_replace){
              this.trackerSelected[imei] = true;
            }
          }
        }
        break;
      }
      case 'admin': {
        this.ticketForm = new FormGroup({
          id_admin: new FormControl(this.ticket.id_admin, {
            updateOn: 'change',
            validators: [Validators.required, Validators.minLength(1)]
          }),
          important: new FormControl(this.ticket.important, {
            updateOn: 'change',
            validators: [Validators.minLength(1)]
          })
        });
        this.admins = DataService.array.admin.filter(admin => !admin.call_center);
        break;
      }
      case 'status': {
        this.ticketForm = new FormGroup({
          status: new FormControl(this.ticket.status, {
            updateOn: 'change',
            validators: [Validators.required, Validators.minLength(1)]
          })
        });
        break;
      }
      case 'contacts': {
        this.ticketForm = new FormGroup({
          contacts: new FormControl(this.ticket.$contacts, {
            updateOn: 'change',
            validators: [Validators.minLength(1)]
          }),
          contacts_email: new FormControl(this.ticket.$contacts_email, {
            updateOn: 'change',
            validators: [Validators.minLength(1)]
          })
        });
        break;
      }
    }
    
  }
  
  async dismiss() {
    await this.modalController.dismiss(this.ticket);
  }
  
  async changePageContent(isForward: boolean){
    ViewService.updateView.next()
    
    switch(this.currentPage){
      case 'info': //Pagina scelta tipo messaggio
        if(isForward){
          let {title, is_visible} = this.ticketForm.value;
          if(!is_visible || is_visible === 'false'){
            is_visible = false;
          }else{
            is_visible = true
          }
  
          if(title !== this.ticket.title || is_visible !== this.ticket.is_visible){
            try{
              await this.view.showLoading();
  
              let newTicket: any = await this.api.patchTicket(this.ticket._id, title, is_visible);
              if(newTicket){
                this.ticket.setData(newTicket);
              }
              
            }catch(err){
              this.view.presentAlert('Errore', 'Impossibile modificare il ticket', ['Ok']);
  
              console.error(err)
            }
            await this.view.hideLoading();
            
          }
          if(this.single_page){
            this.dismiss();
          }else{
            this.currentPage = 'type';
            this.loadForm();
          }
        }else{
          this.dismiss()
        }
        break;
      case 'type':
        if(isForward){
  
          let {organization, type, sub_type} = this.ticketForm.value;
          type = parseInt(type);
          if(sub_type) sub_type = parseInt(sub_type);
          let trackers = null;
          let trackers_replace = null;
          
          // Controllo se è diversa la lista di tracker coinvolti
          let to_update: boolean = false;
          if(sub_type === 4 || sub_type === 5){
            if(Object.keys(this.trackerSelected).length <= 0){
              return this.view.presentAlert('Errore', 'Devi avere almeno un tracker per questa tipologia di ticket', ['Ok']);
            }
            
            if(sub_type === 4){
              if(!this.ticket.trackers || Object.keys(this.trackerSelected).length !== this.ticket.trackers.length){
                to_update = true;
              }else{
                for(const imei of this.ticket.trackers){
                  if(!this.trackerSelected[imei]){
                    to_update = true;
                    break;
                  }
                }
              }
              trackers = Object.keys(this.trackerSelected);
            }
  
            if(sub_type === 5){
              if(!this.ticket.trackers_replace || Object.keys(this.trackerSelected).length !== this.ticket.trackers_replace.length){
                to_update = true;
              }else{
                for(const imei of this.ticket.trackers_replace){
                  if(!this.trackerSelected[imei]){
                    to_update = true;
                    break;
                  }
                }
              }
              trackers = undefined;// In questo modo l'api non elimina la lista di tracker con problemi se presente
              trackers_replace = Object.keys(this.trackerSelected);
            }
            
          }
          
          if(to_update || (organization && organization !== this.ticket.id_organization) || type !== this.ticket.type || (sub_type && sub_type !== this.ticket.sub_type)){
    
            await this.view.showLoading();
  
            try{
              let newTicket: any = await this.api.patchTicketType(this.ticket._id, organization, type, sub_type || null, trackers, sub_type === 5 ? Object.keys(this.trackerSelected) : null);
              if(newTicket){
                this.ticket.setData(newTicket);
              }
            }catch(err){
              this.view.presentAlert('Errore', 'Impossibile modificare il ticket', ['Ok']);
              await this.view.hideLoading();
              return;
            }
          }
          await this.view.hideLoading();
          if(this.single_page){
            this.dismiss();
          }else{
            this.currentPage = 'admin';
          }
        }else{
          if(this.single_page){
            this.dismiss();
          }else{
            this.currentPage = 'info';
          }
        }
        this.loadForm();
        break;
      case 'admin':
        if(isForward){
          let {id_admin, important} = this.ticketForm.value;
          important = Boolean(important); // parse string boolean
          if(id_admin !== this.ticket.id_admin || important !== this.ticket.important){
            await this.view.showLoading();
            try{
              let newTicket: any = await this.api.patchTicketAdmin(this.ticket._id, id_admin, important);
              if(newTicket){
                this.ticket.setData(newTicket);
              }
              await this.view.hideLoading();
            }catch(err){
              this.view.presentAlert('Errore', 'Impossibile modificare il ticket', ['Ok']);
              await this.view.hideLoading();
              return;
            }
          }
          if(this.single_page){
            this.dismiss();
          }else{
            this.currentPage = 'status';
            this.loadForm();
          }
        }else{
          if(this.single_page){
            this.dismiss();
          }else{
            this.currentPage = 'type';
          }
        }
        this.loadForm();
        break;
      case 'status':
        if(isForward){
          let {status} = this.ticketForm.value;
          status = parseInt(status);
          if(status !== this.ticket.status){
            await this.view.showLoading();
            try{
              let newTicket: any = await this.api.patchTicketStatus(this.ticket._id, status);
              if(newTicket){
                this.ticket.setData(newTicket);
              }
              await this.view.hideLoading();
            }catch(err){
              this.view.presentAlert('Errore', err?.error?.description || 'Impossibile modificare il ticket', ['Ok']);
              await this.view.hideLoading();
              return;
            }
          }
          if(this.single_page){
            this.dismiss();
          }else{
            this.currentPage = 'contacts';
          }
        }else{
          if(this.single_page){
            this.dismiss();
          }else{
            this.currentPage = 'admin';
          }
        }
        this.loadForm();
        break;
      case 'contacts':
        if(isForward){
          let {contacts, contacts_email} = this.ticketForm.value;
  
          await this.view.showLoading();
          try{
            let contacts_ids: any = {};
            let contacts_email_ids: any = {};
    
            for(const contact of contacts){
              contacts_ids[contact._id] = {};
            }
    
            for(const contact of contacts_email){
              contacts_email_ids[contact._id] = {};
            }
    
            let newTicket: any = await this.api.patchTicketContacts(this.ticket._id, this.ticket.platform_type, contacts_ids, contacts_email_ids);
            if(newTicket){
              this.ticket.setData(newTicket);
            }
            await this.view.hideLoading();
          }catch(err){
            this.view.presentAlert('Errore', 'Impossibile modificare il ticket', ['Ok']);
            await this.view.hideLoading();
            return;
          }
          this.dismiss();
        }else{
          if(this.single_page){
            this.dismiss();
          }else{
            this.currentPage = 'status';
          }
        }
        this.loadForm();
        break;
    }
    
    ViewService.updateView.next();
  }
  
  
  
}
