import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import Company from '../../classes/company';
import {ViewService} from 'common-lib';
import {Contact} from '../../classes/contact';
import {Ticket} from '../../classes/ticket';
import {ApiService} from '../../services/api.service';
import {TwilioService} from '../../services/twilio.service';
import {Storage} from '@ionic/storage';
import {DataService} from '../../services/data.service';
import {AlertController, ModalController, PopoverController} from '@ionic/angular';
import {CreateMessagePage} from '../../app/create-message/create-message.page';
import {WhatsappMsgToExpiredChatPage} from '../../app/whatsapp-msg-to-expired-chat/whatsapp-msg-to-expired-chat.page';
import {TwilioCall} from '../../classes/twilioCall';
import * as lodash from 'lodash';
import {NewCallPage} from '../../app/new-call/new-call.page';
import {EmailMessage} from '../../classes/emailMessage';
import {SendEmailPage} from '../../app/send-email/send-email.page';
import {ModifyTicketPage} from '../../app/modify-ticket/modify-ticket.page';
import {NewContactPage} from '../../app/new-contact/new-contact.page';
import {AttachmentPage} from '../../app/attachment/attachment.page';
import {NewTicketPage} from '../../app/new-ticket/new-ticket.page';
import {TrackerPage} from '../../app/tracker/tracker.page';
import {ParticipantAdmin} from '../../classes/participantAdmin';
import {WhatsappMessage} from '../../classes/whatsappMessage';
import {CompanyAddPage} from '../../app/company-add/company-add.page';
import {PopoverListComponent} from '../popover-list/popover-list.component';
import {ChatIsExpiredPipe} from '../../pipes/chatIsExpired.pipe';
@Component({
  selector: 'app-company-body',
  templateUrl: './company-body.component.html',
  styleUrls: ['./company-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ChatIsExpiredPipe]
  
})
export class CompanyBodyComponent implements OnChanges {
  
  @Input() target: Ticket | Contact;
  @Output() selectTarget = new EventEmitter<Ticket | Contact>();
  
  company: Company;
  
  selected_ticket: Ticket;
  selected_contact: Contact;
  
  conversation_type_ticket: 'messaggi' | 'chiamate';
  conversation_type_contact: 'last_messages' | 'chiamate_contatto' | 'chiamate_company';
  
  listener = {
    view: null,
    updateData: null,
    remoteUpdate: null
  };
  
  showSendMessage: boolean = false;
  showWhatsappMessage: boolean = false;
  route_message_selected: number; //1 whatsapp, 2 web
  
  attachment: any;
  message_text: string;
  
  search_contact: string;
  
  chips_ticket_status: any[] = [
    {
      id: 1,
      lbl: 'Aperti',
      selected: true
    },
    {
      id: 2,
      lbl: 'In lavorazione',
      selected: true
    },
    {
      id: 3,
      lbl: 'Chiusi',
      selected: true
    }
  ];
  chip_ticket_status_selected: any = {1: true, 2: true, 3: true};
  
  chip_calls_attivo: string = 'tutti';
  chip_calls: any = [
    {
      id: 'tutti',
      lbl: 'Tutte',
      selected: this.chip_calls_attivo === 'tutti'
    },
    {
      id: 'da_leggere',
      lbl: 'Da leggere',
      selected: this.chip_calls_attivo === 'da_leggere'
    },
    {
      id: 'ricevute',
      lbl: 'Ricevuta',
      selected: this.chip_calls_attivo === 'ricevute'
    },
    
    {
      id: 'effettuate',
      lbl: 'Effettuate',
      selected: this.chip_calls_attivo === 'effettuate'
    },
    {
      id: 'perse',
      lbl: 'Perse',
      selected: this.chip_calls_attivo === 'perse'
    },
  ];
  
  can_send_email_phones: boolean = false;
  
  chips_contacts: any[] = [];
  chip_contacts_selected: any = {};
  
  
  selected_phone_contact: any;
  
  
  constructor(
    public view: ViewService,
    private cd: ChangeDetectorRef,
    private api: ApiService,
    public twilioService: TwilioService,
    private data: DataService,
    private storage: Storage,
    private modalController: ModalController,
    private popoverController: PopoverController,
    private alertCtrl: AlertController,
    private chatIsExpirePipe: ChatIsExpiredPipe
    
  ) {
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.target) {
      
      // Setto la selected company
      this.company = this.target.$company;
      this.storage.set('chat_company_selected', {_id: this.company._id});
      
      // Recupero tutti i contatti e tutte i ticket della company
      this.company.$contacts = this.data.getContactsByCompany(this.target.$company._id);
      this.company.$tickets = this.data.getTicketByCompany(this.target.$company); // Si basa anche sui contatti trovati nel metodo precedente
      
      if (this.target instanceof Ticket) {
        this.selectTicket(this.target, true);
      } else if (this.target instanceof Contact) {
        this.selectContact(this.target);
      }
      
    }
  }
  
  ngOnInit() {
    this.listenerView();
    this.listenerUpdateData();
    this.listenerRemoteUpdate();
  }
  
  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
    if (this.listener.updateData) {
      this.listener.updateData.unsubscribe();
    }
    if (this.listener.remoteUpdate) {
      this.listener.remoteUpdate.unsubscribe();
    }
  }
  
  async selectContact(contact: Contact) {
    this.hideShowMessage()
    this.selected_ticket = undefined;
    this.selected_contact = contact;
    console.log(this.selected_contact)
    this.resetChipCalls()
    if (!this.conversation_type_contact) this.conversation_type_contact = 'last_messages';
    
    // Recupero call e email che non sono già scaricate
    let res: any = await this.api.getCallByContact(this.selected_contact._id, 50, 0, 'asc'); //TODO: gestire paginazione
    if (res && res.data) {
      contact.$calls = [];
      contact.$has_more_calls = res.has_more;
      for (let raw of res.data) {
        let call: TwilioCall = new TwilioCall(raw, {
          getAdmin: this.data.getAdminById,
          getPlatformByType: this.data.getPlatformByType,
          getTicketById: this.data.getTicketById,
          getContactById: this.data.getContactById
        });
        contact.$calls.push(call);
      }
    }
    
    if (contact.$company) {
      let res: any = await this.api.getCallsByCompany(this.selected_contact.id_company, 50, 0); //TODO: gestire paginazione
      if (res && res.data) {
        contact.$company.$calls = [];
        contact.$company.$has_more_calls = res.has_more;
        for (let raw of res.data) {
          let call: TwilioCall = new TwilioCall(raw, {
            getAdmin: this.data.getAdminById,
            getPlatformByType: this.data.getPlatformByType,
            getTicketById: this.data.getTicketById,
            getContactById: this.data.getContactById
          });
          contact.$company.$calls.push(call);
        }
      }
    }
    
    contact.$tickets = this.data.getTicketsByContact(this.selected_contact._id);
    
    
    if (contact.$tickets) {
      contact.$tickets.sort((a: Ticket, b: Ticket) => {
        if (a.last_update < b.last_update) {
          return -1;
        } else if (a.last_update > b.last_update) {
          return 1;
        } else {
          return 0;
        }
      });
      
      /** Recupero gli ultimi 5 ticket per prendere i messaggi e dare un riassunto delle comunicazioni **/
      const tickets: string[] = [];
      for (let i = 0; i < 5 && i < contact.$tickets.length; i++) {
        tickets.push(contact.$tickets[i]._id);
      }
      try {
        this.chips_contacts = [];
        this.chip_contacts_selected = {};
        let response: any = await this.api.getAllMessagesInTickets(tickets);
        if (response) {
          response.$getMediaInfo = this.data.getMediaInfo;
          contact.updateMessagesInTicketsFromApiResponse(response);
          for (const c of contact.$list_phones) {
            this.chips_contacts.push({
              id: c,
              lbl: c,
              selected: true
            });
            this.chip_contacts_selected[c] = true;
          }
          for (const e of contact.$list_emails) {
            this.chips_contacts.push({
              id: e,
              lbl: e,
              selected: true
            });
            this.chip_contacts_selected[e] = true;
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
    
    this.selectTarget.emit(this.selected_contact);
    ViewService.updateView.next();
  }
  
  changeChipAllMessages(event: any) {
    this.chip_contacts_selected[event.id] = event.selected;
    ViewService.updateView.next();
  }
  
  /**
   *
   * @param ticket
   * @param hideShowMessage, optional: parametro per forzare la cancellazione del messaggio in campo di testo
   */
  async selectTicket(ticket: Ticket, hideShowMessage?: boolean) {
    if(hideShowMessage){
      this.hideShowMessage()
    }
    this.selected_contact = undefined;
    this.selected_ticket = ticket;
    this.resetChipCalls()
    if (!this.conversation_type_ticket) this.conversation_type_ticket = 'messaggi';
    this.manageTicketSendMethods()
    
    let res: any = await this.api.getCallByTicket(this.selected_ticket._id, 50, 0, 'asc');
    if (res && res.data) {
      ticket.$calls = [];
      ticket.$has_more_calls = res.has_more;
      for (let raw of res.data) {
        let call: TwilioCall = new TwilioCall(raw, {
          getAdmin: this.data.getAdminById,
          getPlatformByType: this.data.getPlatformByType,
          getTicketById: this.data.getTicketById,
          getContactById: this.data.getContactById
        });
        ticket.$calls.push(call);
      }
    }
    
    res = await this.api.getMessagesByTicket(this.selected_ticket._id, 'asc');
    if (res) {
      this.selected_ticket.updateMessagesInTicketFromApiResponse(res, false);
    }
    this.selectTarget.emit(this.selected_ticket);
    
    if(this.selected_ticket.$contacts.length === 1){
      //se ho solo un contatto allora
      this.selected_phone_contact = this.selected_ticket?.$contacts[0].$list_phones[0]
    }else{
      //se ne ho più di uno prendo l'ultimo messaggio whatsapp e seleziono quel numero
      if(this.selected_ticket?.$messages[this.selected_ticket?.$messages.length -1].$customer_phone){
        this.selected_phone_contact = this.selected_ticket?.$messages[this.selected_ticket?.$messages.length -1].$customer_phone;
      }else{
        //altrimenti prento il primo contatto
        if(this.selected_ticket?.$contacts[0] && this.selected_ticket?.$contacts[0].$list_phones.length > 0) {
          this.selected_phone_contact = this.selected_ticket.$contacts[0].$list_phones[0]
        }
      }
    }
    
    ViewService.updateView.next();
  }
  
  async manageOpenWhatsappModal(){
    const foundContact = this.selected_ticket.$contacts.find(elem => elem.$list_phones.includes(this.selected_phone_contact));
    if(foundContact && foundContact.last_whatsapp && this.selected_ticket.$last_message &&  !this.chatIsExpirePipe.transform(foundContact.last_whatsapp[this.selected_ticket.$last_message.chat_owner])  ){
      //chat ancora valida
      this.openSendMessage(1);
    }else{
      //chat scaduta
      await this.modalWhatsappMsgToExpiredChat();
    }
  }
  
  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
    });
  }
  
  listenerUpdateData() {
    this.listener.updateData = DataService.updateData$.subscribe((update?: any) => {
      switch (update.type) {
        case 'contact':
          console.log('update contact in company');
          try {
            if (this.company && this.company.$contacts.find((elem: Contact) => elem._id === update._id)) {
              const contact: Contact = this.data.getContactById(update._id);
              if (!contact) {
                //siamo in delete
                this.company.$contacts = this.data.getContactsByCompany(this.target.$company._id);
                if (this.selected_contact?._id) {
                  this.selected_contact = undefined;
                }
              } else {
                if (this.selected_contact?._id === update._id) {
                  this.selectContact(this.selected_contact);
                } else {
                  ViewService.updateView.next();
                }
              }
            } else {
              const contact: Contact = this.data.getContactById(update._id);
              if (contact?.$company?._id === this.company._id) {
                this.company.$contacts.push(contact);
                ViewService.updateView.next();
              }
            }
          } catch (err) {
            console.error(err);
          }
          break;
        case 'ticket':
          console.log('update ticket in company');
          try {
            if (this.company && this.company.$tickets.find((elem: Ticket) => elem._id === update._id)) {
              if (this.selected_ticket?._id === update._id) {
                this.selectTicket(this.selected_ticket);
              } else {
                ViewService.updateView.next();
              }
            } else {
              const ticket: Ticket = this.data.getTicketById(update._id);
              console.log(ticket);
              if (ticket?.$company?._id === this.company._id) {
                this.company.$tickets.push(ticket);
                ViewService.updateView.next();
              }
            }
          } catch (err) {
            console.error(err);
          }
          break;
      }
    });
  }
  
  listenerRemoteUpdate() {
    this.listener.remoteUpdate = DataService.remoteUpdate$.subscribe(async (update?: any) => {
      if (update.element === 'call') {
        switch (update.type) {
          case 'update_list_to_read':
            try {
              
              if (update.id_contact) {
                
                if (this.selected_contact && this.selected_contact._id === update.id_contact) {
                  for (const call of this.selected_contact.$calls) {
                    if (call.platform_type === update.platform_type && call.type === update.sub_chat) {
                      call.to_read = false;
                    }
                  }
                  for (const call of this.selected_contact.$company?.$calls) {
                    if (call.platform_type === update.platform_type && call.type === update.sub_chat) {
                      call.to_read = false;
                    }
                  }
                  ViewService.updateView.next();
                }
                
                if (this.selected_ticket && this.selected_ticket.haveContact(update.id_contact)) {
                  for (const call of this.selected_ticket.$calls) {
                    if (call.platform_type === update.platform_type && call.type === update.sub_chat) { // questo update è di un filone specifico
                      call.to_read = false;
                    }
                  }
                  ViewService.updateView.next();
                }
              }
              
              if (update.id_admin) { // abbiamo letto tutte le call di uno specifico admin
                if (this.selected_contact) {
                  for (const call of this.selected_contact.$calls) {
                    if (call.origin_admin_id === update.id_admin) {
                      call.to_read = false;
                    }
                  }
                  for (const call of this.selected_contact.$company?.$calls) {
                    if (call.origin_admin_id === update.id_admin) {
                      call.to_read = false;
                    }
                  }
                  
                  if (this.company.$tickets) {
                    for (const ticket of this.company.$tickets) {
                      if (ticket.id_admin === update.id_admin) {
                        ticket.unread_calls = 0;
                      }
                    }
                  }
                  ViewService.updateView.next();
                }
                if (this.selected_ticket) {
                  for (const call of this.selected_ticket.$calls) {
                    if (call.origin_admin_id === update.id_admin) {
                      call.to_read = false;
                    }
                  }
                  ViewService.updateView.next();
                }
              }
              
            } catch (err) {
              console.error(err);
            }
            break;
          
          case 'update':
            try {
              if (!update.sid) break;
              if (this.selected_contact) {
                let call: TwilioCall = this.selected_contact.$calls.find((elem: TwilioCall) => {
                  if (elem.haveSid(update.sid) || elem.haveSid(update.parent_sid)) return elem
                });
                if (call) {
                  await this.twilioService.updateCallFromRemote(call);
                } else if (update.id_company && this.selected_contact._id === update.id_company) {
                  this.selected_contact.$calls.push(await this.twilioService.updateCallFromRemote(undefined, update.sid));
                }
                
                call = this.selected_contact.$company?.$calls.find((elem: TwilioCall) => {
                  if (elem.haveSid(update.sid) || elem.haveSid(update.parent_sid)) return elem
                });
                if (call) {
                  await this.twilioService.updateCallFromRemote(call);
                } else if (update.id_company && this.selected_contact._id === update.id_company) {
                  this.selected_contact.$calls.push(await this.twilioService.updateCallFromRemote(undefined, update.sid));
                }
              }
              
              if (this.selected_ticket) {
                let call: TwilioCall = this.selected_ticket.$calls.find((elem: TwilioCall) => {
                  if (elem.haveSid(update.sid) || elem.haveSid(update.parent_sid)) return elem
                });
                if (call) {
                  await this.twilioService.updateCallFromRemote(call);
                } else if (update.id_ticket && this.selected_ticket._id === update.id_ticket) {
                  this.selected_ticket.$calls.push(await this.twilioService.updateCallFromRemote(undefined, update.sid));
                }
              }
            } catch (err) {
              console.error(err);
            }
            break;
        }
      }
    });
  }
  
  openSendMessage(route_selected: number) {
    this.showSendMessage = true;
    this.route_message_selected = route_selected;
  }
  
  hideShowMessage() {
    this.showSendMessage = false;
    this.route_message_selected = null;
    this.attachment = undefined;
    this.message_text = null;
    
  }
  
  async modalWhatsappMsgToExpiredChat() {
    console.log(this.selected_phone_contact)
    const modal = await this.modalController.create({
      backdropDismiss: true,
      component: WhatsappMsgToExpiredChatPage,
      componentProps: {
        to_phone: this.selected_phone_contact,
        selectedCompany: this.selected_ticket.$company,
        id_ticket: this.selected_ticket._id,
        ticket: this.selected_ticket
      },
      cssClass: "modal_big",
    });
    await modal.present();
    await modal.onDidDismiss();
  }
  
  selectFile(event: any) {
    let input: any = document.createElement('input');
    input.type = 'file';
    input.accept = "image/*,application/pdf";
    input.addEventListener("change", (event) => {
      this.uploadFile(event);
    });
    input.click();
  }
  
  removeFile() {
    this.attachment = undefined
  }
  
  /**
   * Riconosce la selezione di un file e fa partire l'upload
   * @param event
   */
  uploadFile(event: any) {
    const target: any = event.target || event.srcElement;
    if (target.files[0]) {
      this.attachment = {
        name: target.files[0].name,
        type: target.files[0].type.split("/")[0],
        contentType: target.files[0].type
      };
      this.attachment.body = new Blob([target.files[0]]);
      
      ViewService.updateView.next();
    }
  }
  
  // Invia il nuovo messaggio
  async sendMessage() {
    if (!this.message_text && !this.attachment) return;
    await this.view.showLoading('Invio messaggio...');
    try {
      let fileName, path: string;
      if (this.attachment) {
        path = await this.data.uploadMedia(this.attachment.name, this.attachment.body, {
          contentType: this.attachment.contentType,
          cacheControl: "max-age=31536000"
        });
        fileName = this.attachment.name;
        this.attachment = undefined;
      }
      
      switch (this.route_message_selected) {
        case 1:
          let last_message: { chat_owner, chat_customer };
          if (this.selected_ticket.$messages.length > 0) {
            let message = lodash.cloneDeep(this.selected_ticket.$messages)
            message.reverse()
            last_message = message.find((elem) => elem.message_type === 'whatsapp')
          }
          
          const whatsapp_phone = this.selected_ticket.$platform.whatsapp_number_platform.find(elem => elem.type === 1)
          const chat_owner = last_message?.chat_owner || whatsapp_phone.number;
          const chat_customer = last_message?.chat_customer || `whatsapp:${this.selected_phone_contact}`
          
          //devo capire a che tipo mi sto appoggiando
          const whatsapp_number = this.selected_ticket.$platform.whatsapp_number_platform.find(elem => elem.number === chat_owner);
          if(whatsapp_number && whatsapp_number.send_type === 2){
            await this.api.postWhatsappMessageMeta(chat_owner, chat_customer, this.message_text, this.selected_ticket._id, fileName, path);
          }else{
            await this.api.postWhatsappMessage(chat_owner, chat_customer, this.message_text, this.selected_ticket._id, fileName, path);
          }
  
          break;
        case 2:
          await this.api.postWebMessage(this.message_text, this.selected_ticket._id, fileName, path);
          break;
      }
      this.message_text = undefined;
    } catch (err) {
      console.error(err)
      this.view.modalAlert('Errore', 'Impossibile inviare il messaggio', 'warn', ['Ok']);
    }
    ViewService.updateView.next();
    await this.view.hideLoading();
  }
  
  async openSendMessageModal(ticket: Ticket) {
    const modal = await this.modalController.create({
      backdropDismiss: true,
      component: CreateMessagePage,
      componentProps: {
        ticket: ticket
      },
      cssClass: "modal_big",
    });
    await modal.present();
    await modal.onDidDismiss();
  }
  
  // Apre il modal di creazione nuova company
  async newCall() {
    const modal = await this.modalController.create({
      backdropDismiss: true,
      component: NewCallPage,
      componentProps: {
        contact: this.selected_contact,
        ticket: this.selected_ticket
      },
      cssClass: "modal_big",
    });
    await modal.present();
    await modal.onDidDismiss();
  }
  
  // Apre il modal di invio email ad una nuova email
  async modalNewEmail(platform_type?: number, email_owner?: string, email_customer?: string, old_email?: EmailMessage, ticket?: Ticket, use_contact_phone?: boolean) {
    const modal = await this.modalController.create({
      backdropDismiss: true,
      component: SendEmailPage,
      componentProps: {
        platform_type: platform_type,
        email_destinatario: email_customer,
        email_mittente: email_owner,
        old_email: old_email,
        ticket: ticket,
        use_contact_phone: use_contact_phone
      },
      cssClass: "modal_big",
    });
    await modal.present();
    const {data} = await modal.onDidDismiss();
  }
  
  async createTickteFromMessage(message?: WhatsappMessage | EmailMessage, ticket?: Ticket) {
    return await this.view.presentAlert('Attenzione', `Vuoi creare un nuovo ticket partendo da questo messaggio?`, [{
      text: 'Annulla',
      role: 'cancel',
      cssClass: 'secondary'
    }, {
      text: 'Conferma',
      handler: async () => {
        this.view.showLoading('Creazione ticket in corso')
        
        try {
          const res: any = await this.api.createTicketFromMessageId(ticket._id.toString(), message._id.toString(), message.message_type)
          if (res) {
            let ticket_result: Ticket = this.data.getTicketById(res._id.toString())
            await this.selectTicket(ticket_result)
          }
          this.view.hideLoading()
        } catch (err) {
          this.view.hideLoading()
          console.error(err)
        }
      }
    }]);
  }
  
  async modalNewTicket() {
    const modal = await this.modalController.create({
      backdropDismiss: true,
      component: NewTicketPage,
      componentProps: {
        contact: this.selected_contact,
      },
      cssClass: "modal_big",
    });
    await modal.present();
  }
  
  async modalNewContact(company?: Company) {
    const modal = await this.modalController.create({
      backdropDismiss: true,
      component: NewContactPage,
      componentProps: {
        company: company,
      },
      cssClass: "modal_big",
    });
    await modal.present();
    const {data} = await modal.onDidDismiss();
    if (data.data) {
    
    }
  }
  
  changeChipTicket(event: any) {
    this.chip_ticket_status_selected[event.id] = event.selected;
    ViewService.updateView.next();
  }
  
  closeBody() {
    this.selected_contact = undefined;
    this.selected_ticket = undefined;
    this.selectTarget.emit(undefined);
    ViewService.updateView.next();
  }
  
  async openModifyTicket(ticket: Ticket) {
    if (ticket) {
      const modal = await this.modalController.create({
        component: ModifyTicketPage,
        componentProps: {
          currentPage: 'info',
          ticket: lodash.cloneDeep(ticket),
        },
        backdropDismiss: false,
        cssClass: "modal_big",
      });
      
      await modal.present();
      
      modal.onDidDismiss().then((data: any) => {
        if (data && data.data) {
          this.selectTicket(ticket);
        }
      });
    }
  }
  
  async openModifyTicketStep(ticket: Ticket, step: 'info' | 'type' | 'admin' | 'status' | 'contacts') {
    if (ticket) {
      const modal = await this.modalController.create({
        component: ModifyTicketPage,
        componentProps: {
          currentPage: step,
          ticket: lodash.cloneDeep(ticket),
          single_page: true,
        },
        backdropDismiss: false,
        cssClass: "modal_big",
      });
      
      await modal.present();
      
      modal.onDidDismiss().then((data: any) => {
        if (data && data.data) {
          this.selectTicket(ticket);
        }
      });
    }
  }
  
  async openModifyContact(contact: Contact) {
    if (contact) {
      const modal = await this.modalController.create({
        component: NewContactPage,
        componentProps: {
          contact: contact,
        },
        backdropDismiss: false,
        cssClass: "modal_big",
      });
      
      await modal.present();
    }
  }
  
  openImage(url: string) {
    window.open(url, '_blank')
  }
  
  // Fa vedere lista allegati di una email
  async showAttachmentsEmail(ev: any, email: EmailMessage) {
    if (email.$mediaInfos) {
      const popover = await this.popoverController.create({
        component: AttachmentPage,
        event: ev,
        translucent: true,
        componentProps: {
          attachments: email.$mediaInfos,
          type: 'show'
        }
      });
      popover.onDidDismiss().then((data: any) => {
        if (data.data === null) this.attachment = undefined;
        ViewService.updateView.next();
      });
      await popover.present();
    }
  }
  
  async openTrackerDetail(tracker: ParticipantAdmin) {
    const modal = await this.modalController.create({
      component: TrackerPage,
      componentProps: {
        id: tracker._id,
        tracker: tracker
      },
      cssClass: "modal_very_big",
    });
    return await modal.present();
  }
  
  async modifyReadCount(ticket: Ticket, type: 'messages' | 'calls', count_unread: number) {
    await this.view.showLoading();
    try {
      switch (type) {
        case 'messages':
          await this.api.patchMessagesCountUnread(ticket._id, count_unread);
          break;
        case 'calls':
          break;
      }
    } catch (err) {
      this.view.modalAlert('Errore', 'Impossibile modificare il conteggio messaggi letti', 'warn', ['Ok']);
    }
    await this.view.hideLoading();
  }
  
  changeChipCalls(event: any) {
    this.chip_calls_attivo = event.id;
    ViewService.updateView.next();
  }
  
  resetChipCalls() {
    this.chip_calls_attivo = 'tutti';
    ViewService.updateView.next();
  }
  
  manageSelectContact(id_contact: string) {
  
  }
  
  async modalUnionContact() {
    let elements: any = [];
    
    for (let c of DataService.array.contacts) {
      let aus: any = {
        first_row: `${c.name || 'Contatto senza nome'}`,
        second_row: `${c.note || ''}`,
        id: c._id
      };
      if (c.id_company === this.selected_contact.id_company && c._id !== this.selected_contact._id) elements.push(aus);
    }
    
    const popover = await this.popoverController.create({
      backdropDismiss: true,
      component: PopoverListComponent,
      componentProps: {
        elements: elements,
        type: 'two_lines',
        title: `Seleziona il contatto in cui vuoi unire`,
        enableSearch: true
      },
      cssClass: "modal_list",
    });
    await popover.present();
    await popover.onDidDismiss().then(async (data: any) => {
      if (data?.data?.id) {
        let c: Contact = DataService.array.contacts.find((elem: Contact) => {
          return elem._id === data.data.id
        });
        if (!c) return;
        const alert = await this.alertCtrl.create({
          header: 'Sei sicuro?',
          message: `<small>Vuoi eliminare il contatto <b>${this.selected_contact.name || this.selected_contact._id}</b> e unire ticket, email, telefono nell contatto <b>${c.name || c._id}</b>?</small>`,
          buttons: [
            {
              text: 'Annulla',
              role: 'cancel',
              cssClass: 'secondary'
            }, {
              text: 'Conferma',
              handler: async (data) => {
                await this.view.showLoading('Unione contatti');
                try {
                  /** 1. Ho entrambi i contatti **/
                  const res: any = await this.api.patchUnionContacts(this.selected_contact._id, c._id);
                } catch (err) {
                  console.error(err.error.description);
                  this.view.presentAlert('Errore', 'Impossibile unire i due contatti. ' + err?.error?.description, ['Ok']);
                }
                await this.view.hideLoading();
              }
            }
          ]
        });
        await alert.present();
        
      }
    })
  }
  
  async ticketCreated(event: any) {
    const ticket = this.company.$tickets.find((elem: Ticket) => elem._id === event.id);
    if (ticket) {
       await this.selectTicket(ticket, true);
      
      if (event.type) {
        switch (event.type) {
          case 1: //whatsapp
            if (!this.selected_ticket?.$last_message?.chat_owner || !ticket.$contacts[0].last_whatsapp[this.selected_ticket.$last_message.chat_owner] || (ticket.$contacts[0].last_whatsapp[this.selected_ticket.$last_message.chat_owner.replace('whatsapp:', '')] + (24 * 60 * 60 * 1000)) < Date.now()) {
              //chat scaduta
              await this.modalWhatsappMsgToExpiredChat();
            } else {
              //chat valida
              this.openSendMessage(1);
            }
            break;
          case 2: //web
          case 4: //non specificata
            this.openSendMessage(2);
            break;
          case 3: //mail
            await this.modalNewEmail(ticket.platform_type, null, null, null, ticket);
            break;
          case 4: //non specificata
            
            break;
        }
      }
    }
  }
  
  // Apre il modal di creazione nuova company
  async modalNewCompany(company?: Company, type?: 'data' | 'contacts') {
    const modal = await this.modalController.create({
      backdropDismiss: true,
      component: CompanyAddPage,
      componentProps: {
        company: company,
        type: type
      },
      cssClass: "modal_big",
    });
    await modal.present();
    await modal.onDidDismiss();
  }
  
  // Apre il modal di unione della company con un'altra company
  async modalUnionCompanies(company: Company) {
    let elements: any = [];
    for (let c of DataService.array.companies) {
      let aus: any = {
        first_row: `${c.label || c._id}`,
        second_row: `${c.note || ''}`,
        id: c._id
      };
      if (c._id !== company._id) elements.push(aus);
    }
    const popover = await this.popoverController.create({
      backdropDismiss: true,
      component: PopoverListComponent,
      componentProps: {
        elements: elements,
        type: 'two_lines',
        title: `Seleziona l'azienda in cui vuoi unire`,
        enableSearch: true
      },
      cssClass: "modal_list",
    });
    await popover.present();
    await popover.onDidDismiss().then(async (data: any) => {
      if (data?.data?.id) {
        let c: Company = DataService.array.companies.find((elem: Company) => {
          return elem._id === data.data.id
        });
        if (!c) return;
        const alert = await this.alertCtrl.create({
          header: 'Sei sicuro?',
          message: `<small>Vuoi eliminare l'azienda <b>${company.label || company._id}</b> e trasferire i contatti, le organizzazioni e le attività nell'azienda <b>${c.label || c._id}</b>?</small>`,
          buttons: [
            {
              text: 'Annulla',
              role: 'cancel',
              cssClass: 'secondary'
            }, {
              text: 'Conferma',
              handler: async (data) => {
                await this.view.showLoading('Unione aziende');
                try {
                  const res: any = await this.api.patchUnionCompanies(company._id, c._id);
                  if (res) {
                    c.setAllFields(res);
                  }
                  this.selectCompany(c);
                } catch (err) {
                  console.error(err);
                  this.view.presentAlert('Errore', 'Impossibile unire le due aziende', ['Ok']);
                }
                await this.view.hideLoading();
              }
            }
          ]
        });
        await alert.present();
        
      }
    });
  }
  
  async selectCompany(company: Company) {
    // Cerco il primo contatto della company e lo apro
    const contact: Contact = this.data.getContactByCompany(company._id);
    if (!contact) {
      return this.view.presentAlert('Errore', 'Impossibile aprire la company perchè non ha contatti', ['OK']);
    }
    this.selectTarget.emit(contact);
  }
  
  manageTicketSendMethods() {
    this.can_send_email_phones = false;
    
    if (this.selected_ticket) {
      
      if (!this.selected_ticket.$contacts_email.length) {
        //vado a vedere se ha contatto phone e se nei contatti phone ci sono le mail
        if (this.selected_ticket.$contacts?.length) {
          for (let phone of this.selected_ticket.$contacts) {
            if (phone.$list_emails.length) {
              this.can_send_email_phones = true;
            }
          }
        }
        
      }
      
      
    }
  }
  
  async refreshAll() {
    await this.view.showLoading('Aggiornamento in corso...');
    try {
      const res: any = await this.api.refreshAllCompanyData(this.target.$company._id);
      if (res) {
        if (res.company) {
          this.company.setAllFields(res.company);
        }
        if (res.contacts) {
          for (const c of res.contacts) {
            let contact: Contact = this.data.getContactById(c._id);
            if (contact) {
              contact.setData(c);
            } else {
              contact = new Contact(c, {
                getPlatformByType: this.data.getPlatformByType,
                getAdminById: this.data.getAdminById,
                getCompanyById: this.data.getCompanyById,
                getTicketById: this.data.getTicketById
              });
              DataService.array.contacts.push(contact);
            }
          }
        }
        if (res.tickets) {
          for (const t of res.tickets) {
            let ticket: Ticket = this.data.getTicketById(t._id);
            if (ticket) {
              ticket.setData(t);
            } else {
              ticket = new Ticket(t, {
                getPlatformByType: this.data.getPlatformByType,
                getOrganizationById: this.data.getOrganizationById,
                getCompanyByOrganizationId: this.data.getCompanyByOrganizationId,
                getAdminById: this.data.getAdminById,
                getContactById: this.data.getContactById,
                getParticipantByTrackerImei: this.data.getParticipantByImei,
                getMediaInfo: this.data.getMediaInfo,
                getTicketById: this.data.getTicketById
              });
              DataService.array.tickets.push(ticket);
            }
          }
        }
        ViewService.updateView.next();
      }
      
    } catch (err) {
      console.error(err);
      this.view.presentAlert('Errore', 'Impossibile ricaricare la pagina', ['Ok']);
    }
    await this.view.hideLoading();
  }
  
  get hasMessages(): boolean {
    return !!this.selected_ticket.$messages && this.selected_ticket.$messages.length > 0;
  }
  
  get isLastMessageWhatsapp(): boolean {
    if (this.hasMessages) {
      const lastMessage = this.selected_ticket.$messages[this.selected_ticket.$messages.length - 1];
      return lastMessage.message_type === 'whatsapp';
    }
    return false;
  }
  
  get isLastMessageEmail(): boolean {
    if (this.hasMessages) {
      const lastMessage = this.selected_ticket.$messages[this.selected_ticket.$messages.length - 1];
      return lastMessage.message_type === 'email';
    }
    return false;
  }
  
  get isLastMessageWeb(): boolean {
    if (this.hasMessages) {
      const lastMessage = this.selected_ticket.$messages[this.selected_ticket.$messages.length - 1];
      return lastMessage.message_type === 'web';
    }
    return false;
  }
  
}
