import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import {Platform} from "../../classes/platform";
import {
  AuthService,
  Utility,
  ViewService
} from "common-lib";
import {
  ModalController,
  NavController,
  PopoverController,
  ToastController
} from "@ionic/angular";
import {ApiService} from "../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import { DataService } from 'src/services/data.service';
import {EmailMessage} from "../../classes/emailMessage";
import {Ticket} from '../../classes/ticket';
import {platform} from 'os';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.page.html',
  styleUrls: ['./send-email.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendEmailPage implements OnInit {
  
  platforms: Platform[] = [];
  platform: Platform;
  @Input() platform_type: number;
  
  body: string;
  
  @Input() old_email: EmailMessage;
  @Input() email_mittente: string;
  @Input() email_destinatario: string;
  @Input() ticket: Ticket;
  
  @Input() list_email: string[] = [];

  @Input() use_contact_phone: boolean = false;
  
  subject: string;
  disabled_subject: string;
  
  isResponse: boolean;
  attachments: any[] = [];
  listener = {
    view: null
  };
  customer_emails: any[] = [];
  
  constructor(
    private cd: ChangeDetectorRef,
    private api: ApiService,
    private data: DataService,
    private modalCtrl: ModalController,
    private auth: AuthService,
    private nav: NavController,
    public popoverController: PopoverController,
    private route: ActivatedRoute,
    private router: Router,
    private toastCtrl: ToastController,
    private view: ViewService,
  ) { }
  
  ngOnInit() {
    
    //devo gestire il caso in cui non passa l'old mail.
    // Sto creando una nuova mail prenendo come soggetto il num del ticket con il prefisso re
  }
  
  ionViewDidEnter() {
    this.listenerView();
    for(let platform of DataService.platforms){
      this.platforms.push(platform);
    }
    /* Imposto la platform */
    if(this.platform_type) this.changePlatform();
    
    /* Verifica se sto facendo reply a una mail*/
    if(this.old_email){
      this.customer_emails.push(this.email_destinatario)
      this.isResponse = true;
      this.subject = this.old_email.subject;
      this.disabled_subject = '';
      if(!this.old_email.subject.includes('RE') && !this.old_email.subject.includes('Re')) this.subject = 'Re: ' + this.subject;
    }else{
      for(let contact of this.ticket.$contacts_email){
        if(contact.$list_emails.length){
          for(let email of contact.$list_emails){
            this.customer_emails.push(email)
          }
        }
      }
      this.disabled_subject = `ticket#${this.ticket.num}#`
      this.subject = this.ticket.title || ''
    }
    
    /* Imposta di default la mail del mittente */
    const email = this.platform.email_platform.find((elem)=> elem.type === 1) //di default quella di commerciale
    this.email_mittente = email.fromMail || this.platform.email_platform[0].fromMail
    
    /* Verifico se la variabile use_contact_phone è true */
    if(this.use_contact_phone){
      const emails: any = []
      if(this.ticket.$contacts.length > 0){
        for(let contact of this.ticket.$contacts){
          if(contact.$list_emails.length > 0){
            for(let email of contact.$list_emails){
              emails.push(email) //alimento la lista mail con le mail del contatto phone.
            }
          }
        }
        
        if(emails.length > 0){
          this.customer_emails = []
          this.customer_emails= emails
        }
      }
      
    }
  
    /* Imposta la mail del destinatario*/
    if(this.customer_emails.length === 1){
      this.email_destinatario = this.customer_emails[0]
    }
    ViewService.updateView.next();
  }
  
  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }
  
  dismiss(data?: any) {
    this.modalCtrl.dismiss(data);
  }
  
  /**
   * Riconosce la selezione di un file e fa partire l'upload
   * @param event
   */
  uploadFile(event: any) {
    const target: any = event.target || event.srcElement;
    if (target.files) {
      for(let file of target.files){
        this.attachments.push({
          name: file.name,
          type: file.type,
          body: new Blob([file])
        });
      }
    }
    ViewService.updateView.next();
  }
  
  removeAttach(index: number){
    this.attachments.splice(index, 1);
    ViewService.updateView.next();
  }
  
  async confirm(){
    console.log(this.ticket)
    if(!this.email_mittente) return this.view.presentAlert('Errore', 'Devi inserire l\'email del mittente', ['Ok']);
    if(!Utility.validateEmail(this.email_mittente)) return this.view.presentAlert('Errore', 'L\'email del mittente non è valida', ['Ok']);
    if(!this.email_destinatario) return this.view.presentAlert('Errore', 'Devi inserire almenno un\'email del destinatario', ['Ok']);
    if(!Utility.validateEmail(this.email_destinatario)) return this.view.presentAlert('Errore', 'L\'email del destinatario non è valida', ['Ok']);
  
    await this.view.showLoading('Invio email...');
    try{
      let attachments: any[] = [];
      if(this.attachments){
        for(let attachment of this.attachments){
          attachments.push({
            path: await this.data.uploadMedia(attachment.name, attachment.body, { contentType: attachment.type, cacheControl: "max-age=31536000" }),
            fileName: attachment.name,
            contentType: attachment.type
          });
        }
      }
      
      /* Verifico se devo aggiungere un contatto email al ticket */
      if(this.use_contact_phone){
        // 1. ottengo il contatto dalla mail destinatario
        const contact = this.getContactByEmailInPhones(this.email_destinatario)
        
        if(contact){
          //Api per aggiunta contatto email in ticket
          const ticket_contact = this.ticket.contacts_email || {};
          ticket_contact[contact] = {}
          await this.api.patchTicketContacts(this.ticket._id, this.ticket.platform_type, undefined, ticket_contact)
        }
      }
      
      await this.api.postNewEmail( this.email_mittente, this.email_destinatario, this.body, `${this.disabled_subject ? this.disabled_subject + ' - ' : ''}${this.subject}`, attachments, this.ticket._id);
      ViewService.updateView.next();
      await this.view.hideLoading();
      this.dismiss();
    }catch(err){
      console.error(err)
      this.view.modalAlert('Errore', 'Impossibile inviare email', 'warn', ['Ok']);
      await this.view.hideLoading();
    }
    
  }
  
  changePlatform(){
    for(let platform of this.platforms){
      if(platform.type.toString() === this.platform_type.toString()){
        this.platform = platform;
        ViewService.updateView.next();
        return;
      }
    }
  }
  
  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
    });
  }
  
  getContactByEmailInPhones(email_to_check: string){

    for(let contact of this.ticket.$contacts){
      if(contact.$list_emails.length > 0){
  
        for(let email of contact.$list_emails){
          if(email === email_to_check) return contact._id
        }
        
      }
    }
  }
}
