import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ViewService as View, ViewService} from 'common-lib';
import {DataService} from '../../services/data.service';
import {ModalController} from '@ionic/angular';
import {ApiService} from '../../services/api.service';
import {Admin} from '../../classes/admin';
import {SmsToTracker} from '../../classes/smsToTracker';

@Component({
  selector: 'app-sms-to-phone',
  templateUrl: './sms-to-phone.page.html',
  styleUrls: ['./sms-to-phone.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmsToPhonePage implements OnInit {
  
  @Input() isModal: boolean;
  @Input() iccid: string;
  @Input() text: string;
  
  id_admin: string;
  phone: string;
  priority: number;
  start_interval: string;
  end_interval: string;
  tab: string = 'send_sms';
  
  sms_list: any[];
  admin_list: any[];
  
  listener = {
    view: null,
  };
  
  admins: Admin[];
  
  constructor(
    private route: ActivatedRoute,
    private ref: ChangeDetectorRef,
    private view: ViewService,
    public data: DataService,
    private modalController: ModalController,
    private api: ApiService
  ) {
  
  }
  
  ngOnInit() {
    this.listenerView();
    DataService.isReady().then(async () => {
      this.admins = [];
      await this.data.updateAdmins();
      for(const admin of DataService.array.admin){
        if(!admin.call_center) this.admins.push(admin);
      }
      if(this.admins.length > 0){
        this.id_admin = this.data.current_admin._id;
      }
      if(this.isModal && this.iccid){
        this.priority = 3;
      }else{
        this.priority = 1;
      }
      this.start_interval = '';
      this.end_interval = '';
      View.updateView.next();
    });
  }
  
  ionViewDidEnter() {
  
  }
  
  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }
  
  listenerView() {
    this.listener.view = View.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.ref.markForCheck();
    });
  }
  
  async confirmAndSend(){
    if(!this.id_admin) return this.view.presentAlert('Errore', "Devi scegliere un admin", ['Ok']);
    if(!this.priority) return this.view.presentAlert('Errore', "Devi specificare una priorità di invio SMS", ['Ok']);
    if(!this.isModal && !this.phone) return this.view.presentAlert('Errore', "Devi inserire un numero di telefono", ['Ok']);
    if(!this.text) return this.view.presentAlert('Errore', "Devi inserire il testo dell'SMS", ['Ok']);
    
    // Controllo intervallo invio
    if((this.start_interval && !this.end_interval) || (!this.start_interval && this.end_interval)){
      return this.view.presentAlert('Errore', "Devi specificare entrambi gli orari di invio e non solo uno", ['Ok']);
    }
    const start_hour: number = parseInt(this.start_interval);
    const end_hour: number = parseInt(this.end_interval);
    
    // Ottengo i numeri di telefono
    let phones: string[]
    if(!this.isModal && this.phone){
      phones = this.phone.split(/[;:]\s?/);
      phones = phones.filter((elem: string) => !!elem);
  
      if(phones.length <= 0){
        return this.view.presentAlert('Errore', "Devi specificare almeno un numero di telefono", ['Ok']);
      }
    }
    
    await this.view.presentAlert('Sei sicuro?', `Sicuro di voler inviare SMS`, [{
      text: 'Annulla',
      role: 'cancel',
      cssClass: 'secondary'
    }, {
      text: 'Conferma',
      handler: async () => {
        await this.view.showLoading('Invio SMS in corso...');
        try{
          await this.api.postSmsToSim(this.text, this.priority, this.id_admin, start_hour, end_hour, phones, this.iccid);
          this.view.presentAlert('Perfetto', 'SMS inviati', ['Ok']);
        }catch(err){
          console.error(err);
          this.view.presentAlert('Errore', 'Impossibile inviare SMS', ['Ok']);
        }
        await this.view.hideLoading();
        if(this.isModal) this.dismiss({send: true});
      }
    }]);
  }
  
  async getSms() {
    let phones: string[] = this.phone.split(/[;:]\s?/);
    phones = phones.filter((elem: string) => !!elem);

    if(!phones) return this.view.presentAlert('Errore', "Devi specificare almeno un numero di telefono", ['Ok']);
  
    await this.view.showLoading("scaricamento SMS per numero...")
    try {
      let res: any = await this.api.postRetrivePhonesSMS(phones);
      if (res?.length > 0) {
        this.sms_list = []; // no limit & skip
        this.sms_list.push(
          ...res.map(el =>
            new SmsToTracker(el, {getAdmin: this.data.getAdminById})
          )
        );
      }
    } catch (e) {
      console.error(`e`, e)
      this.view.presentAlert('Errore', 'Errore reperimento comandi sim', ['Ok']);
    }

    await this.view.hideLoading();
    View.updateView.next()
  }
  
  async checkSmsMachine() {
    await this.data.updateAdmins();
    await this.view.showLoading("scaricamento dati sms machine...");
    try {
      let res: any = await this.api.getRetriveCheckSmsMachine();
      if (res?.length > 0) {
        const sms_list: SmsToTracker[] = []; // no limit & skip
        sms_list.push(
          ...res.map(el =>
            new SmsToTracker(el, {getAdmin: this.data.getAdminById})
          )
        );
        const admins: any = {};
        this.admin_list = [];
        for(const sms of sms_list){
          if(admins[sms.id_admin]){
            admins[sms.id_admin].counter += 1;
            admins[sms.id_admin].sms.push(sms);
          }else{
            admins[sms.id_admin] = {
              counter: 1,
              admin: sms.$admin,
              sms: [sms]
            }
          }
        }
        // Recupero gli admin
        for(const admin in admins){
          this.admin_list.push(admins[admin]);
        }
      }
    } catch (e) {
      console.error(`e`, e)
      this.view.presentAlert('Errore', 'Errore reperimento comandi sim', ['Ok']);
    }
    await this.view.hideLoading();
    View.updateView.next()
  }
  
  dismiss(data?: any){
    this.modalController.dismiss(data);
  }
}
