import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CcEmailsInfoPage } from './cc-emails-info.page';

const routes: Routes = [
  {
    path: '',
    component: CcEmailsInfoPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CcEmailsInfoPageRoutingModule {}
