import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import {Order} from '../classes/order';

@Pipe({
  name: 'ordersFilter'
})
export class OrdersFilterPipe implements PipeTransform {
  
  transform(
    list: Order[],
    status: 'all' | 'to_manage' | 'not_paid' | 'paid' | 'paid_not_prepared' | 'preparation' | 'shipping' | 'shipping_with_problem' | 'delivered' | 'partial_activated' | 'total_activated' | 'cancelling' | 'cancelled',
    interval: 'all' | 'today' | 'week' | 'month',
    partial_activation_date: 'all' | '1_week' | '2_week' | '3_week' | '1_month',
    date_order: 'decrescent' | 'crescent',
    changed: number
  ): any {
    
    if(!list || list.length <= 0) return list;
    
    let filtered: Order[] = [];
    for(let order of list){
      if(
        status === 'all' ||
        (status === 'to_manage' && order.status < 3) ||
        (status === 'not_paid' && order.$invoice?.status_formatted !== 'paid') ||
        (status === 'paid' && order.$invoice?.status_formatted === 'paid') ||
        (status === 'paid_not_prepared' && order.$invoice?.status_formatted === 'paid' && order.status === 0) ||
        (status === 'preparation' && order.status === 1) ||
        (status === 'shipping' && order.status === 2) ||
        (status === 'shipping_with_problem' && order.status === 2 && order.$problem_with_delivery) ||
        (status === 'delivered' && order.status === 3) ||
        (status === 'partial_activated' && order.status === 3 && order.$imei_activation_status?.$imei_not_activated.length > 0) ||
        (status === 'total_activated' && order.status === 3 && order.$imei_activation_status?.$imei_not_activated.length <= 0) ||
        (status === 'cancelling' && order.status === 98) ||
        (status === 'cancelled' && order.status === 99) ||
        (status === 'preparation' && order.status === 1)
      ){
        const creation_time : any = moment(order.created_timestamp);
        
        switch (interval){
          case 'all': {
            filtered.push(order);
          }
            break;
          case 'today': {
            if(creation_time.isBetween(moment().startOf('day'), moment().endOf('day'))){
              filtered.push(order);
            }
          }
            break;
          case 'week': {
            if(creation_time.isBetween(moment().startOf('week'), moment().endOf('week'))){
              filtered.push(order);
            }
          }
            break;
          case 'month': {
            if(creation_time.isBetween(moment().startOf('month'), moment().endOf('month'))){
              filtered.push(order);
            }
          }
            break;
        }
  
        if(order.delivered_timestamp && status === 'partial_activated'){
          if(
            (partial_activation_date === '1_week' && order.$delivered_days < 7) ||
            (partial_activation_date === '2_week' && order.$delivered_days < 14) ||
            (partial_activation_date === '3_week' && order.$delivered_days < 21) ||
            (partial_activation_date === '1_month' && order.$delivered_days < 31)
          ){
            filtered.splice(filtered.length - 1, 1);
          }
          
        }
      }
    }

    if(filtered.length > 0){
      if(date_order === 'crescent'){
        filtered.sort((a: Order, b: Order) => a.created_timestamp - b.created_timestamp);
      }else{
        filtered.sort((a: Order, b: Order) => b.created_timestamp - a.created_timestamp);
      }
    }
    return filtered;
  }

}
