import {
  Injectable, Pipe,
  PipeTransform
} from '@angular/core';

@Pipe({
  name: 'arrayLimit'
})
@Injectable()
export class ArrayLimitPipe implements PipeTransform {
  transform(allElements: any[], limit: number, controller?: any, has_more_field?: string, changed?: number): any[] {
    if(!allElements) allElements = [];
    let filtered: any[] = [];
    filtered = allElements.slice(0, limit);
    if(controller && has_more_field){
      controller[has_more_field] = filtered.length < allElements.length;
    }
    return filtered;
  }
}


