import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import {AuthService, ViewService} from 'common-lib';
import {Storage} from "@ionic/storage-angular";
import {OrganizationAdmin} from '../classes/organizationAdmin';
import {Platform} from '../classes/platform';
import {DataService} from './data.service';

type Maybe<T> = T | undefined

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    private api: ApiService,
    private auth: AuthService,
    private storage: Storage,
    private view: ViewService,
  ) {}
  
  
  makeArrayOfArrays<T>({
    array,
    chunkSize = 5,
  }: {
    array: T[]
    chunkSize?: number
  }): T[][] {
    // console.log(`makeArrayOfArrays()`, )
    let counter = 0
    
    let loopCount = Math.floor(array.length / chunkSize)
    loopCount += (array.length % chunkSize) && 1
    
    // an array of arrays: T[][]
    let arrayOfChunks: typeof array[] = []
    
    for (let i = 0; i < loopCount; i++) {
      arrayOfChunks.push(
        array.slice(counter, counter + chunkSize)
      )
      counter += chunkSize
    }
    
    // console.log(`makeArrayOfArrays() arrayOfChunks`, arrayOfChunks)
    return arrayOfChunks
  }
  
  
  async getOpenPortalUrl(organization: OrganizationAdmin, isDev: boolean, redirect?: string){
    const res: any = await this.api.getFirebaseUid(organization.login_email_partecipant_owner);
    switch(organization.platform_type){
      case 1:{
        let url: string = isDev ? 'https://dev.balin.app' : 'https://web.balin.app';
        url += `?forcedEmail=${organization.login_email_partecipant_owner}&forcedToken=${res.uid}`
        return url;
      }
      case 2:{
        let url: string = isDev ? 'https://dev.flottaincloud.it' : 'https://web.flottaincloud.it';
        url += `?forcedEmail=${organization.login_email_partecipant_owner}&forcedToken=${res.uid}`
        return url;
      }
    }
  }
  
  
  /**
   * Metodo utilizzatoe per gestire l'apertura del porale
   * @param organization
   * @param isDev
   * @param redirect
   */
  async openPortal(organization: OrganizationAdmin, isDev: boolean, redirect?: string){
    const res: any = await this.api.getFirebaseUid(organization.login_email_partecipant_owner);
  
    switch(organization.platform_type){
      case 1:{
        let url: string = isDev ? 'https://dev.balin.app' : 'https://web.balin.app';
        url += `?forcedEmail=${organization.login_email_partecipant_owner}&forcedToken=${res.uid}`
        if(redirect){
          url += `&redirect=${redirect}`
        }
        window.open(url, '_blank');
        break;}
      case 2:{
        let url: string = isDev ? 'https://dev.flottaincloud.it' : 'https://web.flottaincloud.it';
        url += `?forcedEmail=${organization.login_email_partecipant_owner}&forcedToken=${res.uid}`
        if(redirect){
          url += `&redirect=${redirect}`
        }
        window.open(url, '_blank');
        break;
      }
    }
  }
  
  /**
   *
   * @param organization
   */
  async openPortalWithToken(organization: OrganizationAdmin){
    let platform: Platform = DataService.platforms.find((elem)=>elem.type === organization.platform_type);
    if(platform && platform.url_web_pre_production){
      const url: string = platform.url_web_pre_production;
      const token: any = await this.api.generateToken(organization._id);
      console.log(`${url}?authToken=${token._id}`);
      window.open(`${url}?authToken=${token._id}`, '_blank');
    }
  }
  

}
