import {Pipe, PipeTransform} from '@angular/core';
import {TwilioCall} from '../classes/twilioCall';
import * as lodash from 'lodash';
import Company from '../classes/company';
import {DataService} from '../services/data.service';

@Pipe({
  name: 'searchCalls'
})

/** Pipe specifico per la ricerca dalla lista company in visualizzazione chat **/
export class SearchCallsPipe implements PipeTransform {
  
  transform(
    calls: TwilioCall[],
    filter:string,
    multiple_filter: any[],
    admin_selected: any[],
    platform_selected: any[],
    admin_call_center: any[],
    directions: any[],
    status: any[],
    customer_phone?: string,
    type?: 'important' | 'not_important' |  'all',
    changed?: number,
  ): any[] {
    // Filtra l'array calls in base ai criteri specificati
    if(!calls || calls.length <= 0 ) return calls;
    
    let call_filtered: TwilioCall[] = [];
    let callClone = lodash.cloneDeep(calls);
    
    if(Object.keys(admin_selected).length > 0){
      for(let i = 0; i<callClone.length; i++ ){
        if(admin_selected[callClone[i].origin_admin_id] || admin_selected[callClone[i].final_admin_id]){
          call_filtered.push(callClone[i]);
        }
      }
    }else{
      call_filtered = callClone;
    }
  
    if (Object.keys(platform_selected).length > 0) {
      call_filtered = call_filtered.filter((elem) => {
        if(platform_selected[elem.platform_type]){
          return elem;
        }
      });
    }
    
    if(Object.keys(admin_call_center).length > 0){
      call_filtered = call_filtered.filter((elem) => {
        if(elem.type && admin_call_center[elem.type]){
          return elem;
        }
      });
    }
    
    if (Object.keys(directions).length > 0) {
      call_filtered = call_filtered.filter((elem) => {
        if(directions[elem.direction]){
          return elem;
        }
      });
    }
    

    if (Object.keys(status).length > 0) {
      call_filtered = call_filtered.filter((elem) => {
        for (let state in status) {
          if (status[state] && state === elem.status) {
            return elem;
          }
        }
      });
    }
    if (Object.keys(multiple_filter).length > 0) {
      call_filtered = call_filtered.filter((elem) => {
        for (let state in multiple_filter) {
          if(multiple_filter[state] && state === 'da_leggere' && elem.to_read === true){
            return elem;
          }
          if(multiple_filter[state] && state === 'letti' && (elem.to_read === false || typeof elem.to_read === 'undefined') ){
            return elem;
          }
        }
      });
    }
   
    switch(type){
     case 'important':
       call_filtered = call_filtered.filter((elem) => {
         return !elem.botted;
       })
       break;
      case 'not_important':
        call_filtered = call_filtered.filter((elem) => {
          return elem.botted === true;
        })
        break;
      default:
        break;
   }
    
    if(filter){
      switch (filter) {
        case 'da_leggere':
          call_filtered = call_filtered.filter((elem) => elem.to_read === true);
          break;
        case 'ricevute':
          call_filtered = call_filtered.filter((elem)=> elem.direction === 'incoming')
          break;
        case 'effettuate':
          call_filtered = call_filtered.filter((elem)=> elem.direction === 'outgoing')
          break;
        case 'perse':
          call_filtered = call_filtered.filter((elem)=> elem.direction === 'incoming' && elem.status === 'no-answer')
          break;
        case 'tutte':
          
          break;
        case 'customer_phone':
          if(customer_phone){
            call_filtered = call_filtered.filter((elem)=> elem.from === customer_phone || elem.to === customer_phone )
          }
          break;
        default:
          break;
      }
    }
    
    
    
    return call_filtered;
  }
  
}
