import { Component, OnInit } from '@angular/core';
import {TwilioService} from '../../services/twilio.service';
import { ViewService} from 'common-lib';
import {ModalController, PopoverController} from '@ionic/angular';
import {PopoverAudioSettingComponent} from '../popover-audio-setting/popover-audio-setting.component';
import {DataService} from '../../services/data.service';
import {ApiService} from '../../services/api.service';
import {NewCallPage} from '../../app/new-call/new-call.page';
import {CallRedirectComponent} from '../call-redirect/call-redirect.component';

@Component({
  selector: 'app-twilio-calls-details',
  templateUrl: './twilio-calls-details.component.html',
  styleUrls: ['./twilio-calls-details.component.scss'],
})
export class TwilioCallsDetailsComponent implements OnInit {

  mic_list: MediaDeviceInfo[] = [];
  
  selected_mic: string;
  listener = {
    setInputDevice: null,
  }
  
  
  filtro_calls_attivo: string = 'tutti';
  filtri_calls: any = [
    {
      id: 'tutti',
      lbl: 'Tutte',
      selected: this.filtro_calls_attivo === 'tutti'
    },
    {
      id: 'da_leggere',
      lbl: 'Da leggere',
      selected: this.filtro_calls_attivo === 'da_leggere'
    },
    {
      id: 'ricevute',
      lbl: 'Ricevuta',
      selected: this.filtro_calls_attivo === 'ricevute'
    },
  
    {
      id: 'effettuate',
      lbl: 'Effettuate',
      selected: this.filtro_calls_attivo === 'effettuate'
    },
    {
      id: 'perse',
      lbl: 'Perse',
      selected: this.filtro_calls_attivo === 'perse'
    },
  ];
  
  constructor(
    public twilio: TwilioService,
    public view: ViewService,
    private popoverController: PopoverController,
    public data: DataService,
    private api: ApiService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    this.listenerDevices()
  }
  
  ionViewWillLeave(){
    if(this.listener.setInputDevice){
      this.listener.setInputDevice.unsubscribe();
    }
  }
  
  
  listenerDevices(){
    this.listener.setInputDevice = TwilioService.setInputDevice$.subscribe((obj)=>{
      this.selected_mic = obj.id
    })
  }
  
  
  /**
   * Apro la select per impostare le sorgenti input/output audio
   * @param ev
   */
  async openAudioSetting(ev: any){
    const popover = await this.popoverController.create({
      component: PopoverAudioSettingComponent,
      event: ev,
      translucent: true,
      componentProps: {
        mic_list: this.mic_list,
        selected_mic: this.selected_mic
      }
    });
    await popover.present()
  }
  

  
  /** Recupera le call più vecchie **/
  async showMoreCalls(){
    try{
      await this.view.showLoading();
      await this.twilio.updateAdminCallsList(this.twilio.calls.length);
      ViewService.updateView.next();
    }catch(err){
      console.error(err);
    }
    await this.view.hideLoading();
  }
  
  async isBusy(){
    
    try{
      await this.view.showLoading();
      let updatedAdmin = await this.api.patchAdminStatus(!this.data.current_admin.status)
      if(updatedAdmin){
        this.data.current_admin.setAllFields(updatedAdmin);
      }
      ViewService.updateView.next();
    }catch(err){
      console.error(err)
    }
    await this.view.hideLoading();
  
  }
  
  
  async createCall(){
  
    const modal = await this.modalCtrl.create({
      backdropDismiss: true,
      component: NewCallPage,
      componentProps: {},
      cssClass: "modal_big",
    });
    await modal.present();
    await modal.onDidDismiss();
  }
  
  async openRedirectManager(){
    const modal = await this.modalCtrl.create({
      component: CallRedirectComponent,
      cssClass: 'modal_big'
    });
    modal.onDidDismiss().then((data: any) => {});
    return await modal.present();
  }
  
  // Viene lanciata quando cliccano su un filtro delle chat
  changeFilterChats(event: any){
    this.filtro_calls_attivo = event.id;
    if(this.filtro_calls_attivo !== 'company' && this.filtri_calls[0].id === 'company'){
      this.filtri_calls.splice(0, 1);
    }
    ViewService.updateView.next();
  }
}
