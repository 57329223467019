import {Component, Input, OnInit} from '@angular/core';
import {AlertController, ModalController} from '@ionic/angular';
import {ViewService} from 'common-lib';
import {ApiService} from '../../services/api.service';
import {DataService} from '../../services/data.service';
import {DomSanitizer} from '@angular/platform-browser';
import {DatePipe} from '@angular/common';
import {Ticket} from '../../classes/ticket';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Order} from '../../classes/order';

@Component({
  selector: 'app-tracker-replace',
  templateUrl: './tracker-replace.component.html',
  styleUrls: ['./tracker-replace.component.scss'],
  providers: [DatePipe]
  
})
export class TrackerReplaceComponent implements OnInit {
  
  @Input() title: string;
  @Input() ticket: Ticket;
  
  invoiceForm: FormGroup;
  shippingForm: FormGroup;
  ticketForm: FormGroup;
  recent_orders: Order[] = [];
  constructor(
    private modalCtrl: ModalController,
    public view: ViewService,
    private api: ApiService,
    private data: DataService,
    private sanitizer: DomSanitizer,
    private datePipe: DatePipe,
    private alertCtrl: AlertController
  ) { }

  async ngOnInit() {
    
    //get organization vat
    this.invoiceForm = new FormGroup({
      amount: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(1)]
      }),
      description: new FormControl("Sostituzione localizzatori", {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(1)]
      }),
      vat: new FormControl(0, {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(1)]
      })
    });
    
    this.ticketForm = new FormGroup({
      note: new FormControl(this.ticket?.note, {
        updateOn: 'change',
        validators: [Validators.minLength(1)]
      })
    });
  
  
    this.getOrganizationVat(this.ticket.$organization);
  
    this.shippingForm = new FormGroup({
      name: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(1)]
      }),
      mail: new FormControl(this.ticket.$organization.login_email_partecipant_owner, {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(1)]
      }),
      address: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(1)]
      }),
      city: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(1)]
      }),
      cap: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(1)]
      }),
      state: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(1)]
      }),
      country: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(1)]
      }),
      phone: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(1)]
      }),
    })
    
    
    //devo ottenere gli indirizzi recenti per l'organization
    await this.getRecentOrders();
  }
  
  
  dismiss() {
    this.modalCtrl.dismiss();
  }
  
  async createOrder(){
    const alert = await this.alertCtrl.create({
      header: `Sei sicuro?`,
      message: `Sei sicuro di voler confermare la sostituizione per la richiesta N.${this.ticket.num} `,
      buttons: [
        {
          text: 'Annulla',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Conferma',
          handler: async () => {
            console.log('Confirm Ok');
            try{
              await this.view.showLoading('Creazione ordine in corso...')
              let result = await this.api.confirmReplaceTracker(this.ticket._id.toString(), this.invoiceForm.get('amount').value  ,this.invoiceForm.get('description').value, this.shippingForm.value )
              if(result){
                await this.modalCtrl.dismiss({success: true})
                await this.view.presentAlert('Successo', 'Ordine creato correttamente', ['Ok']);
              }
              await this.view.hideLoading()
            }catch(err){
              console.error(err)
              await this.view.presentAlert('Errore', 'Impossibile confermare la richiesta di sostituzione', ['Ok']);
              await this.view.hideLoading()
            }
            
            
          }
        }
      ]
    });
    await alert.present();
  }
  
  
  getOrganizationVat(organization){
    if(organization.billing_type === 1){ //privato
      this.invoiceForm.get('vat').setValue(22)
    }else if(organization.billing_type === 2 && organization.billing_location === 1){ // azienda italia
      this.invoiceForm.get('vat').setValue(22)
    }else if(organization.billing_type === 2 && organization.billing_location === 2){ // azienda europa
      this.invoiceForm.get('vat').setValue(0)
    }else if(organization.billing_type === 2 && organization.billing_location === 3){ //azienda extra europa
      this.invoiceForm.get('vat').setValue(0)
    }
  }
  
  
  async getRecentOrders(){
    try{
      let recent: any = await this.api.getRecentShippingAddressByOrganizationId(this.ticket?.id_organization)
      if(recent){
        for(let order of recent){
          this.recent_orders.push(new Order(order, {$getOrganizationById: this.data.getOrganizationById, $getTicketByOrderId: this.data.getTicketByOrderId}));
        }
      }
    }catch(err){
      console.error(err)
      this.view.presentAlert('Errore', 'Impossibile ottenere gli ordini recenti', ['Ok'])
    }
  }
  
  async setAddress(shipping_address: any){
    this.shippingForm.get('name').setValue(shipping_address?.name);
    this.shippingForm.get('address').setValue(shipping_address?.address1);
    this.shippingForm.get('city').setValue(shipping_address?.city);
    this.shippingForm.get('cap').setValue(shipping_address?.zipcode);
    this.shippingForm.get('state').setValue(shipping_address?.state);
    this.shippingForm.get('country').setValue(shipping_address?.country);
    this.shippingForm.get('phone').setValue(shipping_address?.phone);
  }
  
  async updateTicket() {
    try {
      this.view.showLoading('Salvataggio nota...')
      let update = await this.api.patchTicket(this.ticket._id.toString(), undefined, undefined, this.ticketForm.get('note').value)
      if(update){
        this.ticket.setData(update)
      }
      this.view.hideLoading()
    } catch (err) {
      console.error(err)
      this.view.hideLoading()
      this.view.presentAlert('Errore', 'Impossibile modifica il ticket', ['Ok'])
    }
  }
  
}
