import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { ModalController, NavController, PopoverController, ToastController } from '@ionic/angular';
import { 
  AuthService, 
  ViewService, ViewService as View,
} from 'common-lib';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';
import { TrackerAdmin } from 'src/classes/trackerAdmin';
import {ApiService} from "../../services/api.service";
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DataService } from 'src/services/data.service';
import Activity from 'src/classes/activity';
import Company from 'src/classes/company';
import { ListParsers } from 'src/classes/listParsers';

// import * as lodash from 'lodash';

@Component({
  selector: 'app-company-select',
  templateUrl: './company-select.page.html',
  styleUrls: ['./company-select.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanySelectPage implements OnInit {
  
  // Data passed in by componentProps
  @Input() selectOptions: Company[] = [];

  listener = {
    data: null,
    view: null,
  };
  
  constructor(
    private cd: ChangeDetectorRef,
    private api: ApiService,
    private data: DataService,
    private modalCtrl: ModalController,
    private auth: AuthService,
    private nav: NavController,
    public popoverController: PopoverController,
    private route: ActivatedRoute,
    private router: Router,
    private toastCtrl: ToastController,
    private view: ViewService,
  ) {}

  ngOnInit() {}
  
  // ionViewDidEnter() {
  //   // this.listenerData();
  //   this.listenerView();
  //   // this.data.getEntityMultiple(this.entity as any);
  //   // this.initSomeDOMelems();
  //   // console.log("this.row",this.row)
  // }
  //
  // ionViewWillLeave() {
  //   if (this.listener.view) {
  //     this.listener.view.unsubscribe();
  //   }
  // }
  //
  // // * content
  //
  // //  for tests
  // consoleLog(i) {
  //   console.log('i', i)
  //   // console.log('i', DataService.array.activity)
  //   // this.router.navigate(['phonebook-activities']);
  // }
  //
  // dismiss(data?: any) {
  //   // using the injected ModalController this page
  //   // can "dismiss" itself and optionally pass back data
  //   this.modalCtrl.dismiss(data);
  // }
  //
  // // * listener
  //
  // listenerView() {
  //   this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
  //     this.view.pipeChanged++;
  //     this.cd.markForCheck();
  //     setTimeout(() => {
  //       this.cd.markForCheck();
  //     }, 250);
  //   });
  // }
  
  

}
