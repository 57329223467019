import {Component, Input, OnInit} from '@angular/core';
import {AlertController, ModalController} from '@ionic/angular';
import {DataService} from '../../services/data.service';
import {AuthService, ViewService as View, ViewService} from 'common-lib';
import {Platform} from '../../classes/platform';
import Company from '../../classes/company';
import {TwilioService} from '../../services/twilio.service';
import {Admin} from '../../classes/admin';
import {ApiService} from '../../services/api.service';
import {Ticket} from '../../classes/ticket';
import {Contact} from '../../classes/contact';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-new-call',
  templateUrl: './new-call.page.html',
  styleUrls: ['./new-call.page.scss'],
})
export class NewCallPage implements OnInit {
  
  @Input() contact: Contact;
  @Input() ticket: Ticket;
  
  selectedPlatform: Platform;
  
  DataService = DataService;
  
  validNumberFromList: any[];
  callFromType: undefined;
  selectedNumber: any; //è l'oggetto call_number_platform
  callTo: string = '+39';
  callPlatformType: undefined;
  
  phoneVerified: boolean = false;
  
  admins: Admin[] = [];
  admins_call_center: Admin[] = [];
  admins_call_center_flotte: Admin[] = [];
  
  
  call_assignment: any;
  is_phone_verified: boolean = false;
  
  phones: any[] = [];
  callForm: FormGroup;
  selectedContact: Contact;
  
  constructor(
    private modalController: ModalController,
    public data: DataService,
    public view: ViewService,
    private alertCtrl: AlertController,
    private twilio: TwilioService,
    private api: ApiService
  ) {
  }
  
  async ngOnInit() {
  
    this.callForm = new FormGroup({
      platform: new FormControl(1, {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(1)]
      }),
      type: new FormControl("", {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(1)]
      }),
      phone_to: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(2)]
      }),
      contact_id: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.minLength(1)]
      }),
      ticket_id: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.minLength(1)]
      }),
    });
    await this.definePhoneTo()
    this.setDefaultParams();
  
    DataService.isReady().then(() => {
      this.selectPlatform(1);
      this.selectCallFrom(1);
    });
  }
  
  ionViewDidEnter() {
    DataService.array.admin.map((elem: Admin) => {
      if (!elem.call_center) {
        this.admins.push(elem);
      } else {
        switch(elem.platform_type){
          case 1:
            this.admins_call_center.push(elem);
            break;
          case 2:
            this.admins_call_center_flotte.push(elem);
            break;
        }
      }
    });
  }
  
  /**
   * imposto i parametri di default
   */
  setDefaultParams(){
    if(this.contact && !this.ticket){
      this.callForm.get('contact_id').setValue(this.contact._id)
      this.callForm.get('phone_to').setValue(this.contact.$list_phones[0]); //imposto il numero di telefono di default
    }else{
      this.callForm.get('ticket_id').setValue(this.ticket._id)
      if(this.ticket.$contacts.length === 1){
        
        this.callForm.get('type').setValue(this.ticket.type)
        this.callForm.get('contact_id').setValue(this.ticket.$contacts[0]._id)
        this.callForm.get('phone_to').setValue(this.ticket.$contacts[0].$list_phones[0]); //imposto il numero di telefono di default
      }
    }
  }
  
  async dismiss() {
    await this.modalController.dismiss();
  }
  
  /**
   * recupero il numero di telefono in base hai paremtri di input:
   */
  async definePhoneTo(){
    if(this.contact && !this.ticket){
      this.phones.push(this.contact.$list_phones[0]) //aggiungo il numero di telefono del cliente in lista
    }else{
      //qua ottengo la lista dei telefoni dei contatti legati al ticket
      for(let contact of this.ticket.$contacts){
        this.phones.push(contact.$list_phones[0])
      }
    }
  }
  
  async setPhone(event: any){
    if(!event){
      this.selectedContact = null;
      return;
    }
    //ottengo il contatto dato il numero
    if(this.contact && !this.ticket){
      this.selectedContact = this.contact
    }else{
      for(let contact of this.ticket.$contacts){
        if(event === contact.$list_phones[0]){
          this.selectedContact = contact
        }
      }
    }
  
  }
  
  /**
   * Seleziono la piattaforma dalla quale avviene la chiamata
   * @param value
   */
  selectPlatform(value: any) {
    if (value) {
      value = Number(value);
      this.callPlatformType = value;
      this.selectedPlatform = DataService.platforms.find(platform => platform.type === value);
      //ottengo i numeri di telefono dalla piattaforma selezionata
      this.validNumberFromList = this.selectedPlatform.call_number_platform;
    } else {
      this.selectedPlatform = undefined;
    }
    View.updateView.next();
  }
  
  /**
   * Metodo utilizzato per confermare il numero di telefono del destinatario della chiamaa
   * @param type
   */
  selectCallFrom(type: any) {
    if (type) {
      //in base al numero scelto imposto la call platform
      this.selectedNumber = this.validNumberFromList.find((elem) => {
        return elem.type === parseInt(type);
      });
      //imposto il numero di telefono del mittente (from)
      this.callFromType = this.selectedNumber?.type;
    } else {
      this.selectedPlatform = undefined;
      this.callFromType = undefined;
    }
    
  }
  
  /**
   * Metodo utilizzato per verificare il numero di telefono al quale effettuare la chiamata
   * Verifiichiamo se appartiane già ad una company e proponiamo le assegnazioni
   */
  async verifyNumber() {
    try {
      this.view.showLoading('Caricamento...');
      const result: any = await this.api.checkCallAssignment(this.callPlatformType, this.callTo.trim());
      if (result.redirects) {
        this.call_assignment = await this.api.checkCallAssignment(this.callPlatformType, this.callTo.trim());
        this.is_phone_verified = true;
      } else {
        this.view.hideLoading();
        await this.save();
      }
    } catch (err) {
      console.error(err);
    }
    this.view.hideLoading();
    this.phoneVerified = true;
  }
  
  /**
   * Metodo utilizzato per confermare le assegnazioni e effettuare la chiamata
   */
  async save() {
    await this.view.showLoading('Creazione chiamata in corso...');
    //validation
    if (!this.callForm.valid) {
      const alert = await this.alertCtrl.create({
        buttons: ['Ok'],
        header: 'Errore',
        message: 'Non tutti i campi obbligatori son stati popolati'
      });
      
      await alert.present();
      await this.view.hideLoading();
    }
  
    this.selectedPlatform = DataService.platforms.find(platform => platform.type === parseInt(this.callForm.get('platform').value));
    //ottengo i numeri di telefono dalla piattaforma selezionata
    this.validNumberFromList = this.selectedPlatform.call_number_platform;
    
    try {
      await this.twilio.makeCall(this.validNumberFromList[0].phone_owner, this.callForm.get('phone_to').value, this.callForm.get('type').value, parseInt(this.callForm.get('platform').value));
      await this.view.hideLoading();
      return this.dismiss();
    } catch (err) {
      console.error(err);
      this.view.presentAlert('Errore', 'Impossibile chiamare', ['Ok']);
    }
    await this.view.hideLoading();
    
  }
  
  /**
   * Metodo utilizzato per cambiare l'admin per gestire l'assegnazione della sub chat
   * @param event
   * @param call_center
   */
  changeRedirectAdmin(event: any, call_center: number) {
    this.call_assignment.redirects[call_center].call_redirect_admin = event._id;
  }
  
  /**
   * Metodo utilizzato per cambiare la gestione del bot per l'admin
   * @param call_center
   */
  changeBotManagedAdmin(call_center: number) {
    this.call_assignment.redirects[call_center].bot_managed = !this.call_assignment.redirects[call_center].bot_managed;
    
  }
  
  checkProcessVerify() {
    if (this.is_phone_verified) {
      this.phoneVerified = false;
      this.is_phone_verified = false;
      this.call_assignment = [];
    }
  }
  
  
  getPlatformTypeSelected(){
    const platform = this.callForm.get('platform').value;
    switch (parseInt(platform)) {
      case 1:
        return 'Balin'
        break;
      case 2:
        return 'Flotta in Cloud'
        break;
    }
  }
  
  getPlatformTypeSelectedInt(){
    return parseInt(this.callForm.get('platform').value);
  }
  
}
