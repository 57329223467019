import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from "@ionic/angular";
import {PopoverSelectComponent} from "./popover-select/popover-select.component";
import { PopoverListComponent } from './popover-list/popover-list.component';
import { TopMenuComponent } from './top-menu/top-menu.component';
import { TableComponent } from './table/table.component';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { CommonLibModule } from 'common-lib';
import {PipesModule} from "../pipes/pipes.module";
import {ActivityComponent} from './activity/activity.component';
import {SendMultipleComunicationComponent} from './send-multiple-comunication/send-multiple-comunication.component';
import {NgJsonEditorModule} from 'ang-jsoneditor';
import {AdvFinalizedDetailComponent} from './adv-finalized-detail/adv-finalized-detail.component';
import {AdvVisitorDetailComponent} from './adv-visitor-detail/adv-visitor-detail.component';
import {OrderActionComponent} from './order-action/order-action.component';
import {InvoiceCostDetailsComponent} from './invoice-cost-details/invoice-cost-details.component';
import {OrganizationCardComponent} from './organization-card/organization-card.component';
import {ImeiOrderComponent} from './imei-order/imei-order.component';
import {ShowJsonComponent} from './show-json/show-json.component';
import {ShippingInfoComponent} from './shipping-info/shipping-info.component';
import {FieldComponent} from './field/field.component';
import {SelectFotaFilesComponent} from './select-fota-files/select-fota-files.component';
import {TwilioCallStatusComponent} from './twilio-call-status/twilio-call-status.component';
import {TwilioCallsDetailsComponent} from './twilio-calls-details/twilio-calls-details.component';
import {CallComponent} from './call/call.component';
import {PopoverAudioSettingComponent} from './popover-audio-setting/popover-audio-setting.component';
import {CallRedirectComponent} from './call-redirect/call-redirect.component';
import {CallInfoComponent} from './call-info/call-info.component';
import {SelectAdminComponent} from './select-admin/select-admin.component';
import {ContactCardComponent} from './contact-card/contact-card.component';
import {TicketCardComponent} from './ticket-card/ticket-card.component';
import {TicketListElmentComponent} from './ticket-list-elment/ticket-list-elment.component';
import {ContactListElementComponent} from './contact-list-element/contact-list-element.component';
import {CompanyBodyComponent} from './company-body/company-body.component';
import {OrganizationElementComponent} from './organization-element/organization-element.component';
import {TrackerElementComponent} from './tracker-element/tracker-element.component';
import {TrackerReplaceComponent} from './tracker-replace/tracker-replace.component';
import {TicketDetailsComponent} from './ticket-details/ticket-details.component';
import {MessageItemComponent} from './message-item/message-item.component';
import {EmailComponent} from './email/email.component';

@NgModule({
  declarations: [
    // component
    TableComponent,
    TopMenuComponent,
    
    // popover
    PopoverListComponent,
    PopoverSelectComponent,
    ActivityComponent,
    SendMultipleComunicationComponent,
    AdvFinalizedDetailComponent,
    AdvVisitorDetailComponent,
    OrderActionComponent,
    InvoiceCostDetailsComponent,
    OrganizationCardComponent,
    ImeiOrderComponent,
    ShowJsonComponent,
    ShippingInfoComponent,
    FieldComponent,
    SelectFotaFilesComponent,
    TwilioCallStatusComponent,
    TwilioCallsDetailsComponent,
    PopoverAudioSettingComponent,
    CallComponent,
    CallRedirectComponent,
    CallInfoComponent,
    SelectAdminComponent,
    ContactCardComponent,
    TicketCardComponent,
    TicketListElmentComponent,
    ContactListElementComponent,
    CompanyBodyComponent,
    OrganizationElementComponent,
    TrackerElementComponent,
    TrackerReplaceComponent,
    TicketDetailsComponent,
    MessageItemComponent,
    EmailComponent
  ],
  entryComponents: [
    // popover
    PopoverListComponent,
    PopoverSelectComponent,
    ActivityComponent,
    SendMultipleComunicationComponent,
    AdvFinalizedDetailComponent,
    AdvVisitorDetailComponent,
    OrderActionComponent,
    InvoiceCostDetailsComponent,
    OrganizationCardComponent,
    ImeiOrderComponent,
    ShowJsonComponent,
    ShippingInfoComponent,
    FieldComponent,
    SelectFotaFilesComponent,
    TwilioCallStatusComponent,
    TwilioCallsDetailsComponent,
    PopoverAudioSettingComponent,
    CallComponent,
    CallRedirectComponent,
    CallInfoComponent,
    SelectAdminComponent,
    ContactCardComponent,
    TicketCardComponent,
    TicketListElmentComponent,
    ContactListElementComponent,
    CompanyBodyComponent,
    OrganizationElementComponent,
    TrackerElementComponent,
    TrackerReplaceComponent,
    TicketDetailsComponent,
    MessageItemComponent,
    EmailComponent
  ],
  imports: [
    CommonModule,
    CommonLibModule,
    FormsModule,
    IonicModule,
    // BrowserModule,
    VirtualScrollerModule,
    PipesModule,
    NgJsonEditorModule,
    ReactiveFormsModule,
    CommonModule,
    CommonModule
  ],
  exports: [
    // component
    TableComponent,
    TopMenuComponent,
    ActivityComponent,
    SendMultipleComunicationComponent,
    AdvFinalizedDetailComponent,
    AdvVisitorDetailComponent,
    OrderActionComponent,
    InvoiceCostDetailsComponent,
    OrganizationCardComponent,
    ImeiOrderComponent,
    ShowJsonComponent,
    ShippingInfoComponent,
    FieldComponent,
    SelectFotaFilesComponent,
    TwilioCallStatusComponent,
    TwilioCallsDetailsComponent,
    PopoverAudioSettingComponent,
    CallComponent,
    CallRedirectComponent,
    CallInfoComponent,
    SelectAdminComponent,
    ContactCardComponent,
    TicketCardComponent,
    TicketListElmentComponent,
    ContactListElementComponent,
    CompanyBodyComponent,
    OrganizationElementComponent,
    TrackerElementComponent,
    TrackerReplaceComponent,
    TicketDetailsComponent,
    MessageItemComponent,
    EmailComponent
  ],
})

export class ComponentsModule {}
