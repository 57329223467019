import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, Input,
  OnInit
} from '@angular/core';
import {
  ActivatedRoute,
} from "@angular/router";
import {
  AuthService, Utility,
  ViewService as View,
  ViewService
} from "common-lib";
import {ApiService} from "../../services/api.service";
import {FotaTask, TrackerAdmin} from '../../classes/trackerAdmin';
import {ParticipantAdmin} from "../../classes/participantAdmin";
import {PopoverSelectComponent} from "../../components/popover-select/popover-select.component";
import {ListParsers} from "../../classes/listParsers";
import {AlertController, ModalController, PopoverController, ToastController} from '@ionic/angular';
import {FormattaPage} from "../formatta/formatta.page";
import {SostituisciPage} from "../sostituisci/sostituisci.page";
import {DetailPage} from "../detail/detail.page";
import {Command} from "../../classes/command";
import {SendCommandPage} from "../send-command/send-command.page";
import {PositionGpsAdmin} from "../../classes/positionGpsAdmin";
import {DatePipe} from "@angular/common";
import {DataService} from "../../services/data.service";
import TwilioSim from 'src/classes/twilioSim';
import { TwilioSimStatusSelectPage } from '../twilio-sim-status-select/twilio-sim-status-select.page';
import TwilioCommand from 'src/classes/twilioCommand';
import SimCommand from "../../classes/simCommand";
import Activity from '../../classes/activity';
import {ActivityAddPage} from '../activity-add/activity-add.page';
import * as lodash from 'lodash';
import {Order} from '../../classes/order';
import {PopoverListComponent} from '../../components/popover-list/popover-list.component';
import {OrganizationAdmin} from '../../classes/organizationAdmin';
import {FotaService} from '../../services/fota.service';
import {SelectFotaFilesComponent} from '../../components/select-fota-files/select-fota-files.component';
import OnomondoSim from '../../classes/onomondoSim';
import OnomondoCommand from '../../classes/onomondoCommand';
import OnceSim, {OnceSimQuotaData, OnceSimQuotaSms} from '../../classes/onceSim';
import OnceCommand from '../../classes/onceCommand';
import {ThingsmobileSim} from '../../classes/thingsmobileSim';
import {SmsToPhonePage} from '../sms-to-phone/sms-to-phone.page';
import {SmsToTracker} from '../../classes/smsToTracker';
declare let $: any;

@Component({
  selector: 'app-tracker',
  templateUrl: './tracker.page.html',
  styleUrls: ['./tracker.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe]
})
export class TrackerPage implements OnInit {
  
  @Input() id: string;
  @Input() imei: string;

  // listener
  listener = {
    view: null,
  };
  
  fields: string[] = ['azioni', 'id_connection', 'timestamp_server', 'timestamp_position', 'position_type', 'avl_id', 'value', 'lat', 'long', 'angle', 'altitude', 'satellites', 'speed', 'gsm_signal', 'gsm_operator', 'gsm_cell_id', 'gsm_area_code', 'external_voltage', 'battery_voltage', 'total_odometer', 'command_response'];
  
  @Input() tracker: TrackerAdmin;
  organization: OrganizationAdmin;
  participant: ParticipantAdmin;
  newNote: string;

  positionsLimit: number = 1000;
  positionsDate: string = Utility.toDateString(new Date());
  positionsTable: any;
  positions: PositionGpsAdmin[] = [];
  commandsMap: any = {};
  haveDate: boolean = false;
  
  // * sim Twilio
  twilioSim: TwilioSim;
  twilioSimCommandKeys = [
    "command",
    "dateCreated",
    "direction",
    "status",
  ];
  twilioSimCommands: TwilioCommand[] = [];
  twilioSimCommands_apiLimitInitialValue = 2;
  twilioSimCommands_apiLimit = this.twilioSimCommands_apiLimitInitialValue;
  
  
  /** Sim onomondo **/
  onomondoSim: OnomondoSim;
  onomondoSimCommands: OnomondoCommand[] = [];
  onceSim: OnceSim;
  onceSimCommands: OnceCommand[] = [];
  onomondoSimCommandKeys = [
    "text",
    "timestamp_create",
    "direction",
  ];
  onceSimCommandKeys = [
    "payload",
    "delivery_date",
    "status",
  ];
  
  thingsmobileSim: ThingsmobileSim;
  sim_commands: SmsToTracker[] = [];
  
  loading_activities: boolean;
  activities: Activity[] = [];
  filtroAttivo: string = 'tutti';
  filtri: any = [
    {
      id: 'tutti',
      lbl: 'Tutte',
      selected: this.filtroAttivo === 'tutti'
    },
    {
      id: 'da_fare',
      lbl: 'Da fare',
      selected: this.filtroAttivo === 'da_fare'
    },
    {
      id: 'fatti',
      lbl: 'Fatti',
      selected: this.filtroAttivo === 'fatti'
    }
  ];
  
  orders: Order[];
  
  loading: boolean = true;
  loading_sim: boolean = true;
  loading_once_usage: boolean;
  loading_sim_commands: boolean = true;
  
  show_sim_history: boolean = false;
  show_status_history: boolean = false;
  show_nota: boolean = false;
  
  loading_fota: boolean = false;
  fota_tasks: FotaTask[];
  fota_device_info: any;
  
  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    public view: ViewService,
    private ref: ChangeDetectorRef,
    private modalController: ModalController,
    private auth: AuthService,
    private datePipe: DatePipe,
    public data: DataService,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    private popoverController: PopoverController,
    private fota: FotaService
  ) {
    this.listenerView();
  }

  ionViewDidEnter() {
    if(!this.id && !this.imei){
      this.route.queryParams.subscribe(async (params: any) => {
        if (params && (params.id || params.imei)) {
          DataService.isReady().then(() => {
            this.getData(params.id, params.imei);
          });
        }
      });
    }else{
      DataService.isReady().then(() => {
        this.getData(this.id, this.imei);
      });
    }
  }

  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }

  ngOnInit() {}

  // Recupera i dati relativi al tracker
  async getData(id: string, imei?: string){
    try{
      this.loading = true;
      View.updateView.next();
      let raw: any;
      if(id){
        raw = await this.api.getTracker(id);
      }else{
        raw = await this.api.getTrackerByImei(imei);
      }
      if(raw){
        if(raw.trackerGps){
          if(!this.tracker){
            this.tracker = new TrackerAdmin(raw.trackerGps, {
              getPlatform: this.data.getPlatformByType,
              getTypeTracker: this.data.getTypeTrackerGps,
              getTypeSim: this.data.getTypeSim
            });
          }else{
            this.tracker.setData(raw.trackerGps);
          }
          switch (this.tracker.type_sim){
            case 1:
              break;
            case 2:
              this.getSimCommands();
              break;
            case 3:
              await this.getTwilioSim(this.tracker);
              this.getTwilioCommandMultiple();
              break;
            case 4:
              await this.getOnomondoSim(this.tracker)
              this.getOnomondoCommandMultiple();
              break;
            case 5:
              await this.getOnceSim(this.tracker)
              // this.getOnomondoCommandMultiple();
              break;
          }
        }
        if(raw.organization){
          this.organization = new OrganizationAdmin(raw.organization);
        }else{
          this.organization = undefined;
        }
        if(raw.partecipant){
          this.participant = new ParticipantAdmin(raw.partecipant);
        }else{
          this.participant = undefined;
        }
        if(raw.commandTrackerGps){
          this.commandsMap = {};
          for(let command of raw.commandTrackerGps){
            this.commandsMap[command._id] = new Command(command);
          }
        }
      }
      this.getFotaInfo();
      setTimeout(() => {
        if(!this.positionsTable){
          this.positionsTable = $('#table_positions').DataTable({
            "scrollX": true,
            "pageLength": 100,
            "order": [[ 3, "desc" ]],
            columnDefs: [
              {
                targets: 2,
                type: 'date-ddmmyyyy-hhmmss'
              },
              {
                targets: 3,
                type: 'date-ddmmyyyy-hhmmss'
              }
            ]
          });
        }
        this.getActivities();
        this.getLastPositions();
      }, 1000);
    }catch(err){
      console.error(err);
      this.view.presentAlert('Errore', 'Impossibile recuperare il tracker', ['Ok']);
    }
    this.loading = false;
    ViewService.updateView.next();
  }
  
  async getActivities(){
    this.activities = [];
    this.loading_activities = true;
    const res: any = await this.api.getActivity(1000, 0, undefined, undefined, undefined, 1, this.tracker.imei);
    if(res?.data){
      for(let raw of res.data){
        this.activities.push(new Activity(raw, {
          getActivity: this.data.getTypeActivity,
          getCompany: this.data.getCompanyById,
          getAdmin: this.data.getAdminById,
          getParticipantByImei: this.data.getParticipantByImei,
          getTicketById: this.data.getTicketById
        }));
      }
    }
    this.loading_activities = false;
    ViewService.updateView.next();
  }

  // Recupera le ultime posizioni
  getLastPositions(){
    let timestamp: number = Date.now();
    if(this.haveDate) timestamp = (new Date(this.positionsDate)).getTime();
    this.api.getPositions(this.tracker.imei, 0, this.positionsLimit, timestamp).then((data: any) => {
      if(data.data){
        if(this.positions){
          this.positions = [];
          $('#table_positions tbody').off( "click", "button" );
          this.positionsTable.clear();
        }
        for(let raw of data.data){
          const position: PositionGpsAdmin = new PositionGpsAdmin(raw);
          this.positionsTable.row.add([
            "<button sclass='btn-edit' data-action='show' data-id='"+position._id+"'>Vedi</button>",
            position.id_connection || null,
            position.timestamp_server ? this.datePipe.transform(position.timestamp_server, 'dd/MM/yyyy - HH:mm:ss') : '',
            position.timestamp_position ? this.datePipe.transform(position.timestamp_position, 'dd/MM/yyyy - HH:mm:ss') : '',
            position.position_type ? position.position_type + ' - ' + ListParsers.parsePositionType(position.position_type) : null,
            position.avl_id !== undefined ? (position.avl_id + ' - ' + ListParsers.parseAvlId(position.avl_id)) : '',
            (position.avl_id !== undefined && position.value !== undefined) ? (position.value + ' - ' + ListParsers.parseAvlValue(position.avl_id, position.value)) : '',
            position.position ? position.position.coordinates[1] : '',
            position.position ? position.position.coordinates[0] : '',
            typeof position.angle === "number" ? position.angle : null,
            typeof position.altitude === "number" ? position.altitude : null,
            typeof position.satellites === "number" ? position.satellites : null,
            typeof position.speed === "number" ? position.speed : null,
            typeof position.gsm_signal === "number" ? position.gsm_signal : null,
            typeof position.gsm_operator === "number" ? position.gsm_operator : null,
            typeof position.gsm_cell_id === "number" ? position.gsm_cell_id : null,
            typeof position.gsm_area_code === "number" ? position.gsm_area_code : null,
            typeof position.external_voltage === "number" ? position.external_voltage : null,
            typeof position.battery_voltage === "number" ? position.battery_voltage : null,
            typeof position.total_odometer === "number" ? position.total_odometer : null,
            position.command_response || null
          ]);
          this.positions.push(position);
        }
      }
      this.positionsTable.draw();
      $('#table_positions tbody').on( 'click', 'button', (event) => {
        this.parseAction(event.target.getAttribute('data-action'), event.target.getAttribute('data-id'));
      });
      ViewService.updateView.next();
    }).catch((err: any) => {
      console.error(err);
    });
  }

  async parseAction(action: string, id: string){
    switch (action) {
      case 'show':
        const position: PositionGpsAdmin = this.positions.find((elem) => {
          return elem._id === id
        });
        if(position){
          const modal = await this.modalController.create({
            component: DetailPage,
            componentProps: {
              json: position.raw,
              title: 'Posizione'
            }
          });
          return await modal.present();
        }
    }
  }

  async changeMagazzino(ev: any){
    const componentProps: any = {
      options: ListParsers.getStoreStates(),
      message: `Cambio da <i>Magazzino Wi-tek</i> possibile <b>solo</b> se il tracker è <b>programmato</b> e ha un <b>iccid_sim</b>`
    };
    let popover: any = await this.view.presentPopover(ev, PopoverSelectComponent, componentProps);
    popover.onDidDismiss().then(async (data: any) => {
      if(data && data.data){
        if(data.data.id !== 1 && this.tracker.magazzino_trackerGps === 1 && (!this.tracker.isProgrammed || !this.tracker.iccid_sim)){
          return this.view.presentAlert('Attenzione', `Per cambiare da <i>Magazzino Wi-tek</i>, il tracker deve essere <b>programmato</b> e avere un <b>iccid_sim</b>`, ['Ok']);
        }
        await this.view.showLoading('Attendere...');
        this.api.patchTrackerMagazzino(this.tracker._id, data.data.id).then((data: any) => {
          this.tracker.setData(data);
          this.view.hideLoading();
          ViewService.updateView.next();
        }).catch((err: any) => {
          console.error(err);
          this.view.hideLoading();
          this.view.presentAlert('Errore', 'Riprova più tardi', ['Ok']);
        });
      }
    });
  }

  async changeSimStatus(ev: any){
    const componentProps: any = {
      options: ListParsers.getSimStates(),
      message: `Una SIM disattiva non è più attivabile, il dispositivo andrà formattato e la SIM fisicamente sostituita nel localizzatore. <b>Su questo portale la SIM non si attiva/disattiva realmente ma l'azione deve essere effettuante anche nel portale del gestore telefonico</b>`
    };
    let popover: any = await this.view.presentPopover(ev, PopoverSelectComponent, componentProps);
    popover.onDidDismiss().then(async (data: any) => {
      if(data && data.data){
        await this.view.showLoading('Attendere...');
        this.api.patchTrackerSimStatus(this.tracker._id, data.data.id).then((data: any) => {
          this.tracker.setData(data);
          this.view.hideLoading();
          ViewService.updateView.next();
        }).catch((err: any) => {
          console.error(err);
          this.view.hideLoading();
          this.view.presentAlert('Errore', 'Riprova più tardi', ['Ok']);
        });
      }
    });
  }

  changeIsActive(){
    let message: string = 'Si andrà a attivare il partecipant riattivando l\'abbonamneto, <b>ricordarsi di riabilitare la SIM</b> sia dal questo portale che dal portale del gestore telefonico. <br> Sei sicuro?';
    if(this.participant.isActive){
      message = 'Si andrà a disattivare il partecipant disabilitando l\'abbonamneto, <b>ricordarsi di disabilitare la SIM</b> sia dal questo portale che dal portale del gestore telefonico. <br> Sei sicuro?';
    }
    let buttons: any[] = [
      {
        text: 'Sì, conferma',
        handler: async () => {
          try{
            await this.view.showLoading('Attendere...');
            const data: any = await this.api.patchParticipant(this.participant._id, {isActive: !this.participant.isActive});
            this.participant.setData(data);
            ViewService.updateView.next();
          }catch(err){
            console.error(err);
            this.view.presentAlert('Errore', 'Riprova più tardi', ['Ok']);
          }
          this.view.hideLoading();
        }
      }, {
        text: 'No, annulla',
        role: 'cancel'
      }
    ];
    this.view.presentAlert('Conferma', message, buttons);
  }

  async addNote(){
    if(!this.newNote){
      return this.view.presentAlert('Attenzione', 'Devi inserire il testo della nota', ['Ok']);
    }
    try{
      this.view.showLoading('Inserimento...');
      let note: any[] = [{note: this.newNote, timestamp: new Date().getTime()}];
      if(this.tracker.note) note = note.concat(this.tracker.note);
      const data: any = await this.api.patchTracker(this.tracker._id, note);
      this.tracker.setData(data);
      this.newNote = undefined;
    }catch(err){
      console.error(err);
      this.view.presentAlert('Errore', 'Riprova più tardi', ['Ok']);
    }
    this.view.hideLoading();
    ViewService.updateView.next();
  }

  async deleteNote(index: number) {
    if (index >= this.tracker.note.length) return;
    let message: string = `Vuoi eliminare la nota: <br><br> <i>` + this.tracker.note[index].note + `</i>`;
    let buttons: any[] = [
      {
        text: 'Sì, conferma',
        handler: async () => {
          try {
            await this.view.showLoading('Attendere...');
            let note: any[] = [];
            note = note.concat(this.tracker.note);
            note.splice(index, 1);
            const data: any = await this.api.patchTracker(this.tracker._id, note);
            this.tracker.setData(data);
            ViewService.updateView.next();
          } catch (err) {
            console.error(err);
            this.view.presentAlert('Errore', 'Riprova più tardi', ['Ok']);
          }
          this.view.hideLoading();
        }
      }, {
        text: 'No, annulla',
        role: 'cancel'
      }
    ];
    this.view.presentAlert('Conferma', message, buttons);
  }

  // Formatta il localizzatore
  async initialize(){
    const modal = await this.modalController.create({
      component: FormattaPage,
      componentProps: {
        tracker: this.tracker
      }
    });
    modal.onDidDismiss().then((data: any) => {
      if(data && data.data && data.data.formatted){
        this.getData(this.tracker._id);
      }
    });
    return await modal.present();
  }

  // Sostituisce il localizzatore
  async sostituisci(){
    const modal = await this.modalController.create({
      component: SostituisciPage,
      componentProps: {
        tracker: this.tracker
      }
    });
    modal.onDidDismiss().then((data: any) => {
      if(data && data.data && data.data.id){
        this.getData(data.data.id);
      }
    });
    return await modal.present();
  }

  // Distrugge il localizzatore, ovverlo disassocia al partecipant
  deactivate(){
    let message: string = `<small>La procedura di disattivazione:<ul>`;
    message += `<li>slega il partecipante dal tracker, elimina definitivamente gli allarmi, i dati GPS e tutti i dati relativi all'associazione partecipante-tracker</li>`;
    message += `<li>Inserisce il partecipante disassociato nello storico associazioni del tracker</li>`;
    message += `<li>Non cambia lo stato della SIM, quindi ricordarsi di settare lo stato della SIM come disattivata;</li>`;
    message += `<li>Imposta statu_trackerGps = 2, ovvero distrutto;</li></ul></small>`;
    let buttons: any[] = [
      {
        text: 'Sì, disattiva',
        handler: async () => {
          try {
            await this.view.showLoading('Disattivazione in corso...');
            const data: any = await this.api.patchTrackerDistruggi(this.tracker._id);
            this.tracker.setData(data);
            this.getData(this.tracker._id);
            ViewService.updateView.next();
          } catch (err) {
            console.error(err);
            this.view.presentAlert('Errore', 'Riprova più tardi', ['Ok']);
          }
          this.view.hideLoading();
        }
      }, {
        text: 'No, annulla',
        role: 'cancel'
      }
    ];
    this.view.presentAlert('Conferma', message, buttons);
  }
  
  async returnTracker(){
    let message: string = `<small>Devi impostare questo stato se il cliente ha specificato che intende <b>rendere il tracker</b></small>`;
    let buttons: any[] = [
      {
        text: 'Sì, confermo',
        handler: async () => {
          try {
            await this.view.showLoading('Impostazione stato in corso in corso...');
            const data: any = await this.api.patchTrackerStatus(this.tracker._id, 3);
            this.tracker.setData(data);
            this.getData(this.tracker._id);
            ViewService.updateView.next();
          } catch (err) {
            console.error(err);
            this.view.presentAlert('Errore', 'Riprova più tardi', ['Ok']);
          }
          this.view.hideLoading();
        }
      }, {
        text: 'No, annulla',
        role: 'cancel'
      }
    ];
    this.view.presentAlert('Sei sicuro?', message, buttons);
  }
  
  async returnedTracker(){
    let message: string = `<small>Devi impostare questo stato se <b>il tracker è stato reso da un utente ed è di nuovo in nostro possesso</b></small>`;
    let buttons: any[] = [
      {
        text: 'Sì, confermo',
        handler: async () => {
          try {
            await this.view.showLoading('Impostazione stato in corso in corso...');
            const data: any = await this.api.patchTrackerStatus(this.tracker._id, 4);
            this.tracker.setData(data);
            this.getData(this.tracker._id);
            ViewService.updateView.next();
          } catch (err) {
            console.error(err);
            this.view.presentAlert('Errore', 'Riprova più tardi', ['Ok']);
          }
          this.view.hideLoading();
        }
      }, {
        text: 'No, annulla',
        role: 'cancel'
      }
    ];
    this.view.presentAlert('Sei sicuro?', message, buttons);
  }
  
  async reprogrammingTracker(){
    let message: string = `<small>Devi impostare questo stato se <b>il tracker è in nostro possesso e deve essere riprogrammato</b>. Sarà poi visibile direttamente nella pagina di riprogrammazione.</small>`;
    let buttons: any[] = [
      {
        text: 'Sì, confermo',
        handler: async () => {
          try {
            await this.view.showLoading('Impostazione stato in corso in corso...');
            const data: any = await this.api.patchTrackerStatus(this.tracker._id, 5);
            this.tracker.setData(data);
            this.getData(this.tracker._id);
            ViewService.updateView.next();
          } catch (err) {
            console.error(err);
            this.view.presentAlert('Errore', 'Riprova più tardi', ['Ok']);
          }
          this.view.hideLoading();
        }
      }, {
        text: 'No, annulla',
        role: 'cancel'
      }
    ];
    this.view.presentAlert('Sei sicuro?', message, buttons);
  }

  // Visualizza l'oggetto json
  async showCommandJson(command: Command){
    if(command) {
      if(!command.status_commandTrackerGps){
        await this.view.showLoading('Scaricamento comando...');
        try{
          const res: any = await this.api.getCommand(command._id);
          if(res) command.setData(res);
        }catch(err){
          console.error(err);
        }
        await this.view.hideLoading();
      }
      const modal = await this.modalController.create({
        component: DetailPage,
        componentProps: {
          json: command.raw,
          title: 'Command'
        }
      });
      return await modal.present();
    }
  }

  // Invia un messaggio
  async sendSingleCommand(){
    const modal = await this.modalController.create({
      component: SendCommandPage,
      componentProps: {
        pageType: 'single_command',
        imei: this.tracker.imei
      }
    });
    modal.onDidDismiss().then((data: any) => {
      if(data && data.data && data.sent){
        this.getData(this.tracker._id);
      }
    });
    return await modal.present();
  }

  // Apre invio configurazione
  async sendConfiguration(){
    const modal = await this.modalController.create({
      component: SendCommandPage,
      componentProps: {
        pageType: 'configuration',
        tracker: this.tracker
      }
    });
    modal.onDidDismiss().then((data: any) => {
      if(data && data.data && data.sent){
        this.getData(this.tracker._id);
      }
    });
    return await modal.present();
  }

  // listener
  listenerView() {
    this.listener.view = View.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.ref.markForCheck();
    });
  }
  
  
  /** SIM TWILIO */
  
  async getTwilioCommandMultiple() {
    if (!this.twilioSim) {
      return;
    }
    this.loading_sim_commands = true;
    View.updateView.next();
    try {
      let res: any = await this.api.getTwilioCommandMultiple({
        iccid: this.twilioSim.iccid,
        limit: this.twilioSimCommands_apiLimit ?? 
          this.twilioSimCommands_apiLimitInitialValue,
        skip: this.twilioSimCommands.length,
      });
      if (res?.length > 0) {
        this.twilioSimCommands = []; // no limit & skip
        this.twilioSimCommands.push(
          ...res.map(el => 
            new TwilioCommand(el)
          )
        );
      }
    } catch (e) {
      console.error(`e`, e)
      this.alert({header: "Errore API", msg: "E' avvenuto un errore " + " durante il caricamento dei comandi della sim Twilio"});
    }
    this.loading_sim_commands = false;
    View.updateView.next()
  }

  async getTwilioSim(tracker: TrackerAdmin) {
    if (tracker.type_sim !== 3 || !tracker.iccid_sim) {
      return;
    }
    this.loading_sim = true;
    View.updateView.next();
    try {
      this.twilioSim = new TwilioSim(
        await this.api.getSimTwilio(tracker.iccid_sim)
      );
    } catch (err) {
      console.error(err)
      this.alert({
        header: "Errore API",
        msg: "E' avvenuto un errore" + 
        " durante il caricamento della sim Twilio"
      })
    }
    this.loading_sim = false;
    View.updateView.next();
  }
  
  async patchTwilioSim(twilioSim: TwilioSim, patchBody: any) {
    await this.view.hideLoading()
    await this.view.showLoading("updating sim Twilio")
    
    try {
      this.twilioSim = new TwilioSim(
        await this.api.patchSimTwilio(
          twilioSim.iccid, patchBody)
      );
      console.log(`patchTwilioSim() this.twilioSim`, this.twilioSim)
    } catch (e) {
      console.error(`e`, e)
      this.alert({
        header: "Errore API",
        msg: "E' avvenuto un errore durante l'update" + 
        " della sim Twilio"
      })
    }
    
    await this.view.hideLoading()
    View.updateView.next()
  }
  
  async postTwilioCommand(command: string) {
    // console.log(`postTwilioCommand()`, )
    // if (!this.twilioSim) {
    //   console.log(`return: !this.twilioSim`, )
    //   return;
    // }
    await this.view.hideLoading()
    await this.view.showLoading("invio nuovo comando a sim Twilio")
    
    try {
      let res: any = await this.api.postTwilioCommand(
        this.twilioSim.iccid,
        {
          command: command,
        },
      );
      if (res) {
        this.twilioSimCommands.unshift(new TwilioCommand(res));
      }
      console.log(`postTwilioCommand() res`, res)
      // console.log(`getTwilioCommandMultiple() this.twilioSimCommands`, 
      //   this.twilioSimCommands)
    } catch (e) {
      console.error(`e`, e)
      this.alert({
        header: "Errore API",
        msg: "E' avvenuto un errore durante l'invio del comando" + 
        " alla sim Twilio"
      })
    }
    
    await this.view.hideLoading()
    View.updateView.next()
  }
  
  /** THINKS MOBILE SIM */
  
  async alertThinksMobileSendCommand() {
    const alert: any = await this.alertCtrl.create({
      buttons: [
        {
          text: 'Invia',
          handler: (data) => {
            if (data?.command) {
              this.sendThinksMobileCommand(data.command);
            }
          },
        },
        {
          text: 'Annulla',
          role: 'cancel',
        },
      ],
      header: "Invia comando SIM thinks mobile",
      inputs: [
        {
          name: 'command',
          type: 'text',
          placeholder: 'comando',
        },
      ],
    });
    await alert.present();
    await alert.onDidDismiss();
  }
  
  async sendThinksMobileCommand(command: string) {
    await this.view.hideLoading();
    await this.view.showLoading("Invio comando a SIM thinks mobile");
    
    try {
      const res = await this.api.sendSimThinksMobileCommand(this.tracker.iccid_sim, command);
      if (res) {
        this.alert({
          header: "Successo",
          msg: "Il comando è stato inviato alla sim"
        });
      }
    } catch (err) {
      console.error(err);
      this.alert({
        header: "Errore",
        msg: "E' avvenuto un errore durante l'invio del comando alla SIM"
      });
    }
    
    await this.view.hideLoading();
  }
  
  
  /** ALTRE SIM NORMALI */
  
  async getSimCommands() {
    if(!this.tracker?.iccid_sim) return;
    this.loading_sim_commands = true;
    View.updateView.next();
    try {
      let res: any = await this.api.postRetrivePhonesSMS(undefined, this.tracker.iccid_sim);
      if(res){
        this.sim_commands = [];
        for(let cmd of res){
          this.sim_commands.push(new SmsToTracker(cmd, {getAdmin: this.data.getAdminById}));
        }
      }
    } catch (err) {
      console.error(err);
      this.alert({
        header: "Errore API",
        msg: "È avvenuto un errore durante il caricamento dei comandi della SIM"
      });
    }
    this.loading_sim_commands = false;
    View.updateView.next();
  }
  
  async alertSimSendCommand() {
    const modal = await this.modalController.create({
      component: SmsToPhonePage,
      componentProps: {
        isModal: true,
        iccid: this.tracker.iccid_sim
      }
    });
    return await modal.present();
  }
  
  async sendSimCommand(command: string) {
    await this.view.hideLoading();
    await this.view.showLoading("Invio comando tramite SIM");
    
    try {
      const res = await this.api.sendSimCommand(this.tracker.iccid_sim, command);
      if (res) {
        this.alert({
          header: "Successo",
          msg: "E'stato richiesto l'invio dell'sms con il comando"
        });
      }
    } catch (err) {
      console.error(err);
      this.alert({
        header: "Errore",
        msg: "E' avvenuto un errore durante l'invio del comando alla SIM"
      });
    }
    
    await this.view.hideLoading();
    this.getSimCommands();
  }
  
  async showSimCommandJson(cmd: SimCommand){
    const modal = await this.modalController.create({
      component: DetailPage,
      componentProps: {
        json: cmd.$raw,
        title: 'Command SIM'
      }
    });
    return await modal.present();
  }

  
  async alert({
    header,
    msg,
  }: {
    header?: string,
    msg: string,
  }) {
    const alert = await this.alertCtrl.create({
      buttons: ['Ok'],
      // cssClass: "",
      header: header ?? "Alert", 
      message: msg,
      // subHeader: "Subtitle",
    });

    await alert.present();

    const { data, role } = await alert.onDidDismiss();
    // console.log('alert.onDidDismiss data', data)
    // console.log('alert.onDidDismiss role', role)
  }
  
  async toast({
    duration,
    msg,
  }: {
    duration?: number,
    msg: string, 
  }) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: duration ?? 2000,
      // position: "middle",
    });
    toast.present();
  }
  
  // * test
  
  consoleLog(i) {
    console.log('i', i)
    // console.log('i', DataService.array.activity)
    // this.router.navigate(['phonebook-activities']);
  }
  
  emptyFunc() {
    console.log(`emptyFunc()`, )
  }
  
  // * windowed content (alert, modal)
  
  async alertTwilioSimSendCommand() {
    const alert = await this.alertCtrl.create({
      buttons: [
        {
          text: 'Invia',
          handler: (data) => {
            console.log('handler data', data)
            if (data?.command) {
              this.postTwilioCommand(data.command)
            }
          },
        },
        {
          text: 'Annulla',
          role: 'cancel',
        },
      ],
      // cssClass: "",
      header: "Invia comando", 
      // message: msg,
      // subHeader: "Subtitle",
      inputs: [
        {
          name: 'command',
          type: 'text',
          placeholder: 'comando',
        },
      ],
    });

    await alert.present();

    const { data, role } = await alert.onDidDismiss();
    // console.log('alert.onDidDismiss data', data)
    // console.log('alert.onDidDismiss role', role)
  }
  
  async modalTwilioSimStatusSelect(currentStatus: string) {
    if (
      currentStatus === "scheduled" || 
      currentStatus === "updating"
    ) {
      this.alert({
        msg: 'Per poter cambiare lo stato bisogna aspettare che ' + 
        'lo stato della sim non sia più "scheduled"(pianificato) o "updating"',
      });
      return;
    }
    
    const selectOptions = [
      {
        lbl: "",
        val: "active",
      },
      {
        disabled: 
          currentStatus === "new" || 
          currentStatus === "ready" || 
          currentStatus === "deactivated"
        ,
        lbl: "",
        val: "suspended",
      },
      {
        disabled: 
          currentStatus === "new" || 
          currentStatus === "ready"
        ,
        lbl: "",
        val: "deactivated",
      },
    ];
    console.log(`currentStatus`, currentStatus)
    console.log(`selectOptions`, selectOptions)
    const selectedOption = selectOptions.find(el => el.val === currentStatus);
    
    const modal = await this.modalController.create({
      component: TwilioSimStatusSelectPage,
      cssClass: "modal_list",
      componentProps: {
        selectedOption: selectedOption,
        selectOptions: selectOptions,
      }
    });
    await modal.present();
    
    const { data } = await modal.onDidDismiss();
    console.log('onDidDismiss() data', data)
    if (data?.selItem) {
      this.patchTwilioSim(this.twilioSim, { status: data.selItem.val });
    }
  }
  
  // =================================
  
  
  // Viene lanciata quando cliccano su un filtro delle attività
  changeFilterActivities(event: any){
    this.filtroAttivo = event.id;
    this.view.pipeChanged++;
    ViewService.updateView.next();
  }
  
  // Apre le azioni possibile al click su una attività
  async activityClick(output: {event: any, activity: Activity}){
    if(!output || !output.event || !output.activity) return;
    output.event.preventDefault();
    const componentProps: any = {
      options: [
        {
          id: 'modify',
          name: 'Modifica attività'
        },
        {
          id: 'delete',
          name: 'Elimina attività'
        }
      ],
      type: 'no_id'
    };
    const popover = await this.popoverController.create({
      component: PopoverSelectComponent,
      event: event,
      translucent: true,
      componentProps: componentProps
    });
    popover.onDidDismiss().then((data: any) => {
      if(data?.data?.id){
        switch (data.data.id) {
          case 'modify':
            this.activityEdit(output.activity);
            break;
          case 'delete':
            this.deleteActivity(output.activity);
            break;
        }
      }
    });
    await popover.present();
  }
  
  // Apre la modifica di una activity
  async activityEdit(activity: Activity) {
    const modal = await this.modalController.create({
      component: ActivityAddPage,
      componentProps: {
        row: lodash.cloneDeep(activity)
      }
    });
    await modal.present();
    modal.onDidDismiss().then((data: any) => {
      if(data && data.data && data.data.recordAdded){
        this.getActivities();
      }
    });
  }
  
  // chiede conferma ed alimina l'attività
  async deleteActivity(activity: Activity){
    const alert = await this.alertCtrl.create({
      header: 'Sei sicuro di voler eliminare questa attività?',
      buttons: [
        {
          text: 'Annulla',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Conferma',
          handler: async (data) => {
            await this.view.showLoading('Eliminazione attività');
            try{
              const res: any = await this.api.deleteActivity(activity._id);
              if(res && res.delete){
                this.getActivities();
              }else{
                throw new Error();
              }
              ViewService.updateView.next();
            }catch(err){
              console.error(err);
              this.view.presentAlert('Errore', 'Impossibile eliminare questa attività', ['Ok']);
            }
            await this.view.hideLoading();
          }
        }
      ]
    });
    await alert.present();
  }
  
  // Apre la creazione di una nuova attività
  async addActivity() {
    if(!this.organization) return this.view.presentAlert('Errore', 'Il tracker non è associato ad una organizzazione quindi non puoi aggiungere attività', ['Ok']);
    const modal = await this.modalController.create({
      component: ActivityAddPage,
      componentProps: {
        organizations: [this.organization],
        imei_tracker: this.tracker._id,
        row: new Activity({type_activity: 1, id_organization: this.organization._id, imei_tracker: this.tracker.imei})
      }
    });
    
    await modal.present();
    
    modal.onDidDismiss().then((data: any) => {
      if(data && data.data && data.data.recordAdded){
        this.getActivities();
      }
    });
  }
  
  async openChat(){
    try{
      await this.view.showLoading('Recupero company...');
      const res: any = await this.api.getCompanyByOrganization(this.organization._id);
      if(res?._id){
        const url: string = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/whatsapp?idCompany=${res._id}`;
        window.open(url, '_blank');
      }else{
        this.view.presentAlert('Errore', 'Non ci sono company associate a questa organizzazione', ['OK']);
      }
    }catch(err){
      console.error(err);
      this.view.presentAlert('Errore', 'Impossibile trovare la company', ['OK']);
    }
    await this.view.hideLoading();
  }
  
  async showOrders(){
    try{
      if(this.tracker.magazzino_trackerGps !== 3) return;
      await this.view.showLoading('Recupero ordini...');
      this.orders = [];
      const res: any = await this.api.getOrdersWithTracker(this.tracker.imei);
      if(res?.length > 0){
        for(let raw of res){
          const order: Order = new Order(raw, {$getOrganizationById: this.data.getOrganizationById, $getTicketByOrderId: this.data.getTicketByOrderId});
          this.orders.push(order);
        }
      }
    }catch(err){
      console.error(err);
      this.view.presentAlert('Errore', 'Impossibile ottenere gli ordini', ['OK']);
    }
    View.updateView.next();
    await this.view.hideLoading();
  }
  
  
  // Attivazione manuale
  async selectOrganization(){
    let list_org_popover: any[] = [];
    for(let organization of DataService.array.organization){
      list_org_popover.push({
        first_row: `${organization.login_email_partecipant_owner || organization._id}`,
        second_row: `${organization.$platform?.name || organization.platform_type} - ${organization.login_trackerGps_active || 0} localizzatori`,
        id: organization._id
      });
    }
    const componentProps: any = {
      elements: list_org_popover,
      enableSearch: true,
      type: 'two_lines',
      title: `Seleziona l'organizzazione che vuoi associare al contatto`
    };
    const popover = await this.popoverController.create({
      component: PopoverListComponent,
      cssClass: "modal_list",
      backdropDismiss: true,
      componentProps: componentProps
    });
    await popover.present();
    const data: any = await popover.onDidDismiss();
    if(data?.data?.id){
      console.log(data.data.id);
      let organization: OrganizationAdmin = DataService.array.organization.find((elem: OrganizationAdmin) => {return elem._id === data.data.id});
      if(!organization) return;
      const alert = await this.alertCtrl.create({
        header: 'Sei sicuro?',
        message: `<small>Vuoi attivare il tracker sull'organizzazione <b>${organization.login_email_partecipant_owner || organization._id}</b>?</small>`,
        buttons: [
          {
            text: 'Annulla',
            role: 'cancel',
            cssClass: 'secondary'
          }, {
            text: 'Conferma',
            handler: async () => {
              await this.view.showLoading("Attivazione tracker in corso...");
              try {
                const res = await this.api.postTrackerManualActivation(this.tracker.imei, organization._id);
                if (res) {
                  this.getData(this.tracker?._id);
                  this.view.presentAlert('Successo', 'Tracker attivato correttamente', ['Ok']);
                }
              } catch (err) {
                console.error(err);
                this.view.presentAlert('Errore', 'Impossibile attivare il tracker', ['Ok']);
              }
              await this.view.hideLoading();
            }
          }
        ]
      });
      await alert.present();
    }
  }
  
  dismiss(data?: any){
    this.modalController.dismiss(data);
  }
  
  /** Recupera le info relative alla scheda FOTA solo per i tracker di tipo teltonika **/
  async getFotaInfo() {
    if(!this.tracker?.isTeltonika()) return;
    this.loading_fota = true;
    this.fota_tasks = [];
    View.updateView.next();
    try {
      await this.fota.init();
      let res: any = await this.api.getFotaTasksRiprogrammazione([this.tracker.imei], 1);
      if(res?.data || res?.data.length <= 0){
        this.fota_tasks = [];
        for(let raw of res.data){
          const task: FotaTask = new FotaTask(raw);
          this.fota_tasks.push(task);
          this.fota_device_info = task.raw?.device;
        }
      }
      if(!this.fota_device_info){ // Non avendo trovato tasks fota non ho nenache le info del device e quindi faccio api diretta a fota
        res = await this.api.getFotaDevice(this.tracker.imei);
        if(res){
          this.fota_device_info = res;
        }
      }
      if(this.fota_device_info.seen_at){
        this.fota_device_info.seen_at = this.fota_device_info.seen_at.replace(' ', 'T');
        this.fota_device_info.last_seen = (new Date(this.fota_device_info.seen_at + 'Z')).valueOf();
      }
      console.log(this.fota_device_info)
    } catch (err) {
      console.error(err);
      this.view.presentAlert('Errore', 'Impossibile recuperare le informazioni FOTA', ['OK']);
    }
    this.loading_fota = false;
    View.updateView.next();
  }
  
  /** Elimina un singolo task **/
  async deleteSingleTask(task: FotaTask, imei: string, event: any){
    event.stopPropagation();
    let message: string = 'Sei sicuro di voler cancellare questo task FOTA?';
    let buttons: any[] = [
      {
        text: 'Sì, conferma',
        handler: async () => {
          await this.view.showLoading('Cancellazione task FOTA...');
          try {
            if(task.status_name === 'Pending' || task.status_name === 'Running') await this.api.postCancelTask(task.id);
          } catch (err) {
            console.error(err);
            this.view.presentAlert('Errore', 'Errore cancellazione task', ['Ok']);
          }
          await this.getFotaInfo();
          await this.view.hideLoading();
        }
      }, {
        text: 'No, annulla',
        role: 'cancel'
      }
    ];
    this.view.presentAlert('Sei sicuro?', message, buttons);
  }
  
  /** Crea un nuovo task di update Firmware **/
  async createTaskFirmware(){
    const modal = await this.modalController.create({
      component: SelectFotaFilesComponent,
      componentProps: {
        type: 'firmware',
        tracker: this.tracker,
        fota_firmwares: FotaService.fota_firmwares,
        fota_configurations: FotaService.fota_configurations
      },
      cssClass: "modal_big",
    });
    modal.onDidDismiss().then(async (file: any) => {
      if(file?.data?.id){
        await this.view.showLoading('Creazione task update firmware FOTA...');
        try {
          const now: any = Date.now();
          await this.api.patchCreateTasksFirmware([this.tracker.imei], file.data.id);
          await Utility.wait(5000);
          await this.getFotaInfo();
          this.view.presentAlert('Successo', 'FOTA task update firmware creato', ['OK']);
        } catch (err) {
          console.error(err)
          this.view.presentAlert('Errore', 'Errore in creazione task FOTA', ['Ok']);
        }
        await this.view.hideLoading();
      }else{
        this.view.presentAlert('Errore', 'Nessun file firmware selezionato', ['Ok']);
      }
    });
    return await modal.present();
  }
  
  /** Crea un nuovo task di update Firmware **/
  async createTaskConfiguration(){
    const modal = await this.modalController.create({
      component: SelectFotaFilesComponent,
      componentProps: {
        type: 'configuration',
        tracker: this.tracker,
        fota_firmwares: FotaService.fota_firmwares,
        fota_configurations: FotaService.fota_configurations
      },
      cssClass: "modal_big",
    });
    modal.onDidDismiss().then(async (file: any) => {
      if(file?.data?.id){
        await this.view.showLoading('Creazione task update configuration FOTA...');
        try {
          const now: any = Date.now();
          await this.api.patchCreateTasksConfiguration([this.tracker.imei], file.data.id);
          await Utility.wait(5000);
          await this.getFotaInfo();
          this.view.presentAlert('Successo', 'FOTA task update configuration creato', ['OK']);
        } catch (err) {
          console.error(err)
          this.view.presentAlert('Errore', 'Errore in creazione task FOTA', ['Ok']);
        }
        await this.view.hideLoading();
      }else{
        this.view.presentAlert('Errore', 'Nessun file configuration selezionato', ['Ok']);
      }
    });
    return await modal.present();
  }
  
  /** Invia il comando web_connect se sim twilio altrimenti dice solo di inviare un sms manualmente **/
  async sendWebConnect(){
    let message: string = `Per forzare la connessione a FOTA devi inviare un SMS alla SIM con iccid <b>${this.tracker.iccid_sim}</b> con il testo <b>"  web_connect"</b>`;
    let buttons: any[] = [
      {
        text: 'Ok',
        role: 'cancel'
      }
    ];
    this.view.presentAlert('Istruzioni', message, buttons);
  }
  
  /** SIM ONOMONDO **/

  async alertOnomondoStatus(current_status: boolean ){
    const alert = await this.alertCtrl.create({
      header: 'Sei sicuro?',
      message: `Vuoi cambiare lo stato della sim ONOMONDO in ${current_status ? ' <b>disattiva</b> ' : ' <b>attiva</b>'}`,
      buttons: [
        {
          text: 'Annulla',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Conferma',
          handler: async () => {
            await this.view.showLoading();
            try {
              let onomondoUpdateSim = await this.api.patchSimOnomondo(this.onomondoSim.sim_id, undefined, !current_status)
              if(onomondoUpdateSim){
                this.onomondoSim.setAllFields(onomondoUpdateSim)
              }
              View.updateView.next();
            } catch (err) {
              console.error(err);
              this.view.presentAlert('Errore', 'Impossibile modificare lo stato della sim onomondo', ['Ok']);
            }
            await this.view.hideLoading();
          }
        }
      ]
    });
    
    await alert.present();
  }
  
  async alertOnomondoSimSendCommand() {
    const alert = await this.alertCtrl.create({
      buttons: [
        {
          text: 'Invia',
          handler: (data) => {
            console.log('handler data', data)
            if (data?.command) {
              this.postOnomondoCommand(data.command)
              View.updateView.next();
            }
          },
        },
        {
          text: 'Annulla',
          role: 'cancel',
        },
      ],
      header: "Invia comando",
      inputs: [
        {
          name: 'command',
          type: 'text',
          placeholder: 'comando',
        },
      ],
    });
    
    await alert.present();
    
    const { data, role } = await alert.onDidDismiss();
  }
  
  
  async postOnomondoCommand(command: string) {
    await this.view.hideLoading()
    await this.view.showLoading("invio nuovo comando a sim Onomondo")
  
    try {
      let res: any = await this.api.postOnomondoCommand(
        this.onomondoSim.sim_id,
        command
      );
      if (res) {
        this.onomondoSimCommands.unshift(new OnomondoCommand(res));
      }
      console.log(`postTwilioCommand() res`, res)
    
    } catch (e) {
      console.error(`e`, e)
      this.alert({
        header: "Errore API",
        msg: "E' avvenuto un errore durante l'invio del comando alla sim Onomondo"
      })
    }
  
    await this.view.hideLoading()
    View.updateView.next()
  }
  
  async getOnomondoSim(tracker: TrackerAdmin) {
    if (tracker.type_sim !== 4 || !tracker.sim_id) {
      return;
    }
    this.loading_sim = true;
    View.updateView.next();
    try {
      this.onomondoSim = new OnomondoSim(
        await this.api.getSimOnomondo(tracker.sim_id)
      );
    } catch (err) {
      console.error(err)
      this.alert({
        header: "Errore API",
        msg: "E' avvenuto un errore durante il caricamento della sim onomondo"
      })
    }
    this.loading_sim = false;
    View.updateView.next();
  }
  
  async getOnomondoCommandMultiple() {
    if (!this.onomondoSim) {
      return;
    }
    this.loading_sim_commands = true;
    View.updateView.next();
    try {
      let res: any = await this.api.getOnomondoCommandMultiple(this.onomondoSim.sim_id, 10);
      if (res && res.length > 0) {
        this.onomondoSimCommands = []; // no limit & skip
        for(let elem of res){
          this.onomondoSimCommands.push(new OnomondoCommand(elem))
        }
        
      }
    } catch (e) {
      console.error(`e`, e)
      this.alert({header: "Errore API", msg: "E' avvenuto un errore  durante il caricamento dei comandi della sim Onomondo"});
    }
    this.loading_sim_commands = false;
    View.updateView.next()
  }
  
  
  async getOnceSim(tracker: TrackerAdmin) {
    if (tracker.type_sim !== 5 || !tracker.iccid_sim) {
      return;
    }
    this.loading_sim = true;
    View.updateView.next();
    try {
      this.onceSim = new OnceSim(
        await this.api.getOnceSim(tracker.iccid_sim)
      );
    } catch (err) {
      console.error(err)
      this.alert({
        header: "Errore API",
        msg: "E' avvenuto un errore durante il caricamento della sim 1nce"
      })
    }
    this.loading_sim = false;
    this.loading_sim_commands = false;
    View.updateView.next();
  }
  
  async alertOnceStatus(){
    const alert = await this.alertCtrl.create({
      header: 'Sei sicuro?',
      message: `Vuoi cambiare lo stato della sim 1nce in ${this.onceSim.status === 'Enabled' ? ' <b>disattiva</b> ' : ' <b>attiva</b>'}`,
      buttons: [
        {
          text: 'Annulla',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Conferma',
          handler: async () => {
            await this.view.showLoading();
            try {
              await this.api.patchSimOnce(this.onceSim.iccid, undefined, this.onceSim.status === 'Enabled' ? 'Disabled' : 'Enabled');
              this.onceSim.status = this.onceSim.status === 'Enabled' ? 'Disabled' : 'Enabled';
              View.updateView.next();
            } catch (err) {
              console.error(err);
              this.view.presentAlert('Errore', 'Impossibile modificare lo stato della sim 1nce', ['Ok']);
            }
            await this.view.hideLoading();
          }
        }
      ]
    });
    
    await alert.present();
  }
  
  async getOnceUsage(){
    this.loading_once_usage = true;
    View.updateView.next();
    try {
      const promises: any[] = [
        this.api.getOnceSimUsageData(this.onceSim.iccid),
        this.api.getOnceSimUsageSms(this.onceSim.iccid)
      ];
      const responses: any[] = await Promise.all(promises);
      if(responses.length > 1){
        this.onceSim.usage_data = new OnceSimQuotaData(responses[0]);
        this.onceSim.usage_sms = new OnceSimQuotaSms(responses[1]);
      }
    } catch (err) {
      console.error(err)
      this.alert({
        header: "Errore API",
        msg: "E' avvenuto un errore durante il caricamento dei dati di utilizzo della sim 1nce"
      })
    }
    this.loading_once_usage = false;
    View.updateView.next();
  }
  
  async getOnceCommandMultiple() {
    if (!this.onceSim){
      return;
    }
    this.loading_sim_commands = true;
    View.updateView.next();
    try {
      let res: any = await this.api.getOnceCommandMultiple(this.onceSim.iccid, 20);
      if (res && res.length > 0) {
        this.onceSimCommands = []; // no limit & skip
        for(let elem of res){
          this.onceSimCommands.push(new OnceCommand(elem))
        }
        
      }
    } catch (e) {
      this.alert({header: "Errore API", msg: "E' avvenuto un errore  durante il caricamento dei comandi della sim 1nce"});
    }
    this.loading_sim_commands = false;
    View.updateView.next()
  }
  
  async alertOnceSimSendCommand() {
    let message: string = "";
    await this.view.showLoading('Controllo limiti utilizzo SMS sim');
    try{
      const res: any = await this.api.getOnceSimUsageSms(this.onceSim.iccid);
      this.onceSim.usage_sms = new OnceSimQuotaSms(res);
      if(this.onceSim.usage_sms.volume < 10){
        message = `<b class="text-danger">Attenzione!! Hai solo più ${this.onceSim.usage_sms.volume} SMS a disposizione su questa SIM di 1nce</b>`;
      }
    }catch(err){
      console.error(err);
      this.view.presentAlert('Errore', 'Impossibile verificare limiti SMS sim', ['Ok']);
    }
    await this.view.hideLoading();
    
    
    const alert = await this.alertCtrl.create({
      buttons: [
        {
          text: 'Invia',
          handler: (data) => {
            if (data?.command) {
              this.postOnceCommand(data.command)
              View.updateView.next();
            }
          },
        },
        {
          text: 'Annulla',
          role: 'cancel',
        },
      ],
      header: "Invia comando",
      message: message,
      inputs: [
        {
          name: 'command',
          type: 'text',
          placeholder: 'Comando',
        },
      ],
    });
    
    await alert.present();
    
    const { data, role } = await alert.onDidDismiss();
  }
  
  async postOnceCommand(command: string) {
    await this.view.hideLoading()
    await this.view.showLoading("invio nuovo comando a sim 1nce")
    
    try {
      let res: any = await this.api.postSimOnceSms(
        this.onceSim.iccid,
        command
      );
      this.getOnceCommandMultiple();
      
    } catch (e) {
      this.alert({
        header: "Errore API",
        msg: "E' avvenuto un errore durante l'invio del comando alla sim 1nce"
      })
    }
    
    await this.view.hideLoading()
    View.updateView.next()
  }
  
  
  
  async getThingsmobileSim(tracker: TrackerAdmin) {
    if (tracker.type_sim !== 1 || !tracker.iccid_sim) {
      return;
    }
    this.loading_sim = true;
    View.updateView.next();
    try {
      this.thingsmobileSim = new ThingsmobileSim(
        await this.api.getThingsmobileSim(tracker.iccid_sim)
      );
    } catch (err) {
      console.error(err)
      this.alert({
        header: "Errore API",
        msg: "E' avvenuto un errore durante il caricamento della sim thingsmobile"
      })
    }
    this.loading_sim = false;
    View.updateView.next();
  }
  
  async openThingsmobileCommands(){
    if(!this.thingsmobileSim){
      await this.view.showLoading();
      await this.getThingsmobileSim(this.tracker);
      await this.view.hideLoading();
    }
    await navigator.clipboard.writeText(this.thingsmobileSim.msisdn)
    const url: string = `https://www.thingsmobile.com/it/personal-area?step=sms`;
    window.open(url, '_blank');
  }
  
  async alertThingsmobileSimSendCommand() {
    let message: string = "";
    
    const alert = await this.alertCtrl.create({
      buttons: [
        {
          text: 'Invia',
          handler: (data) => {
            if (data?.command) {
              this.postThingsmobileCommand(data.command)
              View.updateView.next();
            }
          },
        },
        {
          text: 'Annulla',
          role: 'cancel',
        },
      ],
      header: "Invia comando",
      message: message,
      inputs: [
        {
          name: 'command',
          type: 'text',
          placeholder: 'Comando',
        },
      ],
    });
    
    await alert.present();
    
    const { data, role } = await alert.onDidDismiss();
  }
  
  async postThingsmobileCommand(command: string) {
    await this.view.showLoading("invio nuovo comando a sim Thingsmobile")
    
    try {
     await this.api.postSimThingsmobileSms(
        this.tracker.iccid_sim,
        command
      );
      this.view.presentAlert('Successo', 'Comando SMS inviato correttamente', ['Ok']);
      
    } catch (e) {
      console.error(e)
      this.alert({
        header: "Errore API",
        msg: "E' avvenuto un errore durante l'invio del comando alla sim Thingsmobile"
      })
    }
    
    await this.view.hideLoading()
    View.updateView.next()
  }
  
}
