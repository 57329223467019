export enum AdminRole{
  Superadmin = 1
}

export class Admin {
  
  _id: string;
  private _nickname: string;
  private _email: string;
  image: string;
  call_center: number
  status: number;
  role: AdminRole;
  call_redirect_admin: string;
  _last_twilio_client_online: number;
  _$is_client_offline: boolean;
  platform_type: number;
  in_call: boolean;
  important: boolean = false;
  sms_machine_last_update: number;
  
  $clients: Admin[];
  $aus_redirect_id: string;
  $count_unread_call: number;
  
  admin_reserve_list: string[];
  
  constructor(rawObj?: any) {
    this.setAllFields(rawObj);
  }
  
  setAllFields(rawObj?: any) {
    if (rawObj) {
      this._id = rawObj._id;
      this.nickname = rawObj.nickname;
      this.email = rawObj.email;
      this.image = rawObj.image;
      this.call_center = rawObj.call_center;
      this.status = rawObj.status;
      this.role = rawObj.role;
      this.call_redirect_admin = rawObj.call_redirect_admin;
      this.platform_type = rawObj.platform_type;
      this.last_twilio_client_online = rawObj.last_twilio_client_online;
      this.in_call = rawObj.in_call;
      this.admin_reserve_list = rawObj.admin_reserve_list;
      this.important = rawObj.important;
      this.sms_machine_last_update = rawObj.sms_machine_last_update;
    }
  }
  
  get nickname(): string {
    return this._nickname;
  }
  
  set nickname(value: string) {
    this._nickname = value;
  }
  
  get email(): string {
    return this._email;
  }
  
  set email(value: string) {
    this._email = value;
  }
  
  get last_twilio_client_online(){
    return this._last_twilio_client_online;
  }
  
  set last_twilio_client_online(value: number){
    this._last_twilio_client_online = value;
    if(this.last_twilio_client_online){
      let comparisonTimestamp = Date.now() - 60000 //data attuale meno 60 sec
      if(this.last_twilio_client_online <= comparisonTimestamp){
        this.$is_client_offline = true;
      }else{
        this.$is_client_offline = false
      }
    }
  }
  
  get $is_client_offline(): boolean {
    return this._$is_client_offline;
  }
  
  set $is_client_offline(value: boolean) {
    this._$is_client_offline = value;
  }
  
}
