import { Platform } from "./platform";
import {InvoiceLine} from './invoiceLine';

type Options = { 
  $getPlatformByType?: (type: number) => Platform
}
type TypeFatturazioneElettronica = 1
type $typeFatturazioneElettronica = "fattura24"

export default class Invoice implements Options {
  _id: string
  
  number: string;
  status: string;
  status_formatted: string;
  hosted_invoice_url: string;
  paid: boolean;
  
  idInvoiceStripe: string;
  stripeInvoiceNumber: string; // presente solo quando è fattura elettronica
  fattura24Number: string; // presente solo quando è fattura elettronica
  idOrganization: string;
  private _platform_type: number;
  timestampCreate: number;
  private _typeFatturazioneElettronica: TypeFatturazioneElettronica;
  
  notaCredito: boolean;
  notaCreditoNumber: string;
  isInsert: boolean;
  
  fattura24Object: any;
  fattura24Result: boolean;
  fattura24ResultApi: any;
  
  metadata: any;
  tax: number;
  tax_formatted: string;
  
  total: number;
  total_formatted: string;
  lines: any[];
  
  charge: any;
  period_end: number;
  
  $getPlatformByType: (type: number) => Platform;
  $platform: Platform;
  $raw: any
  $typeFatturazioneElettronica: $typeFatturazioneElettronica
  
  payment_method_label: string;
  total_excluding_tax_formatted: string;
  balance_used_amount: number;
  balance_used_amount_formatted: string;
  amount_due_formatted: string;
  
  constructor(data: any, options?: Options) {
    this.$getPlatformByType = () => undefined;
    if (data) {
      this.setData(data, options);
    }
  }
  
  setData(data: any, options?: Options){
    // super.setData(data);
    if (options) {
      if (options.$getPlatformByType) {
        this.$getPlatformByType = options.$getPlatformByType;
      }
    }
    this._id = data._id || data.id;
    
    this.status = data.status;
    this.status_formatted = data.status_formatted;
    this.hosted_invoice_url = data.hosted_invoice_url;
    this.paid = data.paid;
    
    this.idInvoiceStripe = data.idInvoiceStripe;
    this.stripeInvoiceNumber = data.stripeInvoiceNumber;
    this.number = data.number || data.stripeInvoiceNumber;
    this.fattura24Number = data.fattura24Number;
    this.idOrganization = data.idOrganization;
    this.platform_type = data.platform_type;
    this.timestampCreate = data.timestampCreate;
    this.typeFatturazioneElettronica = data.typeFatturazioneElettronica;
    
    this.notaCredito = data.notaCredito;
    this.notaCreditoNumber = data.notaCreditoNumber;
    this.isInsert = data.isInsert;
    
    this.fattura24Object = data.fattura24Object;
    this.fattura24Result = data.fattura24Result;
    this.fattura24ResultApi = data.fattura24ResultApi;
    
    this.metadata = {};
    if(data.metadata?.type) this.metadata.type = data.metadata.type;
    if(data.metadata?.number_fattura_elettronica) this.metadata.number_fattura_elettronica = data.metadata.number_fattura_elettronica;
    if(data.metadata?.timestamp_fattura_elettronica) this.metadata.timestamp_fattura_elettronica = parseInt(data.metadata.timestamp_fattura_elettronica);
    if(data.metadata?.timestamp_req_bank_transfer_payment){
      this.metadata.timestamp_req_bank_transfer_payment = parseInt(data.metadata.timestamp_req_bank_transfer_payment);
    }
    if(data.metadata?.amount_to_paid_bank_transfer){
      this.metadata.amount_to_paid_bank_transfer = parseInt(data.metadata.amount_to_paid_bank_transfer) / 100;
    }
    if(data.metadata?.amount_to_paid_bank_transfer_formatted){
      this.metadata.amount_to_paid_bank_transfer_formatted = data.metadata.amount_to_paid_bank_transfer_formatted;
    }
    if(data.metadata?.timestamp_bank_transfer_recieve) this.metadata.timestamp_bank_transfer_recieve = parseInt(data.metadata.timestamp_bank_transfer_recieve);
  
    
    this.total = data.total;
    this.total_formatted = data.total_formatted;
    this.tax = data.tax;
    this.tax_formatted = data.tax_formatted;
    this.charge = data.charge_object_formatted;
    this.lines = data.lines;
    this.period_end = data.period_end;
    this.payment_method_label = data.payment_method_label;
    this.$raw = data;
    this.total_excluding_tax_formatted = data.total_excluding_tax_formatted;
    this.balance_used_amount = data.balance_used_amount;
    this.balance_used_amount_formatted = data.balance_used_amount_formatted;
    this.amount_due_formatted = data.amount_due_formatted;
  }
  
  
  public get platform_type(): number {
    return this._platform_type;
  }
  public set platform_type(value: number) {
    this._platform_type = value;
    // * set subfields
    this.$platform = this.$getPlatformByType(value)
  }
  
  public get typeFatturazioneElettronica(): TypeFatturazioneElettronica {
    return this._typeFatturazioneElettronica;
  }
  public set typeFatturazioneElettronica(value: TypeFatturazioneElettronica) {
    this._typeFatturazioneElettronica = value;
    // * set subfields
    switch (value) {
      case 1:
        this.$typeFatturazioneElettronica = "fattura24"
        break;
    
      default:
        break;
    }
  }
  
  mapInvoiceLineWithProduct(elenco_prodotti_shop: any[], type: string){
    if(elenco_prodotti_shop && this.lines.length){
      let newLines = [];
      for(let item of this.lines){
        //devo andare a cercare questo price
        if(type === 'free'){
          newLines.push(new InvoiceLine({...item}))
        }else{
          elenco_prodotti_shop.forEach((prodotto, i)=>{
            if(prodotto.custom_priceId_list){
              for(let custom_list of prodotto.custom_priceId_list){
                if(custom_list.price_id === item.price.id){
                  newLines.push(new InvoiceLine({...item, ...{prodotto_associato: prodotto}}))
                }
              }
            }
          })
        }
   
      }
      this.lines = newLines;
    }
  }
  
  
}
