import { Pipe, PipeTransform } from '@angular/core';
import {MigrationBatch} from '../classes/migration';

@Pipe({
  name: 'searchBatch'
})

export class SearchBachtPipe implements PipeTransform {
  
  transform(batchs: MigrationBatch[], query: string, changed: number): any {
    
    if(!query) return batchs;
    
    query = query.toLowerCase();
    
    let filtered: MigrationBatch[] = [];
    
    for(let batch of batchs){
      if(batch.emails?.length > 0){
        for(let email of batch.emails){
          if(email.indexOf(query) >= 0){
            filtered.push(batch);
            break;
          }
        }
      }
    }
    
    return filtered;
  }
  
}
