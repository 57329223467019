import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ViewService } from 'common-lib';

@Component({
  selector: 'app-popover-list',
  templateUrl: './popover-list.component.html',
  styleUrls: ['./popover-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopoverListComponent implements OnInit {
  
  @Input() enableSearch: boolean;
  @Input() title: string;
  @Input() type: 'two_lines' | undefined;
  @Input() elements: {
    id: any,
    first_row: string,
    second_row?: string,
  }[];
  
  query: string;
  leftList = [
    {
      id: "item1",
      head: "List group item heading",
      body: `
      Donec id elit non mi porta gravida at eget metus. 
      Maecenas sed diam eget risus varius blandit.
      `,
      date: "3 days ago",
      foot: "Donec id elit non mi porta.",
    },
    {
      id: "item2",
      head: "List group item heading",
      body: `
      Donec id elit non mi porta gravida at eget metus. 
      Maecenas sed diam eget risus varius blandit.
      `,
      date: "11/02/1945",
      foot: "Donec id elit non mi porta.",
    },
    {
      id: "item2",
      head: "List group item heading",
      body: `
      Donec id elit non mi porta gravida at eget metus. 
      Maecenas sed diam eget risus varius blandit.
      `,
      date: "11/02/1945",
      foot: "Donec id elit non mi porta.",
    },
    {
      id: "item2",
      head: "List group item heading",
      body: `
      Donec id elit non mi porta gravida at eget metus. 
      Maecenas sed diam eget risus varius blandit.
      `,
      date: "11/02/1945",
      foot: "Donec id elit non mi porta.",
    },
    {
      id: "item2",
      head: "List group item heading",
      body: `
      Donec id elit non mi porta gravida at eget metus. 
      Maecenas sed diam eget risus varius blandit.
      `,
      date: "11/02/1945",
      foot: "Donec id elit non mi porta.",
    },
    {
      id: "item2",
      head: "List group item heading",
      body: `
      Donec id elit non mi porta gravida at eget metus. 
      Maecenas sed diam eget risus varius blandit.
      `,
      date: "11/02/1945",
      foot: "Donec id elit non mi porta.",
    },
    {
      id: "item2",
      head: "List group item heading",
      body: `
      Donec id elit non mi porta gravida at eget metus. 
      Maecenas sed diam eget risus varius blandit.
      `,
      date: "11/02/1945",
      foot: "Donec id elit non mi porta.",
    },
    {
      id: "item2",
      head: "List group item heading",
      body: `
      Donec id elit non mi porta gravida at eget metus. 
      Maecenas sed diam eget risus varius blandit.
      `,
      date: "11/02/1945",
      foot: "Donec id elit non mi porta.",
    },
    {
      id: "item2",
      head: "List group item heading",
      body: `
      Donec id elit non mi porta gravida at eget metus. 
      Maecenas sed diam eget risus varius blandit.
      `,
      date: "11/02/1945",
      foot: "Donec id elit non mi porta.",
    },
    {
      id: "item2",
      head: "List group item heading",
      body: `
      Donec id elit non mi porta gravida at eget metus. 
      Maecenas sed diam eget risus varius blandit.
      `,
      date: "11/02/1945",
      foot: "Donec id elit non mi porta.",
    },
    {
      id: "item2",
      head: "List group item heading",
      body: `
      Donec id elit non mi porta gravida at eget metus. 
      Maecenas sed diam eget risus varius blandit.
      `,
      date: "11/02/1945",
      foot: "Donec id elit non mi porta.",
    },
    {
      id: "item2",
      head: "List group item heading",
      body: `
      Donec id elit non mi porta gravida at eget metus. 
      Maecenas sed diam eget risus varius blandit.
      `,
      date: "11/02/1945",
      foot: "Donec id elit non mi porta.",
    },
    {
      id: "item2",
      head: "List group item heading",
      body: `
      Donec id elit non mi porta gravida at eget metus. 
      Maecenas sed diam eget risus varius blandit.
      `,
      date: "11/02/1945",
      foot: "Donec id elit non mi porta.",
    },
    {
      id: "item2",
      head: "List group item heading",
      body: `
      Donec id elit non mi porta gravida at eget metus. 
      Maecenas sed diam eget risus varius blandit.
      `,
      date: "11/02/1945",
      foot: "Donec id elit non mi porta.",
    },
    {
      id: "item2",
      head: "List group item heading",
      body: `
      Donec id elit non mi porta gravida at eget metus. 
      Maecenas sed diam eget risus varius blandit.
      `,
      date: "11/02/1945",
      foot: "Donec id elit non mi porta.",
    },
    {
      id: "item2",
      head: "List group item heading",
      body: `
      Donec id elit non mi porta gravida at eget metus. 
      Maecenas sed diam eget risus varius blandit.
      `,
      date: "11/02/1945",
      foot: "Donec id elit non mi porta.",
    },
    {
      id: "item2",
      head: "List group item heading",
      body: `
      Donec id elit non mi porta gravida at eget metus. 
      Maecenas sed diam eget risus varius blandit.
      `,
      date: "11/02/1945",
      foot: "Donec id elit non mi porta.",
    },
    {
      id: "item2",
      head: "List group item heading",
      body: `
      Donec id elit non mi porta gravida at eget metus. 
      Maecenas sed diam eget risus varius blandit.
      `,
      date: "11/02/1945",
      foot: "Donec id elit non mi porta.",
    },
    {
      id: "item2",
      head: "List group item heading",
      body: `
      Donec id elit non mi porta gravida at eget metus. 
      Maecenas sed diam eget risus varius blandit.
      `,
      date: "11/02/1945",
      foot: "Donec id elit non mi porta.",
    },
    {
      id: "item2",
      head: "List group item heading",
      body: `
      Donec id elit non mi porta gravida at eget metus. 
      Maecenas sed diam eget risus varius blandit.
      `,
      date: "11/02/1945",
      foot: "Donec id elit non mi porta.",
    },
    {
      id: "item2",
      head: "List group item heading",
      body: `
      Donec id elit non mi porta gravida at eget metus. 
      Maecenas sed diam eget risus varius blandit.
      `,
      date: "11/02/1945",
      foot: "Donec id elit non mi porta.",
    },
    {
      id: "item2",
      head: "List group item heading",
      body: `
      Donec id elit non mi porta gravida at eget metus. 
      Maecenas sed diam eget risus varius blandit.
      `,
      date: "11/02/1945",
      foot: "Donec id elit non mi porta.",
    },
    {
      id: "item2",
      head: "List group item heading",
      body: `
      Donec id elit non mi porta gravida at eget metus. 
      Maecenas sed diam eget risus varius blandit.
      `,
      date: "11/02/1945",
      foot: "Donec id elit non mi porta.",
    },
  ];
  leftList_selectedItem: string;
  
  listener = {
    view: null,
  };

  constructor(
    private ref: ChangeDetectorRef,
    private view: ViewService,
    public popoverController: PopoverController,
  ) { }

  ngOnInit() {}
  
  ionViewDidEnter() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.ref.markForCheck();
    });
  }

  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }
  
  // * content
  
  leftListItemClick(itemId: string) {
    this.leftList_selectedItem = itemId;
  }

  dismiss(data: any){
    this.popoverController.dismiss(data);
  }
}
