import {TrackerAdmin} from './trackerAdmin';
import {OrganizationAdmin} from './organizationAdmin';

export class UserTrackingram{
  _id: any;
  private _$userId: any;
  email: any;
  tokenFirebase: any;
  isUserEnable: any;
  idPipedriveOrganization: any;
  tokenApiPublic: any;
  enableApiPublic: any;
  paymentType: any;
  payment_days_until_due: any;
  stripe_idCustomersStripe: any;
  stripe_idSubscription: any;
  flag_SubscriptionGoogleMaps: any;
  numberLicenseGoogleMapsLivePositionTelegram: any;
  tax_percent: any;
  language: any;
  default_idBotTelegram: any;
  contract_trackingram: any;
  timestamp_contract_trackingram: any;
  ip_contract_trackingram: any;
  contract_trackerGps: any;
  timestamp_contract_trackerGps: any;
  ip_contract_trackerGps: any;
  headQuarter: any;
  headQuarterImage: any;
  smsCount: any;
  months_trackerGps_contract_constraint: any;
  billing_type: any;
  billing_eu: any;
  billing_companyName: any;
  billing_mailPEC: any;
  billing_sdiCode_fatturaElettronica: any;
  billing_name: any;
  billing_surname: any;
  billing_address: any;
  billing_zip: any;
  billing_city: any;
  billing_province: any;
  billing_country: any;
  billing_mail: any;
  billing_phoneCountryCode: any;
  billing_phone: any;
  billing_fax: any;
  billing_vat: any;
  billing_codiceFiscale: any;
  shipping_name: any;
  shipping_address: any;
  timestamp_create: any;
  timestamp_last_send_email: any;
  status_send_email: any;
  alarmMovement_allTracker: any;
  alarmMovement_monday: any;
  alarmMovement_tuesday: any;
  alarmMovement_wednesday: any;
  alarmMovement_thursday: any;
  alarmMovement_friday: any;
  alarmMovement_saturday: any;
  alarmMovement_sunday: any;
  alarmMovement_Timezone: any;
  alarmMovement_idAlarmRegisters: any;
  alarmMovement_textAlarm: any;
  alarmMovement_speedActivation: any;
  alarmMovement_keyOn: any;
  alarmMovement_resendAlarm: any;
  alarmMovement_minuteResend: any;
  alarmMainPower_allTracker: any;
  alarmMainPower_idAlarmRegisters: any;
  alarmMainPower_textAlarm: any;
  isSencondaryUser: any;
  idUserPrincipal: any;
  secondaryUser_allTrackerPermitted: any;
  listTrackerGps: any;
  functionality_sencondaryuser_googleMaps: any;
  functionality_sencondaryuser_history: any;
  functionality_sencondaryuser_report: any;
  functionality_sencondaryuser_poi: any;
  functionality_sencondaryuser_driver: any;
  isLogDisable: any;
  private _$migration_status: any;
  private _$has_can_bus: any;
  private _$avg_price: any;
  private _$white_label_trackers: TrackerAdmin[];
  private _$customer_stripe_object: any;
  private _$upcoming_invoice: any;
  private _$desc_migration_status: object;
  private _$desc_migration_status_software: object;
  private _$count_tracker_white_label_programmed: number;
  
  private $getMigrationStatus: (id: string) => MigrationStatus = () => {return undefined};
  private $hasCanBus: (id: string) => boolean = () => {return undefined};
  private $getTrackersWhiteLabel: (trackers: any[]) => TrackerAdmin[] = () => {return []};
  
  
  constructor(rawObj?: any, options? : {
    getMigrationStatus?: (id: string) => MigrationStatus,
    hasCanBus?: (id: string) => boolean,
    getTrackersWhiteLabel?: (trackers: any[]) => TrackerAdmin[],
  }) {
    this.setAllFields(rawObj, options);
  }
  
  setAllFields(rawObj: any = {}, options? : {
    getMigrationStatus?: (id: string) => MigrationStatus,
    hasCanBus?: (id: string) => boolean,
    getTrackersWhiteLabel?: (trackers: any[]) => TrackerAdmin[],
  }) {
    if(options){
      if(options.getMigrationStatus) this.$getMigrationStatus = options.getMigrationStatus
      if(options.hasCanBus) this.$hasCanBus = options.hasCanBus
      if(options.getTrackersWhiteLabel) this.$getTrackersWhiteLabel = options.getTrackersWhiteLabel
    }
    if (rawObj) {
      this._id = rawObj._id;
      this.$userId = rawObj._id;
      this.email = rawObj.email;
      this.tokenFirebase = rawObj.tokenFirebase;
      this.isUserEnable = rawObj.isUserEnable;
      this.idPipedriveOrganization = rawObj.idPipedriveOrganization;
      this.tokenApiPublic = rawObj.tokenApiPublic;
      this.enableApiPublic = rawObj.enableApiPublic;
      this.paymentType = rawObj.paymentType;
      this.payment_days_until_due = rawObj.payment_days_until_due;
      this.stripe_idCustomersStripe = rawObj.stripe_idCustomersStripe;
      this.stripe_idSubscription = rawObj.stripe_idSubscription;
      this.flag_SubscriptionGoogleMaps = rawObj.flag_SubscriptionGoogleMaps;
      this.numberLicenseGoogleMapsLivePositionTelegram = rawObj.numberLicenseGoogleMapsLivePositionTelegram;
      this.tax_percent = rawObj.tax_percent;
      this.language = rawObj.language;
      this.default_idBotTelegram = rawObj.default_idBotTelegram;
      this.contract_trackingram = rawObj.contract_trackingram;
      this.timestamp_contract_trackingram = rawObj.timestamp_contract_trackingram;
      this.ip_contract_trackingram = rawObj.ip_contract_trackingram;
      this.contract_trackerGps = rawObj.contract_trackerGps;
      this.timestamp_contract_trackerGps = rawObj.timestamp_contract_trackerGps;
      this.ip_contract_trackerGps = rawObj.ip_contract_trackerGps;
      this.headQuarter = rawObj.headQuarter;
      this.headQuarterImage = rawObj.headQuarterImage;
      this.smsCount = rawObj.smsCount;
      this.months_trackerGps_contract_constraint = rawObj.months_trackerGps_contract_constraint;
      this.billing_type = rawObj.billing_type;
      this.billing_eu = rawObj.billing_eu;
      this.billing_companyName = rawObj.billing_companyName;
      this.billing_mailPEC = rawObj.billing_mailPEC;
      this.billing_sdiCode_fatturaElettronica = rawObj.billing_sdiCode_fatturaElettronica;
      this.billing_name = rawObj.billing_name;
      this.billing_surname = rawObj.billing_surname;
      this.billing_address = rawObj.billing_address;
      this.billing_zip = rawObj.billing_zip;
      this.billing_city = rawObj.billing_city;
      this.billing_province = rawObj.billing_province;
      this.billing_country = rawObj.billing_country;
      this.billing_mail = rawObj.billing_mail;
      this.billing_phoneCountryCode = rawObj.billing_phoneCountryCode;
      this.billing_phone = rawObj.billing_phone;
      this.billing_fax = rawObj.billing_fax;
      this.billing_vat = rawObj.billing_vat;
      this.billing_codiceFiscale = rawObj.billing_codiceFiscale;
      this.shipping_name = rawObj.shipping_name;
      this.shipping_address = rawObj.shipping_address;
      this.timestamp_create = rawObj.timestamp_create;
      this.timestamp_last_send_email = rawObj.timestamp_last_send_email;
      this.status_send_email = rawObj.status_send_email;
      this.alarmMovement_allTracker = rawObj.alarmMovement_allTracker;
      this.alarmMovement_monday = rawObj.alarmMovement_monday;
      this.alarmMovement_tuesday = rawObj.alarmMovement_tuesday;
      this.alarmMovement_wednesday = rawObj.alarmMovement_wednesday;
      this.alarmMovement_thursday = rawObj.alarmMovement_thursday;
      this.alarmMovement_friday = rawObj.alarmMovement_friday;
      this.alarmMovement_saturday = rawObj.alarmMovement_saturday;
      this.alarmMovement_sunday = rawObj.alarmMovement_sunday;
      this.alarmMovement_Timezone = rawObj.alarmMovement_Timezone;
      this.alarmMovement_idAlarmRegisters = rawObj.alarmMovement_idAlarmRegisters;
      this.alarmMovement_textAlarm = rawObj.alarmMovement_textAlarm;
      this.alarmMovement_speedActivation = rawObj.alarmMovement_speedActivation;
      this.alarmMovement_keyOn = rawObj.alarmMovement_keyOn;
      this.alarmMovement_resendAlarm = rawObj.alarmMovement_resendAlarm;
      this.alarmMovement_minuteResend = rawObj.alarmMovement_minuteResend;
      this.alarmMainPower_allTracker = rawObj.alarmMainPower_allTracker;
      this.alarmMainPower_idAlarmRegisters = rawObj.alarmMainPower_idAlarmRegisters;
      this.alarmMainPower_textAlarm = rawObj.alarmMainPower_textAlarm;
      this.isSencondaryUser = rawObj.isSencondaryUser;
      this.idUserPrincipal = rawObj.idUserPrincipal;
      this.secondaryUser_allTrackerPermitted = rawObj.secondaryUser_allTrackerPermitted;
      this.listTrackerGps = rawObj.listTrackerGps;
      this.functionality_sencondaryuser_googleMaps = rawObj.functionality_sencondaryuser_googleMaps;
      this.functionality_sencondaryuser_history = rawObj.functionality_sencondaryuser_history;
      this.functionality_sencondaryuser_report = rawObj.functionality_sencondaryuser_report;
      this.functionality_sencondaryuser_poi = rawObj.functionality_sencondaryuser_poi;
      this.functionality_sencondaryuser_driver = rawObj.functionality_sencondaryuser_driver;
      this.isLogDisable = rawObj.isLogDisable;
    }
  }
  
  get $userId(): any {
    return this._$userId;
  }
  
  set $userId(value: any) {
    this._id = value;
    this._$userId = value;
    if(this.$userId){
      this.$migration_status = this.$getMigrationStatus(this.$userId)
      this.$has_can_bus = this.$hasCanBus(this.$userId)
      
    }
  }
  
  get $migration_status(): any {
    return this._$migration_status;
  }
  
  set $migration_status(value: any) {
    this._$migration_status = value;
    if(this.$migration_status && this.$migration_status?.trackers){
      this.$white_label_trackers = this.$getTrackersWhiteLabel(this.$migration_status.trackers)
      if(this.$white_label_trackers?.length){
        this.$count_tracker_white_label_programmed = 0;
        for(let w_t of this.$white_label_trackers){
          if(w_t.isProgrammed) this.$count_tracker_white_label_programmed++;
        }
      }
    }
  
    //Aggiunto un metodo per ottenere lo stato della migrazione dell'utente
    this.$desc_migration_status = {desc: 'Da avviare'}
  
    if(this.$migration_status){
      if(this.$migration_status.automation_process_completed && (this.$count_tracker_white_label_programmed === this.$white_label_trackers.length)){
        this.$desc_migration_status = {desc: 'Terminata', color: 'success'}
      }else if(this.$migration_status.automation_process_completed){
        this.$desc_migration_status = {desc: 'In corso', color: 'warning'}
      }else if (!this.$migration_status.automation_process_completed){
        this.$desc_migration_status = {desc: 'Errore', color: 'danger', tooltip: this.$migration_status?.$last_migration_step?.description}
      }
    }
  
  
    this.$desc_migration_status_software = {desc: 'Da avviare'}
  
    if(this.$migration_status){
      if(this.$migration_status.automation_process_completed){
        this.$desc_migration_status_software = {desc: 'Terminata', color: 'success'}
      } else if (!this.$migration_status.automation_process_completed){
        this.$desc_migration_status_software = {desc: 'In errore', color: 'danger', tooltip: this.$migration_status?.$last_migration_step?.description}
      }
    }
    
  }
  
  get $has_can_bus(): any {
    return this._$has_can_bus;
  }
  
  set $has_can_bus(value: any) {
    this._$has_can_bus = value;
  }
  
  get $avg_price(): any {
    return this._$avg_price;
  }
  
  set $avg_price(value: any) {
    this._$avg_price = value;
  }
  
  get $white_label_trackers(): TrackerAdmin[] {
    return this._$white_label_trackers;
  }
  
  set $white_label_trackers(value: TrackerAdmin[]) {
    this._$white_label_trackers = value;
  }
  
  get $customer_stripe_object(): any {
    return this._$customer_stripe_object;
  }
  
  set $customer_stripe_object(value: any) {
    this._$customer_stripe_object = value;
  }
  
  get $upcoming_invoice(): any {
    return this._$upcoming_invoice;
  }
  
  set $upcoming_invoice(value: any) {
    this._$upcoming_invoice = value;
  }
  
  
  get $desc_migration_status(): object {
    return this._$desc_migration_status;
  }
  
  set $desc_migration_status(value: object) {
    this._$desc_migration_status = value;
  }
  
  get $desc_migration_status_software(): object | any {
    return this._$desc_migration_status_software;
  }
  
  set $desc_migration_status_software(value: object | any) {
    this._$desc_migration_status_software = value;
  }
  
  get $count_tracker_white_label_programmed(): number {
    return this._$count_tracker_white_label_programmed;
  }
  
  set $count_tracker_white_label_programmed(value: number) {
    this._$count_tracker_white_label_programmed = value;
  }
  
  
}

export class TrackersTrackingram{
  _id: any;
  idUser: any;
  typeBuying: any;
  status_trackerGps: any;
  model_trackerGps: any;
  planStripe_trackerGps: any;
  is_contract_constraint: any;
  timestamp_end_contract_constraint: any;
  isRoaming: any;
  timestampRoaming: any;
  accessoryTrackerGps_can_bus: any;
  name: any;
  label_number: any;
  label_string: any;
  imei_trackerGps: any;
  old_imei_trackerGps: any;
  isRent_trackerGps: any;
  typeSim_trackerGps: any;
  sim_ICCID_trackerGps: any;
  sim_dialCode_trackerGps: any;
  sim_phoneNumber_trackerGps: any;
  trackerGps_sold_timestamp_activation: any;
  trackerGps_sold_timestamp_finish_free_period: any;
  trackerGps_sold_start_subscription: any;
  isProgrammed_trackerGps: any;
  emailNotificationForProgrammed: any;
  lastProgrammingResult: any;
  apnConfiguration: any;
  serverConfiguration: any;
  serverPort: any;
  model_name: any;
  firmware_version: any;
  isProgrammedEngine24V: any;
  isProgrammedEngine24V_timestamp: any;
  lastPosition_trackerGps: any;
  lastPosition_trackerGps_firstStop: any;
  lastCanBus_engine_on: any;
  lastCanBus: any;
  lastCanBus_timestampRequest: any;
  isConnectedServer_trackerGps: any;
  timestampLastConnectedServer_trackerGps: any;
  connectionNumber: any;
  serverTcpConnected: any;
  output1_timestamp_setting: any;
  output1_newState: any;
  output2_timestamp_setting: any;
  output2_newState: any;
  odometerGps_timestamp_setting: any;
  odometerGps_newState: any;

  
  constructor(rawObj?: any) {
    this.setAllFields(rawObj);
  }
  
  setAllFields(rawObj: any = {}, ) {
    if (rawObj) {
      this._id = rawObj._id
      this.idUser = rawObj.idUser
      this.typeBuying = rawObj.typeBuying
      this.status_trackerGps = rawObj.status_trackerGps
      this.model_trackerGps = rawObj.model_trackerGps
      this.planStripe_trackerGps = rawObj.planStripe_trackerGps
      this.is_contract_constraint = rawObj.is_contract_constraint
      this.timestamp_end_contract_constraint = rawObj.timestamp_end_contract_constraint
      this.isRoaming = rawObj.isRoaming
      this.timestampRoaming = rawObj.timestampRoaming
      this.accessoryTrackerGps_can_bus = rawObj.accessoryTrackerGps_can_bus
      this.name = rawObj.name
      this.label_number = rawObj.label_number
      this.label_string = rawObj.label_string
      this.imei_trackerGps = rawObj.imei_trackerGps
      this.old_imei_trackerGps = rawObj.old_imei_trackerGps
      this.isRent_trackerGps = rawObj.isRent_trackerGps
      this.typeSim_trackerGps = rawObj.typeSim_trackerGps; //1 wind, 2 things
      this.sim_ICCID_trackerGps = rawObj.sim_ICCID_trackerGps
      this.sim_dialCode_trackerGps = rawObj.sim_dialCode_trackerGps
      this.sim_phoneNumber_trackerGps = rawObj.sim_phoneNumber_trackerGps
      this.trackerGps_sold_timestamp_activation = rawObj.trackerGps_sold_timestamp_activation
      this.trackerGps_sold_timestamp_finish_free_period = rawObj.trackerGps_sold_timestamp_finish_free_period
      this.trackerGps_sold_start_subscription = rawObj.trackerGps_sold_start_subscription
      this.isProgrammed_trackerGps = rawObj.isProgrammed_trackerGps
      this.emailNotificationForProgrammed = rawObj.emailNotificationForProgrammed
      this.lastProgrammingResult = rawObj.lastProgrammingResult
      this.apnConfiguration = rawObj.apnConfiguration
      this.serverConfiguration = rawObj.serverConfiguration
      this.serverPort = rawObj.serverPort
      this.model_name = rawObj.model_name
      this.firmware_version = rawObj.firmware_version
      this.isProgrammedEngine24V = rawObj.isProgrammedEngine24V
      this.isProgrammedEngine24V_timestamp = rawObj.isProgrammedEngine24V_timestamp
      this.lastPosition_trackerGps = rawObj.lastPosition_trackerGps
      this.lastPosition_trackerGps_firstStop = rawObj.lastPosition_trackerGps_firstStop
      this.lastCanBus_engine_on = rawObj.lastCanBus_engine_on
      this.lastCanBus = rawObj.lastCanBus
      this.lastCanBus_timestampRequest = rawObj.lastCanBus_timestampRequest
      this.isConnectedServer_trackerGps = rawObj.isConnectedServer_trackerGps
      this.timestampLastConnectedServer_trackerGps = rawObj.timestampLastConnectedServer_trackerGps
      this.connectionNumber = rawObj.connectionNumber
      this.serverTcpConnected = rawObj.serverTcpConnected
      this.output1_timestamp_setting = rawObj.output1_timestamp_setting
      this.output1_newState = rawObj.output1_newState
      this.output2_timestamp_setting = rawObj.output2_timestamp_setting
      this.output2_newState = rawObj.output2_newState
      this.odometerGps_timestamp_setting = rawObj.odometerGps_timestamp_setting
      this.odometerGps_newState = rawObj.odometerGps_newState
    }
  }
}

/**
 * Lista stati per descrizione step migrazione
 */
enum StatusMigrationDescription {
  EXIST_USER_TRACKINGRAM = "Indica che l'utente di Trackingram esiste",
  EXIST_STRIPE_CUSTOMER_TRACKINGRAM = "Indica che l'utente di Trackingram ha un account Stripe associato per la fatturazione",
  NO_EXIST_USER_WHITELABEL = "Indica che l'utente da migrare non esiste ancora tra i partecipanti di Whitelabel",
  NO_EXIST_USER_FIREBASE_WHITELABEL = "Indica che l'utente da migrare non è ancora presente tra gli utenti di Firebase nel progetto Whitelabel",
  USER_NOT_ALREADY_MIGRATED = "Significa se l'utente non era già stato migrato in precedenza",
  USER_PRE_MIGRATED_STRIPE_CLEAN = "Significa se l'utente era già stato premigrato e non ha fatto operazioni su stripe, altrimenti va gestito il caso manualmente",
  MIGRATION_FIREBASE = "Indica che l'utente è stato correttamente migrato nel progetto di Firebase",
  GET_UID_FIREBASE_WHITELABEL = "Indica che dopo la migrazione da Trackingram a Whitelabel, siamo riusciti a ottenere il nuovo UID",
  NEW_ORGANIZATION_PARTICIPANT_WHITELABEL = "Indica che è stato creato con successo un nuovo partecipante e una nuova organizzazione su Whitelabel",
  GET_TRACKERS_TRACKINGRAM = "Indica che siamo riusciti a ottenere dei tracker da migrare per l'utente di Trackingram",
  INSERT_TRACKERS_WHITELABEL = "Indica che i tracker di Trackingram sono stati inseriti correttamente in Whitelabel. In caso di errori (false), è possibile verificare quali tracker sono stati inseriti o meno nelle informazioni nel campo 'trackers'",
  IT_CHECK = "Indica che l'utente da migrare ha dati di fatturazione italiani. (Per ora, consentiamo solo la migrazione di utenti con fatturazione in Italia per evitare problemi)",
  BILLING_DATA = "Indica che siamo riusciti a salvare i dati di fatturazione di Trackingram all'interno della nuova organizzazione su Whitelabel",
  SET_INVOICE_SETTINGS = "Indica che siamo riusciti a impostare il campo customer.invoice_setting per il metodo di pagamento di default chec serve in whitelabel ma non c'era in trackingram",
  REMOVE_STRIPE_SUB_TRACKINGRAM = "Indica che siamo riusciti a cancellare la subscription Stripe (e non il cliente) dell'utente Trackingram per evitare che rimangano attive le transazioni del vecchio account migrato",
  ADD_CUSTOM_SUB_PRICE = "Indica che siamo riusciti a impostare con successo un prezzo personalizzato per l'abbonamento agli Atrack",
  REMOVE_STRIPE_SUB_USER_REF_TRACKINGRAM = "Indica che siamo riusciti a eliminare il riferimento alla subscription Stripe all'interno dell'utente Trackingram. Questo è importante per evitare problemi in caso di annullamento della migrazione",
  CREATION_STRIPE_WHITELABEL = "Indica che è stato creato con successo un account Stripe per il nuovo utente su Whitelabel",
  CHANGE_STATE_TRACKERS_TRACKINGRAM = "Indica che siamo riusciti a cambiare lo stato dei localizzatori di Trackingram a 6, che significa 'restituito'. Questo evita di riattivare accidentalmente la subscription Stripe nel caso in cui il vecchio utente tenti di accedere nuovamente a Trackingram",
  SET_TRIAL_DAYS = "Indica se siamo riusciti a impostare i giorni di prova gratuita come richiesto dall'admin sul nuovo abbonamento stripe di whitelabel",
  UPDATE_ORGANIZATION_STRIPE="Indica se abbiamo aggiornato e inserito i campi stripe dentro whitelabel",
  REMOVE_FIREBASE_USER_TRACKINGRAM = "Indica che siamo riusciti a eliminare con successo l'utente Trackingram dal progetto Firebase",
  CHANGE_FLAG_LOGIN_ORGANIZATION = "Indica se siamo riusciti a impostare i flag di login di organization ovvero quelli per i contraolli iniziali come visualizzazione demo e richiesta inserimento metodo di pagamento",
  SETTING_API_FUNCTIONALITY = "Indica se siamo riusciti a verificare ed impostare correttamente i dati di abilitazione delle api",
  MIGRATION_POI = "Indica se siamo riusciti a migrare i poi da Trackingram a Flotta in Cloud",
  HAVE_PRICE_SUBSCRIPTION = "Indica che siamo riusciti a calcolare il nuovo prezzo della subscription (es, sub di trackingram a 0)",
  STRIPE_TRACKINGRAM_NOT_TRIAL = "Indica l'abbonamento di Trackingram ha un periodo di prova attivo"
}

export default StatusMigrationDescription;

export class MigrationStatus {
  
  raw: any;
  _id: string;
  id_user_trackingram: string;
  start_automation_process: number;
  email: string;
  platform_type: number;
  private _steps: any;
  stripe_customer_whitelabel: string;
  id_partecipant_whitelabel: string;
  id_organization_whitelabel: string;
  private _trackers: any[];
  organization_whitelabel: any[];
  automation_process_completed: boolean;
  end_automation_process: number;
  
  private _$last_migration_step: string;
  private $getTrackerWhiteLabel: (imei: string) => TrackerAdmin = () => {return undefined};
  private $getTrackerTrackingram: (imei: string) => any = () => {return undefined};
  
  
  constructor(rawObj?: any, options? : {getTrackerWhiteLabel?: (tracker: string) => TrackerAdmin, getTrackerTrackingram?: (id_tracker: string) => any}) {
    this.setAllFields(rawObj, options);
  }
  
  setAllFields(rawObj: any = {}, options?: {getTrackerWhiteLabel?: (tracker: string) => TrackerAdmin, getTrackerTrackingram?: (id_tracker: string) => any}) {
    if(options){
      if(options.getTrackerWhiteLabel) this.$getTrackerWhiteLabel = options.getTrackerWhiteLabel
      if(options.getTrackerTrackingram) this.$getTrackerTrackingram = options.getTrackerTrackingram
    }
    if (rawObj) {
      this.raw = rawObj;
      this._id = rawObj._id;
      this.email = rawObj.email;
      this.id_user_trackingram = rawObj.id_user_trackingram;
      this.start_automation_process = rawObj.start_automation_process;
      this.stripe_customer_whitelabel = rawObj.stripe_customer_whitelabel;
      this.id_partecipant_whitelabel = rawObj.id_partecipant_whitelabel;
      this.id_organization_whitelabel = rawObj.id_organization_whitelabel;
      this.organization_whitelabel = rawObj.organization_whitelabel;
      this.automation_process_completed = rawObj.automation_process_completed;
      this.end_automation_process = rawObj.end_automation_process;
      this.steps = rawObj.steps
      this.trackers = rawObj.trackers
    }
  }
  
  get steps(): any[] {
    return this._steps;
  }
  
  set steps(value: any[]) {
    this._steps = {}
    for(let key in value){
      this._steps[key] = {
        description: StatusMigrationDescription[key.toUpperCase()] || '',
        value: value[key]
      }
    }
    
    if(Object.keys(this._steps).length){
      this.$last_migration_step = this._steps[Object.keys(this._steps).length - 1];
    }
    
  }
  
  
  //ottengo la lista di tracker
  get trackers(): any[] {
    return this._trackers;
  }
  
  set trackers(value: any[]) {
    this._trackers = [];
    if(value && value.length){
      for(let t of value ){
        let tracker = this.$getTrackerWhiteLabel(t.imei)
        let tracker_trackingram = this.$getTrackerTrackingram(t.id_trackingram)
        
        this._trackers.push({...t, ...{tracker, tracker_trackingram}})
      }
    }
  }
  
  get $last_migration_step(): string {
    return this._$last_migration_step;
  }
  
  set $last_migration_step(value: string) {
    this._$last_migration_step = value;
  }
  
}


export class MigrationBatch {
  _id: string;
  name: string;
  private _$status: number;
  private _$software_status: number;
  private _emails: string[];
  timestamp_created: number;
  private _$user_trackingram: UserTrackingram[] = [];
  private _$user_white_label: OrganizationAdmin[] = [];
  batch_delete: boolean = false;
  configuration_price_type: number;
  configuration_price_id: string;
  configuration_sms_machine_admin_id: string;
  migration_log: string;
  
  private $getUserTrackingramByEmail: (email: string) => UserTrackingram = () => {return undefined};
  private $getUserWhiteLabel: (email: string) => OrganizationAdmin = () => {return undefined};
  private $getMigrationStatus: (id: string) => MigrationStatus = () => {return undefined};
  
  constructor(rawObj?: any, options? : {
    getUserTrackingramByEmail?: (email: string) => UserTrackingram,
    getUserWhiteLabel?: (email: string) => OrganizationAdmin,
    getMigrationStatus?: (id: string) => MigrationStatus,
  }) {
    this.setAllFields(rawObj, options);
  }
  
  setAllFields(rawObj: any = {}, options? : {
    getUserTrackingramByEmail?: (email: string) => UserTrackingram
    getUserWhiteLabel?: (email: string) => OrganizationAdmin
    getMigrationStatus?: (id: string) => MigrationStatus
  } ) {
    if(options){
      if(options.getUserTrackingramByEmail) this.$getUserTrackingramByEmail = options.getUserTrackingramByEmail
      if(options.getUserWhiteLabel) this.$getUserWhiteLabel = options.getUserWhiteLabel
      if(options.getMigrationStatus) this.$getMigrationStatus = options.getMigrationStatus
    }
    this._id = rawObj._id;
    this.name = rawObj.name;
    this.emails = rawObj.emails;
    this.$status = rawObj.status;
    this.timestamp_created = rawObj.timestamp_created;
    this.batch_delete = rawObj.batch_delete;
    this.configuration_price_type = rawObj.configuration_price_type ? parseInt(rawObj.configuration_price_type) : undefined;
    this.configuration_price_id = rawObj.configuration_price_id;
    this.configuration_sms_machine_admin_id = rawObj.configuration_sms_machine_admin_id;
    this.migration_log = rawObj.migration_log;
  }
  
  get emails(): string[] {
    return this._emails;
  }
  
  set emails(value: string[]) {
    this.$user_trackingram = []
    this.$user_white_label = []
    for(let email of value){
      const user = this.$getUserTrackingramByEmail(email);
      if(user){
        this.$user_trackingram.push(user)
      }
      
      const user_white_label = this.$getUserWhiteLabel(email);
      if(user_white_label){
        user_white_label.setData(user_white_label, {$getMigrationStatus: this.$getMigrationStatus})
        this.$user_white_label.push(user_white_label)
      }
    }
    
    this._emails = value;
  }
  
  get $user_trackingram(): UserTrackingram[] {
    return this._$user_trackingram;
  }
  
  set $user_trackingram(value: UserTrackingram[]) {
    this._$user_trackingram = value;
  }
  
  get $user_white_label(): OrganizationAdmin[] {
    return this._$user_white_label;
  }
  
  set $user_white_label(value: OrganizationAdmin[]) {
    this._$user_white_label = value;
  }
  
  get $status(): number {
    return this._$status;
  }
  
  set $status(value: number) {
    if (this.$user_trackingram && this.$user_trackingram?.length > 0) {
      
      let allCompleted = this.$user_trackingram.every((user) =>
        user.$migration_status?.automation_process_completed && (user.$count_tracker_white_label_programmed === user.$white_label_trackers?.length)
      );
  
      let software_finish_count = 0;
      this.$user_trackingram.forEach((elem) => {
        if(elem.$migration_status) software_finish_count++; // Se hanno il migration status sono considerati come finiti, perchè ho hanno completato la migrazione software oppure l'hanno terminata in errore
      })
      
      if(software_finish_count && software_finish_count === this.$user_trackingram.length){
        this.$software_status = 3 //completato
      }else if(software_finish_count && software_finish_count > 0){
        this.$software_status = 2; //in corso
      }else{
        this.$software_status = 1; //Da avviare
      }
      
      
      let migrationStarted = false;
      for(let user of this.$user_trackingram){
        if(user.$migration_status){
          migrationStarted = true;
        }
      }
      
      if (allCompleted) {
        this._$status = 3; // Stato 'Terminato'
      } else if (migrationStarted) {
        this._$status = 2; // Stato 'In corso'
      } else {
        this._$status = 1; // Stato 'Da avviare'
      }
    } else {
      this._$status = 1; // Stato 'Errore' se non ci sono utenti
    }
  }
  
  get $software_status(): number {
    return this._$software_status;
  }
  
  set $software_status(value: number) {
    this._$software_status = value;
  }
}
