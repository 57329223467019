import { NgModule } from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'organizations',
    pathMatch: 'full'
  },
  {
    path: 'chat',
    redirectTo: 'chat',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'organizations',
    loadChildren: () => import('./organizations/organizations.module').then( m => m.OrganizationsPageModule)
  },
  {
    path: 'detail',
    loadChildren: () => import('./detail/detail.module').then( m => m.DetailPageModule)
  },
  {
    path: 'trackers',
    loadChildren: () => import('./trackers/trackers.module').then( m => m.TrackersPageModule)
  },
  {
    path: 'tracker',
    loadChildren: () => import('./tracker/tracker.module').then( m => m.TrackerPageModule)
  },
  {
    path: 'insert-tracker',
    loadChildren: () => import('./insert-tracker/insert-tracker.module').then( m => m.InsertTrackerPageModule)
  },
  {
    path: 'formatta',
    loadChildren: () => import('./formatta/formatta.module').then( m => m.FormattaPageModule)
  },
  {
    path: 'sostituisci',
    loadChildren: () => import('./sostituisci/sostituisci.module').then( m => m.SostituisciPageModule)
  },
  {
    path: 'send-command',
    loadChildren: () => import('./send-command/send-command.module').then( m => m.SendCommandPageModule)
  },
  {
    path: 'change-url',
    loadChildren: () => import('./change-url/change-url.module').then( m => m.ChangeUrlPageModule)
  },
  {
    path: 'fake-positions',
    loadChildren: () => import('./fake-positions/fake-positions.module').then( m => m.FakePositionsPageModule)
  },
  {
    path: 'phonebook',
    loadChildren: () => import('./phonebook/phonebook.module').then( m => m.PhonebookPageModule)
  },
  {
    path: 'phonebook-activities',
    loadChildren: () => import('./phonebook-activities/phonebook-activities.module').then( m => m.PhonebookActivitiesPageModule)
  },
  {
    path: 'activity-add',
    loadChildren: () => import('./activity-add/activity-add.module').then( m => m.ActivityAddPageModule)
  },
  {
    path: 'company-edit',
    loadChildren: () => import('./company-edit/company-edit.module').then( m => m.CompanyEditPageModule)
  },
  {
    path: 'company-add',
    loadChildren: () => import('./company-add/company-add.module').then( m => m.CompanyAddPageModule)
  },
  {
    path: 'contact-add',
    loadChildren: () => import('./contact-add/contact-add.module').then( m => m.ContactAddPageModule)
  },
  {
    path: 'contact-edit',
    loadChildren: () => import('./contact-edit/contact-edit.module').then( m => m.ContactEditPageModule)
  },
  {
    path: 'contacts',
    loadChildren: () => import('./contacts/contacts.module').then( m => m.ContactsPageModule)
  },
  {
    path: 'companies',
    loadChildren: () => import('./companies/companies.module').then( m => m.CompaniesPageModule)
  },
  {
    path: 'test-alarm-notification',
    loadChildren: () => import('./test-alarm-notification/test-alarm-notification.module').then( m => m.TestAlarmNotificationPageModule)
  },
  {
    path: 'test-route-alarm',
    loadChildren: () => import('./test-route-alarm/test-route-alarm.module').then( m => m.TestRouteAlarmPageModule)
  },
  {
    path: 'modify-position',
    loadChildren: () => import('./modify-position/modify-position.module').then( m => m.ModifyPositionPageModule)
  },
  {
    path: 'attachment',
    loadChildren: () => import('./attachment/attachment.module').then( m => m.AttachmentPageModule)
  },
  {
    path: 'comment-zendesk',
    loadChildren: () => import('./comment-zendesk/comment-zendesk.module').then( m => m.CommentZendeskPageModule)
  },
  {
    path: 'sms',
    loadChildren: () => import('./sms/sms.module').then( m => m.SmsPageModule)
  },
  {
    path: 'company-select',
    loadChildren: () => import('./company-select/company-select.module').then( m => m.CompanySelectPageModule)
  },
  {
    path: 'inserimento-magazzino',
    loadChildren: () => import('./inserimento-magazzino/inserimento-magazzino.module').then( m => m.InserimentoMagazzinoPageModule)
  },
  {
    path: 'stock-amazon',
    loadChildren: () => import('./stock-amazon/stock-amazon.module').then( m => m.StockAmazonPageModule)
  },
  {
    path: 'stock-amazon-recap',
    loadChildren: () => import('./stock-amazon-recap/stock-amazon-recap.module').then( m => m.StockAmazonRecapPageModule)
  },
  {
    path: 'twilio-sim-status-select',
    loadChildren: () => import('./twilio-sim-status-select/twilio-sim-status-select.module').then( m => m.TwilioSimStatusSelectPageModule)
  },
  {
    path: 'stock-witek',
    loadChildren: () => import('./stock-witek/stock-witek.module').then( m => m.StockWitekPageModule)
  },
  {
    path: 'cc-emails-info',
    loadChildren: () => import('./cc-emails-info/cc-emails-info.module').then( m => m.CcEmailsInfoPageModule)
  },
  {
    path: 'whatsapp-msg-to-expired-chat',
    loadChildren: () => import('./whatsapp-msg-to-expired-chat/whatsapp-msg-to-expired-chat.module').then( m => m.WhatsappMsgToExpiredChatPageModule)
  },
  {
    path: 'check-payments',
    loadChildren: () => import('./check-payments/check-payments.module').then( m => m.CheckPaymentsPageModule)
  },
  {
    path: 'organization-detail',
    loadChildren: () => import('./organization-detail/organization-detail.module').then( m => m.OrganizationDetailPageModule)
  },
  {
    path: 'command-to-sim',
    loadChildren: () => import('./command-to-sim/command-to-sim.module').then( m => m.CommandToSimPageModule)
  },
  {
    path: 'codici-sconto-amazon',
    loadChildren: () => import('./codici-sconto-amazon/codici-sconto-amazon.module').then( m => m.CodiciScontoAmazonPageModule)
  },
  {
    path: 'aggiungi-sconti',
    loadChildren: () => import('./aggiungi-sconti/aggiungi-sconti.module').then( m => m.AggiungiScontiPageModule)
  },
  {
    path: 'landing-pages',
    loadChildren: () => import('./landing-pages/landing-pages.module').then( m => m.LandingPagesPageModule)
  },
  {
    path: 'aggiungi-landing',
    loadChildren: () => import('./aggiungi-landing/aggiungi-landing.module').then( m => m.AggiungiLandingPageModule)
  },
  {
    path: 'contatti-landing',
    loadChildren: () => import('./contatti-landing/contatti-landing.module').then( m => m.ContattiLandingPageModule)
  },
  {
    path: 'choose-number',
    loadChildren: () => import('./choose-number/choose-number.module').then( m => m.ChooseNumberPageModule)
  },
  {
    path: 'choose-owner',
    loadChildren: () => import('./choose-owner/choose-owner.module').then( m => m.ChooseOwnerPageModule)
  },
  {
    path: 'send-email',
    loadChildren: () => import('./send-email/send-email.module').then( m => m.SendEmailPageModule)
  },
  {
    path: 'marketing',
    loadChildren: () => import('./marketing/marketing.module').then( m => m.MarketingPageModule)
  },
  {
    path: 'send-sms',
    loadChildren: () => import('./send-sms/send-sms.module').then( m => m.SendSmsPageModule)
  },
  {
    path: 'reviews',
    loadChildren: () => import('./reviews/reviews.module').then( m => m.ReviewsPageModule)
  },
  {
    path: 'analytics',
    loadChildren: () => import('./analytics/analytics.module').then( m => m.AnalyticsPageModule)
  },
  {
    path: 'referral',
    loadChildren: () => import('./referral/referral.module').then( m => m.ReferralPageModule)
  },
  {
    path: 'edit-referral',
    loadChildren: () => import('./edit-referral/edit-referral.module').then( m => m.EditReferralPageModule)
  },
  {
    path: 'send-dispute',
    loadChildren: () => import('./send-dispute/send-dispute.module').then( m => m.SendDisputePageModule)
  },
  {
    path: 'edit-balance',
    loadChildren: () => import('./edit-balance/edit-balance.module').then( m => m.EditBalancePageModule)
  },
  {
    path: 'payment-actions',
    loadChildren: () => import('./payment-actions/payment-actions.module').then( m => m.PaymentActionsPageModule)
  },
  {
    path: 'supporto-tecnico',
    loadChildren: () => import('./supporto-tecnico/supporto-tecnico.module').then( m => m.SupportoTecnicoPageModule)
  },
  {
    path: 'adv',
    loadChildren: () => import('./adv/adv.module').then( m => m.AdvPageModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./orders/orders.module').then( m => m.OrdersPageModule)
  },
  {
    path: 'inserimento-magazzino-fota',
    loadChildren: () => import('./inserimento-magazzino-fota/inserimento-magazzino-fota.module').then( m => m.InserimentoMagazzinoFotaPageModule)
  },
  {
    path: 'controllo-trackers',
    loadChildren: () => import('./controllo-trackers/controllo-trackers.module').then( m => m.ControlloTrackersPageModule)
  },
  {
    path: 'new-call',
    loadChildren: () => import('./new-call/new-call.module').then( m => m.NewCallPageModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('./chat/chat.module').then( m => m.ChatPageModule),
  },
  {
    path: 'sms-to-phone',
    loadChildren: () => import('./sms-to-phone/sms-to-phone.module').then( m => m.SmsToPhonePageModule)
  },
  {
    path: 'whatsapp',
    redirectTo: 'chat/whatsapp'
  },
  {
    path: 'rubrica',
    redirectTo: 'chat/rubrica'
  },
  {
    path: 'activities-to-do',
    redirectTo: 'chat/activities-to-do'
  },
  {
    path: 'calls',
    redirectTo: 'chat/calls'
  },
  {
    path: 'tickets',
    redirectTo: 'chat/tickets'
  },
  {
    path: 'new-ticket',
    loadChildren: () => import('./new-ticket/new-ticket.module').then( m => m.NewTicketPageModule)
  },
  {
    path: 'create-message',
    loadChildren: () => import('./create-message/create-message.module').then( m => m.CreateMessagePageModule)
  },
  {
    path: 'new-contact',
    loadChildren: () => import('./new-contact/new-contact.module').then( m => m.NewContactPageModule)
  },
  {
    path: 'modify-ticket',
    loadChildren: () => import('./modify-ticket/modify-ticket.module').then( m => m.ModifyTicketPageModule)
  },
  {
    path: 'tracker-replace',
    loadChildren: () => import('./tracker-replace/tracker-replace.module').then( m => m.TrackerReplacePageModule)
  },
  {
    path: 'orders-archivied',
    loadChildren: () => import('./orders-archivied/orders-archivied.module').then( m => m.OrdersArchiviedPageModule)
  },
  {
    path: 'migration',
    loadChildren: () => import('./migration/migration.module').then( m => m.MigrationPageModule)
  },
  {
    path: 'start-migration',
    loadChildren: () => import('./start-migration/start-migration.module').then( m => m.StartMigrationPageModule)
  },
  {
    path: 'batch-migration',
    loadChildren: () => import('./batch-migration/batch-migration.module').then( m => m.BatchMigrationPageModule)
  },
  {
    path: 'reinvio-programmazione',
    loadChildren: () => import('./reinvio-programmazione/reinvio-programmazione.module').then( m => m.ReinvioProgrammazionePageModule)
  },
  {
    path: 'migrazioni-software-errore',
    loadChildren: () => import('./mingrazioni-sofware-errore/mingrazioni-sofware-errore.module').then( m => m.MingrazioniSofwareErrorePageModule)
  },
  {
    path: 'command-to-many-trackers',
    loadChildren: () => import('./command-to-many-trackers/command-to-many-trackers.module').then( m => m.CommandToManyTrackersPageModule)
  },
  {
    path: 'get-tracker-by-email',
    loadChildren: () => import('./get-tracker-by-email/get-tracker-by-email.module').then( m => m.GetTrackerByEmailPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
