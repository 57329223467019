import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import Activity from '../../classes/activity';
import {ViewService} from 'common-lib';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
})
export class ActivityComponent implements OnInit {

  @Input() public activity: Activity;
  @Output() public activity_click = new EventEmitter<{event: any, activity: Activity}>();
  @Output() public activity_right_click = new EventEmitter<{event: any, activity: Activity}>();
  
  constructor(
    public view: ViewService
  ) { }

  ngOnInit() {}
  
  async selectActivity(event: any, activity: Activity){
    this.activity_click.emit({event: event, activity: activity});
  }
  
  async selectRightActivity(event: any, activity: Activity){
    this.activity_right_click.emit({event: event, activity: activity});
  }
}
