import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {ViewService} from 'common-lib';
import {ModalController} from '@ionic/angular';
import {OrganizationAdmin, OrgProblemElement} from '../../classes/organizationAdmin';
import * as moment from 'moment';
import {ApiService} from '../../services/api.service';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-send-multiple-comunication',
  templateUrl: './send-multiple-comunication.component.html',
  styleUrls: ['./send-multiple-comunication.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendMultipleComunicationComponent {
  
  @Input() title: string;
  @Input() message: string;
  @Input() min_interval_days: number;
  @Input() organizations: OrgProblemElement[];
  @Input() stripe_timestamp_key: string;
  
  filtered_organization: OrgProblemElement[] = [];
  
  listener = {view: null};
  
  constructor(
    public view: ViewService,
    private cd: ChangeDetectorRef,
    private modalCtrl: ModalController,
    private api: ApiService,
    private data: DataService
  ) { }
  
  ionViewWillEnter() {}
  
  ionViewDidEnter() {
    this.listenerView();
    this.applyFilterLastCommunication();
  }
  
  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }
  
  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
    });
  }
  
  applyFilterLastCommunication(){
    this.filtered_organization = [];
    if(!this.min_interval_days){
      this.filtered_organization = this.organizations;
    }else{
      const now = moment().add(this.min_interval_days * -1, 'days');
      for(let organization of this.organizations){
        if(
          !organization.organization.stripe_timestamps_communication ||
          !organization.organization.stripe_timestamps_communication[this.stripe_timestamp_key] ||
          moment(organization.organization.stripe_timestamps_communication[this.stripe_timestamp_key] * 1000).isBefore(now, 'seconds')
        ){
          this.filtered_organization.push(organization);
        }
      }
    }
    ViewService.updateView.next();
  }
  
  async sendCommunication() {
    if(this.stripe_timestamp_key === '15'){ // Se è un reminder per i bonifici
      return this.sendCommunicationBankTransfer();
    }
    await this.view.showLoading("Invio comunicazioni...");
    let ids: string[] = this.filtered_organization.map((elem: any) => elem.organization?._id);
    try{
      const res: any = await this.api.patchOrganizationSendLevelCommunication({ids: ids, body: {level: parseInt(this.stripe_timestamp_key)}});
      if(res && res.length > 0){
        for(let raw of res){
          let aus = this.filtered_organization.find((elem: OrgProblemElement) => elem.organization?._id === raw._id);
          if(aus){
            aus.organization.setData(raw, {$getPlatformByType: this.data.getPlatformByType});
          }
        }
        this.cd.markForCheck();
        await this.view.presentAlert('Successo', 'Comunicazioni inviate', ['Ok']);
        this.dismiss();
      }
    }catch(err){
      console.error(err);
      await this.view.presentAlert('Errore', 'Impossibile inviare le comunicazio ni', ['Ok']);
    }
    await this.view.hideLoading();
  }
  
  async sendCommunicationBankTransfer() {
    let list: any[] = [];
    for(let organization of this.filtered_organization){
      list.push({
        id_organization: organization.organization._id,
        invoice_ids: organization.bank_transfert_to_check_list.map((elem) => elem._id)
      });
    }
    await this.view.showLoading("Invio comunicazioni...");
    try{
      const res: any = await this.api.postBankTransfersReminder(list);
      console.log(res);
      if(res?.organizations){
        for(let organization of this.filtered_organization){
          if(res.organizations[organization.organization._id]){
            organization.organization.setData(res.organizations[organization.organization._id]);
          }
        }
      }
      this.cd.markForCheck();
      await this.view.presentAlert('Successo', 'Comunicazioni inviate', ['Ok']);
      this.dismiss();
    }catch(err){
      console.error(err);
      await this.view.presentAlert('Errore', 'Impossibile inviare le comunicazio ni', ['Ok']);
    }
    await this.view.hideLoading();
  }
  
  
  dismiss() {
    this.modalCtrl.dismiss();
  }
}
