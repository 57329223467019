import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import {PopoverController} from "@ionic/angular";

declare type Type = "checkboxes" | "classic" | "contacts" | undefined

@Component({
  selector: 'app-popover-select',
  templateUrl: './popover-select.component.html',
  styleUrls: ['./popover-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopoverSelectComponent implements OnInit {

  @Input() options: any[];
  @Input() message: string;
  @Input() type: Type;
  
  choice: any;
  
  constructor(
    private popoverCtrl: PopoverController,
  ) {}
  
  ngOnInit() {
  }
  
  ionViewWillEnter() {
  }
  
  ionViewDidEnter() {
  }
  
  ionViewWillLeave() {
  }
  
  ionViewDidLeave() {
  }

  ngOnDestroy() {
  }
  
  async dismiss(data: any) {
    await this.popoverCtrl.dismiss(data)
  }
  
}
