import {ListParsers} from "./listParsers";

export class Command {

  raw: any;
  _id: string;
  type: number;
  type_label: string;
  status_commandTrackerGps: number;
  status_commandTrackerGps_label: string;
  timestamp_lastUpdate: number;
  max_retry: number;
  count_retry: number;
  timestamp_insert: number;

  constructor(data?: any) {
    if(data){
      this.setData(data);
    }
  }

  setData(data: any){
    this.raw = data;
    this._id = data._id;
    this.type = data.type;
    this.type_label = ListParsers.parseTypeCommand(this.type);
    this.status_commandTrackerGps = data.status_commandTrackerGps;
    this.status_commandTrackerGps_label = ListParsers.parseCommandState(this.status_commandTrackerGps);
    this.timestamp_lastUpdate = data.timestamp_lastUpdate;
    this.max_retry = data.max_retry;
    this.count_retry = data.count_retry;
    this.timestamp_insert = data.timestamp_insert;
  }
}
