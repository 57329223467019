import { Pipe, PipeTransform } from '@angular/core';
import {Ticket} from '../classes/ticket';

@Pipe({
  name: 'ticketsSort'
})
export class TicketsSortPipe implements PipeTransform {
  
  transform(tickets: Ticket[], type: 'pinned' | 'important' | 'not_important', filtro: string, changed?: number): Ticket[] {
    if(!tickets || tickets.length <= 0) return tickets;
    
    if(filtro === 'to_read'){
      tickets = tickets.filter((element) => element.unread_messages > 0);
    }
    
    switch (type) {
      
      case 'pinned': {
        let pinned: Ticket[] = tickets.filter((element) => (element.status === 1 || element.status === 2) && element.pinned === true);
        pinned = pinned.sort((a, b) => {
          if (!a.last_update && b.last_update) {
            // a non ha last_update, b ha last_update
            return 1;
          }
          if (a.last_update && !b.last_update) {
            // a ha last_update, b non ha last_update
            return -1;
          }
          if (!a.last_update && !b.last_update) {
            // entrambi non hanno last_update, lascia l'ordine inalterato
            return 0;
          }
          // entrambi hanno last_update, usa la logica precedente
          if (a.last_update < b.last_update) {
            return 1;
          }
          if (a.last_update > b.last_update) {
            return -1;
          }
          return 0;
        });
        return pinned;
      }
  
      case 'important': {
        let important: Ticket[] = tickets.filter((element) => (element.status === 1 || element.status === 2) && !element.pinned && element.important === true);
        important = important.sort((a, b) => {
          if (!a.last_update && b.last_update) {
            // a non ha last_update, b ha last_update
            return 1;
          }
          if (a.last_update && !b.last_update) {
            // a ha last_update, b non ha last_update
            return -1;
          }
          if (!a.last_update && !b.last_update) {
            // entrambi non hanno last_update, lascia l'ordine inalterato
            return 0;
          }
          // entrambi hanno last_update, usa la logica precedente
          if (a.last_update < b.last_update) {
            return 1;
          }
          if (a.last_update > b.last_update) {
            return -1;
          }
          return 0;
        });
        return important;
      }
  
      case 'not_important': {
        let not_important: Ticket[] = tickets.filter((element) => (element.status === 1 || element.status === 2) && !element.pinned && !element.important);
        not_important = not_important.sort((a, b) => {
          if (!a.last_update && b.last_update) {
            // a non ha last_update, b ha last_update
            return 1;
          }
          if (a.last_update && !b.last_update) {
            // a ha last_update, b non ha last_update
            return -1;
          }
          if (!a.last_update && !b.last_update) {
            // entrambi non hanno last_update, lascia l'ordine inalterato
            return 0;
          }
          // entrambi hanno last_update, usa la logica precedente
          if (a.last_update < b.last_update) {
            return 1;
          }
          if (a.last_update > b.last_update) {
            return -1;
          }
          return 0;
        });
        return not_important;
      }
    }
    
    
    
  }
  
  
}
