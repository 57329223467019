
type Options = {
  // getAdmin?: (id: string) => Admin | undefined
  // getOrganization?: (id: string) => OrganizationAdmin | undefined
}

export default class TwilioCommand {
  
  dateCreated: string
  direction: string
  command: string
  status: string
  
  // * custom field/prop
  
  
  
  // * injected functions
  
  // private $getAdmin: (id: string) => Admin | undefined = 
  //   () => undefined;
  // private $getOrganization: (id: string) => OrganizationAdmin | undefined = 
  //   () => undefined;
  
  // * constructor
  
  constructor(rawObj?: any, options?: Options) {
    this.setAllFields(rawObj, options);
  }
  
  // * methods
  
  // { pinned }
  // ? see also: Logical nullish assignment (??=)
  setAllFields(rawObj: any = {}, options: Options = {}) {
    if (options) {
      // if (options.getAdmin) this.$getAdmin = options.getAdmin;
      // if (options.getOrganization) this.$getOrganization = options.getOrganization;
    }
    if (rawObj) {
      this.dateCreated = rawObj.dateCreated;
      this.direction = rawObj.direction;
      this.command = rawObj.command;
      this.status = rawObj.status;
      
      // ? this.hasOwnProperty(prop) makes sense 
      // ? rawobj.hasOwnProperty(prop) does NOT
      // for (const prop in rawObj) {
      //   if (rawObj.hasOwnProperty(prop)) {
      //     this[prop] = rawObj[prop];
      //   }
      // }
      
    }
  }
  
  readIccidImeiFromSMS(): {imei: string, iccd: string}{
    let response: any = {imei: '', iccid: ''};
    if(this.status === 'received' && this.command && this.command.indexOf('IMEI:') >= 0 && this.command.indexOf('CCID:') >= 0){
      // Recupero imei e iccid da sms
      const parts: string[] = this.command.split(',');
      if(parts.length === 2){
        response.imei = parts[0];
        response.imei = response.imei.replace('IMEI: ', '');
        response.imei = response.imei.replace(',', '');
        response.iccid = parts[1];
        response.iccid = response.iccid.replace(' CCID: ', '');
      }
    }
    return response;
  }
  
  readResponseCommandFromSMS(expected_response: string): boolean{
    if(this.status === 'received' && this.command && this.command.indexOf(expected_response) >= 0){
      return true;
    }
    return false;
  }
  
  // * getter/setter
  // sort: default(database) fields followed by custom($) fields
  
  
  
  // * getter/setter $customField
  
  
  
  
}
