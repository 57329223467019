import { Pipe, PipeTransform } from '@angular/core';
import {WebMessage} from '../classes/webMessages';
import {WhatsappMessage} from '../classes/whatsappMessage';
import {EmailMessage} from '../classes/emailMessage';

@Pipe({
  name: 'contactsMessages'
})
export class ContactsMessagesPipe implements PipeTransform {
  transform(messages: (WebMessage | WhatsappMessage | EmailMessage)[], contacts: any, changed?: number): (WebMessage | WhatsappMessage | EmailMessage)[] {
    if(!messages || messages.length <= 0) return messages;
    if(!contacts || Object.keys(contacts).length <= 0) return messages;
  
    let filtered: (WebMessage | WhatsappMessage | EmailMessage)[] = [];
    for(const message of messages){
      if(message instanceof WebMessage && (contacts[message.from] || contacts[message.to])){
        filtered.push(message);
        continue;
      }
      if(message instanceof WhatsappMessage && contacts[message.$customer_phone]){
        filtered.push(message);
        continue;
      }
      if(message instanceof EmailMessage && contacts[message.email_customer]){
        filtered.push(message);
      }
    }
    return filtered;
  }


}
