import { Pipe, PipeTransform } from '@angular/core';
import Activity from '../classes/activity';

@Pipe({
  name: 'activitiesFilter'
})
export class ActivitiesFilterPipe implements PipeTransform {
  
  transform(activities: Activity[], ticket: string, status: string, changed: number): Activity[]{
    
    if(!activities) return [];
    
    let filtered: Activity[] = [];
    
    if(ticket){
      for(const activity of activities){
        if(activity.id_ticket && activity.id_ticket === ticket){
          filtered.push(activity);
        }
      }
    }else{
      filtered = activities;
    }
    
    if(status === 'tutti'){
      return filtered;
    }
    
    const type_filtered: Activity[] = [];
    
    switch (status){
      case 'fatti':
        for(const activity of filtered){
          if(activity.status === 3){
            type_filtered.push(activity);
          }
        }
        break;
      case 'da_fare':
        for(const activity of filtered){
          if(activity.status !== 3){
            type_filtered.push(activity);
          }
        }
        break;
    }
    return type_filtered;
  }

}
