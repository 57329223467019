export class ChipCompany{
  
  _id: string;
  category: ChipCompanyCategory;
  code: string;
  order: number;
  name: string;
  
  
  constructor(data?: any) {
    this.setAllFields(data);
  }
  
  setAllFields(data: any) {
    this._id = data._id;
    this.category = data.category;
    this.code = data.code;
    this.order = data.order;
    this.name = data.name;
  }
}

export enum ChipCompanyCategory {
  'Commerciale Azienda' = 1,
  'Commerciale Privato',
  'Assistenza'
}

type Options = {
  getChipCompany?: (string) => ChipCompany | undefined;
}

export class ChipCompanyHistory{
  
  private _chip_id: string;
  added: boolean;
  idAdmin: string;
  
  $chip: ChipCompany;
  
  $getChipCompany: (string) => ChipCompany = () => {return undefined};
  
  constructor(data?: any, options?: Options) {
    this.setAllFields(data, options);
  }
  
  setAllFields(data: any, options?: Options) {
    if(options){
      if (options.getChipCompany) this.$getChipCompany = options.getChipCompany;
    }
    this.chip_id = data.chip_id;
    this.added = data.added;
    this.idAdmin = data.idAdmin;
  }
  
  get chip_id(): string{
    return this._chip_id;
  }
  
  set chip_id(value: string){
    this._chip_id = value;
    this.$chip = this.$getChipCompany(this._chip_id);
  }
}
