import {OrganizationAdmin} from './organizationAdmin';
import Invoice from './invoice';
import * as moment from 'moment';
import {DataService} from '../services/data.service';
import {Subscription} from 'rxjs';
import {Ticket} from './ticket';

type Options = {
  $getOrganizationById?: (id: string) => OrganizationAdmin;
  $checkActivationImei?: (list_imei: string[]) => { $imei_activated: any, $imei_not_activated: any };
  $getTicketByOrderId?: (order_id: string) => Ticket;
  $elenco_prodotti_shop?: any;
  
}

export class Order{
  
  _id: string;
  order_id: string;
  $raw: any;
  private _organization_id: string;
  $organization: OrganizationAdmin;
  $organizationListener: Subscription;
  created_timestamp: number;
  invoice_id: string;
  $invoice: Invoice;
  $ticket: Ticket;
  url_tracking: string;
  shipping_address: {
    name: string,
    address1: string,
    address2: string,
    city: string,
    zipcode: number,
    state: string,
    country: string,
    phone: string
  };
  status: number; // 1 non gestito; 2 preparato; 3 spedito; 4: ricevuto, 5: attivati: 99 xcancellato
  list_imei: string[];
  note: string;
  private _shipping_timestamp: number;
  shipping_number: string;
  private _delivered_timestamp: number;
  type: number; //1 standard, 2 free trial
  
  $shipping_days: number; // Giorni passati dalla spedizione
  $delivered_days: number; // Giorni passati dalla ricezione
  
  $pieces: number; // Numero pezzi in ordine
  $imei_activation_status: {$imei_activated: any, $imei_not_activated: any}; // Lista di imei attivati e non
  $problem_with_delivery: boolean;
  
  // Bolla di trasporto
  delivery_note_number: number; // Numero incrementale bolla di trasporto
  transportation: string; // Incaricato al trasporto
  subject_transportation: string; // Causale di trasporto
  n_packages: number; // Numero colli
  weight: number; // Peso in kg
  port: string; // Porto
  transportation_note: string; // Nota di trasporto
  date_start_shipping: number; // Timestamp inizio trasporto
  delivery_note_creation_date: number; // Data creazione bolla o modifica
  delivery_client_info: any; // Oggetto on le info del cliente nella bolla di trasporto
  archivied: boolean;
  
  $getOrganizationById: (id: string) => OrganizationAdmin = () => {return undefined};
  $checkActivationImei: (list_imei: string[], id_organization: string) => { $imei_activated: any, $imei_not_activated: any } = () => {return {$imei_activated: [], $imei_not_activated: []}};
  $elenco_prodotti_shop: any;
  $getTicketByOrderId: (order_id: string) => Ticket = () => {return undefined};
  
  constructor(data?: any, options?: Options) {
    this.setAllFields(data, options);
  }
  
  setAllFields(data: any, options?: Options) {
    if(options?.$getOrganizationById) this.$getOrganizationById = options.$getOrganizationById;
    if(options?.$checkActivationImei) this.$checkActivationImei = options.$checkActivationImei;
    if(options?.$elenco_prodotti_shop) this.$elenco_prodotti_shop = options.$elenco_prodotti_shop;
    if(options?.$getTicketByOrderId) this.$getTicketByOrderId = options.$getTicketByOrderId;
    if (data) {
      this.$raw = data;
      this._id = data._id;
      this.order_id = data.order_id;
      this.created_timestamp = data.created_timestamp;
      this.invoice_id = data.invoice_id;
      this.organization_id = data.organization_id; // deve stare dopo l'invoice_id
      this.url_tracking = data.url_tracking;
      this.shipping_address = data.shipping_address;
      
      this.status = data.status || 0;
      this.list_imei = data.list_imei || [];
      this.note = data.note;
      this.shipping_timestamp = data.shipping_timestamp;
      this.$problem_with_delivery = false;
      this.shipping_number = data.shipping_number;
      this.delivery_note_number = data.delivery_note_number;
      this.delivered_timestamp = data.delivered_timestamp;
      this.setActivationStatus();
      this.type = data.type;
      
      this.transportation = data.transportation;
      this.subject_transportation = data.subject_transportation;
      this.n_packages = data.n_packages;
      this.weight = data.weight;
      this.port = data.port;
      this.transportation_note = data.transportation_note;
      this.date_start_shipping = data.date_start_shipping;
      this.delivery_note_creation_date = data.delivery_note_creation_date;
      this.delivery_client_info = data.delivery_client_info;
      this.archivied = data.archivied || false;
    }
  }
  
  get organization_id(): string {
    return this._organization_id;
  }
  
  set organization_id(value: string) {
    this._organization_id = value;
    this.$organization = this.$getOrganizationById(this.organization_id);
    if(!this.$organization) return;
    let elenco_prodotti = DataService.general_parameter.elenco_prodotti_shop.find((elem)=>elem.platform_type === this.$organization.platform_type);
    if(!this.$organizationListener){ // Setto lister per aggiornamento organization
      this.$organizationListener = OrganizationAdmin.organizationUpdate$.subscribe((organization?: OrganizationAdmin) => {
        if(organization._id === this._organization_id){
          if(this.$organization !== organization){
            this.organization_id = organization._id; // Richiamo il setter
          }else{
            // potrebbe esserci lo stesso oggetto organization ma la fattura legata all'ordine potrebbe essere cambiata
            this.$invoice = this.$organization.getInvoiceByIdStripe(this.invoice_id);
            if(this.$invoice){
              this.$invoice.mapInvoiceLineWithProduct(elenco_prodotti.products, this.$invoice.metadata?.type );
            }
            if(this.$invoice?.lines?.length > 0){
              this.$pieces = 0;
              for(let line of this.$invoice.lines){
                this.$pieces += line.quantity || 0;
              }
            }
          }
        }
      });
    }
    if(this.$organization){
      this.$invoice = this.$organization.getInvoiceByIdStripe(this.invoice_id);
      if(this.$invoice){
        this.$invoice.mapInvoiceLineWithProduct(elenco_prodotti.products, this.$invoice.metadata?.type);
      }
      if(this.$invoice?.lines?.length > 0){
        this.$pieces = 0;
        for(let line of this.$invoice.lines){
          this.$pieces += line.quantity || 0;
        }
      }
    }
    
    if(this.$invoice?.metadata?.type === 'free'){
      this.$ticket = this.$getTicketByOrderId(this._id)
    }
    
  }
  
  get shipping_timestamp(): number {
    return this._shipping_timestamp;
  }
  
  set shipping_timestamp(value: number) {
    this._shipping_timestamp = value;
    this.$shipping_days = moment().diff(moment(this.shipping_timestamp), 'days');
  }
  
  get delivered_timestamp(): number {
    return this._delivered_timestamp;
  }
  
  set delivered_timestamp(value: number) {
    this._delivered_timestamp = value;
    this.$delivered_days = moment().diff(moment(this.delivered_timestamp), 'days');
  }
  
  setActivationStatus(){
    this.$imei_activation_status = this.$checkActivationImei(this.list_imei, this.organization_id);
  }
}
