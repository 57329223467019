import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  AlertController,
  ModalController,
  NavController,
  PopoverController,
  ToastController
} from '@ionic/angular';
import {
  AuthService, Utility,
  ViewService
} from 'common-lib';
import {ApiService} from "../../services/api.service";
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from 'src/services/data.service';
import Company from 'src/classes/company';
import {WhatsappChat} from "../../classes/whatsappChat";
import {EmailChat} from "../../classes/emailChat";


@Component({
  selector: 'app-company-add',
  templateUrl: './company-add.page.html',
  styleUrls: ['./company-add.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyAddPage implements OnInit {
  
  @Input() company: Company;
  @Input() type: 'data' | 'contacts';
  phones: WhatsappChat[] = [];
  emails: EmailChat[] = [];
  
  @Input() input_phones: string;
  @Input() input_emails: string;
  @Input() input_name: string;
  @Input() input_type: number;
  input_note: string;
  input_review_status: number;

  listener = {
    view: null
  };
  
  constructor(
    private cd: ChangeDetectorRef,
    private api: ApiService,
    private data: DataService,
    private modalCtrl: ModalController,
    private auth: AuthService,
    private nav: NavController,
    public popoverController: PopoverController,
    private route: ActivatedRoute,
    private router: Router,
    private toastCtrl: ToastController,
    private view: ViewService,
    private alertCtrl: AlertController
  ) { }

  ngOnInit() {
  }
  
  ionViewDidEnter() {
    this.listenerView();
    if(this.company){
      this.input_name = this.company.label;
      this.input_note = this.company.note;
      this.input_review_status = this.company.review_status;
      this.input_type = this.company.type;
      this.composeListContact();
      ViewService.updateView.next();
    }
  }

  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }

  dismiss(data?: any) {
    this.modalCtrl.dismiss(data);
  }
  
  composeListContact(){
    let aus_contact: any = {};
    this.phones = [];
    this.emails = [];
    for(let chat of this.company.whatsapp_chats){
      aus_contact[chat.$customer_phone] = chat;
    }
    for(let chat of this.company.sms_chats){
      aus_contact[chat.chat_customer] = chat;
    }
    for(let chat of this.company.call_chats){
      aus_contact[chat.chat_customer] = chat;
    }
    for(let k in aus_contact){
      this.phones.push(aus_contact[k]);
    }
    aus_contact = {};
    for(let chat of this.company.email_chats){
      aus_contact[chat.email_customer] = chat;
    }
    for(let k in aus_contact){
      this.emails.push(aus_contact[k]);
    }
  }

  async save() {
    if(this.company) return this.update();
    
    await this.view.showLoading("Creazione azienda in corso");
    
    try{
      const res: any = await this.api.postCompany({
        label: this.input_name,
        note: this.input_note,
        type: this.input_type
      });
      this.view.presentAlert('Successo', 'Azienda creata', ['Ok']);
      await this.view.hideLoading();
      this.dismiss(res?._id);
    }catch (e) {
      console.error(e);
      this.view.presentAlert('Errore', 'Errore in creazione company ', ['Ok']);
      await this.view.hideLoading();
    }
  }
  
  async update() {
    await this.view.showLoading("Modifica azienda in corso");
    
    try{
      let reviewStatus: number;
      try{
        reviewStatus = this.company.review_status !== parseInt(this.input_review_status?.toString()) ?  parseInt(this.input_review_status.toString()) : undefined
      }catch(err){
        reviewStatus = undefined;
      }
      const res: any = await this.api.patchCompany(this.company._id, {
        label: this.input_name,
        note: this.input_note,
        type: this.input_type,
        review_status: reviewStatus
      });
      this.company.setAllFields(res);
      this.view.presentAlert('Successo', 'Azienda modificata', ['Ok']);
      await this.view.hideLoading();
      this.dismiss();
    }catch (e) {
      console.error(e);
      this.view.presentAlert('Errore', 'Errore generico', ['OK']);
      await this.view.hideLoading();
    }
  }
  
  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
    });
  }

}
