import { Pipe, PipeTransform } from '@angular/core';
import {AdvFinalized} from '../classes/advFinalized';
import * as moment from 'moment';

@Pipe({
  name: 'advFinalizedFilters'
})
export class AdvFinalizedFiltersPipe implements PipeTransform {
  
  transform(
    list: AdvFinalized[],
    associations: 'all' | 'organization' | 'company',
    status: 'all' | 'closed' | 'to_close',
    interval: 'all' | 'today' | 'week' | 'month',
    date_order: 'decrescent' | 'crescent',
    managed: 'all' | 'managed' | 'unmanaged',
    changed: number
  ): any {
    
    if(!list || list.length <= 0) return list;
    
    let filtered: AdvFinalized[] = [];
    
    for(let adv of list){
      if(
        associations === 'all' ||
        (associations === 'organization' && adv.id_organization) ||
        (associations === 'company' && adv.id_company)
      ){
        if(
          status === 'all' ||
          (status === 'closed' && adv.is_closed) ||
          (status === 'to_close' && !adv.is_closed)
        ){
          
          const creation_time : any = moment(adv.creation_time);
          switch (interval){
            case 'all': {
              filtered.push(adv);
            }
              break;
            case 'today': {
              if(creation_time.isBetween(moment().startOf('day'), moment().endOf('day'))){
                filtered.push(adv);
              }
            }
              break;
            case 'week': {
              if(creation_time.isBetween(moment().startOf('week'), moment().endOf('week'))){
                filtered.push(adv);
              }
            }
              break;
            case 'month': {
              if(creation_time.isBetween(moment().startOf('month'), moment().endOf('month'))){
                filtered.push(adv);
              }
            }
              break;
          }
          
        }
      }
    }
    
    /** Array finale **/
    const filteredResult = [];
    
    for (let i = 0; i < filtered.length; i++) {
      switch (managed) {
        case 'all':
          filteredResult.push(filtered[i])
          break;
        case 'unmanaged':
          // Ottieni i visitatori non gestiti
          if (!filtered[i].managed || filtered[i].managed === undefined  ) {
            filteredResult.push(filtered[i]);
          }
          break;
        case 'managed':
          // Ottieni i visitatori gestiti
          if (filtered[i].managed) {
            filteredResult.push(filtered[i]);
          }
          break;
      }
    }
    
    if(filteredResult.length > 0){
      if(date_order === 'crescent'){
        filteredResult.sort((a: AdvFinalized, b: AdvFinalized) => a.creation_time - b.creation_time);
      }else{
        filteredResult.sort((a: AdvFinalized, b: AdvFinalized) => b.creation_time - a.creation_time);
      }
    }
    
    return filteredResult;
  }
}
