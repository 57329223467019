import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Ticket} from '../../classes/ticket';
import {ViewService} from 'common-lib';

@Component({
  selector: 'app-ticket-card',
  templateUrl: './ticket-card.component.html',
  styleUrls: ['./ticket-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketCardComponent implements OnInit {

  @Input() ticket: Ticket;
  @Input() selected: boolean = false;
  @Output() clickEvent = new EventEmitter<Ticket>();
  
  listener = {
    view: null
  };
  
  constructor(
    public view: ViewService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.listenerView();
  }
  
  async ionViewDidEnter() {
  }
  
  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }
  
  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
    });
  }
}
