import {
  Injectable, Pipe,
  PipeTransform
} from '@angular/core';

@Pipe({
  name: 'chatIsExpired'
})
@Injectable()
export class ChatIsExpiredPipe implements PipeTransform {
  transform(window_24h: number, changed?: number): boolean {
    return !window_24h || (window_24h + (24 * 60 * 60 * 1000)) < Date.now();
  }
}


