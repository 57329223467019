import { Pipe, PipeTransform } from '@angular/core';
import {Ticket} from '../classes/ticket';

@Pipe({
  name: 'searchTickets'
})
export class SearchTicketsPipe implements PipeTransform {
  
  transform(tickets: Ticket[], query: string, changed: number): Ticket[] {
    
    if(!query) return tickets;
    
    query = query.toLowerCase();
    
    let filtered: Ticket[] = [];
    
    for(const ticket of tickets){
      
      if(ticket.status === 3) {
        continue;
      }
      
      if(ticket.num?.toLowerCase().indexOf(query) >= 0){
        filtered.push(ticket);
        continue;
      }
      
      if(ticket.$contacts){
        let found: boolean = false;
        for(const contact of ticket.$contacts){
          if(contact.name?.toLowerCase().indexOf(query) >= 0){
            filtered.push(ticket);
            break;
          }
          if(contact.phones){
            for(const phone in contact.phones){
              if(phone.indexOf(query) >= 0){
                filtered.push(ticket);
                found = true;
                break;
              }
            }
          }
          if(!found && contact.$list_emails){
            for(const email of contact.$list_emails){
              if(email.indexOf(query) >= 0){
                filtered.push(ticket);
                found = true;
                break;
              }
            }
          }
          if(found) break;
        }
        if(found) continue;
      }
  
      if(ticket.$organization?.login_email_partecipant_owner?.indexOf(query) >= 0){
        filtered.push(ticket);
        continue;
      }
  
      if(ticket.$company?.label?.toLowerCase().indexOf(query) >= 0){
        filtered.push(ticket);
      }
    }
    
    return filtered;
  }


}
