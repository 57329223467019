import {Component, Input, OnInit} from '@angular/core';
import {TwilioService} from '../../services/twilio.service';

@Component({
  selector: 'app-popover-audio-setting',
  templateUrl: './popover-audio-setting.component.html',
  styleUrls: ['./popover-audio-setting.component.scss'],
})
export class PopoverAudioSettingComponent implements OnInit {

  // @Input() audio_list: MediaDeviceInfo[] = [];
  @Input() mic_list: MediaDeviceInfo[] = [];
  // @Input() selected_audio: string;
  @Input() selected_mic: string;
  
  constructor(
    public twilio: TwilioService,
  ) { }

  ngOnInit() {
    console.log(this.mic_list)
  }
  
  
  setMicAudio(event: any){
    console.log(event);
    this.twilio.client.audio.setInputDevice(event).then(() => {
      console.info('Success!');
      TwilioService.setInputDevice.next({id: event})
    });
  }


}
