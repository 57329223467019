import {Component, Input, OnInit} from '@angular/core';
import {OrganizationAdmin} from '../../classes/organizationAdmin';

@Component({
  selector: 'app-organization-card',
  templateUrl: './organization-card.component.html',
  styleUrls: ['./organization-card.component.scss'],
})
export class OrganizationCardComponent implements OnInit {

  @Input() organization: OrganizationAdmin;
  
  constructor() { }

  ngOnInit() {}

}
