import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {ViewService as View, ViewService} from "common-lib";
import {DataService} from "../../services/data.service";
import {Storage} from '@ionic/storage';
import {ApiService} from "../../services/api.service";

@Component({
  selector: 'app-change-url',
  templateUrl: './change-url.page.html',
  styleUrls: ['./change-url.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeUrlPage implements OnInit {

  listener = {
    view: null,
  };

  preselectUrl: string;
  preselectUrl2: string;
  url: string;
  url2: string;
  urls: string[] = [
    'https://api-dev.balin.app',
    'https://localizzazione-smartphone.oa.r.appspot.com',
    'https://white-label-dev-635e0.ey.r.appspot.com',
    'https://api.495318.net',
    'https://api2.495318.net'
  ];

  constructor(
    private modalController: ModalController,
    private view: ViewService,
    private ref: ChangeDetectorRef,
    public data: DataService,
    private storage: Storage,
    private api: ApiService
  ) { }

  ngOnInit() {
    this.listenerView();

  }

  dismiss(data?: any){
    this.modalController.dismiss(data);
  }

  async confirm(){
    if(this.url) await this.storage.set('baseUrl', this.url);
    if(this.url2) await this.storage.set('baseUrlCommunication', this.url2);
    if(this.preselectUrl) await this.storage.set('baseUrl', this.preselectUrl);
    if(this.preselectUrl2) await this.storage.set('baseUrlCommunication', this.preselectUrl2);
    
    if(this.url || this.url2 || this.preselectUrl || this.preselectUrl2){
      window.location.reload();
    }
  }

  // listener
  listenerView() {
    this.listener.view = View.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.ref.markForCheck();
      setTimeout(() => {
        this.ref.markForCheck();
      }, 250);
    });
  }

}
