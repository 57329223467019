import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {JsonEditorComponent, JsonEditorOptions} from 'ang-jsoneditor';

@Component({
  selector: 'app-show-json',
  templateUrl: './show-json.component.html',
  styleUrls: ['./show-json.component.scss'],
})
export class ShowJsonComponent implements OnInit {

  @Input() title: string;
  @Input() data: any;
  
  editorOptions: JsonEditorOptions;
  @ViewChild(JsonEditorComponent, { static: false }) editor: JsonEditorComponent;
  
  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.indentation = 1;
    this.editorOptions.expandAll = false;
    this.editorOptions.mainMenuBar = true;
    this.editorOptions.statusBar = false;
    this.editorOptions.mode = 'tree';
    this.editorOptions.modes = ['tree', 'code'];
  }
  
  dismiss() {
    this.modalCtrl.dismiss();
  }
}
