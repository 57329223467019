import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Ticket} from '../../classes/ticket';
import {ViewService} from 'common-lib';
import {PopoverSelectComponent} from '../popover-select/popover-select.component';
import {ModalController, PopoverController} from '@ionic/angular';
import {ApiService} from '../../services/api.service';
import {ModifyTicketPage} from '../../app/modify-ticket/modify-ticket.page';

@Component({
  selector: 'app-ticket-list-elment',
  templateUrl: './ticket-list-elment.component.html',
  styleUrls: ['./ticket-list-elment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketListElmentComponent implements OnInit {
  
  @Input() ticket: Ticket;
  @Input() selected: boolean;
  
  @Output() dxClick = new EventEmitter<any>();
  @Output() sxClick = new EventEmitter<any>();
  
  listener = {
    view: null
  };
  
  constructor(
    private cd: ChangeDetectorRef,
    public view: ViewService,
    private popoverController: PopoverController,
    private api: ApiService,
    private modalCtrl: ModalController
  ) { }
  
  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }
  
  ngOnInit() {
    this.listenerView();
  }
  
  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
    });
  }
  
  async sxClickEmitter(event: any){
    if(this.sxClick.observers.length > 0){
      return this.sxClick.emit(event);
    }
    
    // Se non ha listener esterni apro le azioni di defauult sul componente al click
    event.preventDefault();
    
    const componentProps: any = {
      options: [
        {
          id: 'change_status',
          name: 'Cambia stato ticket'
        }
      ],
      type: 'no_id'
    };
    const popover = await this.popoverController.create({
      component: PopoverSelectComponent,
      event: event,
      translucent: true,
      componentProps: componentProps
    });
    popover.onDidDismiss().then((data: any) => {
      if(data?.data?.id){
        switch (data.data.id) {
          case 'change_status':
            this.changeTicketStatus(event);
            break;
        }
      }
    });
    await popover.present();
  }
  
  dxClickEmitter(event: any){
    if(this.dxClick.observers.length > 0){
      return this.dxClick.emit(event)
    }
  }
  
  async changeTicketStatus(event: any){
    const modal = await this.modalCtrl.create({
      component: ModifyTicketPage,
      componentProps: {
        single_page: true,
        currentPage: 'status',
        ticket: this.ticket,
      },
      cssClass: "modal_big",
    });
  
    await modal.present();
  }
}
