import {Admin} from './admin';
import {TwilioCall} from './twilioCall';

export enum AssignType {
  Automatica = 1,
  Whatsapp = 2,
  Manuale = 3
}

export enum SubChatType {
  Commerciale = 1,
  Tecnico = 2,
  Amministrazione = 3
}

type Options = {
  getAdminById: (id_admin: string) => Admin;
}

export class SubChat {
  
  private $getAdminById: (string) => Admin = () => {return undefined};
  
  $admin: Admin;
  private _id_admin: string; // id dell'admin gestore
  assign_type: AssignType;
  unread_calls: number;
  important: boolean;
  type: SubChatType; // Tipo di sub chat (commerciale, tecnico, amministrativo)
  last_update: number;
  platform_type: number;
  private _$calls: TwilioCall[] = [];
  private _$hasMoreCalls: boolean;
  
  constructor(raw?: any, options?: Options) {
    this.setAllFields(raw, options);
  }
  
  setAllFields(raw: any, options?: Options) {
    if(options){
      if(options.getAdminById) this.$getAdminById = options.getAdminById;
    }
    if(raw){
      this.type = raw.type;
      this.id_admin = raw.id_admin;
      this.assign_type = raw.assign_type;
      this.unread_calls = raw.unread_calls;
      this.important = raw.important;
      this.last_update = raw.last_update;
      this.platform_type = raw.platform_type;
    }
  }
  
  set id_admin(value: string){
    this._id_admin = value;
    if(this.id_admin){
      this.$admin = this.$getAdminById(this.id_admin);
    }
  }
  
  get id_admin(): string{
    return this._id_admin;
  }
  
  get $calls(): TwilioCall[] {
    return this._$calls;
  }
  
  set $calls(value: TwilioCall[]) {
    this._$calls = value;
  }
  
  get $hasMoreCalls(): boolean {
    return this._$hasMoreCalls;
  }
  
  set $hasMoreCalls(value: boolean) {
    this._$hasMoreCalls = value;
  }
  
  isAutoAssigned(): boolean{
    return this.assign_type === 1 || this.assign_type === 2;
  }
}
