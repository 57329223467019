import { Injectable } from '@angular/core';
import {ParticipantAdmin} from "../classes/participantAdmin";
import { ApiService } from './api.service';
import {AuthService, ViewService, ViewService as View} from 'common-lib';
import Activity from 'src/classes/activity';
import { Subject } from 'rxjs';
import {Admin} from "../classes/admin";
import {Platform} from "../classes/platform";
import {Storage} from "@ionic/storage-angular";
import { OrganizationAdmin } from 'src/classes/organizationAdmin';
import {TypeTrackerGps} from "../classes/typeTrackerGps";
import Invoice from 'src/classes/invoice';
import Company from "../classes/company";
import {SwService} from './sw.service';
import {ChipCompany} from '../classes/chipCompany';
import {ShowJsonComponent} from '../components/show-json/show-json.component';
import {ModalController} from '@ionic/angular';
import {Ticket} from '../classes/ticket';
import {Contact} from '../classes/contact';
import {MigrationService} from './migration.service';

declare let firebase: any;
declare let version: any;

// move somewhere nicer
type Entity = | "activity" | "admin" | "company" | "contact" | "organization";

@Injectable({
  providedIn: 'root'
})
export class DataService {

  version: string = version;
  commandConfigurationsMap: any = {};
  selectedParticipant: ParticipantAdmin;
  static firebase_storage: any;
  
  static ready = new Subject<any>();
  static ready$ = DataService.ready.asObservable();
  
  static updateData = new Subject<any>();
  static updateData$ = DataService.updateData.asObservable();
  
  static changeFilter = new Subject<any>();
  static changeFilter$ = DataService.changeFilter.asObservable();
  
  static remoteUpdate = new Subject<any>();
  static remoteUpdate$ = DataService.remoteUpdate.asObservable();
  
  static swUpdate = new Subject<any>();
  static swUpdate$ = DataService.swUpdate.asObservable();
  
  static dataReady: boolean;
  
  selectedPlatforms: Map<number, Platform> = new Map();
  selectedAdmin: Map<string, Admin> = new Map();
  current_admin: Admin;
  
  static platforms: Platform[] = [];
  static typesTrackerGps: TypeTrackerGps[] = [];
  static typesSim: { description: string, type_sim: number }[] = [];
  
  static chips_company: Map<string, ChipCompany> = new Map<string, ChipCompany>();
  
  show_calls: boolean;
  
  listener = {
    data: null,
    sw_updates: null
  };
  
  
  static array = {
    activity: [],
    admin: [], // utenti admin (impiegati ufficio commerciale)
    organization: <OrganizationAdmin[]> [],
    companies: [], // tutte le company non filtrate per admin
    tickets: <Ticket[]>[],
    contacts: [],
    participants: []
  };
  has_more = {
    activity: true,
    admin: true,
    organization: true,
  };
  limitAllApi = 50000;
  limit = {
    activity: this.limitAllApi,
    admin: this.limitAllApi,
    allApi: this.limitAllApi,
    organization: 3000,
  };
  loading = {
    // entity
    activity: false,
    admin: false,
    organization: false,
  };
  // unused
  static map = {
    activity: new Map(),
    admin: new Map(),
    participants_trackers: new Map<string, ParticipantAdmin>()
  };
  // unused
  skip = {
    activity: 0,
    company: 0,
  };
  
  static general_parameter: any = {};
  
  topbar_counter: any = {};
  companies: Company[] = [];
  
  chatNotificationCounter: number = 0;
  
  tickets: Ticket[] = [];
  contacts: Contact[] = [];
  
  visitorUnamanaged: number;
  
  constructor(
    private api: ApiService,
    private auth: AuthService,
    private view: ViewService,
    private storage: Storage,
    private sw: SwService,
    private modalCtrl: ModalController,
  ) {
    this.listenerData();
  }
  
  initialize(loginResponse: any) {
    if (loginResponse) {
      this.initCommunication();
    }
  }
  
  async initCommunication() {
    await this.view.showLoading("Scaricamento dati in corso...");
    await this.sw.initialize();
    
    if (this.listener.sw_updates) this.listener.sw_updates.unsubscribe();
    this.listener.sw_updates = SwService.remoteUpdate$.subscribe((event: any) => {
      switch (event.type) {
        case 'organizations':
          this.updateOrganizations(event.data);
          DataService.swUpdate.next(event);
          break;
        case 'participants':
          this.updateParticipants(event.data);
          DataService.swUpdate.next(event);
          break;
      }
    });
    
    DataService.firebase_storage = firebase.storage().ref();
    View.updateView.next();
    
    try {
      let res: any[] = await Promise.all([
        this.api.getAdmins(this.limit.allApi, 0),
        this.api.getGeneralParameter(),
        this.getChipsCompany(),
        this.getAllOrganizations(),
        this.getAllParticipants()
      ]);
      for (const raw of res[0].data) {
        const admin: Admin = new Admin(raw);
        if (AuthService.getUID() === raw.uidFirebase) {
          this.current_admin = admin;
        }
        DataService.array.admin.push(admin);
      }
      DataService.updateData.next(`admin`);
      DataService.general_parameter = res[1];
      if (DataService.general_parameter?.array_platform) {
        DataService.typesTrackerGps = [];
        for (let rawType of DataService.general_parameter.type_trackerGps) {
          DataService.typesTrackerGps.push(new TypeTrackerGps(rawType));
        }
        DataService.typesSim = [];
        for (let rawSim of DataService.general_parameter.type_sim) {
          DataService.typesSim.push(rawSim);
        }
        DataService.platforms = [];
        for (let rawPlatform of DataService.general_parameter.array_platform) {
          DataService.platforms.push(new Platform(rawPlatform, {
            getTypeTracker: this.getTypeTrackerGps,
            getSim: this.getTypeSim
          }));
        }
      }
      DataService.updateData.next("typeActivity");
      
      await this.loadAdminsFilter();
  
  
      /** Api per tutti i dati necessari in chat */
      res = await Promise.all([
        this.getAllTickets(0, this.limit.allApi, []),
        this.getAllContacts(0, this.limit.allApi, []),
        this.getAllCompany(0, this.limit.allApi, [])
      ]);
  
      /** Caricamento company */
      let companies: any[] = res[2];
      if(companies){
        this.companies = [];
        DataService.array.companies = [];
        for(let rawCompany of companies){
          const c: Company = new Company(rawCompany, {
            getAdmin: this.getAdminById,
            getAdminByType: this.getAdminByTypeCallCenter,
            getOrganization: this.getOrganizationById,
            getPlatformByOwnerName: this.getPlatformByOwnerName,
            getParticipantByImei: this.getParticipantByImei,
            getPlatformByType: this.getPlatformByType,
            getCompanyByPhone: this.getCompanyByPhone,
            getContacts: this.getContactsByCompany,
            getTickets: this.getTicketByCompany,
            getTicketById: this.getTicketById,
            getChipCompany: this.getChipCompanyById,
          });
          DataService.array.companies.push(c);
        }
      }
  
      /** Caricamento contatti */
      let contacts: any[] = res[1];
      if(contacts){
        this.contacts = [];
        DataService.array.contacts = [];
        for(let raw of contacts){
          const contact: Contact = new Contact(raw, {
            getPlatformByType: this.getPlatformByType,
            getAdminById: this.getAdminById,
            getCompanyById: this.getCompanyById,
            getTicketById: this.getTicketById
          });
          DataService.array.contacts.push(contact);
        }
      }
      
      for(let c of DataService.array.companies){
        if(c){
          c.setAllFields(c, {getContacts: this.getContactsByCompany})
        }
      }
      
      /** Caricamento tickets */
      let tickets: any[] = res[0];
      if(tickets){
        this.tickets = [];
        DataService.array.tickets = [];
        for(let raw of tickets){
          const ticket: Ticket = new Ticket(raw, {
            getPlatformByType: this.getPlatformByType,
            getOrganizationById: this.getOrganizationById,
            getCompanyByOrganizationId: this.getCompanyByOrganizationId,
            getAdminById: this.getAdminById,
            getContactById: this.getContactById,
            getParticipantByTrackerImei: this.getParticipantByImei,
            getMediaInfo: this.getMediaInfo,
            getTicketById: this.getTicketById
          });
          DataService.array.tickets.push(ticket);
        }
      }
  
      this.loadAdminsFilter();
      
      this.triggerNotificationsDatabase();
    } catch (e) {
      console.error(e);
      await this.view.modalAlert(
        "Errore", 
        "Errore nel caricamento dati comunicazione, ricaricare l'app",
        "", // icon
        ["Ok"],
      );
      
    }
    
    try{
      const visitorUnamanged: any = await this.api.countUnmanagedVisitor();
      if(visitorUnamanged){
        this.visitorUnamanaged = visitorUnamanged.total;
      }
    }catch(err){
      console.error(err)
    }
    await this.view.hideLoading();
    DataService.dataReady = true;
    DataService.ready.next();
    View.updateView.next()
  }
  
  async getAllOrganizations(){
    let organizations: any = await this.api.getOrganizations();
    DataService.array.organization = [];
    for(let rawObj of organizations){
      const org: OrganizationAdmin = new OrganizationAdmin(undefined);
      DataService.array.organization.push(org);
      org.setData(rawObj, {$getPlatformByType: this.getPlatformByType})
    }
  }
  
  async getAllParticipants(){
    let participant: any = await this.api.getPartecipant();
    DataService.array.participants = [];
    for(let rawObj of participant){
      const part: ParticipantAdmin = new ParticipantAdmin(undefined);
      DataService.array.participants.push(part);
      part.setDataAdmin(rawObj);
    }
  }
  
  private async getAllCompany(skip: number, limit: number, raws_companies: any[]){
    const res: any = await this.api.getCompanyMultiple(limit, skip);
    if(res){
      raws_companies = raws_companies.concat(res.data);
      if(res?.has_more){
        return await this.getAllCompany(raws_companies.length, limit, raws_companies);
      }else{
        return raws_companies;
      }
    }
  }
  
  private async getAllContacts(skip: number, limit: number, raws_contacts: any[]){
    const res: any = await this.api.getContacts(limit, skip);
    if(res){
      raws_contacts = raws_contacts.concat(res.data);
      if(res?.has_more){
        return await this.getAllContacts(raws_contacts.length, limit, raws_contacts);
      }else{
        return raws_contacts;
      }
    }
  }
  
  private async getAllTickets(skip: number, limit: number, raws_tickets: any[]){
    const res: any = await this.api.getTickets(limit, skip);
    if(res){
      raws_tickets = raws_tickets.concat(res.data);
      if(res?.has_more){
        return await this.getAllTickets(raws_tickets.length, limit, raws_tickets);
      }else{
        return raws_tickets;
      }
    }
  }
  
  async updateOrganizations(raw_organizations: any[]){
    if(!raw_organizations) return;
    for(let raw of raw_organizations){
      let org: OrganizationAdmin = DataService.array.organization.find((element: OrganizationAdmin) => element._id === raw._id);
      if(org){
        org.setData(raw);
      }else{
        org = new OrganizationAdmin(undefined);
        DataService.array.organization.push(org);
        org.setData(raw, {$getPlatformByType: this.getPlatformByType})
      }
    }
  }
  
  async updateParticipants(raw_participants: any[]){
    if(!raw_participants) return;
    for(let raw of raw_participants){
      let part: ParticipantAdmin = DataService.array.participants.find((element: ParticipantAdmin) => element._id === raw._id);
      if(part){
        part.setDataAdmin(raw);
      }else{
        part = new ParticipantAdmin(undefined);
        DataService.array.participants.push(part);
        part.setDataAdmin(raw)
      }
      if(part.type === 2){
        DataService.map.participants_trackers.set(part.imei, part);
      }
    }
  }
  
  async getEntityMultiple(options: {
    entity: Entity,
    getAll?: boolean,
    throwToCaller?: boolean,
  }) {
    const {
      throwToCaller,
      entity,
      getAll,
    } = options;
    
    // * checks
    
    if (this.loading[entity] && !getAll) {
      console.log(`return: (loading.${entity} && !getAll) == true`);
      return;
    }
    this.loading[entity] = true;
    // await this.view.showLoading(`caricamento ${entity}`)
    // View.updateView.next()
    
    // if (this.loading.initCommunication) {
    //   console.log(`return: loading.initCommunication == true`)
    //   await this.view.hideLoading()
    //   View.updateView.next()
    //   this.loading[entity] = false;
    //   return;
    // }
    if (!this.has_more[entity]) {
      console.log(`return: has_more.${entity} == false`)
      // await this.view.hideLoading()
      // View.updateView.next()
      this.loading[entity] = false;
      return;
    }
    if (!await this.userIsAuth()) {
      console.log("return: user not auth")
      // await this.view.hideLoading()
      // View.updateView.next()
      this.loading[entity] = false;
      return;
    }
    // if (DataService.array[entity].length > 0) {
    //   console.log("return: arrayToPopulate.length > 0");
    //   return;
    // }
    
    let res: any;
    try {
      // eg. this will become: getActivityMultiple
      res = await this.api[`get${this.capitalizeFirstLetter(entity)}Multiple`]
        (this.limit[entity], DataService.array[entity].length);
      this.has_more[entity] = res.has_more;
      
      // concat in place
      switch (entity) {
        case "activity":
          DataService.array[entity].push(
            ...res.data.map((rawObj: any) => {
              return new Activity(rawObj);
            })
          );
          break;
        case "organization":
          DataService.array[entity].push(
            ...res.data.map((rawObj: any) => {
              return new OrganizationAdmin(rawObj, {$getPlatformByType: this.getPlatformByType});
            })
          );
          break;
        default:
          DataService.array[entity].push(...res.data);
          break;
      }
      View.updateView.next()
      // this.mapAddElemMultiple(entity, res.data);
      
      if (getAll && this.has_more[entity]) {
        this.getEntityMultiple(options);
      } else {
        // console.log(`get ${entity}s`, DataService.array[entity])
        DataService.updateData.next(entity);
      }
    } catch (e) {
      if (throwToCaller) {
        throw e; // handle 'e' in caller()'s try-catch
      } else {
        console.error(e);
        await this.view.modalAlert(
          "Errore",
          `Errore nel caricamento dall'entità ${entity}, ricaricare l'app`,
          "", // icon
          ["Ok"],
        );
      }
    }
    // console.log("getEntityMultiple() res", res)
    
    // await this.view.hideLoading()
    // View.updateView.next()
    this.loading[entity] = false;
  }
  
  // Carica i filtri su piattaforma e agente da storage
  async loadAdminsFilter(){
    try{
      await this.storage.get('selectedAdmins').then((data: any) => {
        for(let key in data){
          if(data[key]) this.selectedAdmin.set(key, this.getAdminById(key) || new Admin());
        }
      });
      await this.storage.get('selectedPlatforms').then((data: any) => {
        for(let key in data){
          if(data[key]){
            this.selectedPlatforms.set(parseInt(key), this.getPlatformByType(parseInt(key)) || new Platform());
          }
        }
      });
      this.updateFilteredElementsByAdmins();
    }catch(err){
      console.error(err);
    }
  }
  
  // Filtra i ticket e i contatti a seconda della lista di admin in visualizzazione
  updateFilteredElementsByAdmins(){
    this.tickets = [];
    for(const ticket of DataService.array.tickets){
      if(this.selectedAdmin.get(ticket.id_admin)){
        this.tickets.push(ticket);
      }
    }
    
    this.contacts = [];
    for(const contact of DataService.array.contacts){
      for(const sub_chat of contact.sub_chat){
        if(this.selectedAdmin.get(sub_chat.id_admin)){
          this.contacts.push(contact);
          break;
        }
      }
    }
    
    ViewService.updateView.next();
  }
  
  userIsAuth() {
    return (
      this.auth.isReady().then(() => {
        // console.log("userIsAuth() then");
        return true;
      }).catch((e) => {
        console.error("userIsAuth() catch", e);
      })
    );
  }
  
  listenerData() {
    this.listener.data = DataService.updateData$.subscribe((action: string) => {
      switch (action) {
        case "admin":
          DataService.array.activity.forEach((el: Activity) => {
            el.idAdminCreator = el.idAdminCreator;
            el.idAdminManager = el.idAdminManager;
          });
          break;
        case "company":
          DataService.array.activity.forEach((el: Activity) => {
            el.idCompany = el.idCompany;
          });
          break;
        case "typeActivity":
          DataService.array.activity.forEach((el: Activity) => {
            el.type_activity = el.type_activity;
          });
          DataService.array.organization.forEach((el: OrganizationAdmin) => {
            el.$platform = this.getPlatformByType(el.platform_type);
          });
          break;
      }
    });
  }
  
  getCompanyById(id: string){
    try{
      return DataService.array.companies.find((elem: Company) => {
        return elem._id === id;
      });
    }catch(err){
      console.error(err);
    }
    return;
  }
  
  getCompanyByIdOrganization(id_organization: string){
    try{
      for(let c of DataService.array.companies){
        if(c.$organizations){
          const organization = c.idOrganizations.find((id: string) => id === id_organization);
          if(organization){
            return c;
          }
        }
      }
    }catch(err){
      console.error(err);
    }
    return;
  }
  
  getCompanyByPhone(phone: string): Company{
    try{
      for(let c of DataService.array.companies){
        if(c.haveContactWithPhone(phone)){
          return c;
        }
      }
    }catch(err){
      console.error(err);
    }
    return;
  }
  
  getCompanyByOrganizationId(id_organization: string): Company{
    try{
      for(let c of DataService.array.companies){
        if(c.organizations?.hasOwnProperty(id_organization)){
          return c;
        }
      }
    }catch(err){
      console.error(err);
    }
  }
  
  getCompaniesByIdOrganization(id_organization: string){
    let result: Company[] = [];
    try{
      for(let c of DataService.array.companies){
        if(c.$organizations){
          const organization = c.$organizations?.find((id: string) => id === id_organization);
          if(organization){
            result.push(c);
          }
        }
      }
    }catch(err){
      console.error(err);
    }
    return result;
  }
  
  // Ritorna il tipo di attività
  getTypeActivity(type: number): {description: string, type: number} {
    try{
      return DataService.general_parameter.typeActivity.find((elem: any) => {
        return elem.type === type;
      });
    }catch(err){
      console.error(err);
    }
    return;
  }
  
  // Fa il match di una mail o un numero di telefono con quelli presenti in general parametr per assistenza o commerciale e ne ritorna il nome
  getPlatformByOwnerName(owner: string): Platform{
    if(DataService.general_parameter?.array_platform){
      for(let platform of DataService.general_parameter.array_platform) {
        if(platform.whatsapp_number_platform){
          for (let phone of platform.whatsapp_number_platform){
            if(phone.number === owner){
              return platform;
            }
          }
        }
        if(platform.email_platform){
          for (let email of platform.email_platform){
            if(email.fromMail === owner){
              return platform;
            }
          }
        }
        if(platform.sms_number_platform){
          for (let phone of platform.sms_number_platform){
            if(phone.number === owner){
              return platform;
            }
          }
        }
        if(platform.call_number_platform){
          for (let call of platform.call_number_platform){
            if(call.number === owner){
              return platform;
            }
          }
        }
      }
      if(owner.indexOf('whatsapp:') <= 0){
        // Caso in cui ho un numero di telefono sms, che non ho trovato e voglio cercarlòo anche nelle whatsapp_number_platform
        for(let platform of DataService.general_parameter.array_platform) {
          if (platform.whatsapp_number_platform) {
            for (let phone of platform.whatsapp_number_platform) {
              if (phone.number.indexOf(owner) >= 0) {
                return platform;
              }
            }
          }
        }
      }
    }
    return;
  }
  
  getPlatformByType(type: number): Platform {
    return DataService.platforms.find((elem: Platform) => elem.type === type);
  }
  
  getPlatformByPhone(phone: string): Platform {
    return DataService.platforms.find((elem: Platform) => {
      if(elem.whatsapp_number_platform){
        return elem.whatsapp_number_platform.find((e: any) => e.number === phone)
      }
      return;
    });
  }
  
  getAdminById(id: string): Admin{
    return DataService.array.admin.find((el) => el._id === id);
  }
  
  getAdminNickname(id: string) {
    const foundObj = DataService.array.admin[
      DataService.array.admin.findIndex((a) => a._id === id)
    ];
    return foundObj?.nickname || "sconosciuto";
  }
  
  getAdminByEmail(email: string) {
    return DataService.array.admin.find((el) => el.email === email);
  }
  
  getAdminByTypeCallCenter(type: number, platform_type: number) {
    return DataService.array.admin.find((el: Admin) => el.call_center && el.call_center === type && el.platform_type === platform_type);
  }
  
  createInvoice(objLiteral: any) {
    return new Invoice(
      objLiteral,
      {
        $getPlatformByType: this.getPlatformByType,
      },
    )
  }
  
  async getMediaInfo(name: string): Promise<{url: string, metadata: any}>{
    if(name){
      try{
        const ref: any = DataService.firebase_storage.child(name);
        let results: any[] = await Promise.all([
          ref.getDownloadURL(),
          ref.getMetadata()
        ]);
        let result: any = {
          url: results[0],
          metadata: results[1]
        };
        ViewService.updateView.next();
        return result;
      }catch(err){
        console.error(err);
        return undefined
      }
    }
  }
  
  async uploadMedia(name: string, file: any, metadata?: any){
    if(name && file){
      try{
        const url: string = `temp/${Date.now()}_${Math.floor(Math.random() * 10000) + 1}/${name}`
        const ref: any = DataService.firebase_storage.child(url);
        await ref.put(file, metadata);
        return url;
      }catch(err){
        console.error(err);
        return undefined
      }
    }
  }
  
  createOrganizationAdmin(objLiteral: any): OrganizationAdmin {
    const org: OrganizationAdmin = new OrganizationAdmin(
      objLiteral,
      {
        $getPlatformByType: this.getPlatformByType,
      },
    )
    if(objLiteral.login_email_partecipant_owner === 'privato_ita@vigofra.it') {
      console.log(org)
    }
    return org
  }
  
  getOrganizationById(id: string): OrganizationAdmin {
    return DataService.array.organization.find((el: OrganizationAdmin) => el._id === id);
  }
  
  getTicketByOrderId(id: string): Ticket {
    return DataService.array.tickets.find((el: Ticket) => el.id_order === id);
  }
  
  getOrganizationByEmailOwner(email: string): OrganizationAdmin {
    return DataService.array.organization.find((el: OrganizationAdmin) => el.login_email_partecipant_owner === email);
  }
  
  getTypeTrackerGps(type: number): TypeTrackerGps{
    return DataService.typesTrackerGps.find((elem: TypeTrackerGps) => elem.type_trackerGps === type);
  }
  
  getTypeSim(type: number): {description: string, type_sim: number}{
    return DataService.typesSim.find((elem: {description: string, type_sim: number}) => elem.type_sim === type);
  }
  
  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  
  // Dice se è terminato il caricamento dei dati iniziali
  static isReady(){
    return new Promise((resolve) => {
      if(DataService.dataReady) return resolve();
      let listener: any = DataService.ready$.subscribe(() => {
        listener.unsubscribe();
        return resolve();
      });
    });
  }
  
  mapAddElem(entity: Entity, key: string, value: any) {
    // if (!DataService.map[entity].has(value[key])) {
      DataService.map[entity].set(value[key], value);
      View.updateView.next()
    // }
  }
  
  mapAddElemMultiple(entity: Entity, array: any[], key: string = "_id") {
    // console.log("arrayayayay",array)
    array.forEach((elem) => {
      this.mapAddElem(entity, key, elem);
    });
    View.updateView.next()
    // setTimeout(() => {
    //   console.log("mapAddElemMultiple()", DataService.map[entity]);
    // }, 1000);
  }
  
  firebaseDbRef: any;
  
  private triggerNotificationsDatabase(){
    if(this.firebaseDbRef) this.firebaseDbRef.off();
    this.firebaseDbRef = firebase.database().ref('/communication_portal/');
    let first: boolean = true;
    this.firebaseDbRef.on('value', (snapshot: any) => {
      if(!first){
        const data: any = snapshot.val();
        switch (data.element) {
          case 'company':
            this.manageCompanyUpdate(data);
            break;
          case 'contact':
            this.manageContactUpdate(data);
            break;
          case 'ticket':
            this.manageTicketUpdate(data);
            break;
          case 'adv':
            this.manageVisitorUpdate(data)
            break;
          case 'referral':
            this.manageReferralUpdate(data);
            break;
            
        }
        DataService.remoteUpdate.next(data);
      }else{
        first = false;
      }
    });
  }
  
  /** Gestisce un update di company **/
  async manageCompanyUpdate(data: any){
    switch (data.type) {
      case 'delete':
        try{
          for(let i=0; i<DataService.array.companies.length; i++){
            if(DataService.array.companies[i]._id === data._id){
              DataService.array.companies.splice(i, 1);
              break;
            }
          }
          for(let i=0; i<this.companies.length; i++){
            if(this.companies[i]._id === data._id){
              this.companies.splice(i, 1);
              break;
            }
          }
          this.loadAdminsFilter();
        }catch(err){
          console.error('Errore in gestione update di company');
        }
        break;
      case 'update_union': { // Arriva quando è stata fatta una union in questa company e vanno quindi aggiornati tutti i contatti coinvolti che avranno id_company con quella nuova
        try{
          const res: any = await this.api.getCompanyContacts(data._id);
          if(res){
            for(const raw of res){
              let contact: Contact = DataService.array.contacts.find((elem: Contact) => {
                return elem._id === raw._id;
              });
              if(contact){
                contact.setData(raw);
              }else{
                contact = new Contact(raw, {
                  getPlatformByType: this.getPlatformByType,
                  getAdminById: this.getAdminById,
                  getCompanyById: this.getCompanyById,
                  getTicketById: this.getTicketById
                });
                DataService.array.contacts.push(contact);
              }
              DataService.updateData.next({type: "contact", _id: contact._id});
            }
            this.loadAdminsFilter();
            DataService.updateData.next({type: "company", _id: data._id});
  
          }
        }catch(err){
          console.error('Errore in gestione update di company');
        }
        break;
      }
      default:
        try{
          const res: any = await this.api.getCompany(data._id);
          let company: Company = DataService.array.companies.find((elem: Company) => {
            return elem._id === data._id;
          });
          if(company){
            company.setAllFields(res);
          }else{
            company = new Company(res, {
              getAdmin: this.getAdminById,
              getAdminByType: this.getAdminByTypeCallCenter,
              getOrganization: this.getOrganizationById,
              getPlatformByOwnerName: this.getPlatformByOwnerName,
              getParticipantByImei: this.getParticipantByImei,
              getPlatformByType: this.getPlatformByType,
              getCompanyByPhone: this.getCompanyByPhone,
              getContacts: this.getContactsByCompany,
              getTickets: this.getTicketByCompany,
              getTicketById: this.getTicketById,
              getChipCompany: this.getChipCompanyById,
            });
            DataService.array.companies.push(company);
          }
          DataService.updateData.next({type: "company", _id: company._id});
          this.loadAdminsFilter();
        }catch(err){
          console.error('Errore in gestione update di company');
        }
        break;
    }
  }
  
  /** Gestisce gli aggiornamenti di tipo contatto */
  async manageContactUpdate(data: any){
    if(!data._id) return; // Non avendo l'id non possiamo fare niente
    switch (data.type) {
      case 'delete': {
          if(data._id){
            try{
              for(let i=0; i<DataService.array.contacts.length; i++){
                if(DataService.array.contacts[i]._id === data._id){
                  DataService.array.contacts.splice(i, 1);
                  break;
                }
              }
              DataService.updateData.next({type: "contact", _id: data._id});
              this.loadAdminsFilter();
            }catch(err){
              console.error('Errore in gestione delete contact');
            }
          }
        return;
      }
      case 'new_contact': { // Arriva quando un cliente ci contatta per la prima volta e quindi è stato creata una company, un contatto ed eventualmente un ticket
        if(data._id && data.id_company){
          await this.retrieveCompany(data.id_company, true);
          await this.retrieveContact(data._id, true);
          if(data.id_ticket){
            await this.retrieveTicket(data.id_ticket, true);
          }
          DataService.updateData.next({type: "contact", _id: data._id});
          this.loadAdminsFilter();
        }
        break;
      }
      case 'update_call': { // Arriva quando una call è stata assegnata ad un contatto, mi basta aggiornare il contatore delle call_non lette
        if(data.sub_chat?.type && data.sub_chat?.platform_type){
          const contact: Contact = await this.retrieveContact(data._id, false);
          if(contact){
            contact.updateUnreadCallsSubChat(data.sub_chat.type, data.sub_chat.platform_type, data.sub_chat.unread_calls, data.sub_chat.last_update);
            ViewService.updateView.next();
            DataService.updateData.next({type: "contact", _id: contact._id});
          }
        }
        break;
      }
      case 'update_last_whatsapp': { // Arriva quando cambia la data di ultimo whatsapp ricevuto per la finestra delle 24 h
        if (data._id && data.last_whatsapp && data.chat_owner) {
          const contact: Contact = await this.retrieveContact(data._id, false);
          if(contact){
            if (!contact.last_whatsapp) {
              contact.last_whatsapp = {};
            }
            contact.last_whatsapp[data.chat_owner] = data.last_whatsapp
            DataService.updateData.next({type: "contact", _id: contact._id});
          }
        }
        break;
      }
      default: {
        await this.retrieveContact(data._id, true);
        DataService.updateData.next({type: "contact", _id: data._id});
        this.loadAdminsFilter();
        break;
      }
    }
  }
  
  /** Cerca il contatto nella lista di quelli presenti o lo scarica da remoto se non lo trova */
  async retrieveContact(id_contact: string, force_remote?: boolean){
    let contact: Contact = DataService.array.contacts.find((elem: Contact) => elem._id === id_contact);
    let contact_raw: any;
    if(force_remote || !contact){
      contact_raw = await this.api.getContact(id_contact);
      if(!contact){
        contact = new Contact(contact_raw, {
          getPlatformByType: this.getPlatformByType,
          getAdminById: this.getAdminById,
          getCompanyById: this.getCompanyById,
          getTicketById: this.getTicketById
        });
        if(!DataService.array.contacts.find((elem: Contact) => elem._id === id_contact)) {
          DataService.array.contacts.push(contact); // Non essendoci in lista lo aggiunge
          this.loadAdminsFilter();
        }
      }else{
        contact.setData(contact_raw);
      }
    }
    return contact;
  }
  
  async manageTicketUpdate(data: any){
    switch (data.type) {
      case 'delete': {
        return; // TODO: gestione delete di un ticket (se vogliamo farlo)
      }
      case 'update_call': { // Arriva quando una call è stata assegnata ad un ticket, mi basta aggiornare il contatore delle call_non lette
        if(data._id){
          const ticket: Ticket = await this.retrieveTicket(data._id, false);
          ticket.unread_calls = data.unread_calls;
          DataService.updateData.next({type: "ticket", _id: ticket._id});
        }else if(data.ids && data.ids.length > 0){
          for(const id_ticket of data.ids){
            const ticket: Ticket = await this.retrieveTicket(id_ticket, false);
            ticket.unread_calls = data.unread_calls;
          }
          console.log('Modifica conteggi ticket');
          ViewService.updateView.next();
        }
        break;
      }
      case 'update_multiple': { // Arriva quando una call è stata assegnata ad un ticket, mi basta aggiornare il contatore delle call_non lette
        if(data.ids && data.ids.length > 0){
          for(const id_ticket of data.ids){
            const ticket: Ticket = await this.retrieveTicket(id_ticket, true);
            DataService.updateData.next({type: "ticket", _id: ticket._id});
          }
          ViewService.updateView.next();
        }
        break;
      }
      case 'new_ticket_in_organization': { // Arriva quando si crea un ticket su organizzazione e viene creata automaticamente una company che ha il riferimento a quella organizzazione
        if(data._id && data.id_company){
          await this.retrieveCompany(data.id_company, true);
          await this.retrieveTicket(data._id, true);
          DataService.updateData.next({type: "ticket", _id: data._id});
          this.loadAdminsFilter();
        }
        break;
      }
      case 'update_message': { // Arriva quando un messaggio whatsapp è stato aggiunto ad un ticket
        const ticket: Ticket = await this.retrieveTicket(data._id, false);
        ticket.unread_messages = data.unread_messages;
        ticket.last_update = data.last_update;
        ViewService.updateView.next();
        DataService.updateData.next({type: "ticket", _id: ticket._id});
        break;
      }
      default: {
        await this.retrieveTicket(data._id, true);
        DataService.updateData.next({type: "ticket", _id: data._id});
        this.loadAdminsFilter();
        break;
      }
    }
  }
  
  /** Cerca il ticket nella lista di quelli presenti o lo scarica da remoto se non lo trova */
  async retrieveTicket(id_ticket: string, force_remote?: boolean): Promise<Ticket>{
    let ticket: Ticket = DataService.array.tickets.find((elem: Ticket) => elem._id === id_ticket);
    let contact_raw: any;
    if(force_remote || !ticket){
      contact_raw = await this.api.getTicket(id_ticket);
      if(!ticket){
        ticket = new Ticket(contact_raw, {
          getPlatformByType: this.getPlatformByType,
          getOrganizationById: this.getOrganizationById,
          getCompanyByOrganizationId: this.getCompanyByOrganizationId,
          getAdminById: this.getAdminById,
          getContactById: this.getContactById,
          getParticipantByTrackerImei: this.getParticipantByImei,
          getMediaInfo: this.getMediaInfo,
          getTicketById: this.getTicketById
        });
        if(!DataService.array.tickets.find((elem: Ticket) => elem._id === id_ticket)){
          DataService.array.tickets.push(ticket); // Non essendoci in lista lo aggiunge
          this.loadAdminsFilter();
        }
      }else{
        console.log('Sono in ticket found')
        ticket.setData(contact_raw);
      }
    }
    ViewService.updateView.next();
    return ticket;
  }
  
  async retrieveCompany(id_company: string, force_remote?: boolean): Promise<Company>{
    let company: Company = DataService.array.companies.find((elem: Company) => elem._id === id_company);
    let company_raw: any;
    if(force_remote || !company){
      company_raw = await this.api.getCompany(id_company);
      if(!company){
        company = new Company(company_raw, {
          getAdmin: this.getAdminById,
          getAdminByType: this.getAdminByTypeCallCenter,
          getOrganization: this.getOrganizationById,
          getPlatformByOwnerName: this.getPlatformByOwnerName,
          getParticipantByImei: this.getParticipantByImei,
          getPlatformByType: this.getPlatformByType,
          getCompanyByPhone: this.getCompanyByPhone,
          getContacts: this.getContactsByCompany,
          getTickets: this.getTicketByCompany,
          getTicketById: this.getTicketById,
          getChipCompany: this.getChipCompanyById,
        });
        if(!DataService.array.companies.find((elem: Company) => elem._id === id_company)){
          DataService.array.companies.push(company); // Non essendoci in lista lo aggiunge
          this.companies.push(company);
        }
      }else{
        company.setAllFields(company_raw);
      }
    }
    ViewService.updateView.next();
    return company;
  }
  
  getParticipantByImei(imei: string){
    return DataService.map.participants_trackers.get(imei);
  }
  
  private async getChipsCompany(){
    DataService.chips_company.clear();
    const response: any = await this.api.getChipsCompany();
    if(response?.data){
      for(const raw of response.data){
        const chip: ChipCompany = new ChipCompany(raw);
        DataService.chips_company.set(chip._id, chip);
      }
    }
  }
  
  private getChipCompanyById(id: string){
    return DataService.chips_company.get(id);
  }
  
  async showJSON(json: any, title?: string){
    const modal = await this.modalCtrl.create({
      component: ShowJsonComponent,
      componentProps: {
        title: title,
        data: json
      },
      cssClass: "modal_big"
    });
    await modal.present();
  }
  
  async updateAdmins() {
    this.view.showLoading();
    try {
      const res: any = await this.api.getAdmins(this.limit.allApi, 0);
      for(const raw of res.data){
        const admin: Admin = this.getAdminById(raw._id);
        if(admin){
          admin.setAllFields(raw);
        }
      }
    } catch (err) {
      console.error('Errore in aggiornamento admins: ',err)
    }
    this.view.hideLoading()
  }
  
  
  getTicketsByContact(id_contact: string): Ticket[]{
    const list: Ticket[] = [];
    for(const ticket of DataService.array.tickets){
      if(ticket.haveContact(id_contact)){
        list.push(ticket);
      }
    }
    return list;
  }
  
  getTicketById(id_ticket: string): Ticket{
    for(const ticket of DataService.array.tickets){
      if(ticket._id === id_ticket){
        return ticket;
      }
    }
  }
  
  getContactsByCompany(id_company: string): Contact[]{
    const list: Contact[] = [];
    for(const contact of DataService.array.contacts){
      if(contact.id_company === id_company){
        list.push(contact);
      }
    }
    return list;
  }
  
  getContactByCompany(id_company: string): Contact{
    const list: Contact[] = [];
    for(const contact of DataService.array.contacts){
      if(contact.id_company === id_company){
        return contact;
      }
    }
  }
  
  getContactById(id_contact: string): Contact{
    return DataService.array.contacts.find((elem: Contact) => elem._id === id_contact);
  }
  
  /** Recupera tutti i ticket della company, ovvero tutti i ticket agganciati a contatti della company o ad organizazzioni associate alla company */
  getTicketByCompany(company: Company): Ticket[]{
    if(!company) return [];
    let tickets_hash = new Map<string, Ticket>();
    if(company.$contacts){
      for(const contact of company.$contacts){
        for(const ticket of DataService.array.tickets){
          if(ticket.contacts?.hasOwnProperty(contact._id) || ticket.contacts_email?.hasOwnProperty(contact._id)){
            tickets_hash.set(ticket._id, ticket);
          }
        }
      }
    }

    if(company.$organizations){
      for(const organization of company.$organizations){
        for(const ticket of DataService.array.tickets){
          if(ticket.id_organization === organization._id){
            tickets_hash.set(ticket._id, ticket);
          }
        }
      }
    }
    
    return Array.from(tickets_hash.values());
  }
  
  async manageVisitorUpdate(data: any){
    switch (data.type) {
      default: {
        const visitorUnamanged: any = await this.api.countUnmanagedVisitor();
        if(visitorUnamanged){
          this.visitorUnamanaged = visitorUnamanged.total;
        }
        DataService.updateData.next({type: data.type, _id: data._id});
        this.loadAdminsFilter();
        break;
      }
    }
  }
  
  async manageReferralUpdate(data: any){
    switch (data.type) {
      default: {
        const visitorUnamanged: any = await this.api.countUnmanagedVisitor();
        if(visitorUnamanged){
          this.visitorUnamanaged = visitorUnamanged.total;
        }
        DataService.updateData.next({type: data.element, _id: data._id});
        this.loadAdminsFilter();
        break;
      }
    }
  }
  
}
