export class TypeTrackerGps {
  
  commandStartProgrammazione: any[];
  commandStartProgrammazioneFota: any[];
  description: string;
  planTwilioSim: string;
  networkOnomondoSim: string;
  type_trackerGps: number;
  
  constructor(rawObj?: any){
    this.setAllFields(rawObj);
  }
  
  setAllFields(rawObj?: any){
    if(rawObj){
      this.commandStartProgrammazione = rawObj.commandStartProgrammazione;
      this.commandStartProgrammazioneFota = rawObj.commandStartProgrammazioneFota;
      this.description = rawObj.description;
      this.planTwilioSim = rawObj.planTwilioSim;
      this.networkOnomondoSim = rawObj.networkOnomondoSim;
      this.type_trackerGps = rawObj.type_trackerGps;
    }
  }
}
