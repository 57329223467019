import {SubChat} from './subChat';
import {Admin} from './admin';
import {TwilioCall} from './twilioCall';
import {Platform} from './platform';
import Company from './company';
import {Ticket} from './ticket';
import {WhatsappMessage} from './whatsappMessage';
import {WebMessage} from './webMessages';
import {EmailMessage} from './emailMessage';

type Options = {
  getAdminById: (id_admin: string) => Admin;
  getPlatformByType: (platform_type: number) => Platform,
  getCompanyById: (id_company: string) => Company,
  getTicketById: (string) => Ticket
}

export class Contact {

  
  _id: string;
  private _id_company: string;
  name: string;
  private _phones: any;
  private _emails: any;
  private _sub_chat: SubChat[];
  
  $tickets: Ticket[];
  $company: Company;
  $list_phones: string[];
  $list_emails: string[];
  $calls: TwilioCall[] = [];
  $has_more_calls: boolean;
  private _last_whatsapp: any;
  
  $involved_platforms: {platform: Platform, admins: {admin: Admin, sub_chats: SubChat[]}[]}[];
  $involved_admins: {admin: Admin, sub_chats: SubChat[], unread_calls: number}[];
  $messages: (WebMessage | WhatsappMessage | EmailMessage)[];
  
  $getAdminById: (id_admin: string) => Admin = () => {
    return undefined
  };
  $getPlatformByType: (platform_type: number) => Platform = () => {
    return undefined
  };
  $getCompanyById: (id_company: string) => Company = () => {
    return undefined
  };
  $getTicketById: (string: string) => Ticket = () => {return undefined};
  
  
  constructor(data?: any, options?: Options) {
    if (data) {
      this.setData(data, options);
    }
  }
  
  setData(data: any, options?: Options) {
    if (options?.getAdminById) this.$getAdminById = options.getAdminById;
    if(options?.getPlatformByType) this.$getPlatformByType = options.getPlatformByType;
    if(options?.getCompanyById) this.$getCompanyById = options.getCompanyById;
    if(options?.getTicketById) this.$getTicketById = options.getTicketById;
    this._id = data._id;
    this.id_company = data.id_company;
    this.name = data.name;
    this.phones = data.phones;
    this.emails = data.emails;
    this.sub_chat = data.sub_chat;
    this.last_whatsapp = data.last_whatsapp;
  }
  
  get id_company(): string {
    return this._id_company;
  }
  
  set id_company(value: string) {
    this._id_company = value;
    if(this.id_company){
      this.$company = this.$getCompanyById(this.id_company);
    }
  }
  
  get sub_chat(): any | SubChat[] {
    return this._sub_chat;
  }
  
  set sub_chat(value: any | SubChat[]) {
    this._sub_chat = [];
    this.$involved_platforms = [];
    if (value) {
      const involved_admin: any = {};
      for (const key in value) {
        for (const platform_type in value[key]) {
          let raw: any = value[key][platform_type];
          raw.platform_type = parseInt(platform_type);
          const sub_chat = new SubChat(raw, {
            getAdminById: this.$getAdminById
          });
          this._sub_chat.push(sub_chat);
          if(involved_admin[sub_chat.id_admin]){
            involved_admin[sub_chat.id_admin].sub_chats.push(sub_chat);
            involved_admin[sub_chat.id_admin].unread_calls += sub_chat.unread_calls;
          }else{
            involved_admin[sub_chat.id_admin] = {admin: sub_chat.$admin, sub_chats: [sub_chat], unread_calls: sub_chat.unread_calls};
          }
          const platform_summury: any = this.$involved_platforms.find((elem) => elem.platform.type === raw.platform_type)
          if(!platform_summury){
            this.$involved_platforms.push({platform: this.$getPlatformByType(raw.platform_type), admins: [{admin: sub_chat.$admin, sub_chats: [sub_chat]}]});
          }else{
            let admin_summary: any = platform_summury.admins.find((elem) => elem.admin._id === sub_chat.id_admin);
            if(!admin_summary){
              platform_summury.admins.push({admin: sub_chat.$admin, sub_chats: [sub_chat]});
            }else{
              admin_summary.sub_chats.push(sub_chat);
            }
          }
        }
      }
      this.$involved_admins = [];
      for(const key in involved_admin){
        this.$involved_admins.push({admin: involved_admin[key].admin, sub_chats: involved_admin[key].sub_chats, unread_calls: involved_admin[key].unread_calls});
      }
    }
  }
  
  get phones(): any {
    return this._phones;
  }
  
  set phones(value: any) {
    this._phones = value;
    this.$list_phones = [];
    if(this.phones){
      for(const phone in this.phones){
        this.$list_phones.push(phone);
      }
    }
  }
  
  get emails(): any {
    return this._emails;
  }
  
  set emails(value: any) {
    this._emails = value;
    this.$list_emails = [];
    if(this.emails){
      for(const email in this.emails){
        this.$list_emails.push(email.replace(new RegExp('\\|', 'g'), '.'));
      }
    }
  }
  
  updateUnreadCallsSubChat(chat_type: number, platform_type: number, unread_calls: number, last_update: number){
    const sub_chat: SubChat = this.getSubChat(chat_type, platform_type);
    if(sub_chat){
      sub_chat.unread_calls = unread_calls;
      if(last_update) sub_chat.last_update = last_update;
      this.updateTotalUnreadCallInAdmins();
    }
  }
  
  getSubChat(type: number, platform_type: number): SubChat{
    if(this.sub_chat){
      return this.sub_chat.find((elem: SubChat) => elem.type === type && elem.platform_type === platform_type);
    }
  }
  
  // Metodo ausiliario che va a incrementare i conteggi di call degli admin coinvolti nel contatto. Serve principalmente per la grafica della scheda admin;
  private updateTotalUnreadCallInAdmins(){
    for(const admin of this.$involved_admins){
      admin.unread_calls = 0;
      for(const sub_chat of admin.sub_chats){
        admin.unread_calls += sub_chat.unread_calls;
      }
    }
  }
  
  updateMessagesInTicketsFromApiResponse(rawsObject: {
    whatsapp_messages: any[],
    web_messages: any[],
    emails: any[],
    $getMediaInfo
  }){
    this.$messages = [];
    if(rawsObject){
      for(let raw of rawsObject.whatsapp_messages){
        const message: WhatsappMessage = new WhatsappMessage(raw, {
          getAdmin: this.$getAdminById,
          getMediaInfo: rawsObject.$getMediaInfo,
          getTicketById: this.$getTicketById,
        });
        this.$messages.push(message);
      }
      for(let raw of rawsObject.web_messages){
        const message: WebMessage = new WebMessage(raw, {getMediaInfo: rawsObject.$getMediaInfo, getTicketById: this.$getTicketById});
        this.$messages.push(message);
      }
      for(let raw of rawsObject.emails){
        const message: EmailMessage = new EmailMessage(raw, {getMediaInfo: rawsObject.$getMediaInfo, getTicketById: this.$getTicketById});
        this.$messages.push(message);
      }
    }
  }
  
  
  haveContactWithPhone(phone: string){
    return this.$list_phones.includes(phone);
  }
  
  
  haveContactWithEmail(email: string){
    return this.$list_emails.includes(email);
  }
  
  get last_whatsapp(): any {
    return this._last_whatsapp;
  }
  
  set last_whatsapp(value: any) {
    this._last_whatsapp = value;
  }
  
  
  hasSinglePlatform(): boolean | number {
    if(this.sub_chat){
      const single_type = this.sub_chat.filter(elem =>elem.type === 1)
      if(single_type.length === 1){
        return single_type[0].platform_type
      }
    }
    return false;
  }
  
  
  
  
}
