import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
})
export class EmailComponent implements OnInit {

  @Input() email: string;
  
  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {}
  
  dismiss(data?: any) {
    this.modalController.dismiss(data);
  }

}
