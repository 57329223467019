import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ParticipantAdmin} from '../../classes/participantAdmin';
import {LibrarySummary} from '@angular/compiler-cli';
import {ListParsers} from '../../classes/listParsers';
import {ApiService} from '../../services/api.service';
import {TrackerAdmin} from '../../classes/trackerAdmin';
import {DataService} from '../../services/data.service';
import {ViewService} from 'common-lib';

@Component({
  selector: 'app-tracker-element',
  templateUrl: './tracker-element.component.html',
  styleUrls: ['./tracker-element.component.scss'],
})
export class TrackerElementComponent implements OnInit {
  
  @Input() tracker: ParticipantAdmin;
  
  @Output() dxClick = new EventEmitter<any>();
  @Output() sxClick = new EventEmitter<any>();
  
  statusTracker: string;
  constructor(
    private api: ApiService,
    private data: DataService
  ) { }
  
  async ngOnInit() {
    let raw:any = await this.api.getTrackerByImei(this.tracker.imei);
    if(raw && raw.trackerGps){
      const tracker = new TrackerAdmin(raw.trackerGps, {
        getPlatform: this.data.getPlatformByType,
        getTypeTracker: this.data.getTypeTrackerGps,
        getTypeSim: this.data.getTypeSim
      });
      this.statusTracker = ListParsers.statusTrackerGps(tracker.status_trackerGps)
    }
  }

}
