import { Component, OnInit } from '@angular/core';
import {Admin} from '../../classes/admin';
import {DataService} from '../../services/data.service';
import {PopoverController} from '@ionic/angular';

@Component({
  selector: 'app-select-admin',
  templateUrl: './select-admin.component.html',
  styleUrls: ['./select-admin.component.scss'],
})
export class SelectAdminComponent implements OnInit {
  
  title: string;
  bot_section: boolean;
  bot: boolean;
  confirm_assign: boolean;
  assign_type: number;
  admins: Admin[] = []
  
  constructor(
    public data: DataService,
    private popoverCtrl: PopoverController
  ) { }

  ngOnInit() {}
  
  async setAdmin(admin: Admin){
    await this.popoverCtrl.dismiss(admin);
  }
  
  dismiss(data){
    this.popoverCtrl.dismiss(data);
  }

}
