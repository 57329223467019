import { Injectable } from '@angular/core';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class FotaService {

  static fota_configurations: any[];
  static fota_firmwares: any[];
  
  constructor(
    private api: ApiService,
  ) { }
  
  async init(){
    if(FotaService.fota_configurations && FotaService.fota_firmwares) return; // E' già inizializzato
    const res: any = await this.api.getFotaConfigurations();
    if(res?.configuration_files){
      FotaService.fota_configurations = res.configuration_files;
    }
    if(res?.firmware_files){
      FotaService.fota_firmwares = res.firmware_files;
    }
  }
  
}
