import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {JsonEditorComponent, JsonEditorOptions} from 'ang-jsoneditor';
import {AlertController, ModalController, ToastController} from '@ionic/angular';
import {AdvVisitor} from '../../classes/advVisitor';
import {ViewService} from 'common-lib';
import {ApiService} from '../../services/api.service';

@Component({
  selector: 'app-adv-visitor-detail',
  templateUrl: './adv-visitor-detail.component.html',
  styleUrls: ['./adv-visitor-detail.component.scss'],
})
export class AdvVisitorDetailComponent implements OnInit {
  
  @Input() adv_visitor: AdvVisitor;
  @Input() event: any;
  editorOptions: JsonEditorOptions;
  @ViewChild(JsonEditorComponent, { static: false }) editor: JsonEditorComponent;
  managed: boolean;
  
  managed_chips: any = [
    {
      id: 'managed',
      lbl: 'Gestito',
      main_color: '#15a84a',
      sub_color: '#ffffff',
      selected: false
    },
    {
      id: 'unmanaged',
      lbl: 'Da gestire',
      main_color: '#fc2f00',
      sub_color: '#ffffff',
      selected: false
    }
  ];
  managed_chip_selected: any = this.managed_chips[0];
  
  
  constructor(
    private modalCtrl: ModalController,
    public view: ViewService,
    private api: ApiService,
    private toastController: ToastController,
    private alertController: AlertController
    
  ) {}
  
  ngOnInit() {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.indentation = 1;
    this.editorOptions.expandAll = false;
    this.editorOptions.mainMenuBar = true;
    this.editorOptions.statusBar = false;
    this.editorOptions.mode = 'tree';
    this.editorOptions.modes = ['tree', 'code'];
    
    this.managed = this.adv_visitor.managed || false;
    if(this.adv_visitor.managed){
      this.managed_chips[0].selected = true;
      this.managed_chip_selected = this.managed_chips[0]
    }else{
      this.managed_chips[1].selected = true;
      this.managed_chip_selected = this.managed_chips[1]
    }
  }
  
  dismiss(data?: any) {
    this.modalCtrl.dismiss(data);
  }
  
  
  async saveAdv(){
    await this.view.showLoading('Modifica adv...');
    try{
      const res: any = await this.api.patchAdvVisitor(this.adv_visitor._id, this.managed);
      if(res){
        this.adv_visitor.setAllFields(res);
        this.event()
        ViewService.updateView.next();
      }
    }catch(err){
      console.error(err);
      this.view.presentAlert('Errore', 'Impossibile modificare adv', ['Ok']);
    }
    await this.view.hideLoading();
  }
  
  changeManagedAdv(event: any){
    if(event.id){
      switch(event.id){
        case 'managed':
          this.managed = true;
          break;
        case 'unmanaged':
          this.managed = false;
          break;
      }
    }
    this.managed_chip_selected = event
  }
  
  async requestVisitors(){
    //metodo per richiedere la lista di visitors legati all'elemento attuale
    
    //Devo chiamare l'api getVisitors facendo una ricorsione
    
    await this.view.showLoading('Caricamento visitors in corso...')
   
    try{
      const res: any = await this.api.postAdvVisitorMatchContact(this.adv_visitor.cookie, this.adv_visitor.ip, this.adv_visitor._id);
      if(res){
        this.adv_visitor.$visitors = res;
        this.adv_visitor.$visitors_last_update = Date.now()
      }
    }catch(err){
      console.error(err)
      this.view.presentAlert('Errore', 'Impossibile modificare adv', ['Ok']);
    }
  
    await this.view.hideLoading();
  
  }
  
  async copyText(text: string){
    if(text){
      await navigator.clipboard.writeText(text)
      await this.showToast('Testo copiato', 1500, "success")
    }
  }
  
  async showToast(message: string, duration: number, color?: string){
    const toast = await this.toastController.create({
      message: message,
      duration: duration ? duration : 2000,
      color: color ? color : "success"
    });
    
    toast.present();
  }
  
  async patchArchivied(){
    const alert = await this.alertController.create({
      header: 'Archivia contatto',
      message: 'Inserisci la motivazione per l\'archiviazione:',
      inputs: [
        {
          name: 'motivazione',
          type: 'text',
          placeholder: 'Motivazione',
        },
      ],
      buttons: [
        {
          text: 'Annulla',
          role: 'cancel',
        },
        {
          text: 'Conferma',
          handler: async (data) => {
            if (data.motivazione && data.motivazione.trim() !== '') {
              await this.view.showLoading('Modifica contatto in corso...')
              try{
                //chiudo solo la modale, sto cercando di archiviare un contatto, viene prima finalizzato quindi cancellato, le azioni devono essere sull adv finalized
                await this.view.hideLoading();
                this.modalCtrl.dismiss({archivied: true, reason: data.motivazione})
              }catch(err){
                console.error(err);
                this.view.presentAlert('Errore', 'Impossibile modificare adv', ['Ok']);
              }
            } else {
              await this.view.hideLoading();
  
              // Se la motivazione non è stata inserita, mostra un messaggio di errore
              const errorAlert = await this.alertController.create({
                header: 'Errore',
                message: 'Inserisci una motivazione valida.',
                buttons: ['OK'],
              });
              await errorAlert.present();
              
            }
          },
        },
      ],
    });
  
    await alert.present();
    
  }
  
  
}
