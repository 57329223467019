import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { ModalController, NavController, PopoverController, ToastController } from '@ionic/angular';
import { 
  AuthService, 
  ViewService, ViewService as View,
} from 'common-lib';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';
import { TrackerAdmin } from 'src/classes/trackerAdmin';
import {ApiService} from "../../services/api.service";
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DataService } from 'src/services/data.service';
import Activity from 'src/classes/activity';
import Company from 'src/classes/company';
import { ListParsers } from 'src/classes/listParsers';
// import Contact from 'src/classes/contact';

import * as lodash from 'lodash';

@Component({
  selector: 'app-contact-edit',
  templateUrl: './contact-edit.page.html',
  styleUrls: ['./contact-edit.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactEditPage implements OnInit {
  
  cols = [
    {
      css: "",
      prop: "label",
      label: "Nome",
      cellContentTd: "",
      isAscending: true,
    },
    {
      css: "",
      prop: "email",
      label: "Email",
      cellContentTd: "",
      isAscending: true,
    },
    {
      prop: "dial_code",
      label: "Prefisso (dial code)",
      cellContentTd: "select",
      outputOptions: DataService.general_parameter.list_dial_code_phone,
      outputOpt_labelProp: "name",
      outputOpt_valueProp: "dial_code",
      pipe_sortAlphabetically_crescent: true,
      // pipe_sortAlphabetically_disabled: true,
      // selectChanged: this.tableRowTd_selectChanged_v1,
    },
    {
      css: "",
      prop: "partial_phone",
      label: "Phone (senza prefisso)",
      cellContentTd: "",
      isAscending: true,
    },
    // {
    //   css: "",
    //   prop: "whatsapp_phone",
    //   label: "Phone (Whatsapp)",
    //   cellContentTd: "",
    //   isAscending: true,
    // },
    // * these 3 were already commented out
    // {
    //   css: "text-center",
    //   prop: "cliente_balin",
    //   label: "Cliente Balin?",
    //   cellContentTd: "bool",
    //   isAscending: true,
    // },
    // {
    //   css: "text-nowrap",
    //   prop: "azienda",
    //   label: "Azienda",
    //   cellContentTd: "",
    //   isAscending: true,
    // },
    // {
    //   css: "",
    //   prop: "agente",
    //   label: "Agente",
    //   cellContentTd: "",
    //   isAscending: true,
    // },
  ];
  
  initialRow: any = {};
  
  // Data passed in by componentProps
  // @Input() row: Contact;

  listener = {
    data: null,
    view: null,
  };
  
  constructor(
    private cd: ChangeDetectorRef,
    private api: ApiService,
    private data: DataService,
    private modalCtrl: ModalController,
    private auth: AuthService,
    private nav: NavController,
    public popoverController: PopoverController,
    private route: ActivatedRoute,
    private router: Router,
    private toastCtrl: ToastController,
    private view: ViewService,
  ) { }

  ngOnInit() {}
  
  // ionViewDidEnter() {
  //   // this.listenerData();
  //   this.listenerView();
  //   // this.data.getEntityMultiple(this.entity as any);
  //   // this.initSomeDOMelems();
  //   console.log("this.row",this.row)
  //   this.initialRow = lodash.cloneDeep(this.row);
  // }
  //
  // ionViewWillLeave() {
  //   if (this.listener.view) {
  //     this.listener.view.unsubscribe();
  //   }
  // }
  //
  // // * content
  //
  // //  for tests
  // consoleLog(i) {
  //   console.log('i', i)
  //   // console.log('i', DataService.array.activity)
  //   // this.router.navigate(['phonebook-activities']);
  // }
  //
  // dismiss(data?: any) {
  //   // using the injected ModalController this page
  //   // can "dismiss" itself and optionally pass back data
  //   this.modalCtrl.dismiss(data);
  // }
  //
  // async presentToast(msg: string) {
  //   const toast = await this.toastCtrl.create({
  //     message: msg,
  //     duration: 2000,
  //     // position: "middle",
  //   });
  //   toast.present();
  // }
  //
  // async save() {
  //   await this.view.showLoading("salvataggio in corso")
  //   View.updateView.next()
  //
  //   // moved to ApiService
  //   // if (!this.row.dial_code) {
  //   //   this.row.dial_code = undefined;
  //   // }
  //
  //   // fields to send only if they have changed
  //   if (this.row.email === this.initialRow.email) {
  //     this.row.email = undefined;
  //   }
  //   // if (this.row.partial_phone === this.initialRow.partial_phone) {
  //   //   this.row.partial_phone = undefined;
  //   // }
  //   if(this.row.label === this.initialRow.label){
  //     this.row.label = undefined;
  //   }
  //   if (this.row.partial_phone === this.initialRow.partial_phone && this.row.dial_code === this.initialRow.dial_code) {
  //     this.row.partial_phone = undefined;
  //     this.row.dial_code = undefined;
  //   }
  //
  //   let res: any;
  //   try {
  //     // res = await this.api.patchContact(this.row);
  //     res = await this.api.patchContact({
  //       _id: this.row._id,
  //       dial_code: this.row.dial_code,
  //
  //       partial_phone: this.row.partial_phone,
  //       email: this.row.email,
  //       label: this.row.label,
  //       // moved to ApiService
  //       // partial_phone: this.row.partial_phone || "",
  //       // email: this.row.email || "",
  //       // label: this.row.label || "",
  //       // : this.row.,
  //     });
  //     console.log("save() res", res)
  //
  //     let foundElemIndex: number = DataService.array.contact.findIndex(
  //       (el) => el._id === this.row._id
  //     );
  //     // console.log("foundElemIndex",foundElemIndex);
  //     if (foundElemIndex != -1) {
  //       // edit
  //       // DataService.array.company[foundElemIndex] = this.row;
  //       DataService.array.contact[foundElemIndex] = new Contact(res, {getCompanies: this.data.getCompaniesByContactId});
  //       this.presentToast("Salvataggio avvenuto")
  //       this.dismiss({ recordEdited: true });
  //     }
  //   } catch (e) {
  //     console.error(e);
  //     const { error } = e;
  //     // untested
  //     await this.view.modalAlert(
  //       "Errore salvataggio",
  //       `
  //       Tipo: ${error.type}, \n
  //       Descrizione: ${error.description}
  //       `,
  //       "", // icon
  //       ["Ok"],
  //     );
  //   }
  //
  //   await this.view.hideLoading()
  //   View.updateView.next()
  // }
  //
  // // * listener
  //
  // listenerView() {
  //   this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
  //     this.view.pipeChanged++;
  //     this.cd.markForCheck();
  //     setTimeout(() => {
  //       this.cd.markForCheck();
  //     }, 250);
  //   });
  // }
  //
  // // * table methods
  //
  // // unused
  // /* parseDatePickerOutput(row: any, prop: string, ISOString: string) {
  //   console.log("parseDatePickerOutput() date", ISOString)
  //   row[prop] = (new Date(ISOString).getTime()) / 1000;
  //
  //   setTimeout(() => {
  //     console.log("timeout row", row)
  //   }, 250)
  //   // View.updateView.next()
  // } */
  //
  // tableRowClick(row) {
  //   console.log('tableRowClick()', row);
  //   // this.rowClick.emit(row);
  //
  //   // switch (this.model) {
  //   //   case "company":
  //   //     this.router.navigate(['phonebook-activities']);
  //   //     break;
  //
  //   //   default:
  //   //     break;
  //   // }
  // }
  // tableRowTd_btnAction(row) {
  //   console.log('row', row);
  // }
  // tableRowTd_radioClick(row) {
  //   console.log('row', row);
  // }
  //
  // tableRowTd_selectChanged(row) {
  //   console.log('row', row);
  // }
  // // unused
  // /* tableRowTd_selectChanged_v1(
  //   row: any, prop: string, option_valueProp: string | number,
  //   valPropBool?: boolean
  // ) {
  //   // console.log("prop", prop)
  //   console.log("option_valueProp", option_valueProp)
  //   if (valPropBool) {
  //     const ret: boolean = option_valueProp === "true" ? true : false;
  //     row[prop] = ret;
  //   } else {
  //     row[prop] = option_valueProp;
  //   }
  //
  //   setTimeout(() => {
  //     console.log("timeout row", row)
  //   }, 250)
  //   // View.updateView.next()
  // } */
  //
  // tableRowTh_btnAction(col) {
  //   console.log('col', col);
  // }
  
  

}
