import {
  Injectable, Pipe,
  PipeTransform
} from '@angular/core';

/**
 * Pipe che ritorna il colore da mettere al bordo degli item di whatsapp chat per capire lo stato della finestra temporale di comunicazione
 */
@Pipe({
  name: 'colors_chat_expiration'
})
@Injectable()
export class ColorsChatExpirationPipe implements PipeTransform {
  transform(ms: number, ms_warn_state: number, changed?: number): string {
    if(!ms) return 'danger';
    const remaining_ms: number = ((24*60*60*1000) + ms) - Date.now();
    if(remaining_ms > 0){
      if(remaining_ms <= ms_warn_state){
        return 'warning';
      }else{
        return 'success';
      }
    }else{
      return 'danger';
    }
  }
}


