import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Contact} from '../../classes/contact';
import {ViewService} from 'common-lib';

@Component({
  selector: 'app-contact-list-element',
  templateUrl: './contact-list-element.component.html',
  styleUrls: ['./contact-list-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactListElementComponent implements OnInit {
  
  @Input() contact: Contact;
  @Input() selected: boolean;
  
  @Output() dxClick = new EventEmitter<any>();
  @Output() sxClick = new EventEmitter<any>();
  
  listener = {
    view: null
  };
  
  constructor(
    private cd: ChangeDetectorRef,
    public view: ViewService
  ) { }
  
  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }
  
  ngOnInit() {
    this.listenerView();
  }
  
  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
    });
  }

}
